html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
audio,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
mark,
menu,
meter,
nav,
output,
progress,
section,
summary,
time,
video {
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  margin: 0;
  padding: 0;
}

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
blockquote {
  display: block;
}

nav ul {
  list-style: none;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

ul ul {
  list-style: circle;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

ins {
  text-decoration: underline;
}

del {
  text-decoration: line-through;
}

mark {
  background: none;
}

abbr[title],
dfn[title],
acronym[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input[type="submit"],
input[type="button"],
button {
  margin: 0;
  padding: 0;
}

input,
select,
a img {
  vertical-align: middle;
}

b,
strong {
  font-weight: 700;
}

i,
em {
  font-style: italic;
}

html {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  word-break: break-word;
}

p {
  margin-bottom: 20px;
}

ul,
ol {
  margin-left: 35px;
  margin-bottom: 20px;
}

dl {
  margin-bottom: 20px;
}

dl dt {
  font-weight: 700;
  margin-top: 10px;
}

dl dt:first-child {
  margin-top: 0;
}

dl dd {
  margin-left: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin-bottom: 20px;
}

figure {
  max-width: 100%;
}

pre {
  overflow: auto;
  padding: 10px 10px;
  background: #f3f3f3;
  margin-bottom: 20px;
}

.clear {
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  zoom: 1;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.alignnone {
  display: block;
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.corzo-content-area p img.alignright {
  clear: right;
}

.alignleft {
  float: left;
  margin: 5px 40px 10px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  text-align: center;
  max-width: 100%;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

figcaption.wp-caption-text {
  margin-top: 20px;
}

figure {
  line-height: 0;
}

.wp-block-image figcaption,
figcaption,
figcaption.wp-caption-text {
  margin-top: 0.7em;
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 1.7;
}

.bypostauthor {
  max-width: 100%;
}

.gallery-caption {
  max-width: 100%;
}

.sticky {
  max-width: 100%;
}

.single-post figure.alignleft {
  margin-right: 30px;
}

.single-format-gallery figcaption.wp-caption-text {
  margin-bottom: 20px;
}

.single-format-gallery figure.gallery-item {
  margin-bottom: 20px;
}

.navigation {
  text-align: right;
  margin-bottom: 30px;
}

.navigation .screen-reader-text {
  display: none;
}

.corzo-body .post-password-form input[type="password"] {
  padding: 14px 20px;
  margin-left: 5px;
  margin-right: 6px;
}

.corzo-body .post-password-form input[type="submit"] {
  padding: 16px 35px 15px;
}

.blocks-gallery-grid,
.wp-block-gallery {
  display: flex;
}

.wp-block-gallery .alignnone {
  margin: 0;
}

figcaption.blocks-gallery-caption {
  margin-bottom: 20px;
  margin-top: 0;
}

.blocks-gallery-item .blocks-gallery-item__caption {
  margin-bottom: 0;
}

body .page-links > a,
body .nav-links > a,
body .nav-links > span.dots {
  background-color: #f0f0f0;
  color: #919191;
}

body .page-links > span,
body .page-links > a:hover,
body .nav-links > span.current,
body .nav-links > a:hover {
  background-color: #3db166;
  color: #fff;
}

.page-links {
  clear: left;
  padding-top: 30px;
  margin-bottom: 20px;
}

.page-links > span,
.page-links > a {
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  padding: 9px 14px;
  margin-right: 7px;
  font-weight: 700;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

body .page-links > span.screen-reader-text {
  display: none;
}

.nav-links {
  clear: left;
}

.nav-links > span,
.nav-links > a {
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  padding: 9px 14px;
  margin-right: 3px;
  font-weight: 700;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

body .nav-links a {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  font-size: 13px;
}

nav.navigation.comment-navigation {
  display: inline-block;
  width: 100%;
}

.nav-links .nav-previous {
  float: left;
}

.nav-links .nav-next {
  float: right;
}

.wp-block-gallery {
  margin-left: 0;
}

.gallery {
  text-align: center;
  margin-bottom: 30px;
}

.gallery:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.gallery-columns-2 .gallery-item {
  float: left;
  width: 50%;
}

.gallery-columns-3 .gallery-item {
  float: left;
  width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  float: left;
  width: 25%;
}

.gallery-columns-5 .gallery-item {
  float: left;
  width: 20%;
}

.gallery-columns-6 .gallery-item {
  float: left;
  width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  float: left;
  width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  float: left;
  width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  float: left;
  width: 11.11%;
}

.gallery-columns-10 .gallery-item {
  float: left;
  width: 10%;
}

.gallery-columns-2 .gallery-item:nth-child(2n + 1) {
  clear: left;
}

.gallery-columns-3 .gallery-item:nth-child(3n + 1) {
  clear: left;
}

.gallery-columns-4 .gallery-item:nth-child(4n + 1) {
  clear: left;
}

.gallery-columns-5 .gallery-item:nth-child(5n + 1) {
  clear: left;
}

.gallery-columns-6 .gallery-item:nth-child(6n + 1) {
  clear: left;
}

.gallery-columns-7 .gallery-item:nth-child(7n + 1) {
  clear: left;
}

.gallery-columns-8 .gallery-item:nth-child(8n + 1) {
  clear: left;
}

.gallery-columns-9 .gallery-item:nth-child(9n + 1) {
  clear: left;
}

.gallery-columns-10 .gallery-item:nth-child(10n + 1) {
  clear: left;
}

cite {
  padding-bottom: 20px;
  display: inline;
}

.wp-block-spacer {
  clear: both;
}

.wp-block-cover {
  margin-top: 5px;
  margin-bottom: 20px;
}

.wp-block-cover,
.corzo-body .wp-block-cover a,
.corzo-body .wp-block-cover a:hover {
  color: #fff;
}

.wp-block-button {
  margin-bottom: 20px;
}

.corzo-body a.wp-block-button__link,
.corzo-body a.wp-block-button__link:hover {
  color: #fff;
}

.is-style-outline .wp-block-button__link {
  border-width: 2px;
  border-style: solid;
  border-color: #32373c;
}

.wp-block-button.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

.corzo-body .is-style-outline a.wp-block-button__link {
  color: #32373c;
}

.wp-block-media-text {
  margin-bottom: 30px;
}

.wp-block-media-text .wp-block-media-text__content {
  padding-top: 20px;
}

.wp-block-embed__wrapper iframe {
  max-width: 100%;
}

hr.wp-block-separator {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

hr.wp-block-separator.is-style-wide {
  width: 100%;
}

html,
body {
  height: 100%;
}

.corzo-body-outer-wrapper {
  min-height: 100%;
}

.corzo-body-outer-wrapper {
  position: relative;
  overflow: hidden;
}

.corzo-body-wrapper.corzo-with-frame {
  position: relative;
}

.corzo-page-wrapper {
  clear: both;
}

body.corzo-boxed .corzo-body-wrapper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

body.corzo-boxed .corzo-body-wrapper.corzo-left {
  margin-left: 0;
}

body.corzo-boxed .corzo-body-wrapper.corzo-right {
  margin-right: 0;
}

body.corzo-boxed.corzo-boxed-border .corzo-body-wrapper {
  box-shadow: 0 -5px 9px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 -5px 9px rgba(0, 0, 0, 0.25);
}

body.corzo-boxed.corzo-background-pattern {
  background-position: center;
  background-repeat: repeat;
}

.corzo-body-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}

.corzo-container {
  margin-left: auto;
  margin-right: auto;
}

.corzo-column-10 {
  float: left;
  width: 16.6666%;
}

.corzo-column-12 {
  float: left;
  width: 20%;
}

.corzo-column-15 {
  float: left;
  width: 25%;
}

.corzo-column-20 {
  float: left;
  width: 33.3333%;
}

.corzo-column-24 {
  float: left;
  width: 40%;
}

.corzo-column-30 {
  float: left;
  width: 50%;
}

.corzo-column-36 {
  float: left;
  width: 60%;
}

.corzo-column-40 {
  float: left;
  width: 66.6666%;
}

.corzo-column-45 {
  float: left;
  width: 75%;
}

.corzo-column-48 {
  float: left;
  width: 80%;
}

.corzo-column-50 {
  float: left;
  width: 83.3333%;
}

.corzo-column-60 {
  float: left;
  width: 100%;
}

.corzo-sidebar-right {
  float: right;
}

.corzo-sidebar-center {
  float: none;
  display: inline-block;
}

.corzo-content-area {
  padding-top: 60px;
  padding-bottom: 40px;
}

.corzo-sidebar-area {
  padding-top: 60px;
}

.corzo-line-height-0 {
  line-height: 0;
}

a,
button {
  text-decoration: none;
  transition: background 300ms, color 300ms, border-color 300ms;
  -moz-transition: background 300ms, color 300ms, border-color 300ms;
  -o-transition: background 300ms, color 300ms, border-color 300ms;
  -webkit-transition: background 300ms, color 300ms, border-color 300ms;
}

img {
  max-width: 100%;
  height: auto;
}

blockquote {
  quotes: none;
  padding: 30px 30px 15px;
  font-size: 17px;
  margin-bottom: 35px;
  font-style: italic;
  margin-top: 35px;
  border-left-width: 2px;
  border-left-style: solid;
}

.corzo-blockquote-style-2 blockquote {
  font-size: 20px;
  font-weight: 800;
  padding-top: 10px;
  padding-left: 35px;
  padding-right: 0;
  font-style: normal;
  padding-bottom: 10px;
  border-left-width: 4px;
}

.corzo-blockquote-style-2 blockquote p {
  margin-bottom: 0;
}

.corzo-blockquote-style-3 .wp-block-quote {
  padding: 20px 0 1px;
  background: transparent;
  border: 0;
}

.corzo-blockquote-style-3 .wp-block-quote:before {
  content: "“";
  float: left;
  font-size: 212px;
  margin-top: -14px;
  height: 50px;
  line-height: 1;
  font-style: normal;
  margin-right: 30px;
}

.corzo-blockquote-style-3 .wp-block-quote p {
  overflow: hidden;
  font-size: 26px;
  font-style: italic;
}

q {
  margin-right: 15px;
  display: inline-block;
  padding: 10px 20px;
  vertical-align: middle;
  border-left-width: 2px;
  border-left-style: solid;
}

.corzo-media-image {
  line-height: 0;
  position: relative;
}

.corzo-media-video video {
  width: 100%;
}

.corzo-left-align {
  text-align: left;
}

.corzo-center-align {
  text-align: center;
}

.corzo-right-align {
  text-align: right;
}

.corzo-body-wrapper {
  z-index: 1;
  position: relative;
}

input,
textarea {
  border-width: 1px 1px 2px 1px;
  border-style: solid;
}

input:focus,
textarea:focus {
  outline: none;
}

.corzo-button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  padding: 15px 33px;
  display: inline-block;
  -webkit-appearance: none;
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
  border-width: 0;
  border-style: solid;
  cursor: pointer;
  transition: background 300ms, color 300ms, border-color 300ms;
  -moz-transition: background 300ms, color 300ms, border-color 300ms;
  -o-transition: background 300ms, color 300ms, border-color 300ms;
  -webkit-transition: background 300ms, color 300ms, border-color 300ms;
}

input[type="button"]:focus,
input[type="submit"]:focus,
input[type="reset"]:focus {
  outline: none;
}

.corzo-page-preload {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  /* background-image: url(../../../../../../../../../themes/corzo/images/preload.gif); */
  background-position: center;
  background-repeat: no-repeat;
}

.corzo-page-preload.corzo-out {
  background-image: none;
}

.corzo-top-bar {
  position: relative;
  border-bottom-style: solid;
}

.corzo-top-bar-background {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.corzo-top-bar-container {
  margin-left: auto;
  margin-right: auto;
}

.corzo-top-bar-container-inner {
  position: relative;
}

.corzo-top-bar-left {
  float: left;
}

.corzo-top-bar-left-text {
  display: inline;
}

.corzo-top-bar-right {
  float: right;
}

.corzo-top-bar-right-text {
  display: inline;
}

.corzo-top-bar-right-social {
  display: inline;
  margin-left: 10px;
}

.corzo-top-bar-right-social a {
  margin-left: 25px;
}

.corzo-top-bar .corzo-top-bar-menu {
  display: inline-block;
  margin-right: 23px;
  margin-bottom: 0;
}

.corzo-top-bar .corzo-top-bar-menu > li {
  float: none;
  display: inline-block;
}

.corzo-top-bar .corzo-top-bar-menu > li > a {
  display: inline;
  margin-right: 16px;
}

.sf-menu.corzo-top-bar-menu > .corzo-normal-menu ul {
  margin-left: 0;
  font-size: 13px;
}

.sf-menu.corzo-top-bar-menu > .corzo-normal-menu:first-child ul {
  margin-left: -17px;
}

.sf-menu.corzo-top-bar-menu > .corzo-normal-menu ul li {
  padding: 0 18px 10px;
}

.sf-menu.corzo-top-bar-menu > .corzo-normal-menu ul li:first-child {
  padding-top: 10px;
}

.sf-menu.corzo-top-bar-menu > .corzo-normal-menu ul li > a {
  background: transparent;
}

.sf-menu.corzo-top-bar-menu > .corzo-normal-menu ul li > a:after {
  margin-left: 10px;
}

.sf-menu.corzo-top-bar-menu > .corzo-mega-menu .sf-mega-full {
  left: 0;
  right: 0;
  padding-top: 18px;
  padding-bottom: 8px;
}

.sf-menu.corzo-top-bar-menu > .corzo-mega-menu .sf-mega-section-inner ul {
  font-size: 13px;
}

.sf-menu.corzo-top-bar-menu > .corzo-mega-menu .sf-mega-section-inner ul li {
  padding: 0 18px 10px;
}

.corzo-top-bar .corzo-top-bar-menu > li {
  line-height: 1.3;
  border-right-width: 1px;
  border-right-style: solid;
  margin-right: 12px;
}

.corzo-top-bar .corzo-top-bar-menu > li:last-child {
  border: none;
  margin-right: 0;
}

.corzo-top-bar .corzo-top-bar-menu > li > a {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.corzo-mm-menu-wrap {
  display: none;
}

.admin-bar .mm-menu {
  margin-top: 32px;
}

.corzo-mobile-button-hamburger-with-border {
  font-size: 16px;
  padding: 6px;
  line-height: 0;
  width: 32px;
  height: 32px;
  text-align: center;
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.corzo-right-menu-button {
  display: inline-block;
  margin-left: 27px;
}

.corzo-mobile-menu {
  float: left;
  margin-top: -8px;
  margin-left: 21px;
}

.corzo-mobile-menu-right {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -9px;
}

.corzo-mobile-menu-right:first-child {
  position: relative;
  float: right;
  margin: 0;
  padding: 8px 0;
}

.corzo-mobile-menu-left {
  position: absolute;
  left: 30px;
  top: 50%;
  margin-top: -9px;
}

.corzo-mobile-menu-left .corzo-mobile-menu {
  margin-left: 0;
}

.corzo-mobile-header .corzo-mobile-logo-center .corzo-logo-inner {
  margin-left: auto;
  margin-right: auto;
}

.corzo-mobile-header.corzo-fixed-navigation .corzo-fixed-nav-logo {
  display: block;
}

.corzo-mobile-header.corzo-fixed-navigation .corzo-orig-logo {
  display: none;
}

.corzo-mobile-button-hamburger {
  box-sizing: border-box;
  display: block;
  width: 32px;
  height: 32px;
  position: relative;
}

.corzo-mobile-button-hamburger:before,
.corzo-mobile-button-hamburger:after,
.corzo-mobile-button-hamburger span {
  content: "";
  display: block;
  width: 22px;
  height: 2px;
  position: absolute;
  left: 5px;
}

.corzo-mobile-button-hamburger:before {
  top: 8px;
}

.corzo-mobile-button-hamburger span {
  top: 15px;
}

.corzo-mobile-button-hamburger:after {
  top: 22px;
}

.corzo-mobile-button-hamburger:before {
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.corzo-mobile-button-hamburger span {
  transition: opacity 0.15s 0.4s ease;
}

.corzo-mobile-button-hamburger:after {
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.corzo-mobile-button-hamburger.corzo-active:before {
  -webkit-transform: translate3d(0, 7px, 0) rotate(135deg);
  transform: translate3d(0, 7px, 0) rotate(135deg);
  transition-delay: 0.1s;
}

.corzo-mobile-button-hamburger.corzo-active span {
  transition-delay: 0s;
  opacity: 0;
}

.corzo-mobile-button-hamburger.corzo-active:after {
  transition-delay: 0.1s;
  -webkit-transform: translate3d(0, -7px, 0) rotate(-135deg);
  transform: translate3d(0, -7px, 0) rotate(-135deg);
}

.corzo-right-menu-button.corzo-mobile-button-hamburger-small {
  margin-left: 20px;
}

.corzo-mobile-button-hamburger-small {
  box-sizing: border-box;
  display: block;
  width: 32px;
  height: 32px;
  position: relative;
}

.corzo-mobile-button-hamburger-small:before,
.corzo-mobile-button-hamburger-small:after,
.corzo-mobile-button-hamburger-small span {
  content: "";
  display: block;
  width: 16px;
  height: 2px;
  position: absolute;
  left: 7px;
}

.corzo-mobile-button-hamburger-small:before {
  top: 11px;
}

.corzo-mobile-button-hamburger-small span {
  top: 15px;
}

.corzo-mobile-button-hamburger-small:after {
  top: 19px;
  width: 10px;
  transition: width 200ms;
  -moz-transition: width 200ms;
  -o-transition: width 200ms;
  -webkit-transition: width 200ms;
}

.corzo-mobile-button-hamburger-small:hover:after {
  width: 16px;
}

.corzo-navigation .sf-menu {
  display: inline-block;
  line-height: 1.7;
  text-align: left;
  margin-bottom: 0;
}

.corzo-navigation .sf-menu > li:first-child {
  padding-left: 0;
}

.corzo-navigation .sf-menu > li:last-child {
  padding-right: 0;
}

.corzo-navigation .sf-menu > li > a {
  letter-spacing: 0.5px;
}

.corzo-navigation-submenu-indicator .sf-menu > li > a.sf-with-ul-pre:after {
  display: inline-block;
  margin-left: 13px;
  content: "\f107";
  font-family: fontAwesome;
}

.corzo-navigation .sf-menu > .corzo-normal-menu li {
  padding: 1px 11px;
}

.corzo-navigation .sf-menu > .corzo-normal-menu li:first-child {
  padding-top: 12px;
}

.corzo-navigation .sf-menu > .corzo-normal-menu li:last-child {
  padding-bottom: 12px;
}

.corzo-navigation .sf-menu > .corzo-normal-menu li > a {
  display: block;
  padding: 6px 30px 6px 12px;
  position: relative;
}

.corzo-navigation .sf-menu > .corzo-normal-menu li > a.sf-with-ul-pre:after {
  position: absolute;
  right: 10px;
  content: "\f105";
  font-family: fontAwesome;
}

.corzo-navigation .sf-menu > .corzo-normal-menu .sub-menu {
  font-size: 14px;
}

.corzo-navigation .sf-menu > .corzo-mega-menu .sf-mega-full {
  left: 0;
  right: 0;
}

.corzo-navigation .sf-menu > .corzo-mega-menu .sf-mega-section-wrap {
  overflow: hidden;
  border-top-width: 1px;
  border-top-style: solid;
}

.corzo-navigation
  .sf-menu
  > .corzo-mega-menu
  .sf-mega-section-wrap:first-child {
  border-top: none;
}

.corzo-navigation .sf-menu > .corzo-mega-menu .sf-mega-section {
  padding-bottom: 999px;
  margin-bottom: -999px;
  border-left-width: 1px;
  border-left-style: solid;
}

.corzo-navigation .sf-menu > .corzo-mega-menu .sf-mega-section:first-child {
  border-left: none;
}

.corzo-navigation .sf-menu > .corzo-mega-menu .sf-mega-section-inner {
  padding: 31px 28px;
}

.corzo-navigation .sf-menu > .corzo-mega-menu .sf-mega-section-inner > a {
  font-size: 15px;
  display: block;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 0 12px;
}

.corzo-navigation
  .sf-menu
  > .corzo-mega-menu
  .sf-mega-section-inner
  .sub-menu
  a {
  font-size: 14px;
  padding: 5px 12px;
  display: block;
}

.corzo-navigation .corzo-navigation-slide-bar-style-1 {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  position: absolute;
  display: none;
  margin-top: -8px;
  padding-top: 5px;
  overflow: hidden;
}

.corzo-navigation .corzo-navigation-slide-bar-style-1:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  bottom: 0;
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-color: transparent;
}

.corzo-navigation .corzo-navigation-slide-bar-style-2 {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  position: absolute;
  display: none;
  margin-top: -3px;
  overflow: hidden;
}

.corzo-navigation .corzo-navigation-slide-bar-style-dot {
  text-align: center;
  position: absolute;
  display: none;
  margin-top: -6px;
  overflow: hidden;
}

.corzo-navigation .corzo-navigation-slide-bar-style-dot:before {
  content: " ";
  display: block;
  width: 6px;
  margin: 0;
  border-bottom-width: 3px;
  border-top-width: 3px;
  border-style: solid;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.corzo-navigation .corzo-navigation-slide-bar-style-dot-center {
  text-align: center;
  position: absolute;
  display: none;
  margin-top: -6px;
  overflow: hidden;
}

.corzo-navigation .corzo-navigation-slide-bar-style-dot-center:before {
  content: " ";
  display: block;
  width: 6px;
  margin: 0 auto;
  border-bottom-width: 3px;
  border-top-width: 3px;
  border-style: solid;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.corzo-navigation .sf-menu a i {
  margin-right: 10px;
}

.corzo-navigation .sf-vertical > li {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.corzo-navigation .sf-vertical > li:first-child {
  padding-top: 0;
}

.corzo-navigation .sf-vertical > li > a {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: block;
}

.corzo-navigation .sf-vertical ul.sub-menu {
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

.corzo-navigation .sf-vertical ul.sub-menu li {
  padding: 2px 11px;
}

.corzo-navigation .sf-vertical ul.sub-menu li:first-child {
  padding-top: 20px;
}

.corzo-navigation .sf-vertical ul.sub-menu li:last-child {
  padding-bottom: 20px;
}

.corzo-navigation .sf-vertical ul.sub-menu li > a {
  display: block;
  padding: 4px 12px;
}

.corzo-navigation .sf-vertical ul.sub-menu li > a.sf-with-ul:after,
.corzo-navigation-submenu-indicator .sf-vertical > li > a.sf-with-ul:after {
  float: right;
  margin-left: 18px;
  content: "\f105";
  font-family: fontAwesome;
}

.corzo-bullet-anchor {
  position: fixed;
  top: 50%;
  right: 30px;
  margin-top: -50px;
  z-index: 10;
  opacity: 0;
  transition: opacity 200ms;
  -moz-transition: opacity 200ms;
  -o-transition: opacity 200ms;
  -webkit-transition: opacity 200ms;
}

.corzo-bullet-anchor.corzo-init {
  opacity: 1;
}

.corzo-bullet-anchor a {
  display: block;
  width: 17px;
  height: 17px;
  margin-bottom: 5px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.corzo-bullet-anchor a:before {
  content: " ";
  width: 9px;
  height: 9px;
  margin: 3px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  transition: background-color 200ms;
  -moz-transition: background-color 200ms;
  -o-transition: background-color 200ms;
  -webkit-transition: background-color 200ms;
}

.corzo-breadcrumbs a {
  color: silver;
}

.corzo-breadcrumbs,
.corzo-breadcrumbs a:hover {
  color: #777;
}

.corzo-breadcrumbs {
  font-size: 14px;
  padding: 17px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.corzo-breadcrumbs-item > span {
  margin: 0 22px;
}

.corzo-breadcrumbs-item > span:first-child {
  margin-left: 0;
}

.corzo-breadcrumbs-item a span {
  transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
  -webkit-transition: color 200ms;
}

.corzo-top-search-wrap input.search-field {
  border-bottom-color: #303030;
  color: #fff;
}

.corzo-top-search-wrap .corzo-top-search-submit,
.corzo-top-search-wrap .corzo-top-search-close {
  color: #fff;
}

.corzo-top-search-wrap input::-webkit-input-placeholder {
  color: #fff;
}

.corzo-top-search-wrap input::-moz-placeholder {
  color: #fff;
}

.corzo-top-search-wrap input:-ms-input-placeholder {
  color: #fff;
}

.corzo-top-search-wrap input:-moz-placeholder {
  color: #fff;
}

.corzo-top-search-row {
  display: table;
  width: 100%;
  height: 100%;
}

.corzo-top-search-cell {
  display: table-cell;
  vertical-align: middle;
  padding-left: 30px;
  padding-right: 30px;
}

.corzo-top-search-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  display: none;
}

.corzo-top-search-wrap form {
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.corzo-top-search-wrap input.search-field {
  background: transparent;
  width: 100%;
  border-width: 0 0 3px;
  font-size: 40px;
  padding: 0 120px 25px 0;
  line-height: 48px;
  letter-spacing: 0.5px;
}

.corzo-top-search-wrap input.search-submit {
  position: absolute;
  top: 0;
  right: 52px;
  width: 48px;
  height: 48px;
  padding: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.corzo-top-search-wrap .corzo-top-search-submit {
  font-size: 30px;
  position: absolute;
  right: 52px;
  top: 0;
  line-height: 48px;
  width: 48px;
  text-align: center;
}

.corzo-top-search-wrap .corzo-top-search-close {
  font-size: 55px;
  position: absolute;
  right: 0;
  top: -4px;
  line-height: 48px;
  width: 48px;
  text-align: center;
  cursor: pointer;
}

.corzo-overlay-menu-row {
  display: table;
  width: 100%;
  height: 100%;
}

.corzo-overlay-menu-cell {
  display: table-cell;
  vertical-align: middle;
}

.corzo-overlay-menu-content {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  display: none;
}

.corzo-overlay-menu-content ul.menu {
  list-style: none;
  text-align: center;
  padding-top: 180px;
  padding-bottom: 140px;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.corzo-overlay-menu-content ul.menu > li {
  font-size: 23px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
  margin-bottom: 17px;
  padding-bottom: 17px;
  letter-spacing: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
  transition: opacity 300ms, transform 300ms;
  -moz-transition: opacity 300ms, -moz-transform 300ms;
  -o-transition: opacity 300ms, -o-transform 300ms;
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
}

.corzo-overlay-menu-content.corzo-active ul.menu > li {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: none;
  transform: none;
}

.corzo-overlay-menu-content ul.sub-menu {
  display: none;
  padding-top: 20px;
  padding-bottom: 5px;
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}

.corzo-overlay-menu-content ul.sub-menu > li {
  font-size: 15px;
  letter-spacing: 0;
  text-transform: none;
  font-weight: 400;
  margin-bottom: 15px;
}

.corzo-overlay-menu-content ul.sub-menu ul.sub-menu {
  max-width: 380px;
  margin: 20px auto 20px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
}

.corzo-overlay-menu-content .corzo-overlay-menu-close {
  line-height: 1;
  font-size: 48px;
  position: absolute;
  left: 50%;
  top: 75px;
  margin-left: -24px;
  cursor: pointer;
}

.corzo-overlay-menu-content .corzo-overlay-menu-close:after {
  content: "\4d";
  font-family: elegantIcons;
}

.corzo-overlay-menu-content ul li.menu-item-has-children > a:after {
  content: "\f107";
  font-family: "fontAwesome";
  margin-left: 15px;
}

body.admin-bar .corzo-overlay-menu-content {
  margin-top: 32px;
  overflow-y: scroll;
}

.corzo-main-menu-right-button {
  float: left;
  padding: 8px 15px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: -11px;
  margin-left: 30px;
}

.corzo-main-menu-right-button.corzo-style-round {
  padding: 10px 26px;
  margin-top: -14px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

.corzo-main-menu-right-button.corzo-style-round-with-shadow {
  padding: 13px 21px 14px;
  margin-top: -14px;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11);
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

.corzo-main-menu-right {
  float: left;
  margin-top: -7px;
}

.corzo-main-menu-search {
  float: left;
  margin-top: -5px;
  margin-left: 25px;
}

.corzo-main-menu-search i {
  font-size: 14px;
  margin-top: -1px;
  cursor: pointer;
}

.corzo-main-menu-cart {
  float: left;
  margin-top: -4px;
  padding-left: 15px;
  margin-left: 5px;
  position: relative;
}

.corzo-main-menu-cart i {
  font-size: 16px;
  margin-top: -1px;
  cursor: pointer;
}

.corzo-main-menu-cart > .corzo-top-cart-count {
  font-size: 10px;
  font-weight: 700;
  vertical-align: super;
  width: 16px;
  height: 16px;
  line-height: 15px;
  position: absolute;
  top: -5px;
  right: -8px;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.corzo-logo {
  position: relative;
}

.corzo-header-wrap {
  position: relative;
  z-index: 103;
}

.corzo-header-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.corzo-header-container-inner {
  position: relative;
}

.corzo-header-style-plain {
  position: relative;
  border-bottom-style: solid;
  z-index: 101;
}

.corzo-header-style-plain .corzo-navigation {
  line-height: 0;
}

.corzo-header-style-plain .corzo-header-background {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.corzo-header-background-transparent {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
}

.corzo-header-style-plain.corzo-style-menu-left .corzo-logo {
  float: left;
}

.corzo-header-style-plain.corzo-style-menu-left .corzo-navigation {
  overflow: hidden;
}

.corzo-header-style-plain.corzo-style-menu-left .corzo-main-menu {
  float: left;
  vertical-align: top;
}

.corzo-header-style-plain.corzo-style-menu-left .corzo-main-menu-right-wrap {
  float: right;
  line-height: 1.7;
  margin-left: 10px;
  vertical-align: top;
}

.corzo-header-style-plain.corzo-style-menu-left .corzo-main-menu-right-button {
  margin-left: 0;
}

.corzo-header-style-plain.corzo-style-menu-left
  .corzo-main-menu-right-button.corzo-button-2 {
  margin-left: 10px;
}

.corzo-header-style-plain.corzo-style-menu-left .corzo-main-menu-search,
.corzo-header-style-plain.corzo-style-menu-left .corzo-main-menu-cart {
  display: inline-block;
  float: none;
  vertical-align: top;
  margin-top: 0;
  line-height: 1.4;
}

.corzo-header-style-plain.corzo-style-menu-right .corzo-logo {
  float: left;
}

.corzo-header-style-plain.corzo-style-menu-right .corzo-navigation {
  float: right;
}

.corzo-header-style-plain.corzo-style-menu-right .corzo-main-menu {
  display: inline-block;
  vertical-align: top;
}

.corzo-header-style-plain.corzo-style-menu-right .corzo-main-menu-right-wrap {
  display: inline-block;
  line-height: 1.7;
  margin-left: 10px;
  vertical-align: top;
}

.corzo-header-style-plain.corzo-style-center-logo .corzo-logo-inner {
  margin-left: auto;
  margin-right: auto;
}

.corzo-header-style-plain.corzo-style-center-logo .corzo-navigation {
  text-align: center;
  position: relative;
}

.corzo-header-style-plain.corzo-style-center-logo .corzo-main-menu {
  display: inline-block;
  vertical-align: top;
}

.corzo-header-style-plain.corzo-style-center-logo .corzo-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.corzo-header-style-plain.corzo-style-center-menu .corzo-logo {
  position: absolute;
}

.corzo-header-style-plain.corzo-style-center-menu .corzo-navigation {
  text-align: center;
}

.corzo-header-style-plain.corzo-style-center-menu .corzo-main-menu {
  display: inline-block;
  vertical-align: top;
}

.corzo-header-style-plain.corzo-style-center-menu .corzo-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.corzo-header-style-plain.corzo-style-splitted-menu .corzo-logo {
  padding-top: 0;
  padding-bottom: 0;
}

.corzo-header-style-plain.corzo-style-splitted-menu .corzo-navigation {
  text-align: center;
}

.corzo-header-style-plain.corzo-style-splitted-menu .corzo-main-menu {
  display: inline-block;
  vertical-align: top;
}

.corzo-header-style-plain.corzo-style-splitted-menu
  .corzo-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.corzo-header-style-plain.corzo-style-splitted-menu .corzo-main-menu-left-wrap {
  position: absolute;
  left: 0;
  line-height: 1.7;
}

.corzo-header-style-plain.corzo-style-splitted-menu .corzo-right-menu-button {
  margin-left: 0;
}

.corzo-header-boxed-wrap {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
}

.corzo-header-style-boxed.corzo-fixed-navigation {
  margin-top: 0;
}

.corzo-header-style-boxed .corzo-header-container-item {
  position: relative;
}

.corzo-header-style-boxed .corzo-navigation {
  line-height: 0;
}

.corzo-header-style-boxed .corzo-header-background {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.corzo-header-style-boxed.corzo-style-menu-right .corzo-logo {
  float: left;
}

.corzo-header-style-boxed.corzo-style-menu-right .corzo-navigation {
  float: right;
}

.corzo-header-style-boxed.corzo-style-menu-right .corzo-main-menu {
  display: inline-block;
  vertical-align: top;
}

.corzo-header-style-boxed.corzo-style-menu-right .corzo-main-menu-right-wrap {
  display: inline-block;
  line-height: 1.7;
  margin-left: 10px;
  vertical-align: top;
}

.corzo-header-style-boxed.corzo-style-center-menu .corzo-logo {
  position: absolute;
}

.corzo-header-style-boxed.corzo-style-center-menu .corzo-navigation {
  text-align: center;
}

.corzo-header-style-boxed.corzo-style-center-menu .corzo-main-menu {
  display: inline-block;
  vertical-align: top;
}

.corzo-header-style-boxed.corzo-style-center-menu .corzo-main-menu-right-wrap {
  position: absolute;
  line-height: 1.7;
}

.corzo-header-style-boxed.corzo-style-splitted-menu .corzo-logo {
  padding-top: 0;
  padding-bottom: 0;
}

.corzo-header-style-boxed.corzo-style-splitted-menu .corzo-navigation {
  text-align: center;
}

.corzo-header-style-boxed.corzo-style-splitted-menu .corzo-main-menu {
  display: inline-block;
  vertical-align: top;
}

.corzo-header-style-boxed.corzo-style-splitted-menu
  .corzo-main-menu-right-wrap {
  position: absolute;
  line-height: 1.7;
}

.corzo-header-style-boxed.corzo-style-splitted-menu .corzo-main-menu-left-wrap {
  position: absolute;
  line-height: 1.7;
}

.corzo-header-style-boxed.corzo-style-splitted-menu .corzo-right-menu-button {
  margin-left: 0;
}

.corzo-header-style-bar .corzo-logo {
  float: left;
}

.corzo-header-style-bar .corzo-logo-right-text {
  float: right;
  text-align: right;
  font-size: 13px;
}

.corzo-header-style-bar .corzo-logo-right-text-content {
  font-weight: 500;
}

.corzo-header-style-bar .corzo-logo-right-text-image {
  float: left;
  margin-right: 20px;
  margin-top: 10px;
}

.corzo-header-style-bar .corzo-logo-right-text-inner {
  overflow: hidden;
  font-size: 17px;
  text-align: left;
}

.corzo-header-style-bar .corzo-header-container-inner {
  position: relative;
}

.corzo-navigation-bar-wrap {
  position: relative;
}

.corzo-navigation-bar-wrap.corzo-style-transparent {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
}

.corzo-navigation-bar-wrap .corzo-navigation {
  line-height: 0;
  position: relative;
}

.corzo-navigation-bar-wrap .corzo-navigation-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.corzo-navigation-bar-wrap .corzo-navigation-container {
  position: relative;
}

.corzo-navigation-bar-wrap .corzo-main-menu {
  display: inline-block;
  vertical-align: top;
}

.corzo-navigation-bar-wrap.corzo-style-left .corzo-main-menu-right-wrap {
  float: right;
  line-height: 1.7;
  margin-left: 10px;
}

.corzo-navigation-bar-wrap.corzo-style-center {
  text-align: center;
}

.corzo-navigation-bar-wrap.corzo-style-center .corzo-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.corzo-header-style-bar.corzo-style-center .corzo-logo {
  float: none;
}

.corzo-header-style-bar.corzo-style-center .corzo-logo-inner {
  margin-left: auto;
  margin-right: auto;
}

.corzo-header-style-bar.corzo-style-center .corzo-logo-right-text {
  position: absolute;
  top: 0;
  right: 0;
}

.corzo-header-style-bar.corzo-style-2 .corzo-header-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.corzo-navigation-header-style-bar.corzo-style-2 {
  border: 0;
}

.corzo-navigation-header-style-bar.corzo-style-2 .corzo-main-menu {
  padding: 0 25px;
}

.corzo-navigation-header-style-bar.corzo-style-2 .corzo-main-menu-right-wrap {
  padding: 0 25px;
}

.corzo-navigation-header-style-bar.corzo-style-2 {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.corzo-navigation-header-style-bar.corzo-style-2.corzo-fixed-navigation
  .corzo-navigation-container {
  position: static;
}

.corzo-navigation-header-style-bar.corzo-style-2.corzo-fixed-navigation
  .corzo-navigation-background {
  left: 0;
  right: 0;
  margin-left: 0;
  margin-right: 0;
}

.corzo-header-side-content {
  position: relative;
  z-index: 98;
}

.corzo-header-side-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.corzo-header-side-nav.corzo-allow-slide {
  position: absolute;
  bottom: auto;
}

.corzo-header-side-nav.corzo-fix-bottom {
  position: fixed;
  top: auto;
  bottom: 0;
}

.corzo-header-side-nav.corzo-fix-top {
  position: fixed;
}

.corzo-header-side-nav.corzo-style-left {
  left: 0;
}

.corzo-header-side-nav.corzo-style-right {
  right: 0;
}

.corzo-header-side-nav .corzo-pos-bottom,
.corzo-header-side-nav.corzo-style-middle .corzo-pos-middle {
  opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.corzo-header-side-nav .corzo-pos-bottom.corzo-active,
.corzo-header-side-nav.corzo-style-middle .corzo-pos-middle.corzo-active {
  opacity: 1;
  filter: alpha(opacity=100);
}

.corzo-header-side-nav.corzo-style-side .corzo-header-social {
  padding-top: 20px;
  padding-bottom: 40px;
}

.corzo-header-side-nav.corzo-style-side .corzo-header-social a {
  margin-left: 25px;
}

.corzo-header-side-nav.corzo-style-side .corzo-header-social a:first-child {
  margin-left: 0;
}

.corzo-header-side-nav.corzo-style-side .corzo-main-menu-right-wrap {
  padding-top: 22px;
}

.corzo-header-side-nav.corzo-style-side .corzo-main-menu-search {
  float: none;
  display: inline-block;
  margin-top: 0;
  margin-left: 20px;
}

.corzo-header-side-nav.corzo-style-side .corzo-main-menu-cart {
  float: none;
  display: inline-block;
  margin-top: 0;
  margin-left: 0;
}

.corzo-header-side-nav.corzo-style-side
  .corzo-main-menu-right-wrap
  > div:first-child {
  margin-left: 0;
}

.corzo-header-side-nav.corzo-style-left .sf-vertical ul.sub-menu {
  text-align: left;
  margin-left: 0;
}

.corzo-header-side-nav.corzo-style-right .sf-vertical ul.sub-menu {
  left: auto;
  right: 100%;
  text-align: right;
}

.corzo-header-side-nav.corzo-style-right
  .sf-vertical
  ul.sub-menu
  li
  > a.sf-with-ul:after {
  content: "\f104";
  float: left;
  margin-left: 0;
  margin-right: 18px;
}

.corzo-header-style-side.corzo-center-align .corzo-logo-inner {
  margin-right: auto;
  margin-left: auto;
}

.corzo-header-style-side.corzo-right-align .corzo-logo-inner {
  margin-right: 0;
  margin-left: auto;
}

.corzo-header-side-nav.corzo-style-side-toggle {
  text-align: center;
}

.corzo-header-side-nav.corzo-style-side-toggle .corzo-logo-inner {
  margin-left: auto;
  margin-right: auto;
}

.corzo-header-side-nav.corzo-style-side-toggle .corzo-main-menu-search,
.corzo-header-side-nav.corzo-style-side-toggle .corzo-main-menu-cart {
  float: none;
  margin-bottom: 8px;
  padding-left: 0;
  margin-left: 0;
  margin-top: 0;
}

.corzo-header-side-nav.corzo-style-side-toggle .corzo-header-icon {
  padding-bottom: 20px;
  padding-top: 30px;
}

body.admin-bar .corzo-header-side-nav {
  padding-top: 32px;
}

html.mm-opened body.admin-bar .corzo-header-side-nav {
  padding-top: 0;
}

.corzo-main-menu-right-wrap .corzo-top-bar-right-social {
  float: left;
}

.corzo-logo-inner {
  transition: max-width 200ms;
  -moz-transition: max-width 200ms;
  -o-transition: max-width 200ms;
  -webkit-transition: max-width 200ms;
}

.corzo-logo-inner img {
  width: 100%;
}

.corzo-fixed-nav-logo {
  display: none;
}

.corzo-sticky-navigation.corzo-fixed-navigation .corzo-fixed-nav-logo {
  display: block;
}

.corzo-sticky-navigation.corzo-fixed-navigation .corzo-orig-logo {
  display: none;
}

.corzo-sticky-navigation.corzo-style-fixed .corzo-logo,
.corzo-sticky-navigation.corzo-style-fixed .corzo-navigation,
.corzo-sticky-navigation.corzo-style-fixed .corzo-navigation .sf-menu > li > a {
  transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
  -moz-transition: background 300ms, color 300ms, border-color 300ms,
    padding 200ms;
  -o-transition: background 300ms, color 300ms, border-color 300ms,
    padding 200ms;
  -webkit-transition: background 300ms, color 300ms, border-color 300ms,
    padding 200ms;
}

.corzo-sticky-navigation.corzo-style-fixed .corzo-navigation-top {
  transition: top 200ms;
  -moz-transition: top 200ms;
  -o-transition: top 200ms;
  -webkit-transition: top 200ms;
}

.corzo-sticky-navigation.corzo-style-fixed.corzo-animate-fixed-navigation
  .corzo-logo-inner
  img {
  transition: height 200ms;
  -moz-transition: height 200ms;
  -o-transition: height 200ms;
  -webkit-transition: height 200ms;
}

.corzo-fixed-navigation.corzo-style-fixed,
.corzo-fixed-navigation.corzo-style-slide {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.corzo-fixed-navigation.corzo-style-slide .corzo-logo-inner img {
  max-height: 35px !important;
  width: auto;
}

body.corzo-sticky-navigation-no-logo .corzo-fixed-navigation .corzo-logo {
  display: none;
}

body.corzo-sticky-navigation-no-logo
  .corzo-mobile-header.corzo-fixed-navigation
  .corzo-logo {
  display: block;
}

body.admin-bar .corzo-fixed-navigation.corzo-style-fixed,
body.admin-bar .corzo-fixed-navigation.corzo-style-slide {
  margin-top: 32px;
}

@media screen and (max-width: 782px) {
  body.admin-bar .corzo-fixed-navigation.corzo-style-slide {
    margin-top: 0;
  }
}

.corzo-animate-fixed-navigation.corzo-header-style-plain .corzo-navigation-top {
  top: 30px;
}

.corzo-animate-fixed-navigation.corzo-header-style-plain.corzo-style-splitted-menu
  .corzo-logo {
  margin-top: -10px;
}

.corzo-animate-fixed-navigation.corzo-header-style-plain.corzo-style-splitted-menu
  .corzo-navigation
  .sf-menu
  > li
  > a {
  padding-top: 0;
}

.corzo-animate-fixed-navigation.corzo-header-style-plain.corzo-style-splitted-menu
  .corzo-navigation-top {
  top: 22px;
}

.corzo-animate-fixed-navigation.corzo-header-style-plain.corzo-style-center-logo
  .corzo-logo {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

.corzo-animate-fixed-navigation.corzo-header-style-boxed.corzo-style-splitted-menu
  .corzo-logo {
  margin-top: -10px;
  padding-top: 0;
  padding-bottom: 0;
}

.corzo-animate-fixed-navigation.corzo-header-style-boxed.corzo-style-splitted-menu
  .corzo-navigation
  .sf-menu
  > li
  > a {
  padding-top: 0;
}

.corzo-page-title-wrap {
  /* background-image: url(../../../../../../../../../themes/corzo/images/page-title-background.jpg); */
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  clear: both;
}

.corzo-page-title-wrap .corzo-page-title-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.corzo-page-title-wrap .corzo-page-title-top-gradient {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.corzo-page-title-wrap .corzo-page-title-bottom-gradient {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.corzo-page-title-wrap .corzo-page-title-container {
  position: relative;
}

.corzo-page-title-wrap .corzo-page-title {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.corzo-page-title-wrap .corzo-page-caption-divider {
  width: 80px;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  margin-top: 35px;
}

.corzo-page-title-wrap.corzo-center-align .corzo-page-caption-divider {
  margin-left: auto;
  margin-right: auto;
}

.corzo-page-title-wrap.corzo-right-align .corzo-page-caption-divider {
  margin-right: 0;
  margin-left: auto;
}

.corzo-page-title-wrap.corzo-style-small .corzo-page-title {
  font-size: 37px;
  margin-bottom: 0;
}

.corzo-page-title-wrap.corzo-style-small .corzo-page-caption {
  font-size: 16px;
  margin-bottom: 13px;
}

.corzo-page-title-wrap.corzo-style-small .corzo-page-title-content {
  padding-top: 93px;
  padding-bottom: 87px;
}

.corzo-page-title-wrap.corzo-style-medium .corzo-page-title {
  font-size: 54px;
  margin-bottom: 0;
}

.corzo-page-title-wrap.corzo-style-medium .corzo-page-caption {
  font-size: 19px;
  margin-bottom: 13px;
}

.corzo-page-title-wrap.corzo-style-medium .corzo-page-title-content {
  padding-top: 126px;
  padding-bottom: 116px;
}

.corzo-page-title-wrap.corzo-style-large .corzo-page-title {
  font-size: 75px;
  margin-bottom: 0;
}

.corzo-page-title-wrap.corzo-style-large .corzo-page-caption {
  font-size: 25px;
  margin-bottom: 13px;
}

.corzo-page-title-wrap.corzo-style-large .corzo-page-title-content {
  padding-top: 184px;
  padding-bottom: 168px;
}

.corzo-page-title-wrap.corzo-style-custom .corzo-page-title {
  font-size: 37px;
  margin-bottom: 0;
}

.corzo-page-title-wrap.corzo-style-custom .corzo-page-caption {
  font-size: 16px;
  margin-bottom: 0;
}

.corzo-page-title-event-time {
  margin-top: 12px;
}

.corzo-page-title-event-link {
  margin-top: 12px;
}

.corzo-footer-wrapper {
  background-position: center;
}

.corzo-footer-wrapper .corzo-footer-container {
  overflow: hidden;
}

.corzo-footer-wrapper .corzo-widget-title {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 35px;
}

.corzo-footer-wrapper .corzo-widget-title .gdlr-core-flexslider-nav {
  float: right;
  margin-left: 20px;
}

.corzo-footer-wrapper .widget {
  margin-bottom: 60px;
}

.corzo-footer-wrapper .widget:last-child {
  margin-bottom: 0;
}

.corzo-footer-wrapper.corzo-with-column-divider .corzo-footer-column {
  padding-bottom: 1029px;
  margin-bottom: -1020px;
  border-left: 1px;
  border-style: solid;
  border-color: inherit;
  padding-left: 30px;
  padding-right: 30px;
}

.corzo-footer-wrapper.corzo-with-column-divider
  .corzo-footer-column:first-child {
  border: none;
}

.corzo-fixed-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

body.corzo-boxed .corzo-fixed-footer .corzo-footer-wrapper,
body.corzo-boxed .corzo-fixed-footer .corzo-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.corzo-footer-back-to-top-button {
  position: fixed;
  z-index: 9;
  right: 30px;
  bottom: 30px;
  font-size: 18px;
  line-height: 20px;
  width: 45px;
  height: 45px;
  text-align: center;
  padding: 12px;
  cursor: pointer;
  opacity: 0;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  pointer-events: none;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.corzo-footer-back-to-top-button.corzo-scrolled {
  opacity: 1;
  pointer-events: auto;
}

.corzo-footer-wrapper {
  padding-top: 70px;
}

.corzo-copyright-container {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.corzo-copyright-text {
  text-align: center;
  font-size: 14px;
}

.corzo-copyright-left {
  float: left;
}

.corzo-copyright-right {
  float: right;
}

.corzo-single-article-head-divider {
  width: 100px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  margin-top: 35px;
}

.corzo-single-article.corzo-style .corzo-single-article-title {
  font-size: 39px;
  font-weight: 700;
  margin-bottom: 15px;
}

.corzo-single-article.corzo-style .corzo-single-article-head {
  text-align: left;
}

.corzo-single-article.corzo-style .corzo-blog-info-wrapper {
  margin-bottom: 8px;
}

.corzo-single-article.corzo-style .corzo-excerpt-read-more {
  font-weight: 700;
  margin-top: 25px;
}

.corzo-single-article-date-wrapper {
  float: left;
  width: 58px;
  border-right-width: 1px;
  border-right-style: solid;
  line-height: 1;
  margin-top: 8px;
  margin-right: 30px;
  padding-right: 20px;
  text-align: center;
}

.corzo-single-article-date-wrapper .corzo-single-article-date-day {
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: -4px;
  letter-spacing: 1px;
  white-space: nowrap;
}

.corzo-single-article-date-wrapper .corzo-single-article-date-month {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.corzo-single-article-date-wrapper .corzo-single-article-date-year {
  font-size: 12px;
  margin-top: 4px;
}

.corzo-single-article {
  padding-bottom: 5px;
}

.corzo-single-article .corzo-single-article-content ul,
.corzo-single-article .corzo-single-article-content ol {
  line-height: 2;
}

.corzo-single-article .corzo-single-article-thumbnail {
  margin-bottom: 42px;
}

.corzo-single-article .corzo-single-article-head {
  margin-bottom: 32px;
}

.corzo-single-article .corzo-single-article-title {
  font-size: 37px;
  font-weight: 700;
  margin-bottom: 16px;
  letter-spacing: 0.5px;
}

.corzo-single-article .corzo-single-article-head-right {
  overflow: hidden;
}

.corzo-blog-info-wrapper .corzo-head {
  margin-right: 13px;
  vertical-align: middle;
}

.corzo-blog-info-wrapper .corzo-head i {
  font-size: 15px;
}

.corzo-blog-info-wrapper .corzo-blog-info {
  display: inline-block;
  margin-right: 25px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.corzo-blog-info-wrapper .corzo-blog-info:last-child {
  margin-right: 0;
}

.corzo-sticky-banner {
  line-height: 1;
  display: inline-block;
  letter-spacing: 0.5px;
  padding: 18px 20px 17px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 22px;
}

.corzo-sticky-banner i {
  font-size: 12px;
  margin-right: 10px;
}

.corzo-single-article-thumbnail .corzo-sticky-banner {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
}

body.single-post .corzo-sidebar-style-none {
  margin-left: auto;
  margin-right: auto;
}

body.blog .corzo-sidebar-style-none {
  margin-left: auto;
  margin-right: auto;
}

body.blog .corzo-single-article,
body.search .corzo-single-article,
body.archive .corzo-single-article {
  padding-bottom: 60px;
}

.corzo-archive-taxonomy-description {
  margin-bottom: 60px;
}

.corzo-blog-aside-format .corzo-single-article-content {
  font-size: 18px;
  font-style: italic;
  padding: 50px 60px 40px;
}

.corzo-blog-aside-format .corzo-excerpt-read-more {
  display: none;
}

.corzo-blog-quote-format .corzo-single-article-content {
  padding: 50px 45px 40px;
  position: relative;
}

.corzo-blog-quote-format .corzo-blog-quote-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.corzo-blog-quote-format .corzo-blog-quote {
  float: left;
  font-size: 120px;
  font-weight: 800;
  margin-top: -10px;
  line-height: 1;
  margin-right: 35px;
  position: relative;
}

.corzo-blog-quote-format .corzo-blog-content-wrap {
  overflow: hidden;
  position: relative;
}

.corzo-blog-quote-format .corzo-blog-quote-content {
  font-size: 18px;
  font-style: italic;
}

.corzo-blog-quote-format .corzo-blog-quote-author {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 20px;
  font-weight: 700;
}

.corzo-blog-quote-format .corzo-blog-quote-author:before {
  content: "-";
  margin-right: 2px;
}

.corzo-blog-quote-format .corzo-excerpt-read-more {
  display: none;
}

.corzo-blog-quote-format blockquote,
.corzo-blog-quote-format q {
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
}

.corzo-blog-link-format .corzo-blog-icon-link {
  font-size: 23px;
  float: left;
  margin-right: 30px;
  margin-top: 5px;
}

.corzo-blog-link-format .corzo-blog-content-wrap {
  overflow: hidden;
}

.corzo-blog-link-format .corzo-blog-title {
  font-size: 20px;
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 9px;
}

.corzo-blog-link-format .corzo-blog-content {
  font-size: 14px;
  margin-bottom: 0;
}

.corzo-blog-link-format .corzo-excerpt-read-more {
  display: none;
}

.corzo-single-social-share {
  padding-top: 10px;
  padding-bottom: 48px;
}

.corzo-single-author {
  text-align: center;
  margin-bottom: 55px;
  margin-top: 41px;
  border-top-width: 1px;
  border-top-style: solid;
}

.corzo-single-author .corzo-single-author-wrap {
  max-width: 80%;
  margin: -41px auto 0;
}

.corzo-single-author .corzo-single-author-avartar {
  max-width: 80px;
  margin: 0 auto 37px;
  overflow: hidden;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.corzo-single-author .corzo-single-author-caption {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 9px;
  letter-spacing: 0.5px;
}

.corzo-single-author .corzo-single-author-title {
  font-size: 26px;
  margin-bottom: 22px;
}

.corzo-single-nav-area {
  border-top: 1px;
  border-bottom: 1px;
  border-style: solid;
  padding: 33px 0;
  margin-bottom: 55px;
}

.corzo-single-nav i {
  font-size: 25px;
}

.corzo-single-nav .corzo-text {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
}

.corzo-single-nav-left {
  float: left;
}

.corzo-single-nav-left i {
  margin-right: 15px;
  vertical-align: middle;
}

.corzo-single-nav-right {
  float: right;
}

.corzo-single-nav-right i {
  margin-left: 15px;
  vertical-align: middle;
}

.corzo-single-author-tags {
  margin-bottom: 65px;
  margin-top: -18px;
}

.corzo-single-author-tags a {
  display: inline-block;
  font-size: 12px;
  padding: 5px 13px 4px;
  margin-right: 12px;
  border-width: 2px;
  border-style: solid;
  font-weight: 700;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.corzo-blog-style-1 .corzo-single-related-post-title {
  font-size: 22px;
  margin-bottom: 30px;
  font-weight: 700;
  text-align: left;
  letter-spacing: 0;
  text-transform: none;
}

.corzo-single-related-post-container
  .gdlr-core-blog-grid
  .gdlr-core-blog-title {
  font-size: 17px;
  font-weight: 600;
}

.corzo-single-blog-title-style-4.corzo-single-article-head {
  text-align: center;
  margin-bottom: 0;
}

.corzo-single-blog-title-style-4.corzo-single-article
  .corzo-single-article-title {
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 20px;
}

.corzo-single-blog-title-style-4
  .corzo-blog-info-wrapper
  .corzo-blog-info
  .corzo-head {
  display: none;
}

.corzo-single-blog-title-style-4 .corzo-blog-info-wrapper .corzo-blog-info {
  margin-right: 0;
  text-transform: none;
  font-size: 15px;
  font-weight: 400;
}

.corzo-single-blog-title-style-4
  .corzo-blog-info-wrapper
  .corzo-blog-info
  .corzo-blog-info-sep {
  margin: 0 15px;
}

.corzo-single-blog-title-style-4
  .corzo-blog-info-wrapper
  .corzo-blog-info:first-child
  .corzo-blog-info-sep {
  display: none;
}

.corzo-blog-style-4 .corzo-single-article-thumbnail img {
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.corzo-blog-style-4 .corzo-single-article-thumbnail.corzo-media-gallery img {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.corzo-blog-style-4 .corzo-single-nav-area {
  border-top: 0;
  padding-top: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.corzo-blog-style-4 .corzo-single-nav .corzo-text {
  font-size: 12px;
  letter-spacing: 3px;
  display: block;
  margin-bottom: 9px;
}

.corzo-blog-style-4 .corzo-single-nav-title {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;
}

.corzo-blog-style-4 .corzo-single-nav-left {
  max-width: 50%;
}

.corzo-blog-style-4 .corzo-single-nav-right {
  text-align: right;
  max-width: 50%;
}

.corzo-blog-style-4 .gdlr-core-social-share-item {
  float: left;
}

.corzo-blog-style-4
  .gdlr-core-social-share-item
  .gdlr-core-social-share-count
  .gdlr-core-count {
  font-size: 13px;
  font-weight: 700;
}

.corzo-blog-style-4
  .gdlr-core-social-share-item
  .gdlr-core-social-share-count
  .gdlr-core-suffix {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 3px;
}

.corzo-blog-style-4 .gdlr-core-social-share-item .gdlr-core-divider {
  display: none;
}

.corzo-blog-style-4 .gdlr-core-social-share-item .gdlr-core-social-share-count {
  margin-right: 32px;
}

.corzo-blog-style-4 .corzo-single-social-share {
  border-top-width: 1px;
  border-top-style: solid;
  padding-top: 19px;
}

.corzo-blog-style-4 .corzo-single-magazine-author-tags {
  padding-bottom: 0;
  float: right;
}

.corzo-blog-style-4 .corzo-single-magazine-author-tags a {
  font-size: 11px;
  letter-spacing: 2px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px 17px 10px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}

.corzo-blog-style-4 .corzo-comments-area .comment-author {
  font-size: 16px;
}

.corzo-blog-style-4 section.comment-content {
  font-size: 15px;
}

.corzo-blog-style-4 .corzo-single-related-post-title,
.corzo-blog-style-4 .corzo-comments-area .corzo-comments-title,
.corzo-blog-style-4 .corzo-comments-area .comment-reply-title {
  font-size: 22px;
  margin-bottom: 30px;
  font-weight: 700;
  text-align: left;
  letter-spacing: 0;
  text-transform: none;
}

.corzo-blog-style-4 .corzo-single-author {
  border-top: none;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 45px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.corzo-blog-style-4 .corzo-single-author .corzo-single-author-avartar {
  float: left;
  margin-bottom: 0;
  margin-right: 33px;
}

.corzo-blog-style-4 .corzo-single-author .corzo-single-author-wrap {
  max-width: 100%;
  margin: 0;
}

.corzo-blog-style-4 .corzo-single-author-content-wrap {
  overflow: hidden;
  text-align: left;
}

.corzo-blog-style-4 .corzo-single-author .corzo-single-author-title {
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 700;
}

.corzo-blog-style-4 .corzo-comments-area {
  border-top-width: 1px;
  border-top-style: solid;
  padding-top: 45px;
  margin-top: 5px;
}

.corzo-blog-style-4 .corzo-comments-area .comment-time {
  font-style: normal;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.corzo-blog-style-4 .corzo-comments-area .edit-link,
.corzo-blog-style-4 .corzo-comments-area .comment-reply {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}

.corzo-blog-style-4 .corzo-comments-area .comment-respond {
  background: transparent;
  padding: 10px 0 30px;
  margin-bottom: 40px;
}

.corzo-blog-style-4 .corzo-comments-area textarea {
  border-bottom-width: 1px;
}

.corzo-blog-style-4 .corzo-comments-area input[type="text"] {
  border-bottom-width: 1px;
}

.corzo-blog-style-4 .corzo-comments-area .corzo-comment-form-author {
  width: 33.33%;
}

.corzo-blog-style-4 .corzo-comments-area .corzo-comment-form-email {
  width: 33.33%;
  padding-right: 7px;
}

.corzo-blog-style-4 .corzo-comment-form-url {
  width: 33.33%;
  float: left;
  padding-left: 7px;
}

.corzo-blog-style-4 .corzo-comments-area .form-submit {
  text-align: left;
}

.corzo-blog-style-4 .corzo-comments-area .form-submit input[type="submit"] {
  font-size: 12px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

.corzo-blog-style-4
  .corzo-single-related-post-wrap
  .gdlr-core-blog-grid
  .gdlr-core-blog-title {
  margin-bottom: 6px;
  font-weight: 700;
}

.corzo-blog-style-4 .corzo-button,
.corzo-blog-style-4 input[type="button"],
.corzo-blog-style-4 input[type="submit"],
.corzo-blog-style-4 input[type="reset"] {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  padding: 21px 35px 23px;
}

.corzo-blog-title-wrap
  .corzo-single-blog-title-style-2
  .corzo-single-article-title {
  margin-bottom: 0;
  margin-top: 5px;
}

.corzo-single-blog-title-style-2
  .corzo-blog-info-wrapper
  .corzo-blog-info
  .corzo-head {
  display: none;
}

.corzo-single-blog-title-style-2 .corzo-blog-info-wrapper .corzo-blog-info {
  margin-right: 0;
  text-transform: none;
  font-size: 13px;
}

.corzo-single-blog-title-style-2
  .corzo-blog-info-wrapper
  .corzo-blog-info
  .corzo-blog-info-sep {
  margin: 0 15px;
}

.corzo-single-blog-title-style-2
  .corzo-blog-info-wrapper
  .corzo-blog-info:first-child
  .corzo-blog-info-sep {
  display: none;
}

.corzo-blog-style-2 .corzo-single-author .corzo-single-author-wrap {
  margin: 0;
}

.corzo-blog-style-2 .corzo-single-author {
  border-top-width: 0;
  margin-top: 0;
  text-align: left;
}

.corzo-blog-style-2 .corzo-single-author .corzo-single-author-avartar {
  margin: 0;
  float: left;
  margin-right: 30px;
  max-width: 100px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.corzo-blog-style-2 .corzo-single-author .corzo-single-author-caption {
  display: none;
}

.corzo-blog-style-2 .corzo-single-author .corzo-single-author-content-wrap {
  overflow: hidden;
  padding-top: 12px;
}

.corzo-blog-style-2 .corzo-single-author .corzo-single-author-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.corzo-blog-style-2 .gdlr-core-social-share-item {
  text-align: left;
}

.corzo-blog-style-2 .gdlr-core-social-share-item .gdlr-core-social-share-count {
  margin-right: 25px;
  font-weight: 700;
}

.corzo-blog-style-2
  .gdlr-core-social-share-item
  .gdlr-core-social-share-count
  .gdlr-core-count {
  font-size: 16px;
}

.corzo-blog-style-2
  .gdlr-core-social-share-item
  .gdlr-core-social-share-count
  .gdlr-core-suffix {
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
}

.corzo-blog-style-2
  .gdlr-core-social-share-item
  .gdlr-core-social-share-count
  .gdlr-core-divider {
  display: none;
}

.corzo-blog-style-2 .corzo-single-article {
  padding-bottom: 0;
}

.corzo-blog-style-2 .corzo-single-nav-area {
  border: none;
  padding: 30px 0 0;
  margin-bottom: 0;
}

.corzo-blog-style-2 .corzo-single-nav-area a,
.corzo-blog-style-2 .corzo-single-nav-area a:hover {
  color: #fff;
}

.corzo-blog-style-2 .corzo-single-nav-area-left {
  float: left;
  width: 50%;
  padding: 50px 80px;
  position: relative;
  overflow: hidden;
}

.corzo-blog-style-2 .corzo-single-nav-area-right {
  float: left;
  width: 50%;
  padding: 50px 80px;
  position: relative;
  overflow: hidden;
}

.corzo-blog-style-2 .corzo-single-nav-area-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: transform 300ms ease-out;
  -moz-transition: transform 300ms ease-out;
  -o-transition: transform 300ms ease-out;
  -webkit-transition: transform 300ms ease-out;
}

.corzo-blog-style-2
  .corzo-single-nav-area-left:hover
  .corzo-single-nav-area-background,
.corzo-blog-style-2
  .corzo-single-nav-area-right:hover
  .corzo-single-nav-area-background {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.corzo-blog-style-2 .corzo-single-nav-left i {
  font-size: 20px;
}

.corzo-blog-style-2 .corzo-single-nav-left,
.corzo-blog-style-2 .corzo-single-nav-right {
  float: none;
}

.corzo-blog-style-2 .corzo-single-nav {
  position: relative;
}

.corzo-blog-style-2 .corzo-single-nav .corzo-text {
  letter-spacing: 0;
  text-transform: none;
}

.corzo-blog-style-2 .corzo-single-nav-title {
  font-size: 22px;
  font-weight: 800;
  display: block;
  margin-top: 2px;
}

.corzo-blog-style-2 .corzo-single-nav-area-overlay {
  opacity: 0.7;
  filter: alpha(opacity=62);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.corzo-blog-style-2 .corzo-comment-wrapper {
  padding-top: 80px;
}

.corzo-blog-style-2 .corzo-comment-content {
  margin: 0 auto;
}

.corzo-blog-style-2 .corzo-comments-area .corzo-comments-title {
  text-align: left;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 800;
  font-size: 20px;
}

.corzo-blog-style-2 .corzo-comments-area .comment-avatar {
  max-width: 90px;
  border-radius: 3px;
}

.corzo-blog-style-2 .corzo-comments-area .comment-author {
  font-size: 18px;
}

.corzo-blog-style-2 .corzo-comments-area .comment-time {
  font-size: 13px;
  font-style: normal;
}

.corzo-blog-style-2 .corzo-comments-area .comment-content {
  font-size: 15px;
}

.corzo-blog-style-2 .corzo-comments-area .comment-meta {
  margin-bottom: 16px;
}

.corzo-blog-style-2 .corzo-comments-area .comment-reply {
  text-transform: none;
  font-weight: 800;
  letter-spacing: 0;
}

.corzo-blog-style-2 .corzo-comments-area .comment-article {
  padding-bottom: 0;
  border-bottom-width: 0;
}

.corzo-blog-style-2 .corzo-comments-area .comment-reply-title {
  font-size: 20px;
  letter-spacing: 0;
  text-align: left;
  text-transform: none;
  font-weight: 800;
  margin-bottom: 35px;
}

.corzo-blog-style-2 .corzo-comments-area .comment-respond {
  padding: 0 0 85px;
  background: transparent;
  margin-bottom: 0;
}

.corzo-blog-style-2 .corzo-comments-area textarea {
  font-size: 14px;
  padding: 20px 22px;
  margin-bottom: 22px;
}

.corzo-blog-style-2 .corzo-comments-area input[type="text"] {
  font-size: 14px;
  padding: 20px 22px;
  margin-bottom: 22px;
}

.corzo-blog-style-2 .corzo-comments-area .corzo-comment-form-author {
  width: 33.33%;
  padding-right: 13px;
}

.corzo-blog-style-2 .corzo-comments-area .corzo-comment-form-email {
  width: 33.33%;
  padding-right: 7px;
}

.corzo-blog-style-2 .corzo-comments-area .corzo-comment-form-url {
  width: 33.33%;
  float: left;
  padding-left: 14px;
}

.corzo-blog-style-2 .corzo-comments-area .form-submit {
  text-align: left;
  padding-top: 0;
}

.corzo-blog-style-2 .corzo-comments-area .form-submit input[type="submit"] {
  font-size: 13px;
  text-transform: none;
  padding: 16px 30px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.corzo-blog-style-2 .corzo-single-related-post-wrap {
  padding: 80px 0 50px;
}

.corzo-blog-style-2 .corzo-single-related-post-container {
  padding-left: 80px;
  padding-right: 80px;
}

.corzo-blog-style-2 .corzo-single-related-post-title {
  font-size: 20px;
  margin-bottom: 40px;
  font-weight: 800;
}

.corzo-blog-style-2 .corzo-single-related-post-container {
  margin: 0 auto;
}

.corzo-blog-style-2 .gdlr-core-blog-grid .gdlr-core-blog-title {
  font-size: 21px;
}

.corzo-blog-style-2 .corzo-single-related-post-wrap .gdlr-core-sticky-banner {
  display: none;
}

.corzo-blog-style-3 .corzo-single-nav-area {
  padding-top: 50px;
  padding-bottom: 0;
  border-bottom: 0;
}

.corzo-blog-style-3 .corzo-single-nav-left,
.corzo-blog-style-3 .corzo-single-nav-right {
  max-width: 50%;
}

.corzo-blog-style-3 .corzo-single-nav-right {
  text-align: right;
}

.corzo-blog-style-3 .corzo-single-nav .corzo-text {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 600;
  display: block;
  margin-bottom: 2px;
}

.corzo-blog-style-3 .corzo-single-nav .corzo-single-nav-title {
  font-size: 20px;
  font-weight: 600;
}

.corzo-blog-style-3 .corzo-single-social-share {
  float: right;
  padding-top: 0;
  padding-bottom: 50px;
}

.corzo-blog-style-3 .gdlr-core-social-share-item.gdlr-core-style-round a {
  font-size: 18px;
  width: 40px;
  height: 40px;
  padding: 11px 0;
}

.corzo-blog-style-3
  .gdlr-core-social-share-item
  .gdlr-core-social-share-count
  .gdlr-core-count {
  font-size: 15px;
  margin-right: 6px;
}

.corzo-blog-style-3
  .gdlr-core-social-share-item
  .gdlr-core-social-share-count
  .gdlr-core-suffix {
  font-size: 15px;
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0;
}

.corzo-blog-style-3 .corzo-single-author .corzo-single-author-avartar {
  margin: 0;
  float: left;
  margin-right: 30px;
  max-width: 116px;
}

.corzo-blog-style-3 .corzo-single-author .corzo-single-author-wrap {
  max-width: 100%;
  margin: 0;
}

.corzo-blog-style-3 .corzo-single-author {
  border: none;
  margin-top: 10px;
  margin-bottom: 60px;
  padding: 45px 55px 40px 40px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
}

.corzo-blog-style-3 .corzo-single-author-content-wrap {
  overflow: hidden;
  text-align: left;
}

.corzo-blog-style-3 .corzo-single-author .corzo-single-author-title {
  font-size: 18px;
  margin-bottom: 25px;
}

.corzo-blog-style-3 .corzo-single-author .corzo-single-author-caption {
  font-size: 13px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 2px;
}

.corzo-blog-style-3 .corzo-single-author .corzo-single-author-avartar img {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.corzo-blog-style-3 .corzo-single-magazine-author-tags {
  margin-bottom: 50px;
  padding-bottom: 0;
  float: left;
}

.corzo-blog-style-3 .corzo-comments-area .corzo-comments-title {
  font-size: 25px;
  text-align: left;
  letter-spacing: 0;
  text-transform: none;
  font-weight: 800;
  margin-bottom: 30px;
}

.corzo-blog-style-3 .corzo-comments-area .comment-respond {
  background: transparent;
  padding: 0;
}

.corzo-blog-style-3 .corzo-comments-area textarea,
.corzo-blog-style-3 .corzo-comments-area input[type="text"] {
  border-bottom-width: 1px;
  font-size: 14px;
  font-weight: 500;
}

.corzo-blog-style-3 .corzo-comments-area input[type="checkbox"] {
  vertical-align: middle;
  margin: 0 10px 3px 0;
}

.corzo-blog-style-3 .corzo-comments-area .form-submit {
  text-align: left;
}

.corzo-blog-style-3 .corzo-comments-area .form-submit input[type="submit"] {
  text-transform: none;
  font-size: 14px;
  letter-spacing: 0;
  padding: 15px 33px 19px;
}

.corzo-blog-style-3 .corzo-comments-area .comment-reply-title {
  text-align: left;
  text-transform: none;
  letter-spacing: 0;
  font-size: 28px;
}

.corzo-blog-style-3 .corzo-comments-area .comment-time {
  display: inline;
  margin-right: 12px;
}

.corzo-blog-style-3 .corzo-comments-area .comment-time time {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0;
}

.corzo-blog-style-3 .corzo-comments-area .comment-reply {
  position: static;
  display: inline;
  font-size: 14px;
  letter-spacing: 0;
  text-decoration: underline;
}

.corzo-blog-style-3 .corzo-comments-area .edit-link {
  font-size: 14px;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.corzo-blog-style-3 .corzo-single-related-post-wrap {
  margin-bottom: 5px;
}

.corzo-blog-style-3 .corzo-single-related-post-title {
  font-size: 28px;
  margin-bottom: 40px;
  font-weight: 700;
}

.corzo-blog-style-3
  .corzo-single-related-post-wrap
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  font-size: 13px;
}

.corzo-blog-magazine .corzo-single-article {
  padding-bottom: 0;
}

.corzo-blog-magazine .corzo-single-article-content .corzo-single-social-share {
  padding-bottom: 30px;
}

.corzo-blog-magazine .gdlr-core-social-share-item .gdlr-core-divider {
  display: none;
}

.corzo-blog-magazine
  .gdlr-core-social-share-item
  .gdlr-core-social-share-count {
  margin-left: 20px;
}

.corzo-blog-magazine
  .gdlr-core-social-share-item
  .gdlr-core-social-share-count
  .gdlr-core-count,
.corzo-blog-magazine
  .gdlr-core-social-share-item
  .gdlr-core-social-share-count
  .gdlr-core-suffix {
  font-size: 13px;
  font-weight: 400;
}

.corzo-blog-magazine .corzo-single-social-share {
  padding-top: 0;
  padding-bottom: 23px;
}

.corzo-single-magazine-author-tags {
  padding-bottom: 23px;
}

.corzo-single-magazine-author-tags i {
  margin-right: 20px;
  font-size: 18px;
  vertical-align: middle;
}

.corzo-single-magazine-author-tags a {
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  border-bottom-width: 2px;
  border-style: solid;
  margin-right: 13px;
  margin-bottom: 10px;
}

.corzo-blog-magazine .corzo-single-nav-area {
  margin-top: 60px;
  margin-bottom: 45px;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
}

.corzo-blog-magazine .corzo-single-author {
  border: none;
  text-align: left;
  padding: 40px;
  margin-top: 50px;
}

.corzo-blog-magazine .corzo-single-author .corzo-single-author-wrap {
  margin: 0;
}

.corzo-blog-magazine .corzo-single-author .corzo-single-author-avartar {
  float: left;
  margin-right: 30px;
}

.corzo-blog-magazine .corzo-single-author-content-wrap {
  overflow: hidden;
}

.corzo-blog-magazine .corzo-single-author .corzo-single-author-caption {
  display: none;
}

.corzo-blog-magazine .corzo-single-author .corzo-single-author-title {
  font-size: 16px;
  margin-bottom: 18px;
}

.corzo-blog-magazine .corzo-single-author-description {
  font-size: 15px;
}

.corzo-blog-magazine .corzo-single-nav-left,
.corzo-blog-magazine .corzo-single-nav-right {
  max-width: 50%;
}

.corzo-blog-magazine .corzo-single-nav-right {
  text-align: right;
}

.corzo-blog-magazine .corzo-single-nav-area-divider {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  border-left-width: 1px;
  border-left-style: solid;
}

.corzo-blog-magazine .corzo-single-nav .corzo-text {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  display: block;
  margin-bottom: 8px;
}

.corzo-blog-magazine .corzo-single-nav .corzo-single-nav-title {
  font-size: 15px;
  font-weight: 700;
}

.corzo-blog-magazine
  .corzo-single-related-post-wrap
  .corzo-single-related-post-title {
  font-size: 16px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 10px;
  margin-bottom: 35px;
}

.corzo-blog-magazine .corzo-comment-wrapper {
  margin-top: 45px;
}

.corzo-blog-magazine
  .corzo-single-related-post-wrap
  .gdlr-core-blog-grid
  .gdlr-core-blog-info-wrapper {
  padding-top: 0;
}

.corzo-blog-magazine
  .corzo-single-related-post-wrap
  .gdlr-core-blog-grid
  .gdlr-core-blog-title {
  margin-bottom: 10px;
}

.corzo-blog-magazine
  .corzo-single-related-post-wrap
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  font-size: 11px;
}

.corzo-blog-magazine
  .corzo-single-related-post-wrap
  .gdlr-core-blog-grid-with-frame
  .gdlr-core-blog-grid-frame {
  padding: 20px;
}

.corzo-comments-area .corzo-comments-title {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 55px;
}

.corzo-comments-area ol.comment-list {
  list-style: none;
  margin-bottom: 55px;
  margin-left: 0;
}

.corzo-comments-area ol.comment-list ol {
  margin-left: 50px;
  list-style: none;
}

.corzo-comments-area ol.comment-list .comment-content ol {
  list-style: decimal;
}

.corzo-comments-area .comment-article {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.corzo-comments-area .comment-avatar {
  max-width: 80px;
  margin-right: 35px;
  float: left;
  overflow: hidden;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.corzo-comments-area .comment-avatar img {
  display: block;
}

.corzo-comments-area .comment-meta {
  position: relative;
  margin-bottom: 20px;
}

.corzo-comments-area .comment-author {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 5px;
}

.corzo-comments-area .comment-body {
  overflow: hidden;
}

.corzo-comments-area .comment-time {
  font-size: 14px;
  font-style: italic;
}

.corzo-comments-area .comment-reply {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
  position: absolute;
  right: 0;
  top: 4px;
}

.corzo-comments-area .comment-respond {
  padding: 0;
  margin-bottom: 85px;
}

.corzo-comments-area ol.comment-list .comment-respond {
  margin-top: 55px;
}

.corzo-comments-area .comment-reply-title {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 40px;
}

.corzo-comments-area .comment-reply-title small {
  margin-left: 10px;
}

.corzo-comments-area textarea {
  width: 100%;
  padding: 18px 22px;
  display: block;
  font-size: 14px;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  margin: 0;
  margin-bottom: 14px;
}

.corzo-comments-area input[type="text"] {
  font-size: 14px;
  width: 100%;
  display: block;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  padding: 16px 22px;
  margin-bottom: 14px;
}

.corzo-comments-area input[type="text"]:focus,
.corzo-comments-area textarea:focus {
  outline: none;
}

.corzo-comments-area .corzo-comment-form-author {
  width: 50%;
  padding-right: 7px;
  float: left;
}

.corzo-comments-area .corzo-comment-form-email {
  width: 50%;
  padding-left: 7px;
  float: left;
}

.corzo-comments-area .form-submit {
  text-align: center;
  padding-top: 14px;
}

.corzo-blog-title-wrap {
  /* background-image: url(../../../../../../../../../themes/corzo/images/page-title-background.jpg); */
  background-position: center;
  position: relative;
  background-size: cover;
  overflow: hidden;
}

.corzo-blog-title-wrap .corzo-blog-title-container {
  position: relative;
}

.corzo-blog-title-wrap .corzo-blog-title-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
}

.corzo-blog-title-wrap.corzo-feature-image .corzo-blog-title-top-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 413px;
  opacity: 1;
  /* background: url(../../../../../../../../../themes/corzo/images/blog-title-top-gradient.png)
    center top repeat-x; */
}

.corzo-blog-title-wrap.corzo-feature-image .corzo-blog-title-bottom-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 413px;
  opacity: 1;
  /* background: url(../../../../../../../../../themes/corzo/images/blog-title-gradient.png)
    center bottom repeat-x; */
}

.corzo-blog-title-wrap.corzo-style-small .corzo-blog-title-content {
  padding-top: 93px;
  padding-bottom: 87px;
}

.corzo-blog-title-wrap.corzo-style-large .corzo-blog-title-content {
  padding-top: 184px;
  padding-bottom: 168px;
}

.corzo-blog-title-wrap .corzo-single-article-title {
  font-size: 57px;
  font-weight: 700;
  margin-bottom: 11px;
  letter-spacing: 0.5px;
}

.corzo-blog-title-wrap .corzo-single-article-head-right {
  overflow: hidden;
}

.corzo-blog-title-wrap .corzo-single-article-date-wrapper {
  margin-top: 11px;
}

.corzo-not-found-wrap {
  text-align: center;
  position: relative;
}

.corzo-not-found-wrap .corzo-not-found-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.27;
  filter: alpha(opacity=27);
  background-position: center;
  background-size: cover;
  /* background-image: url(../../../../../../../../../themes/corzo/images/404-background.jpg); */
}

.corzo-not-found-wrap .corzo-not-found-content {
  position: relative;
  padding: 60px 0 65px;
}

.corzo-not-found-wrap .corzo-not-found-head {
  font-size: 150px;
  line-height: 1;
  margin-bottom: 40px;
}

.corzo-not-found-wrap .corzo-not-found-title {
  font-size: 35px;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.corzo-not-found-wrap .corzo-not-found-caption {
  font-size: 18px;
}

.corzo-not-found-wrap .corzo-not-found-back-to-home a {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.corzo-not-found-wrap form.search-form {
  max-width: 430px;
  margin: 58px auto 0;
  position: relative;
  margin-bottom: 13px;
}

.corzo-not-found-wrap form.search-form input.search-field {
  font-size: 15px;
  width: 100%;
  border: none;
  padding: 22px 60px 22px 25px;
  height: 64px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.corzo-not-found-wrap form.search-form input.search-submit {
  position: absolute;
  right: 0;
  top: 0;
  width: 64px;
  height: 64px;
  opacity: 0;
  filter: alpha(opacity=0);
}

.corzo-not-found-wrap form.search-form .corzo-top-search-submit {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -15px;
  font-size: 18px;
}

body.search .corzo-not-found-wrap .corzo-not-found-head {
  font-size: 100px;
  text-transform: uppercase;
  margin-bottom: 18px;
}

body.search .corzo-not-found-wrap form.search-form {
  margin-top: 50px;
}

.corzo-sidebar-area .corzo-widget {
  margin-bottom: 55px;
}

.corzo-sidebar-area .corzo-widget-title {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  position: relative;
  margin-bottom: 28px;
  overflow: hidden;
}

.corzo-sidebar-area .corzo-widget-title.corzo-with-divider {
  border-bottom: 3px;
  border-bottom: solid;
  display: inline-block;
  padding-bottom: 8px;
}

.corzo-sidebar-area .corzo-widget-title .gdlr-core-flexslider-nav {
  float: right;
  margin-left: 20px;
}

.corzo-sidebar-area .corzo-widget-head-text {
  display: block;
  float: left;
  margin-right: 28px;
}

.corzo-sidebar-area .corzo-widget-head-divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  overflow: hidden;
  margin-top: 0.6em;
}

.textwidget select {
  max-width: 100%;
}

.widget_categories .screen-reader-text,
.widget_archive .screen-reader-text {
  display: none;
}

.widget_rss .rss-widget-icon {
  margin-right: 10px;
  margin-top: -3px;
}

.widget_product_categories ul,
.widget_archive ul,
.widget_categories ul,
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_recent_entries ul,
.widget_rss ul {
  position: relative;
  list-style: none;
  margin-left: 0;
}

.widget_product_categories ul li,
.widget_archive ul li,
.widget_categories ul li,
.widget_nav_menu ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_recent_entries ul li,
.widget_rss ul li {
  font-size: 16px;
  margin-bottom: 15px;
  padding-left: 28px;
  line-height: 20px;
  padding-bottom: 15px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.widget_product_categories ul ul,
.widget_archive ul ul,
.widget_categories ul ul,
.widget_nav_menu ul ul,
.widget_meta ul ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_recent_entries ul ul,
.widget_rss ul ul {
  margin-top: 20px;
  margin-bottom: 0;
}

.widget_product_categories ul ul ul,
.widget_archive ul ul ul,
.widget_categories ul ul ul,
.widget_nav_menu ul ul ul,
.widget_meta ul ul ul,
.widget_pages ul ul ul,
.widget_recent_comments ul ul ul,
.widget_recent_entries ul ul ul,
.widget_rss ul ul ul {
  margin-left: 5px;
}

.widget_product_categories ul ul li,
.widget_archive ul ul li,
.widget_categories ul ul li,
.widget_nav_menu ul ul li,
.widget_meta ul ul li,
.widget_pages ul ul li,
.widget_recent_comments ul ul li,
.widget_recent_entries ul ul li,
.widget_rss ul ul li {
  border-top-width: 1px;
  border-top-style: solid;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
  padding-top: 15px;
  margin-top: 15px;
}

.widget_product_categories ul li:before,
.widget_archive ul li:before,
.widget_categories ul li:before,
.widget_nav_menu ul li:before,
.widget_meta ul li:before,
.widget_pages ul li:before,
.widget_recent_comments ul li:before,
.widget_recent_entries ul li:before,
.widget_rss ul li:before {
  content: "\f105";
  font-family: FontAwesome;
  text-align: center;
  position: absolute;
  left: 0;
  display: block;
  height: 20px;
  width: 20px;
  margin-top: -1px;
}

.widget_archive select,
.widget_categories select,
.widget_text select {
  font-size: 14px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  outline: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  /* background: url(../../../../../../../../../themes/corzo/images/dropdown.png)
    no-repeat 97% center; */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.widget_rss span.rss-date {
  margin-bottom: 17px;
  margin-top: 3px;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
  font-size: 12px;
}

.widget_rss .rssSummary {
  line-height: 1.7;
  margin-bottom: 15px;
}

.widget_rss a.rsswidget {
  font-size: 16px;
  font-weight: 700;
}

.widget_rss cite {
  font-size: 14px;
  margin-bottom: 14px;
  display: block;
}

.widget_rss ul li {
  margin-bottom: 27px;
}

.widget_search form {
  display: block;
  position: relative;
}

.widget_search form:after {
  content: "\f002";
  font-family: "fontAwesome";
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -7px;
}

.widget_search label .screen-reader-text {
  display: none;
}

.widget_search .search-form input.search-field {
  font-size: 16px;
  line-height: 17px;
  height: 51px;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  padding: 16px 20px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.widget_search input.search-field:focus {
  outline: none;
}

.widget_search input.search-submit {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 51px;
  padding: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 1;
}

.tagcloud {
  zoom: 1;
}

.tagcloud:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.tagcloud a {
  font-size: 18px !important;
  text-transform: none;
  float: left;
  border-bottom-width: 2px;
  border-style: solid;
  margin-right: 10px;
  margin-bottom: 10px;
}

.widget_calendar table {
  width: 100%;
  margin-bottom: 0;
}

.widget_calendar table thead th {
  font-size: 13px;
  font-weight: 700;
  padding: 10px 0;
}

.widget_calendar table tbody td {
  text-align: center;
  padding: 10px 0;
}

.widget_calendar table tfoot td {
  text-align: center;
  padding: 10px 0;
}

.widget_calendar caption {
  font-size: 14px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.widget ul.menu .sub-menu .sub-menu li {
  padding-left: 20px;
}

.widget ul.menu .sub-menu .sub-menu li:before {
  left: -10px;
}

.widget ul.menu .sub-menu .sub-menu .sub-menu .sub-menu li {
  padding-left: 15px;
}

.widget ul.menu .sub-menu .sub-menu .sub-menu .sub-menu li:before {
  left: -15px;
}

.widget ul.menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu li {
  padding-left: 5px;
}

.widget
  ul.menu
  .sub-menu
  .sub-menu
  .sub-menu
  .sub-menu
  .sub-menu
  .sub-menu
  li:before {
  left: -25px;
}

table {
  width: 100%;
  text-align: center;
  border-spacing: 2px;
  border-collapse: separate;
  margin-bottom: 20px;
}

table tr td {
  padding: 15px 0;
}

table tr th {
  padding: 13px 8px;
  font-size: 16px;
  font-weight: 400;
  word-break: normal;
}

body.corzo-boxed .gdlr-core-column-extend-left .gdlr-core-pbf-background-wrap {
  margin-left: -50px;
}

body.corzo-boxed .gdlr-core-column-extend-right .gdlr-core-pbf-background-wrap {
  margin-right: -50px;
}

.corzo-mobile-header-wrap {
  display: none;
}

.corzo-mobile-header {
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 99;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.corzo-mobile-header.corzo-fixed-navigation {
  display: none;
}

.corzo-mobile-header .corzo-mobile-header-container {
  position: relative;
}

.corzo-mobile-header .corzo-logo-inner {
  line-height: 45px;
  margin-right: 80px;
}

.corzo-mobile-header .corzo-logo-inner img {
  vertical-align: middle;
  width: auto;
}

body .corzo-mobile-header .corzo-logo {
  padding: 0 15px;
}

@media only screen and (max-width: 1140px) {
  html
    .widget_product_search
    input[type="search"]#woocommerce-product-search-field {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1050px) {
  body {
    width: 100%;
    overflow-x: hidden;
  }

  body.corzo-boxed div.corzo-body-wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }

  .corzo-bullet-anchor {
    display: none;
  }

  .corzo-top-bar {
    display: none;
  }

  .corzo-header-wrap.corzo-header-style-plain,
  .corzo-header-wrap.corzo-header-style-bar,
  .corzo-navigation-bar-wrap,
  .corzo-header-background-transparent,
  .corzo-header-boxed-wrap,
  .corzo-header-side-nav,
  .corzo-header-transparent-substitute,
  .corzo-sticky-navigation {
    display: none !important;
  }

  .corzo-mobile-header-wrap {
    display: block;
  }

  .corzo-mobile-header-wrap .corzo-top-bar {
    display: block;
  }

  .corzo-mobile-header-wrap .corzo-top-bar {
    font-size: 13px;
  }

  .corzo-mobile-header-wrap .corzo-top-bar-left,
  .corzo-mobile-header-wrap .corzo-top-bar-right {
    padding-top: 0;
    padding-bottom: 0;
  }

  .corzo-mobile-header.corzo-fixed-navigation {
    display: block;
  }

  .corzo-sticky-menu-placeholder {
    display: none !important;
  }

  body .corzo-header-side-content.corzo-style-left {
    margin-left: 0;
  }

  body .corzo-header-side-content.corzo-style-right {
    margin-right: 0;
  }

  body.single-product.woocommerce div.product form.cart {
    float: none;
    margin-bottom: 30px;
    width: auto;
    margin-right: 0;
  }

  body.single-product.woocommerce div.product .product_meta {
    margin-top: 0;
  }

  .corzo-blog-style-2 .corzo-single-nav-area-left,
  .corzo-blog-style-2 .corzo-single-nav-area-right {
    padding: 30px 40px;
  }

  .corzo-blog-style-2 .corzo-single-related-post-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  body.corzo-body-front .corzo-container {
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
  }

  body.corzo-body-front .gdlr-core-container {
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .corzo-column-10,
  .corzo-column-12,
  .corzo-column-15,
  .corzo-column-20,
  .corzo-column-24,
  .corzo-column-30,
  .corzo-column-36,
  .corzo-column-40,
  .corzo-column-45,
  .corzo-column-48,
  .corzo-column-50,
  .corzo-column-60 {
    width: 100%;
  }

  .corzo-mobile-header-wrap .corzo-top-bar-left,
  .corzo-mobile-header-wrap .corzo-top-bar-right {
    float: none;
    text-align: center;
  }

  .corzo-top-bar-right-text {
    display: block;
  }

  .corzo-top-bar-right-social {
    margin-left: 0;
  }

  .corzo-top-bar-right-social a:first-child {
    margin-left: 0;
  }

  .corzo-page-title-wrap .corzo-page-title-content {
    padding-top: 60px !important;
    padding-bottom: 50px !important;
  }

  .corzo-blog-title-wrap .corzo-blog-title-content {
    padding-top: 60px !important;
    padding-bottom: 50px !important;
  }

  .corzo-single-article-date-wrapper {
    display: none;
  }

  .corzo-overlay-menu-content ul.menu > li {
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .corzo-overlay-menu-content ul.menu {
    padding-top: 120px;
    padding-bottom: 100px;
    max-width: 80%;
  }

  .corzo-overlay-menu-content .corzo-overlay-menu-close {
    top: 35px;
  }

  body.admin-bar .corzo-overlay-menu-content {
    margin-top: 0;
  }

  body.admin-bar .corzo-overlay-menu-content ul.menu {
    padding-top: 160px;
  }

  body.admin-bar .corzo-overlay-menu-content .corzo-overlay-menu-close {
    top: 80px;
  }

  .corzo-comments-area .comment-respond {
    padding: 45px 30px;
  }

  .corzo-comments-area .corzo-comment-form-author {
    width: 100%;
    padding-right: 0;
  }

  .corzo-comments-area .corzo-comment-form-email {
    width: 100%;
    padding-left: 0;
  }

  .corzo-comments-area .comment-avatar {
    float: none;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .corzo-comments-area ol.comment-list ol {
    margin-left: 10px;
  }

  .wp-block-image .alignleft {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .wp-block-image .alignright {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .corzo-blog-style-2 .corzo-comments-area .corzo-comment-form-author {
    width: 100%;
    padding-right: 0;
  }

  .corzo-blog-style-2 .corzo-comments-area .corzo-comment-form-email {
    width: 100%;
    padding-right: 0;
  }

  .corzo-blog-style-2 .corzo-comments-area .corzo-comment-form-url {
    width: 100%;
    padding-left: 0;
  }

  .corzo-blog-style-2 .corzo-single-nav-area {
    padding-top: 0;
  }

  .corzo-blog-style-2 .corzo-single-nav-area-left,
  .corzo-blog-style-2 .corzo-single-nav-area-right {
    float: none;
    width: 100%;
    display: block;
    text-align: center;
  }

  .corzo-blog-style-2 .corzo-single-nav-area a i {
    display: none;
  }

  .corzo-blog-style-2 .corzo-single-related-post-container {
    max-width: 500px;
  }

  .corzo-blog-style-4
    .gdlr-core-social-share-item
    .gdlr-core-social-share-count {
    margin-right: 0;
  }

  .corzo-blog-style-4 .corzo-single-nav-left {
    float: none;
    text-align: center;
    display: block;
    margin-bottom: 30px;
    max-width: none;
  }

  .corzo-blog-style-4 .corzo-single-nav-right {
    float: none;
    text-align: center;
    display: block;
    max-width: none;
  }

  .corzo-blog-style-4 .gdlr-core-social-share-item {
    float: none;
  }

  .corzo-blog-style-4 .corzo-single-magazine-author-tags {
    float: none;
    margin-top: 30px;
    text-align: center;
  }

  .corzo-blog-style-4 .corzo-comments-area .corzo-comment-form-author,
  .corzo-blog-style-4 .corzo-comments-area .corzo-comment-form-email,
  .corzo-blog-style-4 .corzo-comment-form-url {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .corzo-blog-style-4 .corzo-single-author .corzo-single-author-avartar {
    float: none;
    max-width: 90px;
    margin: 0 auto 30px;
  }

  .corzo-blog-style-4 .corzo-single-author-content-wrap {
    text-align: center;
  }

  .corzo-footer-wrapper {
    padding-bottom: 7px;
  }

  .corzo-footer-column {
    margin-bottom: 60px;
  }

  .corzo-footer-wrapper.corzo-with-column-divider .corzo-footer-column {
    padding: 0 15px;
    margin-bottom: 60px;
    border: none;
  }

  .corzo-footer-wrapper.corzo-with-column-divider
    .corzo-footer-column:last-child {
    margin-bottom: 20px;
  }

  .corzo-fixed-footer-placeholder {
    display: none;
  }

  .corzo-fixed-footer {
    position: static;
  }

  .corzo-copyright-left,
  .corzo-copyright-right {
    float: none;
    text-align: center;
  }

  body .woocommerce .col2-set,
  .woocommerce-page .col2-set {
    width: 100%;
    float: none;
    margin-right: 0%;
  }

  body div#order_review {
    width: 100%;
  }
}

@media only screen and (max-width: 419px) {
  body {
    min-width: 320px;
  }

  .corzo-top-search-wrap input.search-field {
    font-size: 30px;
    padding-right: 90px;
  }

  .corzo-top-search-wrap .corzo-top-search-submit {
    right: 48px;
    font-size: 24px;
    margin-top: -2px;
  }

  .corzo-top-search-wrap input.search-submit {
    right: 48px;
  }

  .corzo-top-search-wrap .corzo-top-search-close {
    font-size: 40px;
    top: 0;
  }

  .corzo-top-search-wrap input.search-field {
    padding-bottom: 10px;
  }

  .corzo-top-cart-item .corzo-top-cart-item-content {
    overflow: hidden;
    clear: both;
  }

  .corzo-top-cart-item .corzo-top-cart-item-thumbnail {
    margin-bottom: 7px;
  }

  .corzo-top-cart-item .corzo-top-cart-item-remove {
    top: 1%;
  }
}

.corzo-lightbox-wrapper {
  background: #000;
  background: rgba(0, 0, 0, 0.78);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10001;
  overflow: auto;
}

.corzo-lightbox-wrapper .corzo-lightbox-content-row {
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10003;
}

.corzo-lightbox-wrapper .corzo-lightbox-content-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 20px;
  overflow-y: auto;
}

.corzo-lightbox-content-wrap {
  display: none;
  background: #fff;
}

.corzo-lightbox-wrapper .corzo-lightbox-content-wrap {
  display: block;
  max-width: 835px;
  margin: 0 auto;
}

.corzo-lightbox-wrapper .corzo-lightbox-title {
  color: #0e0e0e;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.corzo-lightbox-wrapper .corzo-lightbox-head {
  position: relative;
  padding-bottom: 24px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  margin-bottom: 45px;
}

.corzo-lightbox-wrapper .corzo-lightbox-content-wrap {
  padding: 30px;
  overflow: hidden;
  position: relative;
}

.corzo-lightbox-wrapper .corzo-lightbox-close {
  cursor: pointer;
  font-size: 25px;
  line-height: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
}

html.corzo-lightbox-on,
html.corzo-lightbox-on body {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.corzo-top-cart-content-wrap .corzo-top-cart-title {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  margin-top: 22px;
  margin-bottom: 45px;
}

.corzo-top-cart-item {
  margin-bottom: 35px;
  position: relative;
}

.corzo-top-cart-item .corzo-top-cart-item-thumbnail {
  max-width: 78px;
  float: left;
  margin-right: 28px;
}

.corzo-top-cart-item .corzo-top-cart-item-content {
  overflow: hidden;
  padding-top: 8px;
}

.corzo-top-cart-item .corzo-top-cart-item-title {
  font-size: 17px;
  font-weight: 800;
  margin-bottom: 2px;
}

.corzo-top-cart-item .corzo-top-cart-item-info {
  font-size: 15px;
}

.corzo-top-cart-item .corzo-top-cart-item-remove {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -9px;
  font-size: 17px;
  cursor: pointer;
}

.corzo-top-cart-item-wrap {
  margin: 0 30px;
}

.corzo-top-cart-item-wrap .corzo-top-cart-item-divider {
  margin: 38px 0 30px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.corzo-top-cart-item-wrap .corzo-top-cart-price-wrap {
  font-size: 17px;
}

.corzo-top-cart-item-wrap .corzo-top-cart-price-wrap .corzo-tail {
  font-weight: 800;
  margin-left: 10px;
}

.corzo-top-cart-button-wrap .corzo-button {
  margin: 0 6px 20px;
}

.corzo-top-cart-content-wrap .corzo-highlight,
.corzo-top-cart-item-wrap
  .corzo-top-cart-price-wrap
  .woocommerce-Price-amount.amount {
  color: #fd2e2e;
}

ul.sf-menu > .menu-item-language img.iclflag {
  vertical-align: baseline;
  margin-right: 10px;
}

ul.sf-menu > .menu-item-language ul.submenu-languages {
  display: none;
  position: absolute;
  z-index: 99;
  list-style: none;
  margin: 0 0 0 -15px;
}

ul.sf-menu > .menu-item-language li {
  padding: 1px 11px;
}

ul.sf-menu > .menu-item-language li a {
  padding: 6px 12px;
  display: block;
}

ul.sf-menu > .menu-item-language li:first-child {
  padding-top: 12px;
}

ul.sf-menu > .menu-item-language li:last-child {
  padding-bottom: 12px;
}

.corzo-custom-wpml-flag {
  margin-right: 26px;
}

.corzo-custom-wpml-flag .corzo-custom-wpml-flag-item {
  margin-left: 20px;
}

.corzo-custom-wpml-flag .corzo-custom-wpml-flag-item:first-child {
  margin-left: 0;
}

.corzo-dropdown-wpml-flag {
  position: relative;
  padding-top: 19px;
  padding-bottom: 19px;
}

.corzo-dropdown-wpml-flag .corzo-dropdown-wpml-flag-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -27px;
  right: 0;
}

.corzo-dropdown-wpml-flag .corzo-dropdown-wpml-current-language {
  font-weight: 700;
}

.corzo-dropdown-wpml-flag .corzo-dropdown-wpml-list {
  position: absolute;
  left: -27px;
  min-width: 120px;
  top: 100%;
  z-index: 99;
  padding: 10px 18px;
  display: none;
}

.corzo-dropdown-wpml-flag .corzo-dropdown-wpml-item {
  display: block;
  white-space: nowrap;
}

.corzo-dropdown-wpml-flag .corzo-dropdown-wpml-current-language {
  margin-right: 25px;
  position: relative;
}

.corzo-dropdown-wpml-current-language:after {
  content: "\f0d7";
  font-family: fontAwesome;
  margin-left: 12px;
  font-size: 12px;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-head-wrap,
.corzo-dropdown-wpml-flag .corzo-dropdown-wpml-list {
  background: #f3f3f3;
}

.corzo-body span.wpcf7-not-valid-tip {
  font-size: 13px;
  margin-bottom: 15px;
}

.corzo-body div.wpcf7-response-output {
  font-size: 13px;
  padding: 6px 20px;
  clear: both;
  margin: 0;
}

.wpcf7 input,
.wpcf7 select {
  font-size: 15px;
  padding: 15px 22px;
  width: 100%;
  display: block;
}

.wpcf7 input[type="button"],
.wpcf7 input[type="submit"],
input[type="reset"] {
  width: auto;
}

.wpcf7 input[type="checkbox"],
.wpcf7 input[type="radio"] {
  width: auto;
  display: inline;
}

.wpcf7 .wpcf7-form-control .wpcf7-list-item {
  display: block;
  margin-bottom: 5px;
}

.wpcf7 textarea {
  font-size: 15px;
  padding: 18px 22px;
  height: 120px;
  width: 100%;
}

figure.woocommerce-product-gallery__wrapper {
  max-width: none;
}

body.woocommerce .woocommerce-error,
body.woocommerce .woocommerce-info,
body.woocommerce .woocommerce-message {
  padding: 0.7em 2em 1em 3.5em !important;
}

.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message {
  padding: 0.7em 2em 1em 3.5em !important;
}

.woocommerce .woocommerce-error:before,
.woocommerce .woocommerce-info:before,
.woocommerce .woocommerce-message:before {
  top: 0.7em;
}

.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button {
  margin-top: 5px;
  padding: 0;
  background-color: transparent;
}

.woocommerce .woocommerce-error .button:hover,
.woocommerce .woocommerce-info .button:hover,
.woocommerce .woocommerce-message .button:hover {
  background-color: transparent;
}

.woocommerce div.product form.cart .variations td.label {
  padding-right: 1.3em;
  padding-left: 1.3em;
}

.woocommerce div.product form.cart .variations td.value,
.woocommerce div.product form.cart .variations th.value {
  min-width: 210px;
}

.woocommerce div.product form.cart .variations select {
  float: left;
  margin-top: 0.3em;
  margin-left: 15px;
  margin-right: 0;
}

a.reset_variations {
  margin-right: 15px;
  margin-left: 7px;
}

.woocommerce div.product form.cart .variations select {
  min-width: 59%;
}

.related.products h2 {
  font-size: 23px;
  margin-bottom: 42px;
}

.woocommerce table.shop_attributes th {
  background: transparent;
  padding-top: 16px;
  padding-bottom: 16px;
}

.woocommerce table.shop_attributes td,
.woocommerce table.shop_attributes th {
  border-bottom: 0;
}

.woocommerce table.shop_attributes {
  border-top: 0;
}

.single-product.woocommerce #review_form #respond p.comment-form-comment {
  margin-bottom: 25px;
}

.woocommerce table.shop_table {
  border: 0;
}

.woocommerce table.shop_table.shop_table_responsive.cart {
  border-spacing: 0;
}

.woocommerce table.shop_table th {
  padding: 17px 12px;
  background: none;
}

.woocommerce table.shop_table.shop_table_responsive.cart tr:nth-child(odd),
.woocommerce table.shop_table.shop_table_responsive.cart tr:nth-child(even) {
  background-color: transparent;
}

.woocommerce table.shop_table td {
  padding: 19px 20px;
}

.woocommerce-cart table.cart img {
  width: 70px;
}

.woocommerce td.product-name dl.variation dd,
.woocommerce td.product-name dl.variation dt {
  margin-bottom: 0;
}

.woocommerce a.remove {
  width: auto;
}

.cart_item input.input-text.qty.text {
  padding: 15px 0 15px 10px;
  border-bottom-width: 1px;
}

.woocommerce-cart table.cart td.actions .coupon .input-text {
  padding: 15px 23px 15px;
  height: 49px;
}

.woocommerce .cart input.button[name="apply_coupon"] {
  height: 49px;
}

.woocommerce #content table.cart td.actions .input-text,
.woocommerce table.cart td.actions .input-text,
.woocommerce-page #content table.cart td.actions .input-text,
.woocommerce-page table.cart td.actions .input-text {
  width: 180px;
}

.woocommerce .cart .button,
.woocommerce .cart input.button {
  padding: 19px 20px 18px;
  border-radius: 0;
  font-size: 12px;
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
  padding: 14px 24px 18px;
  border-radius: 0;
  font-size: 13px;
  font-weight: 700;
}

.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit:disabled[disabled],
.woocommerce a.button.disabled,
.woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled] {
  padding: inherit;
  opacity: inherit;
  padding-left: 23px;
  padding-right: 23px;
}

.woocommerce .cart_totals h2 {
  font-size: 18px;
}

.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th {
  border-top: 0;
}

.woocommerce-cart .cart-collaterals .cart_totals table {
  border-spacing: 0;
  margin: 0;
}

.woocommerce-cart .wc-proceed-to-checkout {
  padding: 0;
}

.woocommerce table.shop_table tbody:first-child tr:first-child td,
.woocommerce table.shop_table tbody:first-child tr:first-child th {
  padding-left: 20px;
  padding-right: 20px;
}

.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th {
  padding-left: 20px;
  padding-right: 20px;
}

.woocommerce a.showcoupon,
.woocommerce a.showcoupon:hover {
  margin-left: 20px;
  color: #baf2ff;
}

.woocommerce .woocommerce-billing-fields h3,
.woocommerce .woocommerce-shipping-fields h3,
#order_review_heading {
  font-size: 17px;
}

.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
  padding: 13px 15px;
  border-bottom-width: 1px;
}

.woocommerce .col2-set .col-1,
.woocommerce-page .col2-set .col-1 {
  margin-bottom: 35px;
}

.woocommerce .col2-set,
.woocommerce-page .col2-set {
  width: 47%;
  float: left;
  margin-right: 3%;
}

.woocommerce .col2-set .col-1,
.woocommerce-page .col2-set .col-1 {
  width: 100%;
}

.woocommerce .col2-set .col-2,
.woocommerce-page .col2-set .col-2 {
  width: 100%;
}

div#order_review {
  float: left;
  width: 50%;
}

.woocommerce form .form-row {
  margin: 0 0 14px;
}

.woocommerce form p.form-row.form-row-first {
  margin: 0;
}

.select2-container .select2-choice {
  padding: 6px 1px 6px 13px;
}

.woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
  width: 49%;
}

.shop_table.woocommerce-checkout-review-order-table {
  border-spacing: 0;
}

.woocommerce table.shop_table th {
  padding: 17px 20px 17px 20px;
}

.woocommerce .cart_item td.product-total {
  padding-right: 20px;
  padding-left: 20px;
}

.woocommerce .cart_item td.product-name {
  padding-left: 20px;
}

.woocommerce table.shop_table td {
  padding-right: 20px;
}

.checkout_coupon .form-row.form-row-last {
  margin-bottom: 0;
}

table tr.cart_item {
  background: transparent;
}

.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
  font-size: 13px;
}

html .woocommerce a.remove {
  border-radius: 100%;
  color: #333 !important;
}

.woocommerce-checkout table tr.cart_item {
  background: transparent;
}

li.woocommerce-MyAccount-navigation-link {
  padding: 13px 0;
  padding-bottom: 13px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.woocommerce-account .woocommerce-MyAccount-navigation {
  width: 28%;
}

li.woocommerce-MyAccount-navigation-link.is-active {
  font-weight: 600;
}

header.woocommerce-Address-title.title h3 {
  font-size: 18px;
}

.woocommerce-Address address {
  padding: 25px;
}

.u-columns.woocommerce-Addresses.col2-set.addresses {
  width: 100%;
  margin-bottom: 40px;
}

.woocommerce-account .woocommerce-MyAccount-content {
  padding: 31px;
}

.woocommerce-Address address,
.woocommerce-account .woocommerce-MyAccount-content {
  background: #f5f5f5;
}

.woocommerce form .select2-selection {
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  height: 43px;
  padding: 7px 6px;
  font-size: 13px;
}

.woocommerce form .select2-selection .select2-selection__arrow {
  top: 8px;
  right: 6px;
}

form.checkout:after {
  content: " ";
  clear: both;
  display: block;
}

.woocommerce div.product form.cart .variations label {
  white-space: nowrap;
}

.woocommerce div.product form.cart .reset_variations {
  white-space: nowrap;
  margin: 0 5px;
}

.woocommerce #content div.product div.images,
.woocommerce div.product div.images,
.woocommerce-page #content div.product div.images,
.woocommerce-page div.product div.images {
  width: 46%;
}

.woocommerce ul.cart_list li dl dd,
.woocommerce ul.cart_list li dl dt,
.woocommerce ul.product_list_widget li dl dd,
.woocommerce ul.product_list_widget li dl dt {
  margin-bottom: 0em;
}

.woocommerce ul.cart_list li dl,
.woocommerce ul.product_list_widget li dl {
  padding-left: 0;
  border-left: 0;
}

.woocommerce .widget_shopping_cart .cart_list li,
.woocommerce.widget_shopping_cart .cart_list li {
  margin-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 15px;
}

.woocommerce .widget_shopping_cart .cart_list li:last-child,
.woocommerce.widget_shopping_cart .cart_list li:last-child {
  margin-bottom: 15px;
}

.woocommerce .widget_shopping_cart .total,
.woocommerce.widget_shopping_cart .total {
  border-top: 0;
  font-size: 17px;
}

.widget_shopping_cart_content .total span.woocommerce-Price-amount.amount {
  margin-left: 5px;
}

.woocommerce ul.cart_list li img,
.woocommerce ul.product_list_widget li img {
  width: 47px;
}

.widget_shopping_cart a.button.wc-forward {
  padding: 19px 28px;
  font-size: 14px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.woocommerce .widget_price_filter .price_slider_amount .button {
  float: left;
  font-size: 14px;
  padding: 16px 26px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.price_slider_amount .price_label {
  margin-top: 24px;
  font-size: 17px;
  font-weight: 700;
}

.woocommerce ul.cart_list li,
.woocommerce ul.product_list_widget li {
  padding: 15px 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.woocommerce ul.cart_list li,
.woocommerce ul.product_list_widget li {
  padding: 15px 0;
  margin: 0;
  list-style: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

html ul.cart_list li a,
html .woocommerce ul.product_list_widget li a {
  font-weight: 400;
}

html .woocommerce ul.cart_list li a,
html .woocommerce ul.product_list_widget li a {
  font-weight: 500;
}

html .woocommerce ul.cart_list li .star-rating {
  float: left;
  margin-top: 10px;
}

html .woocommerce ul.product_list_widget li .star-rating {
  float: left;
  margin-top: 3px;
  margin-right: 10px;
}

html .woocommerce ul.product_list_widget li del {
  margin-right: 10px;
}

html .product_list_widget span.reviewer {
  margin-top: 4px;
  margin-left: 12px;
  float: left;
}

html .widget_product_search label.screen-reader-text {
  display: none;
}

html .widget_product_search input[type="submit"] {
  height: 47px;
}

html .widget_product_search input#woocommerce-product-search-field {
  height: 47px;
  padding: 14px 12px;
  border-bottom-width: 1px;
  width: 62%;
  font-size: 13px;
}

.widget.woocommerce ul.cart_list li:first-child,
.widget.woocommerce ul.product_list_widget li:first-child {
  padding-top: 0;
}

.woocommerce .woocommerce-message:before {
  color: #fff;
}

.woocommerce .woocommerce-error {
  background-color: #bd584e;
  border-top: 3px solid #bd584e;
  color: #fff;
}

.woocommerce .woocommerce-info {
  background-color: #46a5ca;
  border-top: 3px solid #46a5ca;
  color: #fff;
}

.woocommerce .woocommerce-message {
  background-color: #46ca7b;
  border-top: 3px solid #46ca7b;
  color: #fff;
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
  color: #fff;
}

.single-product.woocommerce div.product .quantity .qty {
  background: #f3f3f3;
}

.woocommerce .woocommerce-info:before {
  color: #fff;
}

.woocommerce .woocommerce-error:before,
.woocommerce .woocommerce-info:before,
.woocommerce .woocommerce-message:before {
  color: #fff !important;
  top: inherit;
}

html .woocommerce a.remove:hover {
  color: #afafaf !important;
  background: transparent !important;
}

html .woocommerce form .form-row .required {
  color: #c5c5c5;
}

html .woocommerce form .form-row.woocommerce-invalid .select2-container,
html .woocommerce form .form-row.woocommerce-invalid input.input-text,
html .woocommerce form .form-row.woocommerce-invalid select {
  border-color: #d4d4d4;
}

html .woocommerce form .form-row.woocommerce-invalid label {
  color: #9e9e9e;
}

html .woocommerce form .form-row.woocommerce-validated .select2-container,
html .woocommerce form .form-row.woocommerce-validated input.input-text,
html .woocommerce form .form-row.woocommerce-validated select {
  border-color: #d4d4d4;
}

html .woocommerce span.onsale {
  font-size: 13px;
  font-weight: 800;
  line-height: 46px;
  width: 48px;
  padding: 0 0 0;
  height: 48px;
}

.single-product.woocommerce .corzo-page-wrapper {
  border-top-width: 1px;
  border-top-style: solid;
}

.single-product.woocommerce .woocommerce-breadcrumb {
  font-size: 14px;
}

.single-product.woocommerce .product_title {
  font-size: 40px;
  margin-bottom: 2px;
}

.single-product.woocommerce div.product .woocommerce-product-rating {
  margin-bottom: 2px;
}

.single-product.woocommerce div.product .star-rating {
  font-size: 14px;
  padding-right: 15px;
  box-sizing: content-box;
  margin-right: 10px;
}

.single-product.woocommerce div.product .star-rating:before,
.single-product.woocommerce div.product .star-rating span:before {
  letter-spacing: 3px;
}

.single-product.woocommerce div.product .woocommerce-review-link {
  font-size: 13px;
}

.single-product.woocommerce div.product p.price {
  padding-top: 27px;
  margin-bottom: 28px;
}

.single-product.woocommerce div.product p.price ins {
  text-decoration: none;
}

.single-product.woocommerce div.product p.price .woocommerce-Price-amount {
  font-size: 26px;
  font-weight: 700;
}

.single-product.woocommerce div.product p.price del .woocommerce-Price-amount {
  font-size: 23px;
  margin-right: 9px;
}

.single-product.woocommerce div.product span.onsale {
  position: static;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}

.single-product.woocommerce div.product .quantity .qty {
  height: 60px;
  font-size: 16px;
  width: 75px;
  padding-left: 15px;
  border: none;
}

.single-product.woocommerce div.product form.cart {
  margin: 25px 0 55px 0;
}

.single-product.woocommerce div.product form.cart.variations_form {
  width: 300px;
}

.single-product.woocommerce div.product form.cart .button {
  font-size: 13px;
  height: 60px;
  text-transform: uppercase;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 10px 30px;
}

.single-product.woocommerce div.product .product_meta {
  margin-top: 50px;
}

.single-product.woocommerce div.product .product_meta {
  overflow: hidden;
  padding-top: 5px;
}

.single-product.woocommerce div.product .product_meta > span {
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
}

.single-product.woocommerce div.product .product_meta > span .corzo-head {
  font-weight: 600;
}

.single-product.woocommerce
  div.product
  .woocommerce-variation-add-to-cart-disabled {
  display: none;
}

.single-product.woocommerce div.product .corzo-woocommerce-social-share {
  clear: both;
  padding-top: 15px;
  margin-top: 50px;
  border-top-width: 2px;
  border-top-style: solid;
}

.single-product.woocommerce div.product .gdlr-core-social-share-item a {
  font-size: 18px;
}

.corzo-woocommerce-tab {
  clear: both;
}

.single-product.woocommerce div.product .corzo-woocommerce-tab {
  clear: both;
  padding-top: 60px;
  margin-bottom: 50px;
}

.single-product.woocommerce
  div.product
  .corzo-woocommerce-tab
  .gdlr-core-tab-item-title {
  font-size: 18px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 600;
  padding-bottom: 18px;
}

.single-product.woocommerce
  div.product
  .corzo-woocommerce-tab
  .gdlr-core-tab-item-title-line {
  border-bottom-width: 1px;
  bottom: -1px;
}

.single-product.woocommerce
  div.product
  .corzo-woocommerce-tab
  .gdlr-core-tab-item-title-wrap {
  border-bottom-width: 1px;
}

.single-product.woocommerce
  div.product
  .corzo-woocommerce-tab
  .gdlr-core-tab-item-content {
  text-align: left;
  margin: 0 auto;
}

.single-product.woocommerce .woocommerce-Reviews-title {
  display: none;
}

.single-product.woocommerce #reviews {
  padding-top: 43px;
}

.single-product.woocommerce #reviews .comment-respond {
  text-align: left;
}

.single-product.woocommerce #reviews #comments ol.commentlist li img.avatar {
  border: none;
  width: 85px;
  position: static;
  padding: 0;
  border-radius: 50%;
  margin-right: 30px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text {
  overflow: hidden;
  margin-left: 0;
  padding: 0;
  border: none;
  text-align: left;
}

.single-product.woocommerce #reviews #comments ol.commentlist li {
  padding: 39px 49px;
  margin-bottom: 30px;
}

.single-product.woocommerce
  #reviews
  #comments
  ol.commentlist
  li
  .comment-text
  p.meta {
  margin-bottom: 18px;
}

.single-product.woocommerce
  #reviews
  #comments
  ol.commentlist
  li
  .comment-text
  p.meta
  strong[itemprop="author"] {
  display: block;
  font-size: 17px;
  text-transform: capitalize;
}

.single-product.woocommerce
  #reviews
  #comments
  ol.commentlist
  li
  .comment-text
  p.meta
  time[itemprop="datePublished"] {
  display: block;
  font-size: 15px;
}

.single-product.woocommerce
  #reviews
  #comments
  ol.commentlist
  li
  .comment-text
  .star-rating {
  float: none;
  margin-top: 24px;
}

.single-product.woocommerce #reviews #review_form_wrapper {
  padding-top: 35px;
}

.single-product.woocommerce #reviews .comment-reply-title {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 25px;
  display: block;
}

.single-product.woocommerce #review_form #respond label {
  font-size: 15px;
  font-weight: 600;
}

.single-product.woocommerce #review_form #respond p {
  margin-bottom: 30px;
}

.single-product.woocommerce #review_form #respond label[for="rating"] {
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
}

.single-product.woocommerce #review_form #respond p.stars {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  font-size: 19px;
  line-height: 1;
}

.single-product.woocommerce #review_form #respond p.stars a {
  margin-right: 0;
}

.single-product.woocommerce #review_form #respond input[type="text"],
.single-product.woocommerce #review_form #respond input[type="email"] {
  display: block;
  margin-top: 10px;
  border-bottom-width: 1px;
  width: 100%;
  height: 55px;
}

.single-product.woocommerce #review_form #respond textarea {
  display: block;
  margin-top: 10px;
  border-bottom-width: 1px;
  width: 100%;
  height: 150px;
  padding: 15px;
  font-size: 15px;
}

.single-product.woocommerce #review_form #respond .comment-form-rating {
  margin-bottom: 20px;
}

.single-product.woocommerce #review_form #respond .form-submit input {
  font-size: 13px;
  height: 47px;
  text-transform: uppercase;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 10px 38px;
}

.woocommerce.single-product .related.products {
  clear: left;
}

.woocommerce-account .woocommerce-MyAccount-navigation > ul {
  margin-left: 0;
}

@media only screen and (max-width: 767px) {
  .woocommerce-account .woocommerce-MyAccount-navigation {
    width: auto;
    float: none;
  }
}

.woocommerce div.product form.cart .group_table td {
  padding: 0;
  vertical-align: middle;
}

table.woocommerce-grouped-product-list.group_table tr:nth-child(odd),
table.woocommerce-grouped-product-list.group_table tr:nth-child(even) {
  background-color: transparent;
}

body.woocommerce
  div.product
  form.cart
  .group_table
  td.woocommerce-grouped-product-list-item__label {
  padding-left: 25px;
  font-weight: 700;
  text-align: left;
}

td.woocommerce-grouped-product-list-item__price {
  text-align: left;
  font-weight: 600;
}

.single-product.woocommerce div.product .quantity .qty {
  height: 48px;
  font-size: 16px;
  width: 87px;
  background: transparent !important;
  border: 1px solid #e5e5e5;
}

table.woocommerce-grouped-product-list.group_table {
  border-spacing: 0 15px;
}

.single-product.woocommerce div.product form.cart .button {
  height: 47px;
  padding: 10px 40px;
}

.single-product.woocommerce div.product p.price {
  font-size: 32px !important;
  color: #bbb;
}

.single-product.woocommerce div.product p.price {
  margin-bottom: 0;
}

.single-product.woocommerce div.product p.price {
  padding-top: 20px;
}

.single-product.woocommerce div.product form.cart {
  margin: 25px 0 30px 0;
}

.single-product.woocommerce div.product .product_meta {
  margin-top: 30px;
}

.single-product.woocommerce div.product .logisco-woocommerce-social-share {
  clear: both;
  padding-top: 15px;
  margin-top: 30px;
}

.single-product.woocommerce div.product .product_meta > span {
  font-size: 15px;
}

.woocommerce .cart_totals h2 {
  font-size: 20px;
}

.woocommerce .cross-sells h2 {
  font-size: 20px;
}

.woocommerce table.cart td.actions {
  padding-left: 0;
  padding-right: 0;
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
  font-size: 15px;
  padding: 21px;
}

.woocommerce .cart .button,
.woocommerce .cart input.button {
  padding: 17px 20px 20px;
}

.woocommerce .woocommerce-result-count {
  margin-bottom: 3em;
}

.woocommerce .woocommerce-result-count {
  margin-bottom: 3em;
}

.woocommerce ul.products li.product .woocommerce-loop-category__title,
.woocommerce ul.products li.product .woocommerce-loop-product__title,
.woocommerce ul.products li.product h3 {
  font-size: 1.4em;
}

body .price ins {
  text-decoration: none;
}

.woocommerce ul.products li.product .price ins {
  font-weight: 800;
}

body span.woocommerce-Price-amount.amount {
  font-size: 20px;
}

.woocommerce ul.products li.product .price {
  font-weight: 800;
}

del span.woocommerce-Price-amount.amount {
  font-size: 1.1em;
  font-weight: 600;
}

li.product .gdlr-core-product-default .gdlr-core-product-thumbnail {
  margin-bottom: 30px;
}

li.product
  .gdlr-core-product-default
  .gdlr-core-product-price.gdlr-core-title-font {
  font-size: 18px;
  font-weight: 800;
  text-align: center;
}

li.product .gdlr-core-product-default span.woocommerce-Price-amount.amount {
  font-size: 1em;
}

li.product .gdlr-core-product-default .star-rating {
  margin-top: 8px;
}

li.product .gdlr-core-product-default .gdlr-core-product-price del {
  margin-right: 9px;
  opacity: 0.5;
}

li.product .gdlr-core-product-default .gdlr-core-product-price ins {
  text-decoration: none;
}

.woocommerce ul.products li.product .gdlr-core-product-default h3 {
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: 800;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 0;
}

.woocommerce ul.products li.product .gdlr-core-product-default h3 a {
  color: #0c0c0c;
}

.woocommerce ul.products li.product .gdlr-core-product-default h3 a:hover {
  color: #d32525;
}

.woocommerce ul.products li.product .gdlr-core-product-default .button {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0;
  background: transparent;
  margin-top: 0;
}

.woocommerce ul.products li.product .gdlr-core-product-default img {
  margin-bottom: 0;
}

.woocommerce.single-product .related.products li.product {
  position: relative;
}

.woocommerce.single-product .related.products .onsale {
  position: absolute;
  left: -10px;
  top: -10px;
  margin: 0;
}

.woocommerce-cart .woocommerce,
.woocommerce-checkout .woocommerce {
  margin-bottom: 70px;
}

.corzo-body.woocommerce nav.woocommerce-pagination {
  text-align: right;
  margin-bottom: 30px;
}

.corzo-body.woocommerce nav.woocommerce-pagination ul {
  margin: 0;
  border: 0;
}

.corzo-body.woocommerce nav.woocommerce-pagination ul li {
  border: 0;
}

.corzo-body.woocommerce nav.woocommerce-pagination ul li span,
.corzo-body.woocommerce nav.woocommerce-pagination ul li a {
  font-size: 14px;
  font-weight: 700;
  margin-right: 3px;
  padding: 9px 14px;
  line-height: 1.7;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.mm-menu,
.mm-panels,
.mm-panels > .mm-panel {
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  box-sizing: border-box;
}

.mm-btn,
.mm-menu {
  box-sizing: border-box;
}

.mm-listview a,
.mm-listview a:hover,
.mm-navbar a,
.mm-navbar a:hover {
  text-decoration: none;
}

.mm-hidden {
  display: none !important;
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mm-menu {
  display: block;
  padding: 0;
  position: absolute;
}

.mm-panels,
.mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  position: absolute;
}

.mm-btn,
.mm-panel.mm-highest {
  z-index: 1;
}

.mm-panels {
  overflow: hidden;
}

.mm-panel {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-panel.mm-opened {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.mm-listview .mm-divider,
.mm-listview > li > a,
.mm-listview > li > span,
.mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px;
}

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  transform: none !important;
}

.mm-listview .mm-vertical .mm-panel,
.mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after,
.mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent;
}

.mm-vertical li.mm-opened > .mm-panel,
li.mm-vertical.mm-opened > .mm-panel {
  display: block;
}

.mm-listview > li.mm-vertical > .mm-next,
.mm-vertical .mm-listview > li > .mm-next {
  height: 40px;
  bottom: auto;
}

.mm-listview > li.mm-vertical > .mm-next:after,
.mm-vertical .mm-listview > li > .mm-next:after {
  top: 16px;
  bottom: auto;
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after,
.mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 19px;
}

.mm-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
}

.mm-clear:after,
.mm-clear:before,
.mm-close:after,
.mm-close:before {
  content: "";
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-clear:before,
.mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px;
}

.mm-clear:after,
.mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px;
}

.mm-arrow:after,
.mm-next:after {
  content: "";
  border: 1px solid transparent;
  display: block;
  width: 6px;
  height: 6px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-prev:before {
  border-right: none;
  border-bottom: none;
  left: 0;
}

.mm-arrow:after,
.mm-next:after {
  border-top: none;
  border-left: none;
  right: 2px;
}

.mm-navbar {
  text-align: center;
  line-height: 20px;
  height: 40px;
  margin: 0;
}

.mm-navbar .mm-btn:last-child {
  text-align: right;
  padding-right: 20px;
  right: 0;
}

.mm-panel .mm-navbar {
  display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
  margin: 0 25px 30px;
  position: relative;
}

.mm-listview,
.mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px;
}

.mm-listview > li {
  position: relative;
}

.mm-listview > li,
.mm-listview > li .mm-next,
.mm-listview > li .mm-next:before,
.mm-listview > li:after {
  border-color: inherit;
}

.mm-listview > li > a,
.mm-listview > li > span {
  color: inherit;
  display: block;
  margin: 0;
}

.mm-listview > li > a.mm-arrow,
.mm-listview > li > span.mm-arrow {
  padding-right: 50px;
}

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen + a,
.mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0;
}

.mm-listview .mm-inset {
  list-style: disc inside;
  padding: 0 10px 15px 40px;
  margin: 0;
}

.mm-listview .mm-inset > li {
  padding: 5px 0;
}

.mm-listview .mm-divider {
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px;
}

.mm-listview .mm-spacer {
  padding-top: 40px;
}

.mm-listview .mm-spacer > .mm-next {
  top: 40px;
}

.mm-listview .mm-spacer.mm-divider {
  padding-top: 25px;
}

.mm-page {
  box-sizing: border-box;
  position: relative;
}

.mm-slideout {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  z-index: 2;
}

html.mm-opened {
  overflow-x: hidden;
  position: static;
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

html.mm-blocking #mm-blocker {
  display: block;
}

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed;
}

.mm-menu.mm-offcanvas.mm-current {
  display: block;
}

.mm-menu {
  width: 330px;
}

@media all and (max-width: 380px) {
  .mm-menu {
    width: 280px;
  }
}

html.mm-opening .mm-slideout {
  -webkit-transform: translate3d(330px, 0, 0);
  transform: translate3d(330px, 0, 0);
}

@media all and (max-width: 380px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate3d(280px, 0, 0);
    transform: translate3d(280px, 0, 0);
  }
}

html.mm-right.mm-opened .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

html.mm-right.mm-opened .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu.mm-pageshadow.mm-right:after {
  left: auto;
  right: 100%;
}

.mm-menu.mm-right {
  left: auto;
  right: 0;
}

html.mm-right.mm-opening .mm-slideout {
  -webkit-transform: translate3d(-330px, 0, 0);
  transform: translate3d(-330px, 0, 0);
}

@media all and (max-width: 380px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate3d(-280px, 0, 0);
    transform: translate3d(-280px, 0, 0);
  }
}

.mm-menu.mm-front.mm-right,
.mm-menu.mm-next.mm-right {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

html.mm-pagedim #mm-blocker,
html.mm-pagedim-black #mm-blocker,
html.mm-pagedim-white #mm-blocker {
  opacity: 0;
}

html.mm-pagedim-black.mm-opening #mm-blocker,
html.mm-pagedim-white.mm-opening #mm-blocker,
html.mm-pagedim.mm-opening #mm-blocker {
  opacity: 0.3;
  transition: ease 0.4s;
}

html.mm-pagedim #mm-blocker {
  background: inherit;
}

html.mm-pagedim-white #mm-blocker {
  background: #fff;
}

html.mm-pagedim-black #mm-blocker {
  background: #000;
}

ul.mm-listview li {
  margin: 0 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

ul.mm-listview li a,
ul.mm-listview li span.corzo-mm-menu-blank {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 17px 0;
}

.mm-navbar .mm-btn {
  line-height: 40px;
  font-size: 30px;
  display: block;
  text-align: left;
  left: -8px;
  padding: 0;
}

.mm-navbar .mm-btn:before {
  content: "\34";
  font-family: elegantIcons;
}

.mm-navbar .mmenu-custom-close {
  line-height: 40px;
  font-size: 30px;
  display: block;
  text-align: left;
  margin-left: -8px;
  cursor: pointer;
}

.mm-navbar .mmenu-custom-close:after {
  content: "\4d";
  font-family: elegantIcons;
}

.mm-navbar .mm-title {
  display: none;
}

.mm-navbar .mm-title:first-child {
  display: block;
}

html.corzo-mmenu-left .corzo-sticky-navigation.corzo-fixed-navigation,
html.corzo-mmenu-right .corzo-sticky-navigation.corzo-fixed-navigation {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

html.mm-opening.corzo-mmenu-left
  .corzo-sticky-navigation.corzo-fixed-navigation {
  -webkit-transform: translate3d(330px, 0, 0);
  transform: translate3d(330px, 0, 0);
}

html.mm-opening.corzo-mmenu-right
  .corzo-sticky-navigation.corzo-fixed-navigation {
  -webkit-transform: translate3d(-330px, 0, 0);
  transform: translate3d(-330px, 0, 0);
}

html.corzo-mmenu-left .corzo-mobile-header-wrap .corzo-top-bar,
html.corzo-mmenu-left .corzo-mobile-header,
html.corzo-mmenu-right .corzo-mobile-header-wrap .corzo-top-bar,
html.corzo-mmenu-right .corzo-mobile-header {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

html.mm-opening.corzo-mmenu-left .corzo-mobile-header-wrap .corzo-top-bar,
html.mm-opening.corzo-mmenu-left .corzo-mobile-header {
  -webkit-transform: translate3d(330px, 0, 0);
  transform: translate3d(330px, 0, 0);
}

html.mm-opening.corzo-mmenu-right .corzo-mobile-header-wrap .corzo-top-bar,
html.mm-opening.corzo-mmenu-right .corzo-mobile-header {
  -webkit-transform: translate3d(-330px, 0, 0);
  transform: translate3d(-330px, 0, 0);
}

@media all and (max-width: 380px) {
  html.mm-opening.corzo-mmenu-left
    .corzo-sticky-navigation.corzo-fixed-navigation {
    -webkit-transform: translate3d(280px, 0, 0);
    transform: translate3d(280px, 0, 0);
  }

  html.mm-opening.corzo-mmenu-right
    .corzo-sticky-navigation.corzo-fixed-navigation {
    -webkit-transform: translate3d(-280px, 0, 0);
    transform: translate3d(-280px, 0, 0);
  }

  html.mm-opening.corzo-mmenu-left .corzo-mobile-header-wrap .corzo-top-bar,
  html.mm-opening.corzo-mmenu-left .corzo-mobile-header {
    -webkit-transform: translate3d(280px, 0, 0);
    transform: translate3d(280px, 0, 0);
  }

  html.mm-opening.corzo-mmenu-right .corzo-mobile-header-wrap .corzo-top-bar,
  html.mm-opening.corzo-mmenu-right .corzo-mobile-header {
    -webkit-transform: translate3d(-280px, 0, 0);
    transform: translate3d(-280px, 0, 0);
  }
}

.sf-menu {
  list-style: none;
  margin-left: 0;
}

.sf-menu > li {
  float: left;
  cursor: pointer;
}

.sf-menu > li > a {
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 9;
}

.sf-menu > li > a .corzo-menu-item-description {
  font-size: 13px;
  margin-bottom: -4px;
  display: block;
  font-weight: 400;
}

.sf-menu > .corzo-normal-menu ul {
  list-style: none;
  position: absolute;
  display: none;
  z-index: 99;
  min-width: 220px;
  margin-left: 0;
}

.sf-menu > .corzo-normal-menu ul {
  margin-left: -15px;
}

.sf-menu > .corzo-normal-menu ul ul {
  margin-left: 0;
}

.sf-menu > .corzo-normal-menu.sfHover > ul,
.sf-menu > .corzo-normal-menu.sfHover > ul:hover,
.sf-menu > .corzo-normal-menu li.sfHover > ul {
  display: block;
}

.sf-menu > .corzo-normal-menu ul ul {
  top: 0;
  left: 100%;
}

.sf-menu > .corzo-normal-menu ul.corzo-menu-right {
  right: 0;
}

.sf-menu > .corzo-normal-menu ul ul.sub-menu-right {
  left: auto;
  right: 100%;
}

.sf-menu > .corzo-normal-menu ul ul.sub-menu-left {
  right: auto;
  left: 100%;
}

.sf-menu > .corzo-normal-menu li {
  position: relative;
  white-space: nowrap;
}

.sf-menu > .corzo-normal-menu li a {
  text-decoration: none;
}

.sf-menu > .corzo-normal-menu li > a.sf-with-ul-pre:after {
  content: ">";
}

.sf-menu > .corzo-mega-menu .sf-mega {
  position: absolute;
  display: none;
  z-index: 99;
}

.sf-menu > .corzo-mega-menu.sfHover .sf-mega,
.sf-menu > .corzo-mega-menu.sfHover .sf-mega:hover {
  display: block;
}

.sf-menu > .corzo-mega-menu .sf-mega-section-inner a {
  text-decoration: none;
}

.sf-menu > .corzo-mega-menu .sf-mega-section-inner ul {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}

.sf-vertical {
  list-style: none;
  margin-left: 0;
  left: 100%;
  top: 0;
  margin-bottom: 0;
}

.sf-vertical li {
  position: relative;
}

.sf-vertical ul.sub-menu {
  list-style: none;
  position: absolute;
  display: none;
  left: 100%;
  top: 0;
  z-index: 9;
  min-width: 190px;
}

.sf-vertical ul.sub-menu li {
  white-space: nowrap;
}

.gdlr-core-nav-side-text {
  display: none;
}

.gdlr-core-custom-menu-widget .gdlr-core-nav-side-text {
  display: inline;
  margin-left: 10px;
}

.gdlr-core-custom-menu-widget .gdlr-core-nav-side-text.gdlr-core-with-bg {
  display: inline-block;
  padding: 1px 6px;
  color: #fff;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.products > li.product-category {
  float: left;
  padding: 20px;
  list-style: none;
}

.products > li.product-category.first {
  clear: left;
}

.products > li.product-category h2 {
  font-size: 15px;
  text-align: center;
}

.products.columns-2 > li.product-category {
  width: 50%;
}

.products.columns-3 > li.product-category {
  width: 33.33%;
}

.products.columns-4 > li.product-category {
  width: 25%;
}

.products.columns-5 > li.product-category {
  width: 20%;
}

@media only screen and (max-width: 767px) {
  .products > li.product-category.product {
    width: 50%;
  }

  .products > li.product-category.first {
    clear: none;
  }

  .products > li.product-category:nth-child(2n + 1) {
    clear: left;
  }
}

.mptt-shortcode-wrapper .mptt-shortcode-table {
  table-layout: fixed;
}

.mptt-shortcode-wrapper
  .mptt-shortcode-table
  tbody
  .mptt-event-container
  .timeslot {
  font-size: 15px;
  font-weight: 800;
  line-height: 22px;
}

.mptt-shortcode-wrapper .mptt-shortcode-table tr.mptt-shortcode-row th {
  padding: 22px 10px;
  font-weight: 700;
}

.mptt-shortcode-wrapper
  .mptt-shortcode-table
  tbody
  .mptt-event-container
  .event-title {
  font-size: 15px;
  font-weight: 700;
  margin-top: 25px;
}

.corzo-body select.mptt-navigation-select {
  width: 250px;
  border: 0;
  background: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding-bottom: 10px;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 30px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.corzo-body select.mptt-navigation-select:focus {
  outline: none;
}

.corzo-float-social {
  position: fixed;
  z-index: 999;
  right: 35px;
  top: 50%;
  white-space: nowrap;
  transform: translate(50%, 0) rotate(270deg);
  transform-origin: 50% 50%;
}

.corzo-float-social a {
  margin-right: 24px;
}

.corzo-float-social a:last-child {
  margin-right: 0;
}

.corzo-float-social.corzo-display-after-title {
  opacity: 0;
}

.corzo-float-social .corzo-divider {
  width: 8px;
  border-top-width: 1px;
  border-top-style: solid;
  display: inline-block;
  margin: 0 24px 0.2em;
}

.corzo-float-social,
.corzo-float-social .corzo-float-social-icon {
  color: #fff;
}

.corzo-float-social .corzo-float-social-icon:hover {
  color: #fff;
}

@media only screen and (max-width: 1400px) {
  .corzo-float-social {
    display: none !important;
  }
}
