.gdlr-core-page-builder-body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gdlr-core-page-builder-body p {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: inherit;
  line-height: inherit;
}

.gdlr-core-page-builder-body .gdlr-core-no-p-space p {
  margin-bottom: 0;
}

.gdlr-core-page-builder-body ul {
  margin-top: 0;
}

.gdlr-core-page-builder-body img {
  max-width: 100%;
  height: auto;
}

.gdlr-core-page-builder-body a {
  text-decoration: none;
  transition: background 300ms, color 300ms, border-color 300ms, opacity 300ms;
  -moz-transition: background 300ms, color 300ms, border-color 300ms,
    opacity 300ms;
  -o-transition: background 300ms, color 300ms, border-color 300ms,
    opacity 300ms;
  -webkit-transition: background 300ms, color 300ms, border-color 300ms,
    opacity 300ms;
}

.clear {
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  zoom: 1;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.gdlr-core-container {
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-item-pdb {
  padding-bottom: 30px;
}

.gdlr-core-media-image {
  line-height: 0;
  position: relative;
}

.gdlr-core-media-video video {
  width: 100%;
}

.gdlr-core-left-align {
  text-align: left;
}

.gdlr-core-center-align {
  text-align: center;
}

.gdlr-core-right-align {
  text-align: right;
}

.gdlr-core-content-container {
  padding-top: 60px;
  padding-bottom: 40px;
}

.gdlr-core-line-height-0 {
  line-height: 0;
}

.gdlr-core-column-10 {
  float: left;
  width: 16.6666%;
}

.gdlr-core-column-12 {
  float: left;
  width: 20%;
}

.gdlr-core-column-15 {
  float: left;
  width: 25%;
}

.gdlr-core-column-20 {
  float: left;
  width: 33.3333%;
}

.gdlr-core-column-24 {
  float: left;
  width: 40%;
}

.gdlr-core-column-30 {
  float: left;
  width: 50%;
}

.gdlr-core-column-36 {
  float: left;
  width: 60%;
}

.gdlr-core-column-40 {
  float: left;
  width: 66.6666%;
}

.gdlr-core-column-45 {
  float: left;
  width: 75%;
}

.gdlr-core-column-48 {
  float: left;
  width: 80%;
}

.gdlr-core-column-50 {
  float: left;
  width: 83.3333%;
}

.gdlr-core-column-60 {
  float: none;
  width: 100%;
}

.gdlr-core-column-first {
  clear: left;
}

::placeholder {
  opacity: 1;
}

img[src$=".svg"] {
  width: 100%;
}

ul.slides .clearfix {
  height: auto;
  opacity: 1;
  clear: none;
}

.gdlr-core-pbf-wrapper {
  padding: 60px 0 30px;
  position: relative;
}

.gdlr-core-pbf-wrapper.gdlr-core-wrapper-centering {
  padding-top: 0;
  padding-bottom: 0;
}

.gdlr-core-pbf-wrapper-container {
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-container-custom {
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-pbf-wrapper-container-inner {
  position: relative;
}

.gdlr-core-page-builder-wrapper .gdlr-core-pbf-wrapper-container-inner {
  width: 100%;
}

.gdlr-core-page-builder-wrapper-container-bg-image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.gdlr-core-page-builder-wrapper-container-content {
  position: relative;
}

.gdlr-core-pbf-column,
.gdlr-core-pbf-column-content-margin {
  position: relative;
  transition: border-color 200ms;
  -moz-transition: border-color 200ms;
  -o-transition: border-color 200ms;
  -webkit-transition: border-color 200ms;
}

.gdlr-core-page-builder-column-right-divider {
  position: absolute;
  right: 0;
  border-right-width: 1px;
  top: 50%;
}

.gdlr-core-pbf-column-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gdlr-core-pbf-column-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  min-height: 1px;
}

.gdlr-core-pbf-element {
  clear: both;
}

.gdlr-core-pbf-section:first-child {
  padding-top: 60px;
}

.gdlr-core-pbf-section:first-child:last-child {
  padding-bottom: 30px;
}

.gdlr-core-pbf-wrapper-container {
  display: flex;
  flex-wrap: wrap;
}

.gdlr-core-pbf-wrapper-container > .gdlr-core-pbf-element {
  width: 100%;
}

.gdlr-core-pbf-wrapper-container > .gdlr-core-pbf-wrapper-container-inner {
  width: 100%;
}

.gdlr-core-pbf-background {
  width: 100%;
  height: 100%;
}

.gdlr-core-pbf-background-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -2;
  transition: border-color 300ms, border-width 100ms;
  -moz-transition: border-color 300ms, border-width 100ms;
  -o-transition: border-color 300ms, border-width 100ms;
  -webkit-transition: border-color 300ms, border-width 100ms;
}

.gdlr-core-pbf-background-frame {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: border-color 300ms;
  -moz-transition: border-color 300ms;
  -o-transition: border-color 300ms;
  -webkit-transition: border-color 300ms;
}

.gdlr-core-pbf-background-video {
  pointer-events: none;
}

.gdlr-core-pbf-background-video video {
  width: 100%;
  height: 100%;
}

.gdlr-core-pbf-flexbox {
  display: flex;
  clear: both;
}

.gdlr-core-page-builder-wrapper-top-gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.gdlr-core-page-builder-wrapper-bottom-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.gdlr-core-wrapper-bg-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.gdlr-core-wrapper-bg-overlay.gdlr-core-pos-left {
  left: 0;
  right: 50%;
}

.gdlr-core-wrapper-bg-overlay.gdlr-core-pos-right {
  right: 0;
  left: 50%;
}

.gdlr-core-effect-3d {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.gdlr-core-effect-3d.gdlr-core-animate-transform {
  transition: transform 200ms;
  -moz-transition: -moz-transform 200ms;
  -webkit-transition: -webkit-transform 200ms;
}

.gdlr-core-effect-3d .gdlr-core-pbf-column-content {
  transform: translateZ(50px);
  -webkit-transform: translateZ(50px);
  -moz-transform: translateZ(50px);
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .gdlr-core-pbf-background {
    background-attachment: scroll !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .gdlr-core-pbf-background {
    background-attachment: scroll !important;
  }
}

.gdlr-core-pbf-wrapper-full-no-space .gdlr-core-item-pdlr {
  padding-left: 0;
  padding-right: 0;
}

.gdlr-core-pbf-wrapper-full-no-space .gdlr-core-item-mglr {
  margin-left: 0;
  margin-right: 0;
}

.gdlr-core-pbf-wrapper-full-no-space .gdlr-core-item-mgb {
  margin-bottom: 0;
}

.gdlr-core-pbf-wrapper-full-no-space .gdlr-core-block-item-title-wrap {
  margin-left: 70px;
  margin-right: 60px;
}

.gdlr-core-pbf-wrapper-full-no-space .gdlr-core-pagination {
  padding-left: 40px;
  padding-right: 40px;
}

.gdlr-core-pbf-wrapper-full-no-space
  .gdlr-core-twitter-item
  .gdlr-core-block-item-title-wrap {
  margin-left: 0;
}

.gdlr-core-pbf-sidebar-wrapper {
  clear: both;
  position: relative;
  overflow: hidden;
}

.gdlr-core-pbf-sidebar-content {
  float: none;
  display: inline-block;
}

.gdlr-core-sidebar-item,
.gdlr-core-pbf-sidebar-content-inner {
  position: relative;
}

.gdlr-core-pbf-sidebar-left {
  float: left;
}

.gdlr-core-pbf-sidebar-right {
  float: right;
}

.gdlr-core-pbf-sidebar-padding {
  padding-top: 60px;
  padding-bottom: 40px;
  position: relative;
}

.gdlr-core-pbf-sidebar-left .gdlr-core-pbf-background-wrap {
  right: -1px;
}

.gdlr-core-pbf-sidebar-right .gdlr-core-pbf-background-wrap {
  left: -1px;
}

.gdlr-core-external-plugin-message {
  text-align: center;
  font-size: 19px;
  padding: 24px 15px 24px;
  background: #f7d8bc;
  color: #777;
}

.gdlr-core-particle-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 999px) {
  .gdlr-core-column-extend-left > .gdlr-core-pbf-background-wrap {
    margin-left: -50px;
  }

  .gdlr-core-column-extend-right > .gdlr-core-pbf-background-wrap {
    margin-right: -50px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1170px) {
  .gdlr-core-column-extend-left > .gdlr-core-pbf-background-wrap {
    margin-left: -100px;
  }

  .gdlr-core-column-extend-right > .gdlr-core-pbf-background-wrap {
    margin-right: -100px;
  }
}

@media only screen and (min-width: 1171px) and (max-width: 1400px) {
  .gdlr-core-column-extend-left > .gdlr-core-pbf-background-wrap {
    margin-left: -200px;
  }

  .gdlr-core-column-extend-right > .gdlr-core-pbf-background-wrap {
    margin-right: -200px;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .gdlr-core-column-extend-left > .gdlr-core-pbf-background-wrap {
    margin-left: -300px;
  }

  .gdlr-core-column-extend-right > .gdlr-core-pbf-background-wrap {
    margin-right: -300px;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .gdlr-core-column-extend-left > .gdlr-core-pbf-background-wrap {
    margin-left: -400px;
  }

  .gdlr-core-column-extend-right > .gdlr-core-pbf-background-wrap {
    margin-right: -400px;
  }
}

@media only screen and (min-width: 1801px) {
  .gdlr-core-column-extend-left > .gdlr-core-pbf-background-wrap {
    margin-left: -500px;
  }

  .gdlr-core-column-extend-right > .gdlr-core-pbf-background-wrap {
    margin-right: -500px;
  }
}

.gdlr-core-fluid-video-wrapper {
  width: 100%;
  position: relative;
  padding: 0;
  overflow: hidden;
}

.gdlr-core-fluid-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}

.gdlr-core-button {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  padding: 15px 33px;
  display: inline-block;
  -webkit-appearance: none;
  border-radius: 27px;
  -moz-border-radius: 27px;
  -webkit-border-radius: 27px;
}

.gdlr-core-button.gdlr-core-rectangle {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.gdlr-core-button-with-border {
  border-width: 1px;
  border-style: solid;
}

.gdlr-core-button-full-width {
  display: block;
}

.gdlr-core-button .gdlr-core-content {
  display: inline-block;
  vertical-align: middle;
}

.gdlr-core-button i {
  vertical-align: middle;
  line-height: 1;
  transition: color 300ms;
  -moz-transition: color 300ms;
  -o-transition: color 300ms;
  -webkit-transition: color 300ms;
}

.gdlr-core-button i.gdlr-core-pos-right {
  margin-left: 10px;
}

.gdlr-core-button i.gdlr-core-pos-left {
  margin-right: 10px;
}

.gdlr-core-body .gdlr-core-button.gdlr-core-button-transparent,
.gdlr-core-body .gdlr-core-button.gdlr-core-button-transparent:hover {
  background: transparent;
}

.gdlr-core-body [data-skin] .gdlr-core-button.gdlr-core-button-transparent,
.gdlr-core-body
  [data-skin]
  .gdlr-core-button.gdlr-core-button-transparent:hover {
  background: transparent;
}

.gdlr-core-body
  .gdlr-core-column-pbf[data-skin]
  .gdlr-core-button.gdlr-core-button-transparent,
.gdlr-core-body
  .gdlr-core-column-pbf[data-skin]
  .gdlr-core-button.gdlr-core-button-transparent:hover {
  background: transparent;
}

.gdlr-core-body .gdlr-core-button.gdlr-core-button-bottom-border-on-text {
  position: relative;
  background: transparent;
}

.gdlr-core-body .gdlr-core-button.gdlr-core-button-bottom-border-on-text:hover {
  background: transparent;
}

.gdlr-core-button-bottom-border-on-text .gdlr-core-border-on-text {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: -10px;
  border-bottom-width: 10px;
  border-bottom-style: solid;
}

.gdlr-core-button-bottom-border-on-text .gdlr-core-content {
  position: relative;
}

.gdlr-core-load-more-wrap .gdlr-core-load-more {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  letter-spacing: 2px;
  padding: 20px 50px;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 40px;
}

.gdlr-core-portfolio-item-style-modern-no-space .gdlr-core-load-more {
  margin-top: 50px;
  margin-bottom: 5px;
}

.gdlr-core-portfolio-item-style-modern-desc-no-space .gdlr-core-load-more {
  margin-top: 60px;
}

.gdlr-core-button-with-shadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.gdlr-core-hover-content-item {
  position: relative;
}

.gdlr-core-hover-content-front {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
}

.gdlr-core-hover-content-back {
  position: relative;
  opacity: 0;
  transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
}

.gdlr-core-hover-content-item:hover .gdlr-core-hover-content-front {
  opacity: 0;
}

.gdlr-core-hover-content-item:hover .gdlr-core-hover-content-back {
  opacity: 1;
}

.gdlr-core-hover-content-item .gdlr-core-hover-content-title {
  font-size: 24px;
}

.gdlr-core-hover-content-item .gdlr-core-hover-content-content {
  font-size: 18px;
}

.gdlr-core-hover-content-item
  .gdlr-core-hover-content-button
  .gdlr-core-button {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-image-content-item .gdlr-core-image-content {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.gdlr-core-image-content-item .gdlr-core-image-content-overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  right: 20px;
  -webkit-backface-visibility: hidden;
}

.gdlr-core-image-content .gdlr-core-image-content-thumbnail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}

.gdlr-core-image-content .gdlr-core-image-content-thumbnail-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background 300ms, opacity 300ms;
  -moz-transition: background 300ms, opacity 300ms;
  -webkit-transition: background 300ms, opacity 300ms;
}

.gdlr-core-image-content-item.gdlr-core-style-slide
  .gdlr-core-image-content-overlay-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms;
  -webkit-transition: max-height 500ms;
  -moz-transition: max-height 500ms;
}

.gdlr-core-image-content-item
  .gdlr-core-image-content-overlay.gdlr-core-with-link {
  pointer-events: none;
}

.gdlr-core-image-content:hover .gdlr-core-image-content-overlay-content {
  max-height: 300px;
}

.gdlr-core-image-content .gdlr-core-image-content-title {
  margin-bottom: 14px;
}

.gdlr-core-image-content .gdlr-core-image-content-icon {
  font-size: 18px;
  margin-bottom: 32px;
}

.gdlr-core-image-content .gdlr-core-image-content-link {
  font-size: 20px;
}

.gdlr-core-image-content .gdlr-core-image-content-icon {
  color: #fff;
}

.gdlr-core-image-content .gdlr-core-image-content-title {
  color: #fff;
}

.gdlr-core-image-content .gdlr-core-image-content-overlay-content {
  color: #fff;
}

.gdlr-core-image-content .gdlr-core-image-content-link,
.gdlr-core-image-content .gdlr-core-image-content-link:hover {
  color: #fff;
}

.gdlr-core-hover-box {
  position: relative;
  overflow: hidden;
  background: #fff;
  border-style: solid;
  border-width: 0;
  transition: background 300ms;
  -moz-transition: background 300ms;
  -o-transition: background 300ms;
  -webkit-transition: background 300ms;
}

.gdlr-core-hover-box .gdlr-core-hover-box-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.gdlr-core-hover-box .gdlr-core-hover-box-content-wrap {
  padding: 45px 30px 25px;
  position: relative;
}

.gdlr-core-hover-box .gdlr-core-hover-box-title {
  font-size: 22px;
  transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
  -webkit-transition: color 200ms;
}

.gdlr-core-hover-box .gdlr-core-hover-box-content {
  font-size: 17px;
  transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
  -webkit-transition: color 200ms;
}

.gdlr-core-hover-box .gdlr-core-hover-box-text-link i {
  margin-left: 10px;
}

.gdlr-core-hover-box .gdlr-core-hover-box-text-link {
  font-weight: 700;
}

.gdlr-core-hover-box .gdlr-core-hover-box-thumbnail-inside {
  margin-bottom: 25px;
}

.gdlr-core-hover-box .gdlr-core-hover-box-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  transition: opacity 150ms ease-out;
  -moz-transition: opacity 150ms ease-out;
  -o-transition: opacity 150ms ease-out;
  -webkit-transition: opacity 150ms ease-out;
}

.gdlr-core-hover-box .gdlr-core-hover-box-bg-hover {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  transition: opacity 150ms ease-out;
  -moz-transition: opacity 150ms ease-out;
  -o-transition: opacity 150ms ease-out;
  -webkit-transition: opacity 150ms ease-out;
}

.gdlr-core-hover-box:hover .gdlr-core-hover-box-bg-hover {
  opacity: 1;
}

.gdlr-core-hover-box-item .gdlr-core-flexslider .flex-control-nav {
  margin-top: 45px;
}

.gdlr-core-hover-box-item
  .gdlr-core-flexslider.gdlr-core-with-outer-frame-element
  .flex-viewport {
  padding-top: 15px;
  margin-top: -15px;
  padding-left: 15px;
  margin-left: -15px;
  padding-right: 15px;
  margin-right: -15px;
  padding-bottom: 15px;
  margin-bottom: -15px;
}

.gdlr-core-hover-box.gdlr-core-move-up-with-shadow {
  transition: transform 150ms ease-out, box-shadow 150ms ease-out,
    border-color 300ms ease-out, border-width 300ms, background 300ms;
  -moz-transition: transform 150ms ease-out, -moz-box-shadow 150ms ease-out,
    border-color 300ms ease-out, border-width 300ms, background 300ms;
  -o-transition: transform 150ms ease-out, -o-box-shadow 150ms ease-out,
    border-color 300ms ease-out, border-width 300ms, background 300ms;
  -webkit-transition: transform 150ms ease-out,
    -webkit-box-shadow 150ms ease-out, border-color 300ms ease-out,
    border-width 300ms, background 300ms;
}

.gdlr-core-hover-box-item.gdlr-core-style-left-image .gdlr-core-hover-box {
  padding: 45px 30px 25px;
}

.gdlr-core-hover-box-item.gdlr-core-style-left-image
  .gdlr-core-hover-box-thumbnail-top {
  width: 50px;
  float: left;
  margin-right: 30px;
}

.gdlr-core-hover-box-item.gdlr-core-style-left-image
  .gdlr-core-hover-box-content-wrap {
  overflow: hidden;
  padding: 0;
}

.gdlr-core-hover-box-item.gdlr-core-right-align
  .gdlr-core-hover-box-thumbnail-top {
  margin-left: auto;
}

.gdlr-core-hover-box-item.gdlr-core-center-align
  .gdlr-core-hover-box-thumbnail-top {
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-hover-box-item.gdlr-core-right-align
  .gdlr-core-hover-box-thumbnail-inside {
  margin-left: auto;
}

.gdlr-core-hover-box-item.gdlr-core-center-align
  .gdlr-core-hover-box-thumbnail-inside {
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-hover-box .gdlr-core-hover-thumbnail img:nth-child(2) {
  display: none;
}

.gdlr-core-input-wrap input,
.gdlr-core-input-wrap textarea,
.gdlr-core-input-wrap select {
  border-width: 1px 1px 2px 1px;
  border-style: solid;
}

.gdlr-core-input-wrap input[type="date"] {
  line-height: 1.2;
}

.gdlr-core-input-wrap input:focus,
.gdlr-core-input-wrap textarea:focus,
.gdlr-core-input-wrap select:focus {
  outline: none;
}

.gdlr-core-input-wrap select,
.gdlr-core-input-wrap select:focus,
.gdlr-core-input-wrap input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.gdlr-core-input-wrap input[type="button"],
.gdlr-core-input-wrap input[type="submit"],
.gdlr-core-input-wrap input[type="reset"] {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  padding: 15px 33px;
  display: inline-block;
  -webkit-appearance: none;
}

.gdlr-core-input-wrap input[type="button"],
.gdlr-core-input-wrap input[type="submit"],
.gdlr-core-input-wrap input[type="reset"] {
  border-width: 0;
  border-style: solid;
  cursor: pointer;
  transition: background 300ms, color 300ms, border-color 300ms;
  -moz-transition: background 300ms, color 300ms, border-color 300ms;
  -o-transition: background 300ms, color 300ms, border-color 300ms;
  -webkit-transition: background 300ms, color 300ms, border-color 300ms;
}

.gdlr-core-input-wrap input[type="button"]:focus,
.gdlr-core-input-wrap input[type="submit"]:focus,
.gdlr-core-input-wrap input[type="reset"]:focus {
  outline: none;
}

.gdlr-core-input-wrap input[type="button"].gdlr-core-large,
.gdlr-core-input-wrap input[type="submit"].gdlr-core-large,
.gdlr-core-input-wrap input[type="reset"].gdlr-core-large {
  min-width: 200px;
}

.gdlr-core-input-wrap input[type="button"].gdlr-core-small,
.gdlr-core-input-wrap input[type="submit"].gdlr-core-small,
.gdlr-core-input-wrap input[type="reset"].gdlr-core-small {
  font-size: 13px;
  padding: 14px 28px;
}

.gdlr-core-input-wrap input[type="button"].gdlr-core-full,
.gdlr-core-input-wrap input[type="submit"].gdlr-core-full,
.gdlr-core-input-wrap input[type="reset"].gdlr-core-full {
  width: 100%;
}

.gdlr-core-input-wrap input[type="button"].gdlr-core-round-button,
.gdlr-core-input-wrap input[type="submit"].gdlr-core-round-button {
  border-radius: 27px;
  -moz-border-radius: 27px;
  -webkit-border-radius: 27px;
}

.gdlr-core-input-wrap input[type="button"].gdlr-core-curve-button,
.gdlr-core-input-wrap input[type="submit"].gdlr-core-curve-button {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-input-wrap input[type="submit"].gdlr-core-button-transparent {
  background: transparent;
}

.gdlr-core-input-wrap input[type="submit"].gdlr-core-button-with-border {
  border-width: 2px;
  border-style: solid;
}

.gdlr-core-input-wrap.gdlr-core-round input,
.gdlr-core-input-wrap.gdlr-core-round textarea,
.gdlr-core-input-wrap.gdlr-core-round select,
.gdlr-core-input-wrap.gdlr-core-round input[type="button"],
.gdlr-core-input-wrap.gdlr-core-round input[type="submit"],
.gdlr-core-input-wrap.gdlr-core-round input[type="reset"] {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-input-wrap.gdlr-core-with-column {
  margin-left: -7px;
  margin-right: -7px;
}

.gdlr-core-input-wrap [class^="gdlr-core-column-"] {
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 14px;
}

.gdlr-core-input-wrap.gdlr-core-full-width
  input:not([type="button"]):not([type="reset"]):not([type="submit"]):not(
    [type="file"]
  ):not([type="checkbox"]):not([type="radio"]),
.gdlr-core-input-wrap.gdlr-core-full-width textarea,
.gdlr-core-input-wrap.gdlr-core-full-width select {
  width: 100%;
  display: block;
}

.gdlr-core-input-wrap.gdlr-core-no-border
  input:not([type="button"]):not([type="submit"]):not([type="file"]),
.gdlr-core-input-wrap.gdlr-core-no-border textarea,
.gdlr-core-input-wrap.gdlr-core-no-border select {
  border: none;
}

.gdlr-core-input-wrap.gdlr-core-bottom-border
  input:not([type="button"]):not([type="submit"]):not([type="file"]),
.gdlr-core-input-wrap.gdlr-core-bottom-border textarea,
.gdlr-core-input-wrap.gdlr-core-bottom-border select {
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
  border-width: 0 0 2px 0;
  background: transparent;
}

.gdlr-core-input-wrap.gdlr-core-bottom-border {
  margin-left: -20px;
  margin-right: -20px;
}

.gdlr-core-input-wrap.gdlr-core-bottom-border [class^="gdlr-core-column-"] {
  padding-left: 20px;
  padding-right: 20px;
}

.gdlr-core-input-wrap.gdlr-core-large
  input:not([type="button"]):not([type="submit"]):not([type="file"]),
.gdlr-core-input-wrap.gdlr-core-large select {
  font-size: 15px;
  padding: 15px 22px;
}

.gdlr-core-input-wrap.gdlr-core-large textarea {
  font-size: 15px;
  padding: 18px 22px;
  height: 120px;
}

.gdlr-core-input-wrap.gdlr-core-small.gdlr-core-with-column {
  margin-left: -5px;
  margin-right: -5px;
}

.gdlr-core-input-wrap.gdlr-core-small [class^="gdlr-core-column-"] {
  padding-left: 6px;
  padding-right: 6px;
}

.gdlr-core-input-wrap.gdlr-core-small
  input:not([type="button"]):not([type="reset"]):not([type="submit"]):not(
    [type="file"]
  ):not([type="checkbox"]):not([type="radio"]),
.gdlr-core-input-wrap.gdlr-core-small textarea,
.gdlr-core-input-wrap.gdlr-core-small select {
  margin-bottom: 12px;
}

.gdlr-core-input-wrap.gdlr-core-small
  input:not([type="button"]):not([type="submit"]):not([type="file"]) {
  font-size: 13px;
  padding: 12px 18px;
}

.gdlr-core-input-wrap.gdlr-core-small textarea,
.gdlr-core-input-wrap.gdlr-core-small select {
  font-size: 13px;
  padding: 12px 18px;
  height: 120px;
}

.gdlr-core-input-wrap.gdlr-core-dark-transparent-style
  input:not([type="button"]):not([type="reset"]):not([type="submit"]):not(
    [type="file"]
  ):not([type="checkbox"]):not([type="radio"]),
.gdlr-core-input-wrap.gdlr-core-dark-transparent-style textarea,
.gdlr-core-input-wrap.gdlr-core-dark-transparent-style select {
  background: rgba(49, 49, 49, 0.8);
  color: #adadad;
}

.gdlr-core-input-wrap.gdlr-core-dark-transparent-style
  ::-webkit-input-placeholder {
  color: #adadad;
}

.gdlr-core-input-wrap.gdlr-core-dark-transparent-style ::-moz-placeholder {
  color: #adadad;
}

.gdlr-core-input-wrap.gdlr-core-dark-transparent-style :-ms-input-placeholder {
  color: #adadad;
}

.gdlr-core-input-wrap.gdlr-core-dark-transparent-style :-moz-placeholder {
  color: #adadad;
}

.gdlr-core-pagination a {
  cursor: pointer;
}

.gdlr-core-pagination a.gdlr-core-active {
  cursor: default;
}

.gdlr-core-pagination a,
.gdlr-core-pagination span {
  white-space: nowrap;
  font-weight: 700;
}

.gdlr-core-pagination a.prev:before {
  content: "\f104";
  font-family: fontAwesome;
  line-height: 1;
}

.gdlr-core-pagination a.next:before {
  content: "\f105";
  font-family: fontAwesome;
  line-height: 1;
}

.gdlr-core-pagination.gdlr-core-with-border a,
.gdlr-core-pagination.gdlr-core-with-border span {
  background: transparent;
  border-width: 2px;
  border-style: solid;
}

.gdlr-core-pagination.gdlr-core-with-border a.gdlr-core-active,
.gdlr-core-pagination.gdlr-core-with-border a:hover {
  background: transparent;
}

.gdlr-core-page-builder-body
  [data-skin]
  .gdlr-core-pagination.gdlr-core-with-border
  a,
.gdlr-core-page-builder-body
  [data-skin]
  .gdlr-core-pagination.gdlr-core-with-border
  span,
.gdlr-core-page-builder-body
  [data-skin]
  .gdlr-core-pagination.gdlr-core-with-border
  a.gdlr-core-active,
.gdlr-core-page-builder-body
  [data-skin]
  .gdlr-core-pagination.gdlr-core-with-border
  a:hover {
  background: transparent;
}

.gdlr-core-pagination.gdlr-core-style-plain a,
.gdlr-core-pagination.gdlr-core-style-plain span {
  background: transparent;
  margin-left: 15px;
}

.gdlr-core-pagination.gdlr-core-style-plain a.gdlr-core-active,
.gdlr-core-pagination.gdlr-core-style-plain a:hover {
  background: transparent;
}

.gdlr-core-pagination.gdlr-core-style-plain a:before,
.gdlr-core-pagination.gdlr-core-style-plain span:before {
  content: "/ ";
  margin-right: 15px;
}

.gdlr-core-pagination.gdlr-core-style-plain a:first-child,
.gdlr-core-pagination.gdlr-core-style-plain span:first-child {
  margin-left: 0;
}

.gdlr-core-pagination.gdlr-core-style-plain a:first-child:before,
.gdlr-core-pagination.gdlr-core-style-plain span:first-child:before {
  display: none;
}

.gdlr-core-page-builder-body
  [data-skin]
  .gdlr-core-pagination.gdlr-core-style-plain
  a,
.gdlr-core-page-builder-body
  [data-skin]
  .gdlr-core-pagination.gdlr-core-style-plain
  span,
.gdlr-core-page-builder-body
  [data-skin]
  .gdlr-core-pagination.gdlr-core-style-plain
  a.gdlr-core-active,
.gdlr-core-page-builder-body
  [data-skin]
  .gdlr-core-pagination.gdlr-core-style-plain
  a:hover {
  background: transparent;
}

.gdlr-core-pagination.gdlr-core-style-rectangle a,
.gdlr-core-pagination.gdlr-core-style-rectangle span {
  display: inline-block;
  font-size: 12px;
  padding: 9px 14px;
  margin-right: 1px;
}

.gdlr-core-pagination.gdlr-core-style-rectangle.gdlr-core-with-border a,
.gdlr-core-pagination.gdlr-core-style-rectangle.gdlr-core-with-border span {
  padding: 7px 12px;
}

.gdlr-core-pagination.gdlr-core-style-round a,
.gdlr-core-pagination.gdlr-core-style-round span {
  display: inline-block;
  font-size: 14px;
  padding: 9px 14px;
  margin-right: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-pagination.gdlr-core-style-round.gdlr-core-with-border a,
.gdlr-core-pagination.gdlr-core-style-round.gdlr-core-with-border span {
  padding: 7px 12px;
  line-height: 21px;
}

.gdlr-core-pagination.gdlr-core-style-circle a,
.gdlr-core-pagination.gdlr-core-style-circle span {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 12px;
  margin-right: 4px;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-pagination.gdlr-core-style-circle.gdlr-core-with-border a,
.gdlr-core-pagination.gdlr-core-style-circle.gdlr-core-with-border span {
  line-height: 41px;
}

.gdlr-core-filterer-wrap {
  margin-bottom: 50px;
  position: relative;
}

.gdlr-core-filterer-wrap.gdlr-core-style-text {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.gdlr-core-filterer-wrap.gdlr-core-style-text .gdlr-core-filterer {
  margin: 0 18px;
  white-space: nowrap;
}

.gdlr-core-filterer-wrap.gdlr-core-style-text .gdlr-core-filterer:first-child {
  margin-left: 0;
}

.gdlr-core-filterer-wrap.gdlr-core-style-text .gdlr-core-filterer:last-child {
  margin-right: 0;
}

.gdlr-core-page-builder-body
  [data-skin]
  .gdlr-core-filterer-wrap.gdlr-core-style-text
  .gdlr-core-button-color {
  background: transparent;
}

.gdlr-core-filterer-wrap.gdlr-core-style-button a {
  display: inline-block;
  padding: 10px 20px;
  background: #f1f1f1;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 4px;
  letter-spacing: 1px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.gdlr-core-filterer-wrap.gdlr-core-style-button a:first-child {
  margin-left: 0;
}

.gdlr-core-filterer-wrap.gdlr-core-style-button a:last-child {
  margin-right: 0;
}

.gdlr-core-filterer-wrap.gdlr-core-style-button a.gdlr-core-active {
  cursor: default;
}

.gdlr-core-filterer-slide-bar {
  position: absolute;
  bottom: -6px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #000;
}

.gdlr-core-filterer-wrap.gdlr-core-round-slide-bar
  .gdlr-core-filterer-slide-bar {
  border-bottom: 0;
  bottom: -8px;
}

.gdlr-core-filterer-wrap.gdlr-core-round-slide-bar
  .gdlr-core-filterer-slide-bar:before {
  display: block;
  content: " ";
  width: 8px;
  height: 8px;
  margin: 0 auto;
  border-radius: 4px;
}

.gdlr-core-pbf-wrapper-full-no-space .gdlr-core-filterer-wrap {
  padding-left: 50px;
  padding-right: 50px;
}

.gdlr-core-price-plan-item .gdlr-core-price-plan {
  margin-bottom: 20px;
  padding: 30px 30px 20px 80px;
  position: relative;
  border-width: 3px;
  border-style: solid;
  cursor: pointer;
  border-color: transparent;
  transition: border-color 300ms;
  -moz-transition: border-color 300ms;
  -webkit-transition: border-color 300ms;
}

.gdlr-core-price-plan .gdlr-core-price-plan-title {
  font-size: 21px;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 10px;
}

.gdlr-core-price-plan .gdlr-core-price-plan-content {
  font-size: 16px;
  font-weight: 400;
  display: none;
  margin-bottom: 10px;
}

.gdlr-core-price-plan .gdlr-core-price-plan-price {
  font-size: 21px;
  position: absolute;
  right: 30px;
  top: 30px;
  font-weight: 700;
  font-style: italic;
}

.gdlr-core-price-plan .gdlr-core-price-plan-option {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 38px;
  left: 36px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.gdlr-core-price-plan.gdlr-core-active .gdlr-core-price-plan-option span {
  display: block;
  width: 12px;
  height: 12px;
  margin: 4px;
  border-radius: 50%;
  transition: background-color 300ms;
  -moz-transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
}

.gdlr-core-price-plan.gdlr-core-active .gdlr-core-price-plan-content {
  display: block;
}

.gdlr-core-price-plan-item .gdlr-core-button {
  cursor: pointer;
  margin-top: 30px;
  font-size: 17px;
  font-weight: 700;
  font-style: italic;
  text-transform: none;
  padding: 22px 43px;
}

.gdlr-core-price-plan-item .gdlr-core-button i {
  vertical-align: middle;
  margin-left: 10px;
}

.gdlr-core-price-plan-forms {
  display: none;
}

.gdlr-core-price-plan-forms > div {
  position: relative;
}

.gdlr-core-price-plan-forms .gdlr-core-price-plan-selected-title {
  font-size: 23px;
  margin-bottom: 20px;
}

.gdlr-core-price-plan-forms .gdlr-core-price-plan-selected-back {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic;
  position: absolute;
  bottom: 0;
  right: 0;
}

.gdlr-core-price-plan-forms .gdlr-core-price-plan-selected-back i {
  font-size: 35px;
  vertical-align: middle;
  margin-right: 12px;
}

.gdlr-core-price-plan-forms .gdlr-core-input-wrap input[type="button"],
.gdlr-core-price-plan-forms .gdlr-core-input-wrap input[type="submit"],
.gdlr-core-price-plan-forms .gdlr-core-input-wrap input[type="reset"] {
  font-size: 17px;
  font-weight: 700;
  font-style: italic;
  text-transform: none;
  padding: 22px 43px;
}

.gdlr-core-price-plan-item .gdlr-core-price-plan {
  background: #202020;
}

.gdlr-core-price-plan .gdlr-core-price-plan-title {
  color: #fff;
}

.gdlr-core-price-plan .gdlr-core-price-plan-price {
  color: #fff;
}

.gdlr-core-price-plan .gdlr-core-price-plan-option {
  background: #686868;
}

.gdlr-core-price-plan.gdlr-core-active .gdlr-core-price-plan-option span {
  background: #4c00ff;
}

.gdlr-core-price-plan-item .gdlr-core-price-plan.gdlr-core-active {
  border-color: #4c00ff;
}

@media only screen and (max-width: 767px) {
  .gdlr-core-price-plan-forms .gdlr-core-price-plan-selected-back {
    position: static;
  }

  .gdlr-core-price-plan .gdlr-core-price-plan-price {
    position: static;
    margin-bottom: 12px;
  }
}

.gdlr-core-price-list-item .gdlr-core-price-list {
  line-height: 1.2;
  margin-bottom: 18px;
}

.gdlr-core-price-list-item .gdlr-core-price-list-title {
  float: left;
  margin-right: 20px;
}

.gdlr-core-price-list-item .gdlr-core-price-list-title img {
  vertical-align: middle;
  margin-right: 10px;
}

.gdlr-core-price-list-item .gdlr-core-price-list-price {
  float: right;
  margin-left: 20px;
}

.gdlr-core-price-list-item .gdlr-core-price-list-caption {
  margin-top: 10px;
}

.gdlr-core-price-list-item .gdlr-core-price-list-divider {
  border-width: 0 0 1px;
  border-bottom-style: solid;
  overflow: hidden;
}

.gdlr-core-price-list-item .gdlr-core-price-list-divider:before {
  content: "1";
  visibility: hidden;
  display: block;
  margin-bottom: -5px;
}

.gdlr-core-price-table-item .gdlr-core-price-table-tab-title-wrap {
  text-align: center;
  margin-bottom: 60px;
}

.gdlr-core-price-table-item .gdlr-core-price-table-tab-title-inner {
  display: inline-block;
  line-height: 1;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

.gdlr-core-price-table-item .gdlr-core-price-table-tab-title {
  padding: 16px 43px;
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  transition: background 300ms, color 300ms;
  -moz-transition: background 300ms, color 300ms;
  -o-transition: background 300ms, color 300ms;
  -webkit-transition: background 300ms, color 300ms;
}

.gdlr-core-price-table-item .gdlr-core-price-table-tab-title.gdlr-core-active {
  background: #000;
  color: #fff;
}

.gdlr-core-price-table-item .gdlr-core-price-table-tab-content {
  display: none;
}

.gdlr-core-price-table-item
  .gdlr-core-price-table-tab-content.gdlr-core-active {
  display: block;
}

.gdlr-core-price-table-item .gdlr-core-price-table {
  text-align: center;
  margin: 45px 5px 0;
  overflow: hidden;
}

.gdlr-core-price-table .gdlr-core-price-table-head {
  padding: 33px 20px;
  margin-right: -1px;
}

.gdlr-core-price-table .gdlr-core-price-table-image {
  margin-bottom: 32px;
  margin-top: 20px;
}

.gdlr-core-price-table .gdlr-core-price-table-icon {
  font-size: 51px;
  line-height: 1;
  margin-bottom: 32px;
  margin-top: 20px;
}

.gdlr-core-price-table .gdlr-core-price-table-title {
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 6px;
}

.gdlr-core-price-table .gdlr-core-price-table-caption {
  font-size: 14px;
}

.gdlr-core-price-table .gdlr-core-price-table-content-wrap {
  padding-bottom: 10px;
}

.gdlr-core-price-table .gdlr-core-price-table-content {
  padding: 10px 20px;
}

.gdlr-core-price-table .gdlr-core-price-table-button {
  margin: 35px 20px;
}

.gdlr-core-price-table .gdlr-core-price-table-price {
  line-height: 1;
  padding: 25px 20px 40px;
}

.gdlr-core-price-table .gdlr-core-price-prefix {
  font-size: 25px;
  vertical-align: super;
}

.gdlr-core-price-table .gdlr-core-price-table-price-number {
  font-size: 60px;
  font-weight: 300;
  margin: 0 8px;
  vertical-align: middle;
}

.gdlr-core-price-table .gdlr-core-price-suffix {
  font-size: 17px;
  vertical-align: bottom;
}

.gdlr-core-price-table
  .gdlr-core-price-table-button.gdlr-core-button-with-border {
  border-radius: 30px;
  border-width: 2px;
}

.gdlr-core-price-table
  .gdlr-core-price-table-button.gdlr-core-button-transparent {
  background: transparent !important;
}

.gdlr-core-price-table-item .gdlr-core-price-table.gdlr-core-active {
  margin-top: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-head {
  padding: 34px 20px 26px;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-icon {
  font-size: 70px;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-prefix {
  font-size: 34px;
  font-weight: 500;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-price-number {
  font-size: 72px;
  font-weight: 400;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-price {
  padding: 35px 20px 50px;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-content-wrap {
  padding-bottom: 30px;
}

.gdlr-core-price-list-shortcode ul {
  margin: 0 -20px;
  list-style: none;
}

.gdlr-core-price-list-shortcode ul li {
  padding: 0 20px;
  margin-bottom: 16px;
}

.gdlr-core-price-list-shortcode ul li i {
  display: inline-block;
  width: 25px;
  margin-right: 8px;
}

.gdlr-core-price-list-shortcode.gdlr-core-with-divider ul li {
  padding: 14px 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 0;
}

.gdlr-core-space-shortcode {
  display: block;
}

.gdlr-core-price-table-item.gdlr-core-style-2 .gdlr-core-price-table {
  text-align: left;
  overflow: hidden;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.gdlr-core-price-table-item.gdlr-core-style-2 .gdlr-core-price-table {
  margin: 0 15px;
}

.gdlr-core-price-table-item.gdlr-core-style-2
  .gdlr-core-price-table-price-number {
  font-size: 52px;
  font-weight: 500;
  display: inline-block;
  margin: 0 0 -9px;
}

.gdlr-core-price-table-item.gdlr-core-style-2 .gdlr-core-price-suffix {
  font-size: 14px;
}

.gdlr-core-price-table-item.gdlr-core-style-2 .gdlr-core-price-table-icon {
  margin-top: 0;
  font-size: 51px;
}

.gdlr-core-price-table-item.gdlr-core-style-2 .gdlr-core-price-table-title {
  font-size: 22px;
  text-transform: none;
  font-weight: 500;
}

.gdlr-core-price-table-item.gdlr-core-style-2 .gdlr-core-price-table-head {
  padding: 50px 35px 10px;
  background: transparent !important;
}

.gdlr-core-price-table-item.gdlr-core-style-2 .gdlr-core-price-table-price {
  padding: 0 35px 10px;
  background: transparent !important;
}

.gdlr-core-price-table-item.gdlr-core-style-2 .gdlr-core-price-table-button {
  margin: 35px 35px;
}

.gdlr-core-price-table-item.gdlr-core-style-2
  .gdlr-core-price-table-content-wrap {
  padding-bottom: 40px;
}

.gdlr-core-price-table-item.gdlr-core-style-2 .gdlr-core-price-table-content {
  text-align: left;
  padding: 45px 35px 10px;
}

.gdlr-core-price-table-item.gdlr-core-style-2
  .gdlr-core-price-table-content
  ul {
  margin-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.gdlr-core-price-table-item.gdlr-core-style-2
  .gdlr-core-price-table-content
  ul
  li {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 16px;
  padding-left: 0;
  padding-right: 0;
}

.gdlr-core-price-table-item.gdlr-core-style-2 .gdlr-core-price-table-button {
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-bottom: 10px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.gdlr-core-price-table-item.gdlr-core-style-2
  .gdlr-core-price-table-button.gdlr-core-button-with-border {
  border-radius: 30px;
}

.gdlr-core-price-table-item.gdlr-core-style-2 .gdlr-core-price-table {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
}

.gdlr-core-page-builder-body blockquote {
  margin: 0;
}

.gdlr-core-blockquote-item .gdlr-core-blockquote-item-quote {
  line-height: 1;
}

.gdlr-core-blockquote-item .gdlr-core-blockquote-item-content-wrap {
  overflow: hidden;
}

.gdlr-core-blockquote-item .gdlr-core-blockquote-item-content {
  font-style: italic;
}

.gdlr-core-blockquote-item .gdlr-core-blockquote-item-author:before {
  content: "-";
  margin-right: 4px;
}

.gdlr-core-blockquote-item .gdlr-core-blockquote-item-author {
  font-style: italic;
  font-weight: 700;
}

.gdlr-core-blockquote-item .gdlr-core-blockquote-item-author-position {
  font-weight: 400;
}

.gdlr-core-blockquote-item .gdlr-core-blockquote-item-author-position:before {
  content: "/";
  margin: 0 9px;
}

.gdlr-core-blockquote-item.gdlr-core-left-align
  .gdlr-core-blockquote-item-quote {
  float: left;
}

.gdlr-core-blockquote-item.gdlr-core-right-align
  .gdlr-core-blockquote-item-quote {
  float: right;
}

.gdlr-core-blockquote-item.gdlr-core-small-size
  .gdlr-core-blockquote-item-quote {
  font-size: 80px;
  margin-top: -5px;
}

.gdlr-core-blockquote-item.gdlr-core-small-size
  .gdlr-core-blockquote-item-content {
  font-size: 14px;
}

.gdlr-core-blockquote-item.gdlr-core-small-size
  .gdlr-core-blockquote-item-author {
  font-size: 13px;
  margin-top: 26px;
}

.gdlr-core-blockquote-item.gdlr-core-small-size.gdlr-core-center-align
  .gdlr-core-blockquote-item-quote {
  margin-bottom: -10px;
}

.gdlr-core-blockquote-item.gdlr-core-small-size.gdlr-core-left-align
  .gdlr-core-blockquote-item-quote {
  margin-right: 34px;
}

.gdlr-core-blockquote-item.gdlr-core-small-size.gdlr-core-right-align
  .gdlr-core-blockquote-item-quote {
  margin-left: 34px;
}

.gdlr-core-blockquote-item.gdlr-core-medium-size
  .gdlr-core-blockquote-item-quote {
  font-size: 100px;
  margin-top: -8px;
}

.gdlr-core-blockquote-item.gdlr-core-medium-size
  .gdlr-core-blockquote-item-content {
  font-size: 16px;
}

.gdlr-core-blockquote-item.gdlr-core-medium-size
  .gdlr-core-blockquote-item-author {
  font-size: 14px;
  margin-top: 28px;
}

.gdlr-core-blockquote-item.gdlr-core-medium-size.gdlr-core-center-align
  .gdlr-core-blockquote-item-quote {
  margin-bottom: -16px;
}

.gdlr-core-blockquote-item.gdlr-core-medium-size.gdlr-core-left-align
  .gdlr-core-blockquote-item-quote {
  margin-right: 37px;
}

.gdlr-core-blockquote-item.gdlr-core-medium-size.gdlr-core-right-align
  .gdlr-core-blockquote-item-quote {
  margin-left: 37px;
}

.gdlr-core-blockquote-item.gdlr-core-large-size
  .gdlr-core-blockquote-item-quote {
  font-size: 120px;
  margin-top: -10px;
}

.gdlr-core-blockquote-item.gdlr-core-large-size
  .gdlr-core-blockquote-item-content {
  font-size: 18px;
}

.gdlr-core-blockquote-item.gdlr-core-large-size
  .gdlr-core-blockquote-item-author {
  font-size: 16px;
  margin-top: 30px;
}

.gdlr-core-blockquote-item.gdlr-core-large-size.gdlr-core-center-align
  .gdlr-core-blockquote-item-quote {
  margin-bottom: -22px;
}

.gdlr-core-blockquote-item.gdlr-core-large-size.gdlr-core-left-align
  .gdlr-core-blockquote-item-quote {
  margin-right: 40px;
}

.gdlr-core-blockquote-item.gdlr-core-large-size.gdlr-core-right-align
  .gdlr-core-blockquote-item-quote {
  margin-left: 40px;
}

.gdlr-core-columnize-item .gdlr-core-columnize-item-content {
  column-count: 3;
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-gap: 60px;
  -moz-column-gap: 60px;
  -webkit-column-gap: 60px;
  column-rule-width: 1px;
  -moz-column-rule-width: 1px;
  -webkit-column-rule-width: 1px;
  column-rule-style: solid;
  -moz-column-rule-style: solid;
  -webkit-column-rule-style: solid;
}

.gdlr-core-column-service-item .gdlr-core-column-service-title {
  font-size: 14px;
  line-height: 1.55;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}

.gdlr-core-column-service-item .gdlr-core-column-service-caption {
  font-size: 14px;
  font-style: italic;
  margin-top: 5px;
}

.gdlr-core-column-service-item .gdlr-core-column-service-content {
  font-size: 15px;
}

.gdlr-core-column-service-item .gdlr-core-column-service-read-more {
  font-size: 14px;
  font-style: italic;
  display: inline-block;
}

.gdlr-core-column-service-item .gdlr-core-column-service-read-more i {
  margin-left: 15px;
  vertical-align: middle;
  display: inline-block;
}

.gdlr-core-column-service-item
  .gdlr-core-column-service-media.gdlr-core-media-icon {
  line-height: 1;
}

.gdlr-core-column-service-item
  .gdlr-core-column-service-media.gdlr-core-media-icon
  i {
  font-size: 30px;
}

.gdlr-core-column-service-item
  .gdlr-core-column-service-media.gdlr-core-media-icon.gdlr-core-icon-style-round
  i {
  line-height: 30px;
  width: 30px;
  padding: 17px;
  text-align: center;
  display: inline-block;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
}

.gdlr-core-column-service-item
  .gdlr-core-column-service-media.gdlr-core-character {
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
}

.gdlr-core-column-service-item.gdlr-core-center-align
  .gdlr-core-column-service-title-wrap {
  margin-bottom: 15px;
}

.gdlr-core-column-service-item.gdlr-core-center-align
  .gdlr-core-column-service-media {
  margin-bottom: 28px;
  display: inline-block;
}

.gdlr-core-column-service-item.gdlr-core-column-service-icon-top
  .gdlr-core-column-service-title-wrap {
  margin-bottom: 20px;
}

.gdlr-core-column-service-item.gdlr-core-column-service-icon-top
  .gdlr-core-column-service-media {
  margin-bottom: 28px;
  display: inline-block;
}

.gdlr-core-column-service-icon-left .gdlr-core-column-service-content-wrapper {
  overflow: hidden;
}

.gdlr-core-column-service-icon-left .gdlr-core-column-service-title-wrap {
  margin-bottom: 16px;
}

.gdlr-core-column-service-icon-left .gdlr-core-column-service-media {
  max-width: 30%;
}

.gdlr-core-column-service-icon-left.gdlr-core-left-align
  .gdlr-core-column-service-media {
  float: left;
  margin-right: 25px;
}

.gdlr-core-column-service-icon-left.gdlr-core-right-align
  .gdlr-core-column-service-media {
  float: right;
  margin-left: 25px;
}

.gdlr-core-column-service-icon-left.gdlr-core-no-caption
  .gdlr-core-column-service-title-wrap {
  padding-top: 5px;
}

.gdlr-core-column-service-icon-left.gdlr-core-with-caption
  .gdlr-core-column-service-media.gdlr-core-media-icon {
  margin-top: 7px;
}

.gdlr-core-column-service-icon-left-title .gdlr-core-column-service-title-wrap {
  overflow: hidden;
  margin-bottom: 18px;
}

.gdlr-core-column-service-icon-left-title .gdlr-core-column-service-content {
  clear: both;
}

.gdlr-core-column-service-icon-left-title .gdlr-core-column-service-media {
  margin-bottom: 18px;
  max-width: 30%;
}

.gdlr-core-column-service-icon-left-title.gdlr-core-left-align
  .gdlr-core-column-service-media {
  float: left;
  margin-right: 25px;
}

.gdlr-core-column-service-icon-left-title.gdlr-core-right-align
  .gdlr-core-column-service-media {
  float: right;
  margin-left: 25px;
}

.gdlr-core-column-service-icon-left-title.gdlr-core-no-caption
  .gdlr-core-column-service-title-wrap {
  padding-top: 5px;
}

.gdlr-core-column-service-icon-left-title.gdlr-core-with-caption
  .gdlr-core-column-service-media.gdlr-core-media-icon {
  margin-top: 7px;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-inner {
  border-style: solid;
  padding: 34px 42px 29px;
  position: relative;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-remove {
  position: absolute;
  right: 12px;
  top: 12px;
  line-height: 1;
  cursor: pointer;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-icon {
  font-size: 22px;
  width: 43px;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-title {
  font-size: 14px;
  line-height: 34px;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-head {
  margin-bottom: 18px;
}

.gdlr-core-alert-box-item.gdlr-core-left-align .gdlr-core-alert-box-item-icon {
  float: left;
}

.gdlr-core-alert-box-item.gdlr-core-left-align
  .gdlr-core-alert-box-item-content {
  padding-left: 43px;
}

.gdlr-core-alert-box-item.gdlr-core-right-align .gdlr-core-alert-box-item-icon {
  float: right;
}

.gdlr-core-alert-box-item.gdlr-core-right-align
  .gdlr-core-alert-box-item-content {
  padding-right: 43px;
}

.gdlr-core-alert-box-item.gdlr-core-center-align
  .gdlr-core-alert-box-item-title {
  display: inline-block;
}

.gdlr-core-alert-box-item.gdlr-core-center-align
  .gdlr-core-alert-box-item-icon {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.gdlr-core-alert-box-item.gdlr-core-no-icon .gdlr-core-alert-box-item-content {
  padding-left: 0;
  padding-right: 0;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-icon {
  font-size: 35px;
  margin-bottom: 30px;
  margin-top: 5px;
  display: inline-block;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-image {
  margin-bottom: 37px;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-title-divider {
  width: 23px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  margin: 16px auto 12px;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-caption {
  font-size: 15px;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-content {
  font-size: 15px;
  margin-top: 21px;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-button {
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 13px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.gdlr-core-flipbox .gdlr-core-flipbox-content {
  position: relative;
}

.gdlr-core-flipbox .gdlr-core-flipbox-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}

.gdlr-core-flipbox .gdlr-core-flipbox-frame {
  position: absolute;
  margin: 20px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-style: solid;
  border-width: 5px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-flipbox .gdlr-core-flipbox-front,
.gdlr-core-flipbox .gdlr-core-flipbox-back {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.gdlr-core-flipbox .gdlr-core-flipbox-back {
  display: none;
}

.gdlr-core-flipbox.gdlr-core-after-init .gdlr-core-flipbox-back {
  display: block;
}

.gdlr-core-flipbox .gdlr-core-flipbox-front,
.gdlr-core-flipbox .gdlr-core-flipbox-back {
  padding: 50px 40px 40px;
}

.gdlr-core-flipbox .gdlr-core-flipbox-front.gdlr-core-flipbox-type-outer,
.gdlr-core-flipbox .gdlr-core-flipbox-back.gdlr-core-flipbox-type-outer {
  border-width: 5px;
  border-style: solid;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-flipbox-item .gdlr-core-icon-left .gdlr-core-flipbox-content-wrap {
  overflow: hidden;
}

.gdlr-core-flipbox-item .gdlr-core-icon-left .gdlr-core-flipbox-item-icon,
.gdlr-core-flipbox-item .gdlr-core-icon-left .gdlr-core-flipbox-item-image {
  float: left;
  margin-right: 30px;
}

.gdlr-core-feature-content-item .gdlr-core-feature-content {
  position: relative;
  border-style: solid;
  overflow: hidden;
}

.gdlr-core-feature-content-item .gdlr-core-feature-content-box {
  padding: 30px 40px 30px;
}

.gdlr-core-feature-content-item
  .gdlr-core-feature-content-box.gdlr-core-with-background {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.gdlr-core-feature-content-item
  .gdlr-core-feature-content-box.gdlr-core-without-background {
  background: #000;
  position: relative;
}

.gdlr-core-feature-content-item .gdlr-core-feature-box-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.gdlr-core-feature-content-item .gdlr-core-feature-content-caption {
  font-size: 13px;
  color: #fff;
}

.gdlr-core-feature-content-item .gdlr-core-feature-content-title {
  display: inline-block;
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 18px;
  color: #fff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #d32525;
}

.gdlr-core-feature-content-item .gdlr-core-feature-content-content {
  font-size: 18px;
  color: #fff;
}

.gdlr-core-feature-content-item .gdlr-core-feature-content-learn-more {
  color: #d32525;
  font-weight: 700;
}

.gdlr-core-feature-content-item .gdlr-core-feature-content-learn-more:hover {
  color: #d32525;
}

.gdlr-core-feature-content-item .gdlr-core-feature-content-learn-more i {
  font-size: 31px;
  margin-left: 10px;
  vertical-align: middle;
}

.gdlr-core-feature-content .gdlr-core-feature-content-wrap {
  max-height: 0;
  overflow: hidden;
  transition: max-height 550ms ease-out;
  -webkit-transition: max-height 550ms ease-out;
  -moz-transition: max-height 550ms ease-out;
}

.gdlr-core-feature-content:hover .gdlr-core-feature-content-wrap {
  max-height: 250px;
}

.gdlr-core-feature-content:hover .gdlr-core-feature-box-overlay {
  opacity: 0.8 !important;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box {
  position: relative;
  overflow: hidden;
  padding: 50px 40px 40px;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box-content {
  position: relative;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box-item-icon {
  font-size: 35px;
  margin-bottom: 30px;
  margin-top: 5px;
  display: inline-block;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box-item-image {
  margin-bottom: 37px;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box-item-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box-item-caption {
  font-size: 15px;
  margin-bottom: 18px;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box-item-content {
  font-size: 15px;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box-frame {
  position: absolute;
  margin: 20px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-style: solid;
  border-width: 5px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
}

.gdlr-core-feature-box-item
  .gdlr-core-feature-box.gdlr-core-feature-box-type-outer {
  border-width: 5px;
  border-style: solid;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-promo-box-item .gdlr-core-promo-box-item-title {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  margin-bottom: 14px;
}

.gdlr-core-promo-box-item .gdlr-core-promo-box-item-content {
  font-size: 14px;
}

.gdlr-core-promo-box-item .gdlr-core-promo-box-content-wrap {
  padding-top: 30px;
}

.gdlr-core-promo-box-item
  .gdlr-core-promo-box-content-wrap.gdlr-core-with-frame {
  padding: 35px 35px 15px 35px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
}

.gdlr-core-promo-box-item
  .gdlr-core-promo-box-content-wrap.gdlr-core-with-shadow {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.gdlr-core-marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1;
}

.gdlr-core-pbf-wrapper-marquee {
  position: absolute;
  font-size: 90px;
  font-weight: 800;
  top: 0;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar {
  position: relative;
  margin-top: 32px;
}

.gdlr-core-skill-bar-item.gdlr-core-item-pdb.gdlr-core-size-large
  .gdlr-core-skill-bar {
  margin-top: 30px;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar:first-child {
  margin-top: 0;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-filled {
  height: 100%;
  width: 0%;
  position: relative;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-head {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-title {
  margin-right: 12px;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-right {
  font-weight: 700;
  float: right;
  white-space: nowrap;
  text-transform: none;
}

.gdlr-core-skill-bar-item.gdlr-core-type-round .gdlr-core-skill-bar-progress {
  overflow: hidden;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-skill-bar-item.gdlr-core-type-rectangle2
  .gdlr-core-skill-bar-filled {
  overflow: visible !important;
}

.gdlr-core-skill-bar-item.gdlr-core-type-rectangle2
  .gdlr-core-skill-bar-filled-indicator {
  position: absolute;
  top: -8px;
  bottom: -8px;
  right: 0;
  width: 2px;
}

.gdlr-core-skill-bar-item.gdlr-core-type-rectangle2 .gdlr-core-skill-bar-right {
  position: absolute;
  right: 0;
  top: -36px;
  margin-right: -19px;
  opacity: 0;
  transition: opacity 800ms;
  -moz-transition: opacity 800ms;
  -o-transition: opacity 800ms;
  -webkit-transition: opacity 800ms;
}

.gdlr-core-skill-bar-item.gdlr-core-type-rectangle2
  .gdlr-core-skill-bar-filled.gdlr-core-animate
  .gdlr-core-skill-bar-right {
  opacity: 1;
}

.gdlr-core-skill-bar-item.gdlr-core-size-small .gdlr-core-skill-bar-head {
  font-size: 13px;
  margin-bottom: 12px;
}

.gdlr-core-skill-bar-item.gdlr-core-size-small .gdlr-core-skill-bar-progress {
  height: 6px;
}

.gdlr-core-skill-bar-item.gdlr-core-size-medium .gdlr-core-skill-bar-head {
  font-size: 15px;
  margin-bottom: 12px;
}

.gdlr-core-skill-bar-item.gdlr-core-size-medium .gdlr-core-skill-bar-progress {
  height: 15px;
}

.gdlr-core-skill-bar-item.gdlr-core-size-large .gdlr-core-skill-bar-head {
  font-size: 12px;
  line-height: 18px;
  padding: 11px 18px;
  position: relative;
  z-index: 1;
}

.gdlr-core-skill-bar-item.gdlr-core-size-large .gdlr-core-skill-bar-progress {
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gdlr-core-icon-item {
  line-height: 0;
}

.gdlr-core-icon-item .gdlr-core-icon-item-wrap {
  display: inline-block;
  padding: 20px;
}

.gdlr-core-icon-item .gdlr-core-icon-item-wrap.gdlr-core-with-border {
  border-width: 2px;
  border-style: solid;
}

.gdlr-core-icon-item .gdlr-core-icon-item-wrap.gdlr-core-icon-item-type-circle,
.gdlr-core-icon-item
  .gdlr-core-icon-item-wrap.gdlr-core-icon-item-type-circle
  .gdlr-core-icon-item-icon {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-icon-item .gdlr-core-icon-item-icon {
  font-size: 45px;
  min-width: 45px;
  min-height: 45px;
  text-align: center;
  display: inline-block;
}

.gdlr-core-icon-item .gdlr-core-icon-item-icon.gdlr-core-with-border {
  padding: 20px;
  border-width: 2px;
  border-style: solid;
}

.gdlr-core-skill-circle-item .gdlr-core-skill-circle {
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  position: relative;
}

.gdlr-core-skill-circle-item .gdlr-core-skill-circle-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}

.gdlr-core-skill-circle-item .gdlr-core-skill-circle-head {
  font-weight: 700;
  letter-spacing: 1px;
}

.gdlr-core-skill-circle-item .gdlr-core-skill-circle-caption {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}

.gdlr-core-skill-circle-item .gdlr-core-skill-circle canvas {
  max-width: 100%;
  max-height: 100%;
}

.gdlr-core-skill-circle-item
  .gdlr-core-skill-circle.gdlr-core-skill-circle-align-center {
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-skill-circle-item
  .gdlr-core-skill-circle.gdlr-core-skill-circle-align-left {
  margin-left: 0;
  margin-right: auto;
}

.gdlr-core-skill-circle-item
  .gdlr-core-skill-circle.gdlr-core-skill-circle-align-right {
  margin-left: auto;
  margin-right: 0;
}

.gdlr-core-skill-circle-size-large .gdlr-core-skill-circle-content {
  margin-top: -39px;
}

.gdlr-core-skill-circle-size-large .gdlr-core-skill-circle-head {
  font-size: 22px;
}

.gdlr-core-skill-circle-size-large .gdlr-core-skill-circle-caption {
  font-size: 14px;
  margin-top: 17px;
  padding-bottom: 6px;
}

.gdlr-core-skill-circle-size-small .gdlr-core-skill-circle-content {
  margin-top: -30px;
}

.gdlr-core-skill-circle-size-small .gdlr-core-skill-circle-head {
  font-size: 17px;
}

.gdlr-core-skill-circle-size-small .gdlr-core-skill-circle-caption {
  font-size: 12px;
  margin-top: 10px;
  padding-bottom: 6px;
}

.gdlr-core-skill-circle-item
  .gdlr-core-skill-circle-main-content.gdlr-core-left {
  float: left;
  margin-right: 20px;
}

.gdlr-core-skill-circle-item
  .gdlr-core-skill-circle-main-content.gdlr-core-right {
  float: right;
  margin-left: 20px;
}

.gdlr-core-skill-circle-item .gdlr-core-skill-circle-side-description {
  overflow: hidden;
}

.gdlr-core-skill-circle-item .gdlr-core-skill-circle-side-description-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.gdlr-core-skill-circle-item .gdlr-core-skill-circle-side-description-text {
  font-size: 15px;
}

.gdlr-core-text-box-frame {
  display: inline-block;
}

.gdlr-core-text-box-frame p:last-child {
  margin-bottom: 0;
}

.gdlr-core-accordion-item-tab .gdlr-core-accordion-item-content {
  display: none;
}

.gdlr-core-accordion-item-tab .gdlr-core-accordion-item-title .gdlr-core-head {
  margin-right: 13px;
}

.gdlr-core-accordion-item-tab.gdlr-core-active
  .gdlr-core-accordion-item-content {
  display: block;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-title {
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  margin-top: 17px;
  margin-bottom: 27px;
  letter-spacing: 1px;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-content {
  padding: 5px 0 15px;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-content-wrapper {
  overflow: hidden;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon {
  font-size: 18px;
  float: left;
  width: 48px;
  cursor: pointer;
  text-align: center;
  height: 48px;
  padding: 12px;
  line-height: 20px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  margin-right: 27px;
  transition: background 300ms;
  -moz-transition: background 300ms;
  -o-transition: background 300ms;
  -webkit-transition: background 300ms;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon:before {
  content: "\4c";
  font-family: "ElegantIcons";
}

.gdlr-core-accordion-style-box-icon
  .gdlr-core-accordion-item-tab.gdlr-core-active
  .gdlr-core-accordion-item-icon {
  background: transparent;
}

.gdlr-core-accordion-style-box-icon
  .gdlr-core-accordion-item-tab.gdlr-core-active
  .gdlr-core-accordion-item-icon:before {
  content: "\4b";
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-tab {
  padding-top: 5px;
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-title {
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 20px;
  letter-spacing: 1px;
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-content {
  padding: 0 0 12px;
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-content-wrapper {
  overflow: hidden;
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-icon {
  font-size: 18px;
  float: left;
  width: 20px;
  cursor: pointer;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  margin-right: 17px;
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-icon:before {
  content: "\4c";
  font-family: "ElegantIcons";
}

.gdlr-core-accordion-style-icon
  .gdlr-core-accordion-item-tab.gdlr-core-active
  .gdlr-core-accordion-item-icon:before {
  content: "\4b";
}

.gdlr-core-accordion-style-icon.gdlr-core-icon-pos-right
  .gdlr-core-accordion-item-icon {
  float: right;
}

.gdlr-core-accordion-style-icon.gdlr-core-with-divider
  .gdlr-core-accordion-item-tab {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 15px;
}

.gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-title {
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  padding: 23px 25px 22px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  transition: background 200ms, color 200ms;
  -moz-transition: background 200ms, color 200ms;
  -o-transition: background 200ms, color 200ms;
  -webkit-transition: background 200ms, color 200ms;
}

.gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-content {
  padding: 13px 0 10px;
}

.gdlr-core-accordion-style-background-title-icon
  .gdlr-core-accordion-item-title {
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  padding: 23px 25px 22px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  line-height: 16px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  transition: background 200ms, color 200ms;
  -moz-transition: background 200ms, color 200ms;
  -o-transition: background 200ms, color 200ms;
  -webkit-transition: background 200ms, color 200ms;
}

.gdlr-core-accordion-style-background-title-icon
  .gdlr-core-accordion-item-content {
  padding: 13px 0 10px;
}

.gdlr-core-accordion-style-background-title-icon
  .gdlr-core-accordion-item-title:before {
  content: "\4c";
  font-family: "ElegantIcons";
  font-weight: 400;
  font-size: 16px;
  vertical-align: text-bottom;
}

.gdlr-core-accordion-style-background-title-icon.gdlr-core-icon-pos-left
  .gdlr-core-accordion-item-title:before {
  float: left;
  margin-right: 15px;
}

.gdlr-core-accordion-style-background-title-icon.gdlr-core-icon-pos-right
  .gdlr-core-accordion-item-title:before {
  float: right;
  margin-left: 15px;
}

.gdlr-core-accordion-style-background-title-icon
  .gdlr-core-accordion-item-tab.gdlr-core-active
  .gdlr-core-accordion-item-title:before {
  content: "\4b";
}

.gdlr-core-accordion-style-background-title-icon.gdlr-core-right-align
  .gdlr-core-accordion-item-title:before {
  float: right;
  margin-left: 15px;
  margin-right: 0;
  margin-top: -2px;
}

.gdlr-core-accordion-item.gdlr-core-accordion-style-box-background {
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.gdlr-core-accordion-style-box-background .gdlr-core-accordion-item-title {
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  padding: 23px 25px 22px;
  letter-spacing: 1px;
  margin-bottom: 0;
  line-height: 16px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  transition: background 200ms, color 200ms;
  -moz-transition: background 200ms, color 200ms;
  -o-transition: background 200ms, color 200ms;
  -webkit-transition: background 200ms, color 200ms;
}

.gdlr-core-accordion-style-box-background .gdlr-core-accordion-item-content {
  padding: 25px 25px 10px;
}

.gdlr-core-accordion-style-box-background
  .gdlr-core-accordion-item-title:before {
  content: "\4c";
  font-family: "ElegantIcons";
  font-weight: 400;
  font-size: 16px;
  vertical-align: text-bottom;
}

.gdlr-core-accordion-style-box-background.gdlr-core-icon-pos-left
  .gdlr-core-accordion-item-title:before {
  float: left;
  margin-right: 15px;
}

.gdlr-core-accordion-style-box-background.gdlr-core-icon-pos-right
  .gdlr-core-accordion-item-title:before {
  float: right;
  margin-left: 15px;
}

.gdlr-core-accordion-style-box-background
  .gdlr-core-accordion-item-tab.gdlr-core-active
  .gdlr-core-accordion-item-title:before {
  content: "\4b";
}

.gdlr-core-accordion-style-box-background.gdlr-core-right-align
  .gdlr-core-accordion-item-title:before {
  float: right;
  margin-left: 15px;
  margin-right: 0;
  margin-top: -2px;
}

.gdlr-core-accordion-style-shadow-background .gdlr-core-accordion-item-tab {
  padding-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 20px;
}

.gdlr-core-accordion-style-shadow-background
  .gdlr-core-accordion-item-tab:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.gdlr-core-accordion-style-shadow-background
  .gdlr-core-accordion-item-tab
  .gdlr-core-accordion-item-content-wrapper {
  padding: 2px 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  transition: background-color 300ms, box-shadow 300ms, padding 300ms;
  -webkit-transition: background-color 300ms, box-shadow 300ms, padding 300ms;
  -moz-transition: background-color 300ms, box-shadow 300ms, padding 300ms;
}

.gdlr-core-accordion-style-shadow-background
  .gdlr-core-accordion-item-tab
  .gdlr-core-accordion-item-title {
  font-size: 18px;
  margin-bottom: 0;
  cursor: pointer;
  padding: 7px 0;
}

.gdlr-core-accordion-style-shadow-background
  .gdlr-core-accordion-item-tab
  .gdlr-core-accordion-item-content {
  margin-top: 15px;
}

.gdlr-core-accordion-style-shadow-background
  .gdlr-core-accordion-item-tab.gdlr-core-active
  .gdlr-core-accordion-item-content-wrapper {
  padding: 35px 40px 20px;
  margin-bottom: 15px;
  box-shadow: 0 20px 35px rgb(0 0 0 / 8%);
  -webkit-box-shadow: 0 20px 35px rgb(0 0 0 / 8%);
  -moz-box-shadow: 0 20px 35px rgba(0, 0, 0, 0.08);
}

.gdlr-core-accordion-style-shadow-background
  .gdlr-core-accordion-item-title:before {
  content: "\4c";
  font-family: "ElegantIcons";
  font-weight: 400;
  font-size: 25px;
  line-height: 22px;
  vertical-align: text-bottom;
  float: right;
  margin-left: 15px;
}

.gdlr-core-accordion-style-shadow-background
  .gdlr-core-accordion-item-tab.gdlr-core-active
  .gdlr-core-accordion-item-title:before {
  content: "\4b";
}

.gdlr-core-accordion-style-box-background
  .gdlr-core-accordion-item-title:before,
.gdlr-core-toggle-box-style-box-background
  .gdlr-core-accordion-item-title:before {
  color: #768490;
}

.gdlr-core-accordion-style-box-background
  .gdlr-core-accordion-item-title
  .gdlr-core-head,
.gdlr-core-toggle-box-style-box-background
  .gdlr-core-toggle-box-item-title
  .gdlr-core-head {
  color: #191919;
}

.gdlr-core-accordion-style-box-background .gdlr-core-accordion-item-title,
.gdlr-core-toggle-box-style-box-background .gdlr-core-toggle-box-item-title {
  color: #262626;
}

.gdlr-core-accordion-style-box-background .gdlr-core-accordion-item-title,
.gdlr-core-toggle-box-style-box-background .gdlr-core-toggle-box-item-title {
  background-color: #f8faff;
}

.gdlr-core-accordion-style-box-background
  .gdlr-core-active
  .gdlr-core-accordion-item-title:before,
.gdlr-core-toggle-box-style-box-background
  .gdlr-core-active
  .gdlr-core-accordion-item-title:before {
  color: #768490;
}

.gdlr-core-accordion-style-box-background
  .gdlr-core-active
  .gdlr-core-accordion-item-title,
.gdlr-core-toggle-box-style-box-background
  .gdlr-core-active
  .gdlr-core-toggle-box-item-title {
  color: #262626;
}

.gdlr-core-accordion-style-box-background
  .gdlr-core-active
  .gdlr-core-accordion-item-title,
.gdlr-core-toggle-box-style-box-background
  .gdlr-core-active
  .gdlr-core-toggle-box-item-title {
  background-color: #fff;
}

.gdlr-core-toggle-box-item-tab .gdlr-core-toggle-box-item-content {
  display: none;
}

.gdlr-core-toggle-box-item-tab
  .gdlr-core-toggle-box-item-title
  .gdlr-core-head {
  margin-right: 13px;
}

.gdlr-core-toggle-box-item-tab.gdlr-core-active
  .gdlr-core-toggle-box-item-content {
  display: block;
}

.gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-title {
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  margin-top: 17px;
  margin-bottom: 27px;
  letter-spacing: 1px;
}

.gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-content {
  padding: 5px 0 15px;
}

.gdlr-core-toggle-box-style-box-icon
  .gdlr-core-toggle-box-item-content-wrapper {
  overflow: hidden;
}

.gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon {
  font-size: 18px;
  float: left;
  width: 48px;
  cursor: pointer;
  text-align: center;
  height: 48px;
  padding: 12px;
  line-height: 20px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  margin-right: 27px;
  transition: background 300ms;
  -moz-transition: background 300ms;
  -o-transition: background 300ms;
  -webkit-transition: background 300ms;
}

.gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon:before {
  content: "\4c";
  font-family: "ElegantIcons";
}

.gdlr-core-toggle-box-style-box-icon
  .gdlr-core-toggle-box-item-tab.gdlr-core-active
  .gdlr-core-toggle-box-item-icon {
  background: transparent;
}

.gdlr-core-toggle-box-style-box-icon
  .gdlr-core-toggle-box-item-tab.gdlr-core-active
  .gdlr-core-toggle-box-item-icon:before {
  content: "\4b";
}

.gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-tab {
  padding-top: 5px;
}

.gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-title {
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 20px;
  letter-spacing: 1px;
}

.gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-content {
  padding: 0 0 12px;
}

.gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-content-wrapper {
  overflow: hidden;
}

.gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-icon {
  font-size: 18px;
  float: left;
  width: 20px;
  cursor: pointer;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  margin-right: 17px;
}

.gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-icon:before {
  content: "\4c";
  font-family: "ElegantIcons";
}

.gdlr-core-toggle-box-style-icon
  .gdlr-core-toggle-box-item-tab.gdlr-core-active
  .gdlr-core-toggle-box-item-icon:before {
  content: "\4b";
}

.gdlr-core-toggle-box-style-icon.gdlr-core-icon-pos-right
  .gdlr-core-toggle-box-item-icon {
  float: right;
}

.gdlr-core-toggle-box-style-icon.gdlr-core-with-divider
  .gdlr-core-toggle-box-item-tab {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 15px;
}

.gdlr-core-toggle-box-style-background-title .gdlr-core-toggle-box-item-title {
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  padding: 23px 25px 22px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  transition: background 300ms;
  -moz-transition: background 300ms;
  -o-transition: background 300ms;
  -webkit-transition: background 300ms;
}

.gdlr-core-toggle-box-style-background-title
  .gdlr-core-toggle-box-item-content {
  padding: 13px 0 10px;
}

.gdlr-core-toggle-box-style-background-title-icon
  .gdlr-core-toggle-box-item-title {
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  padding: 23px 25px 22px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  transition: background 300ms;
  -moz-transition: background 300ms;
  -o-transition: background 300ms;
  -webkit-transition: background 300ms;
}

.gdlr-core-toggle-box-style-background-title-icon
  .gdlr-core-toggle-box-item-content {
  padding: 13px 0 10px;
}

.gdlr-core-toggle-box-style-background-title-icon
  .gdlr-core-toggle-box-item-title:before {
  content: "\4c";
  font-family: "ElegantIcons";
  margin-right: 15px;
}

.gdlr-core-toggle-box-style-background-title-icon
  .gdlr-core-toggle-box-item-tab.gdlr-core-active
  .gdlr-core-toggle-box-item-title:before {
  content: "\4b";
}

.gdlr-core-toggle-box-item.gdlr-core-toggle-box-style-box-background {
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.gdlr-core-toggle-box-style-box-background .gdlr-core-toggle-box-item-title {
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  padding: 23px 25px 22px;
  letter-spacing: 1px;
  margin-bottom: 0;
  line-height: 16px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  transition: background 200ms, color 200ms;
  -moz-transition: background 200ms, color 200ms;
  -o-transition: background 200ms, color 200ms;
  -webkit-transition: background 200ms, color 200ms;
}

.gdlr-core-toggle-box-style-box-background .gdlr-core-toggle-box-item-content {
  padding: 25px 25px 10px;
}

.gdlr-core-toggle-box-style-box-background
  .gdlr-core-toggle-box-item-title:before {
  content: "\4c";
  font-family: "ElegantIcons";
  font-weight: 400;
  font-size: 16px;
  vertical-align: text-bottom;
}

.gdlr-core-toggle-box-style-box-background.gdlr-core-icon-pos-left
  .gdlr-core-toggle-box-item-title:before {
  float: left;
  margin-right: 15px;
}

.gdlr-core-toggle-box-style-box-background.gdlr-core-icon-pos-right
  .gdlr-core-toggle-box-item-title:before {
  float: right;
  margin-left: 15px;
}

.gdlr-core-toggle-box-style-box-background
  .gdlr-core-toggle-box-item-tab.gdlr-core-active
  .gdlr-core-toggle-box-item-title:before {
  content: "\4b";
}

.gdlr-core-toggle-box-style-box-background.gdlr-core-right-align
  .gdlr-core-toggle-box-item-title:before {
  float: right;
  margin-left: 15px;
  margin-right: 0;
  margin-top: -2px;
}

.gdlr-core-toggle-box-style-shadow-background .gdlr-core-toggle-box-item-tab {
  padding-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 20px;
}

.gdlr-core-toggle-box-style-shadow-background
  .gdlr-core-toggle-box-item-tab:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.gdlr-core-toggle-box-style-shadow-background
  .gdlr-core-toggle-box-item-tab
  .gdlr-core-toggle-box-item-content-wrapper {
  padding: 2px 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  transition: box-shadow 300ms, padding 300ms;
  -webkit-transition: box-shadow 300ms, padding 300ms;
  -moz-transition: box-shadow 300ms, padding 300ms;
}

.gdlr-core-toggle-box-style-shadow-background
  .gdlr-core-toggle-box-item-tab
  .gdlr-core-toggle-box-item-title {
  font-size: 18px;
  margin-bottom: 0;
  cursor: pointer;
  padding: 7px 0;
}

.gdlr-core-toggle-box-style-shadow-background
  .gdlr-core-toggle-box-item-tab
  .gdlr-core-toggle-box-item-content {
  margin-top: 15px;
}

.gdlr-core-toggle-box-style-shadow-background
  .gdlr-core-toggle-box-item-tab.gdlr-core-active
  .gdlr-core-toggle-box-item-content-wrapper {
  padding: 35px 40px 20px;
  margin-bottom: 15px;
  box-shadow: 0 20px 35px rgb(0 0 0 / 8%);
  -webkit-box-shadow: 0 20px 35px rgb(0 0 0 / 8%);
  -moz-box-shadow: 0 20px 35px rgba(0, 0, 0, 0.08);
}

.gdlr-core-toggle-box-style-shadow-background
  .gdlr-core-toggle-box-item-title:before {
  content: "\4c";
  font-family: "ElegantIcons";
  font-weight: 400;
  font-size: 25px;
  line-height: 22px;
  vertical-align: text-bottom;
  float: right;
  margin-left: 15px;
}

.gdlr-core-toggle-box-style-shadow-background
  .gdlr-core-toggle-box-item-tab.gdlr-core-active
  .gdlr-core-toggle-box-item-title:before {
  content: "\4b";
}

.gdlr-core-toggle-box-item.gdlr-core-icon-pos-none
  .gdlr-core-toggle-box-item-icon {
  display: none;
}

.gdlr-core-port-info-item .gdlr-core-port-info-key {
  display: inline-block;
  min-width: 78px;
  margin-right: 20px;
  font-weight: 700;
}

.gdlr-core-port-info-item .gdlr-core-port-info {
  margin-top: 15px;
}

.gdlr-core-port-info-item .gdlr-core-port-info:first-child {
  margin-top: 0;
}

.gdlr-core-port-info-item .gdlr-core-port-info-wrap.gdlr-core-with-border {
  padding-bottom: 22px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-port-info-item .gdlr-core-port-info-social-share {
  padding-top: 26px;
  margin-top: 45px;
  margin-bottom: 4px;
  border-top-width: 1px;
  border-top-style: solid;
}

.gdlr-core-port-info-item
  .gdlr-core-port-info-social-share
  .gdlr-core-social-share-item {
  padding: 0;
}

.gdlr-core-port-info-item
  .gdlr-core-port-info-social-share
  .gdlr-core-port-info-value {
  display: inline-block;
  line-height: 23px;
}

.gdlr-core-port-info-item
  .gdlr-core-port-info-social-share
  .gdlr-core-social-share-item
  a {
  font-size: 14px;
  margin: 0 10px;
}

.gdlr-core-port-info-item
  .gdlr-core-port-info-social-share
  .gdlr-core-social-share-item
  a:first-child {
  margin-left: 0;
}

.gdlr-core-port-info2 {
  border-top-width: 1px;
  border-top-style: solid;
  padding-top: 17px;
  margin-top: 17px;
  font-size: 16px;
}

.gdlr-core-port-info2:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}

.gdlr-core-port-info2 .gdlr-core-port-info2-content {
  max-width: 250px;
}

.gdlr-core-port-info2 .gdlr-core-port-info2-key {
  font-weight: 700;
  float: left;
}

.gdlr-core-port-info2 .gdlr-core-port-info2-value {
  float: right;
  text-align: right;
}

.gdlr-core-tab-item .gdlr-core-tab-item-title-wrap {
  position: relative;
}

.gdlr-core-tab-item .gdlr-core-tab-item-title {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 700;
  cursor: pointer;
  transition: background 200ms, color 200ms;
  -moz-transition: background 200ms, color 200ms;
  -o-transition: background 200ms, color 200ms;
  -webkit-transition: background 200ms, color 200ms;
}

.gdlr-core-tab-item .gdlr-core-tab-item-content {
  display: none;
}

.gdlr-core-tab-item .gdlr-core-tab-item-content.gdlr-core-active {
  display: block;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title {
  margin-left: -1px;
  padding: 18px 28px 14px;
  border-width: 1px 1px 0;
  border-style: solid;
  display: inline-block;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title:first-child {
  margin-left: 0;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title.gdlr-core-active {
  margin-bottom: -1px;
  padding-bottom: 15px;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title-wrap {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-content {
  padding: 25px 0 5px;
}

.gdlr-core-tab-style2-horizontal .gdlr-core-tab-item-title-wrap {
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.gdlr-core-tab-style2-horizontal .gdlr-core-tab-item-title {
  padding: 0 28px 20px;
  display: inline-block;
}

.gdlr-core-tab-style2-horizontal .gdlr-core-tab-item-title-line {
  position: absolute;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  bottom: -2px;
}

.gdlr-core-tab-style2-horizontal .gdlr-core-tab-item-content {
  padding: 25px 0 5px;
}

.gdlr-core-tab-style3-horizontal .gdlr-core-tab-item-title {
  padding: 0 28px 16px;
  display: inline-block;
}

.gdlr-core-tab-style3-horizontal .gdlr-core-tab-item-title-line {
  position: absolute;
  opacity: 0;
}

.gdlr-core-tab-style3-horizontal
  .gdlr-core-tab-item-title-line.gdlr-core-initiated {
  opacity: 1;
}

.gdlr-core-tab-style3-horizontal .gdlr-core-tab-item-title-line:before {
  content: " ";
  border-width: 3.5px;
  border-style: solid;
  display: block;
  width: 7px;
  margin: 0 auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.gdlr-core-tab-style3-horizontal .gdlr-core-tab-item-content {
  padding: 35px 0 5px;
}

.gdlr-core-tab-style1-vertical .gdlr-core-tab-item-title-wrap {
  max-width: 40%;
}

.gdlr-core-tab-style1-vertical .gdlr-core-tab-item-title {
  padding: 16px 35px 14px;
}

.gdlr-core-tab-style1-vertical .gdlr-core-tab-item-content-wrap {
  overflow: hidden;
}

.gdlr-core-tab-style1-vertical .gdlr-core-tab-item-content {
  padding: 15px 45px 5px;
}

.gdlr-core-tab-style1-vertical.gdlr-core-left-align
  .gdlr-core-tab-item-title-wrap {
  float: left;
  border-right-width: 1px;
  border-right-style: solid;
  margin-right: -1px;
}

.gdlr-core-tab-style1-vertical.gdlr-core-left-align .gdlr-core-tab-item-title {
  border-width: 0 0 1px 1px;
  border-style: solid;
}

.gdlr-core-tab-style1-vertical.gdlr-core-left-align
  .gdlr-core-tab-item-title:first-child {
  border-top-width: 1px;
}

.gdlr-core-tab-style1-vertical.gdlr-core-left-align
  .gdlr-core-tab-item-title.gdlr-core-active {
  margin-right: -1px;
  padding-right: 29px;
}

.gdlr-core-tab-style1-vertical.gdlr-core-left-align
  .gdlr-core-tab-item-content-wrap {
  border-left-width: 1px;
  border-left-style: solid;
}

.gdlr-core-tab-style1-vertical.gdlr-core-right-align
  .gdlr-core-tab-item-title-wrap {
  float: right;
  border-left-width: 1px;
  border-left-style: solid;
  margin-left: -1px;
}

.gdlr-core-tab-style1-vertical.gdlr-core-right-align .gdlr-core-tab-item-title {
  border-width: 0 1px 1px 0;
  border-style: solid;
}

.gdlr-core-tab-style1-vertical.gdlr-core-right-align
  .gdlr-core-tab-item-title:first-child {
  border-top-width: 1px;
}

.gdlr-core-tab-style1-vertical.gdlr-core-right-align
  .gdlr-core-tab-item-title.gdlr-core-active {
  margin-left: -1px;
  padding-left: 29px;
}

.gdlr-core-tab-style1-vertical.gdlr-core-right-align
  .gdlr-core-tab-item-content-wrap {
  border-right-width: 1px;
  border-right-style: solid;
}

.gdlr-core-tab-style2-vertical .gdlr-core-tab-item-title-wrap {
  max-width: 40%;
}

.gdlr-core-tab-style2-vertical .gdlr-core-tab-item-content-wrap {
  overflow: hidden;
}

.gdlr-core-tab-style2-vertical .gdlr-core-tab-item-content {
  padding: 15px 35px 5px;
}

.gdlr-core-tab-style2-vertical .gdlr-core-tab-item-title-line {
  position: absolute;
  border-right-width: 2px;
  border-right-style: solid;
}

.gdlr-core-tab-style2-vertical.gdlr-core-left-align
  .gdlr-core-tab-item-title-wrap {
  float: left;
  border-right-width: 2px;
  border-right-style: solid;
  margin-right: -2px;
}

.gdlr-core-tab-style2-vertical.gdlr-core-left-align .gdlr-core-tab-item-title {
  padding: 12px 50px 12px 0;
}

.gdlr-core-tab-style2-vertical.gdlr-core-left-align
  .gdlr-core-tab-item-content-wrap {
  border-left-width: 2px;
  border-left-style: solid;
}

.gdlr-core-tab-style2-vertical.gdlr-core-left-align
  .gdlr-core-tab-item-title-line {
  right: -2px;
}

.gdlr-core-tab-style2-vertical.gdlr-core-right-align
  .gdlr-core-tab-item-title-wrap {
  float: right;
  border-left-width: 2px;
  border-left-style: solid;
  margin-left: -2px;
}

.gdlr-core-tab-style2-vertical.gdlr-core-right-align .gdlr-core-tab-item-title {
  padding: 12px 0 12px 50px;
}

.gdlr-core-tab-style2-vertical.gdlr-core-right-align
  .gdlr-core-tab-item-content-wrap {
  border-right-width: 2px;
  border-right-style: solid;
}

.gdlr-core-tab-style2-vertical.gdlr-core-right-align
  .gdlr-core-tab-item-title-line {
  left: -2px;
}

.gdlr-core-tab-feature2-content-text {
  font-size: 18px;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-title-wrap {
  display: inline-block;
  cursor: pointer;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-title-item-wrap {
  text-align: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 25px;
  position: relative;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-title-image {
  margin-bottom: 10px;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-bottom-slide-bar {
  position: absolute;
  bottom: -1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: #375eef;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-content-wrap {
  display: none;
  padding-top: 80px;
}

.gdlr-core-tab-feature2-item
  .gdlr-core-tab-feature2-content-wrap.gdlr-core-active {
  display: block;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-content-left {
  width: 52%;
  margin-right: 5%;
  text-align: center;
  float: left;
  overflow: hidden;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-tab-feature2-item
  .gdlr-core-tab-feature2-content-left
  .gdlr-core-image-overlay-icon.fa-play {
  background: rgba(55, 94, 239, 0.5);
  width: 100px;
  height: 100px;
  line-height: 100px;
  padding-left: 5px;
  margin-left: -50px;
  margin-top: -50px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-tab-feature2-item
  .gdlr-core-tab-feature2-content-left
  .gdlr-core-image-overlay-icon.fa-play:before {
  z-index: 1;
  position: relative;
}

.gdlr-core-tab-feature2-item
  .gdlr-core-tab-feature2-content-left
  .gdlr-core-image-overlay-icon.fa-play:after {
  position: absolute;
  content: " ";
  left: 6px;
  right: 6px;
  top: 6px;
  bottom: 6px;
  background: #375eef;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-content-right {
  float: left;
  width: 43%;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-content-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-content-item {
  position: relative;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 30px;
  padding: 35px 35px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  transition: transform 150ms ease-out, box-shadow 150ms ease-out,
    border-color 150ms ease-out;
  -moz-transition: transform 150ms ease-out, -moz-box-shadow 150ms ease-out,
    border-color 150ms ease-out;
  -o-transition: transform 150ms ease-out, -o-box-shadow 150ms ease-out,
    border-color 150ms ease-out;
  -webkit-transition: transform 150ms ease-out,
    -webkit-box-shadow 150ms ease-out, border-color 150ms ease-out;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-content-item:last-child {
  margin-bottom: 0;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-content-hover {
  position: absolute;
  right: 20px;
  top: 18px;
  opacity: 0;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #4ccf5f;
  background: rgb(76, 207, 95, 0.15);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  transition: opacity 200ms;
  -moz-transition: opacity 200ms;
  -o-transition: opacity 200ms;
  -webkit-transition: opacity 200ms;
}

.gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-content-item:hover {
  transform: translate3d(0, -10px, 0);
  -webkit-transform: translate3d(0, -10px, 0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.gdlr-core-tab-feature2-item
  .gdlr-core-tab-feature2-content-item:hover
  .gdlr-core-tab-feature2-content-hover {
  opacity: 1;
}

.gdlr-core-tab-feature2-item.gdlr-core-size-1
  .gdlr-core-tab-feature2-title-wrap {
  width: 100%;
}

.gdlr-core-tab-feature2-item.gdlr-core-size-2
  .gdlr-core-tab-feature2-title-wrap {
  width: 50%;
}

.gdlr-core-tab-feature2-item.gdlr-core-size-3
  .gdlr-core-tab-feature2-title-wrap {
  width: 33.33%;
}

.gdlr-core-tab-feature2-item.gdlr-core-size-4
  .gdlr-core-tab-feature2-title-wrap {
  width: 25%;
}

.gdlr-core-tab-feature2-item.gdlr-core-size-5
  .gdlr-core-tab-feature2-title-wrap {
  width: 20%;
}

.gdlr-core-tab-feature2-item.gdlr-core-size-6
  .gdlr-core-tab-feature2-title-wrap {
  width: 16.66%;
}

.gdlr-core-tab-feature2-item.gdlr-core-size-7
  .gdlr-core-tab-feature2-title-wrap {
  width: 14.28%;
}

.gdlr-core-tab-feature2-item.gdlr-core-size-8
  .gdlr-core-tab-feature2-title-wrap {
  width: 12.5%;
}

@media only screen and (max-width: 767px) {
  .gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-content-left {
    float: none;
    width: auto;
    margin-bottom: 40px;
    margin-right: 0;
  }

  .gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-content-right {
    width: auto;
  }

  .gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-title-image img {
    max-width: 30px;
  }

  .gdlr-core-tab-feature2-item .gdlr-core-tab-feature2-title {
    font-size: 13px;
  }
}

.gdlr-core-tab-feature3-title-wrap .gdlr-core-tab-feature3-title {
  color: #777;
}

.gdlr-core-tab-feature3-title-wrap.gdlr-core-active
  .gdlr-core-tab-feature3-title {
  color: #0082fa;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-content-title {
  color: #222;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-content-text {
  color: #373737;
}

body
  .gdlr-core-tab-feature3-item
  .gdlr-core-tab-feature3-content-button
  .gdlr-core-button,
body
  .gdlr-core-tab-feature3-item
  .gdlr-core-tab-feature3-content-button
  .gdlr-core-button:hover {
  color: #0082fa;
  border-color: #0082fa;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: uppercase;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-title-wrap {
  cursor: pointer;
  padding-bottom: 25px;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-title-item-wrap {
  text-align: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  position: relative;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-title-image {
  margin-bottom: 10px;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-bottom-slide-bar {
  position: absolute;
  height: 13px;
  bottom: -13px;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-bottom-slide-bar:after {
  content: " ";
  width: 0;
  height: 0;
  border-width: 12px 12px 0 12px;
  border-style: solid;
  border-color: #fff transparent transparent;
  display: block;
  margin: -13px auto 0;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-bottom-slide-bar-border {
  color: #375eef;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: #375eef;
}

.gdlr-core-tab-feature3-item
  .gdlr-core-tab-feature3-bottom-slide-bar-border:before {
  content: " ";
  width: 0;
  height: 0;
  border-width: 12px 12px 0 12px;
  border-style: solid;
  display: block;
  margin: 0 auto;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-content-wrap {
  display: none;
  padding-top: 60px;
}

.gdlr-core-tab-feature3-item
  .gdlr-core-tab-feature3-content-wrap.gdlr-core-active {
  display: block;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-content-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 18px;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-content-text {
  font-size: 18px;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-content {
  overflow: hidden;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-content-right {
  float: right;
  padding-left: 40px;
  width: 50%;
}

.gdlr-core-tab-feature3-item
  .gdlr-core-tab-feature3-content-button
  .gdlr-core-button {
  border-width: 2px;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
}

.gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-content-button {
  margin-top: 40px;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-title-background-overlay {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1));
  -moz-background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1));
  -o-background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1));
  -webkit-background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1));
}

.gdlr-core-tab-feature-item
  .gdlr-core-tab-feature-title-background-overlay-active {
  background: #143369;
  background: linear-gradient(rgba(20, 51, 105, 0.6), rgba(20, 51, 105, 1));
  -moz-background: linear-gradient(
    rgba(20, 51, 105, 0.6),
    rgba(20, 51, 105, 1)
  );
  -o-background: linear-gradient(rgba(20, 51, 105, 0.6), rgba(20, 51, 105, 1));
  -webkit-background: linear-gradient(
    rgba(20, 51, 105, 0.6),
    rgba(20, 51, 105, 1)
  );
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-item-content-wrap {
  background: #143369;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-title {
  color: #fff;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-caption {
  color: #c6c6c6;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-left-title {
  color: #fff;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-left-caption {
  color: #c4d8fc;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-right {
  color: #fff;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-right-divider {
  border-bottom-color: #e53d34;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-button-style-solid {
  background: #e53d34;
  color: #fff;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-button-style-solid:hover {
  color: #fff;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-button-style-border {
  border-color: #e53d34;
  color: #fff;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-button-style-border:hover {
  color: #fff;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-title-wrap {
  text-align: center;
  padding: 81px 20px;
  position: relative;
  cursor: pointer;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-title-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  background-size: cover;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-title-background-active {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-size: cover;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-title-background-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  margin-right: -1px;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.gdlr-core-tab-feature-item
  .gdlr-core-tab-feature-title-background-overlay-active {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  margin-right: -1px;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.gdlr-core-tab-feature-item
  .gdlr-core-tab-feature-title-wrap.gdlr-core-active
  .gdlr-core-tab-feature-title-background.gdlr-core-with-active {
  opacity: 0;
}

.gdlr-core-tab-feature-item
  .gdlr-core-tab-feature-title-wrap.gdlr-core-active
  .gdlr-core-tab-feature-title-background-active {
  opacity: 1;
}

.gdlr-core-tab-feature-item
  .gdlr-core-tab-feature-title-wrap.gdlr-core-active
  .gdlr-core-tab-feature-title-background-overlay {
  opacity: 0;
}

.gdlr-core-tab-feature-item
  .gdlr-core-tab-feature-title-wrap.gdlr-core-active
  .gdlr-core-tab-feature-title-background-overlay-active {
  opacity: 1;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-title {
  font-size: 21px;
  font-weight: 800;
  margin-bottom: 8px;
  position: relative;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-title-image {
  margin-bottom: 20px;
  position: relative;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-caption {
  font-size: 16px;
  position: relative;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-size-2 {
  float: left;
  width: 50%;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-size-3 {
  float: left;
  width: 33.33%;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-size-4 {
  float: left;
  width: 25%;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-wrap {
  display: none;
  padding: 100px 90px 90px 80px;
}

.gdlr-core-tab-feature-item
  .gdlr-core-tab-feature-content-wrap.gdlr-core-active {
  display: block;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-left {
  width: 33%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -6px;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-left-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: -5px;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-left-caption {
  font-size: 20px;
  font-weight: 500;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-right {
  overflow: hidden;
  font-size: 19px;
  margin-top: -10px;
  padding-left: 20px;
  padding-right: 20px;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-right-divider {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  margin-bottom: 18px;
  width: 55px;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-button-wrap {
  padding-top: 20px;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-button {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  height: 52px;
  padding: 14px 30px 0;
  display: inline-block;
  margin-right: 15px;
  letter-spacing: 0.5px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-tab-feature-item .gdlr-core-tab-feature-button-style-border {
  padding-top: 12px;
  border-width: 2px;
  border-style: solid;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-item-wrap {
  background: #000;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-item-caption {
  color: #969696;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-item-title {
  color: #fff;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-item-title-overlay {
  background: linear-gradient(
    rgba(221, 51, 51, 0),
    rgba(221, 51, 51, 0),
    rgba(221, 51, 51, 1)
  );
  -moz-background: linear-gradient(
    rgba(221, 51, 51, 0),
    rgba(221, 51, 51, 0),
    rgba(221, 51, 51, 1)
  );
  -o-background: linear-gradient(
    rgba(221, 51, 51, 0),
    rgba(221, 51, 51, 0),
    rgba(221, 51, 51, 1)
  );
  -webkit-background: linear-gradient(
    rgba(221, 51, 51, 0),
    rgba(221, 51, 51, 0),
    rgba(221, 51, 51, 1)
  );
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-divider {
  border-color: #e53d34;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-wrap li {
  background: #222;
  color: #7d7d7d;
}

.gdlr-core-tab-featurev-item
  .gdlr-core-tab-featurev-title-wrap
  li.gdlr-core-active {
  background: #e53d34;
  color: #fff;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-item-content-wrap {
  background: #fff;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-item-caption {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 3px;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-item-title {
  position: relative;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 16px;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-divider {
  position: relative;
  width: 33px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-background-switch > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-size: cover;
  transition: opacity 200ms;
  -moz-transition: opacity 200ms;
  -o-transition: opacity 200ms;
  -webkit-transition: opacity 200ms;
}

.gdlr-core-tab-featurev-item
  .gdlr-core-tab-featurev-background-switch
  > div.gdlr-core-active {
  opacity: 1;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-item-wrap {
  width: 35%;
  float: left;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-item-title-wrap {
  padding-bottom: 46px;
  padding-left: 15%;
  position: relative;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-item-title-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-wrap {
  margin-left: 0;
  list-style: none;
  margin-bottom: 0;
  padding-left: 15%;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-wrap li {
  margin-left: 0;
  list-style: none;
  font-size: 17px;
  margin-bottom: 1px;
  padding: 15px 23px 17px;
  cursor: pointer;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-wrap li:last-child {
  margin-bottom: 0;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-wrap li i {
  margin-right: 12px;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-item-content-wrap {
  overflow: hidden;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-content-wrap {
  display: none;
  padding: 100px 85px 70px;
  background-size: cover;
}

.gdlr-core-tab-featurev-item
  .gdlr-core-tab-featurev-content-wrap.gdlr-core-active {
  display: block;
}

.gdlr-core-tab-featurev-item .gldr-core-tab-featurev-content-title-image {
  margin-bottom: 25px;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-content-title-caption {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 2px;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-content-title {
  font-size: 32px;
  font-weight: 800;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-content-title-wrap {
  margin-bottom: 30px;
}

.gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-content {
  font-size: 17px;
}

.gdlr-core-stunning-text-item .gdlr-core-stunning-text-item-caption {
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.5px;
}

.gdlr-core-stunning-text-item .gdlr-core-stunning-text-item-title {
  font-size: 41px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.gdlr-core-stunning-text-item .gdlr-core-stunning-text-item-content {
  font-size: 16px;
  margin-bottom: 30px;
}

.gdlr-core-stunning-text-item .gdlr-core-stunning-text-item-link {
  font-size: 15px;
  font-style: italic;
  letter-spacing: 0.5px;
}

.gdlr-core-stunning-text-caption-above-title
  .gdlr-core-stunning-text-item-title {
  margin-bottom: 22px;
}

.gdlr-core-stunning-text-caption-above-title
  .gdlr-core-stunning-text-item-caption {
  margin-bottom: 13px;
}

.gdlr-core-stunning-text-caption-below-title
  .gdlr-core-stunning-text-item-title {
  margin-bottom: 15px;
}

.gdlr-core-stunning-text-caption-below-title
  .gdlr-core-stunning-text-item-caption {
  margin-bottom: 25px;
}

.gdlr-core-audio {
  padding: 16px 19px 13px;
}

.gdlr-core-audio .mejs-container {
  height: 30px !important;
}

.gdlr-core-audio .mejs-container .mejs-controls {
  height: 30px;
}

.gdlr-core-audio .mejs-container .mejs-controls .mejs-time {
  font-size: 15px;
  line-height: 1;
  padding-top: 6px;
  height: 17px;
  white-space: nowrap;
}

.gdlr-core-audio
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-total {
  top: 8px;
  height: 10px;
}

.gdlr-core-audio .mejs-container .mejs-controls .mejs-volume-button button,
.gdlr-core-audio .mejs-container .mejs-controls .mejs-playpause-button button {
  display: none;
}

.gdlr-core-audio .mejs-container .mejs-controls .mejs-volume-button {
  line-height: 1;
  margin-left: 20px;
  margin-right: 10px;
  width: 26px;
  height: 26px;
}

.gdlr-core-audio .mejs-container .mejs-controls .mejs-volume-button:before {
  content: "\e069";
  font-family: "ElegantIcons";
  font-size: 16px;
  display: inline-block;
  padding: 5px;
}

.gdlr-core-audio
  .mejs-container
  .mejs-controls
  .mejs-volume-button.mejs-unmute:before {
  content: "\e067";
}

.gdlr-core-audio .mejs-container .mejs-controls .mejs-horizontal-volume-slider {
  height: 26px;
}

.gdlr-core-audio .mejs-container .mejs-controls .mejs-playpause-button {
  line-height: 1;
  margin-right: 10px;
  width: 26px;
  height: 26px;
}

.gdlr-core-audio .mejs-container .mejs-controls .mejs-playpause-button:before {
  font-family: "ElegantIcons";
  font-size: 24px;
  display: inline-block;
  padding: 1px;
  cursor: pointer;
}

.gdlr-core-audio .mejs-container .mejs-controls .mejs-play:before {
  content: "\49";
}

.gdlr-core-audio .mejs-container .mejs-controls .mejs-pause:before {
  content: "\5f";
  font-size: 24px;
  padding: 2px;
}

.gdlr-core-audio .mejs-controls div.mejs-time-rail {
  padding-top: 3px;
  height: 26px;
  margin: 0 5px;
}

.gdlr-core-audio .mejs-container,
.gdlr-core-audio .mejs-container .mejs-controls,
.gdlr-core-audio .mejs-embed,
.gdlr-core-audio .mejs-embed body {
  background: transparent;
}

.gdlr-core-counter-item {
  text-align: center;
}

.gdlr-core-counter-item .gdlr-core-counter-item-top-text {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 22px;
}

.gdlr-core-counter-item .gdlr-core-counter-item-top-icon {
  font-size: 30px;
  margin-bottom: 25px;
  line-height: 1.2;
}

.gdlr-core-counter-item .gdlr-core-counter-item-number {
  font-size: 59px;
  line-height: 1;
  margin-bottom: 25px;
}

.gdlr-core-counter-item .gdlr-core-counter-item-prefix {
  margin-right: 6px;
}

.gdlr-core-counter-item .gdlr-core-counter-item-divider {
  width: 20px;
  margin: 0 auto 25px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.gdlr-core-counter-item .gdlr-core-counter-item-bottom-text {
  font-size: 16px;
  text-transform: uppercase;
}

.gdlr-core-counter-item .gdlr-core-counter-item-side-caption {
  display: table;
}

.gdlr-core-counter-item .gdlr-core-counter-item-side-caption-left {
  display: table-cell;
  vertical-align: middle;
}

.gdlr-core-counter-item
  .gdlr-core-counter-item-side-caption-left
  .gdlr-core-counter-item-number {
  white-space: nowrap;
  margin-bottom: 0;
}

.gdlr-core-counter-item .gdlr-core-counter-item-side-caption-right {
  display: table-cell;
  vertical-align: middle;
}

.gdlr-core-counter-item .gdlr-core-counter-item-side-caption-right {
  font-size: 16px;
  text-transform: uppercase;
  padding-left: 25px;
}

.gdlr-core-counter-item.gdlr-core-left-align {
  text-align: left;
}

.gdlr-core-counter-item.gdlr-core-left-align .gdlr-core-counter-item-divider {
  margin-left: 0;
}

.gdlr-core-counter-item.gdlr-core-right-align {
  text-align: right;
}

.gdlr-core-counter-item.gdlr-core-right-align .gdlr-core-counter-item-divider {
  margin-right: 0;
}

.gdlr-core-countdown-wrap .gdlr-core-countdown-block {
  display: inline-block;
  margin: 0 30px;
}

.gdlr-core-countdown-wrap .gdlr-core-time {
  font-size: 85px;
  font-weight: 300;
  display: block;
  line-height: 1;
  white-space: nowrap;
}

.gdlr-core-countdown-wrap .gdlr-core-unit {
  font-size: 18px;
  display: block;
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.gdlr-core-type-animation-item .gdlr-core-type-animation-item-content {
  font-size: 19px;
  border-style: solid;
  border-width: 0;
}

.gdlr-core-type-animation-item.gdlr-core-with-highlight
  .gdlr-core-type-animation-item-content {
  padding-left: 0.7em;
  padding-right: 0.7em;
  display: inline-block;
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.gdlr-core-block-item-title-wrap {
  position: relative;
  margin-bottom: 60px;
}

.gdlr-core-block-item-title-wrap .gdlr-core-block-item-title-inner {
  position: relative;
}

.gdlr-core-block-item-title-wrap .gdlr-core-block-item-caption {
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.8px;
}

.gdlr-core-block-item-title-wrap .gdlr-core-block-item-title {
  font-size: 41px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.gdlr-core-block-item-title-wrap .gdlr-core-block-item-title i {
  margin-right: 18px;
}

.gdlr-core-block-item-title-wrap .gdlr-core-block-item-title img {
  margin-right: 18px;
}

.gdlr-core-block-item-title-wrap .gdlr-core-block-item-read-more {
  font-size: 14px;
}

.gdlr-core-block-item-title-wrap
  .gdlr-core-block-item-read-more.gdlr-core-style-with-border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 2px;
}

.gdlr-core-block-item-title-wrap
  .gdlr-core-block-item-read-more.gdlr-core-style-right-with-border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 2px;
  position: absolute;
  right: 0;
  bottom: 10px;
}

.gdlr-core-block-item-title-wrap.gdlr-core-left-align
  .gdlr-core-block-item-caption.gdlr-core-top {
  margin-bottom: 5px;
}

.gdlr-core-block-item-title-wrap.gdlr-core-left-align
  .gdlr-core-block-item-caption.gdlr-core-bottom {
  margin-top: 5px;
}

.gdlr-core-block-item-title-wrap.gdlr-core-left-align
  .gdlr-core-block-item-title {
  display: inline-block;
  margin-right: 25px;
}

.gdlr-core-block-item-title-wrap.gdlr-core-left-align
  .gdlr-core-separator.gdlr-core-style-1 {
  display: inline-block;
  height: 32px;
  margin: 0 40px 0 0;
  border-right-width: 1px;
  border-right-style: solid;
  transform: rotate(36deg);
  -moz-transform: rotate(36deg);
  -webkit-transform: rotate(36deg);
  transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  -webkit-transform-origin: 0% 100%;
}

.gdlr-core-block-item-title-wrap.gdlr-core-left-align
  .gdlr-core-separator.gdlr-core-style-2 {
  margin-right: 22px;
}

.gdlr-core-block-item-title-wrap.gdlr-core-left-align
  .gdlr-core-separator.gdlr-core-style-2:before {
  content: " ";
  border-bottom-width: 3px;
  border-top-width: 2px;
  border-style: solid;
  width: 25px;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-block-item-title-wrap.gdlr-core-left-align
  .gdlr-core-separator.gdlr-core-style-2:after {
  content: " ";
  border-bottom-width: 3px;
  border-top-width: 2px;
  border-style: solid;
  opacity: 0.5;
  margin-left: 8px;
  width: 5px;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-block-item-title-wrap.gdlr-core-center-align
  .gdlr-core-block-item-caption.gdlr-core-top {
  margin-bottom: 5px;
}

.gdlr-core-block-item-title-wrap.gdlr-core-center-align
  .gdlr-core-block-item-caption.gdlr-core-bottom {
  margin-top: 5px;
}

.gdlr-core-block-item-title-wrap.gdlr-core-center-align
  .gdlr-core-block-item-read-more {
  display: inline-block;
  margin-top: 10px;
}

.gdlr-core-block-item-title-nav {
  margin-bottom: 40px;
}

.gdlr-core-title-item .gdlr-core-title-item-caption {
  font-size: 16px;
  font-style: italic;
  display: block;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #a5a5a5;
  margin-bottom: 0px;
}

.gdlr-core-title-item .gdlr-core-title-item-caption-prefix {
  border-right-width: 3px;
  border-right-style: solid;
  display: inline-block;
  height: 1em;
  margin-right: 12px;
  margin-bottom: 0.28em;
  vertical-align: middle;
}

.gdlr-core-title-item .gdlr-core-title-item-title {
  font-size: 41px;
  font-weight: 800;
  display: inline-block;
  margin-bottom: 0;
  transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
  -webkit-transition: color 200ms;
}

.gdlr-core-title-item .gdlr-core-title-item-title i {
  margin-right: 30px;
}

.gdlr-core-title-item .gdlr-core-title-item-title-wrap {
  position: relative;
}

.gdlr-core-title-item .gdlr-core-title-item-divider {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-title-item .gdlr-core-title-item-divider.gdlr-core-left {
  left: 0;
}

.gdlr-core-title-item .gdlr-core-title-item-divider.gdlr-core-right {
  right: 0;
}

.gdlr-core-title-item .gdlr-core-title-item-link {
  display: block;
  margin-top: 12px;
}

.gdlr-core-title-item-caption-top.gdlr-core-left-align
  .gdlr-core-title-item-link,
.gdlr-core-title-item-caption-bottom.gdlr-core-left-align
  .gdlr-core-title-item-link {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -9px;
  line-height: 25px;
  font-style: italic;
}

.gdlr-core-title-item-caption-top.gdlr-core-left-align
  .gdlr-core-title-item-link.gdlr-core-overflow,
.gdlr-core-title-item-caption-bottom.gdlr-core-left-align
  .gdlr-core-title-item-link.gdlr-core-overflow {
  position: static;
  margin-top: 10px;
}

.gdlr-core-title-item-caption-top .gdlr-core-title-item-caption {
  margin-bottom: 10px;
}

.gdlr-core-title-item-caption-bottom .gdlr-core-title-item-caption {
  margin-top: 10px;
}

.gdlr-core-title-item-caption-right .gdlr-core-title-item-title-wrap {
  float: left;
  margin-right: 30px;
}

.gdlr-core-title-item-caption-right .gdlr-core-title-item-caption {
  overflow: hidden;
}

.gdlr-core-title-item-left-image {
  float: left;
  margin-right: 30px;
}

.gdlr-core-title-item-left-image-wrap {
  overflow: hidden;
}

.gdlr-core-title-item-left-icon {
  float: left;
  margin-right: 30px;
  margin-top: 10px;
  font-size: 30px;
}

.gdlr-core-title-item-left-icon-wrap {
  overflow: hidden;
}

.gdlr-core-title-item-left-divider {
  border-left-width: 5px;
  border-style: solid;
  margin-right: 30px;
}

.gdlr-core-title-item-left-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 20px;
  margin-bottom: 0.28em;
  vertical-align: middle;
  background: #ddd;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: #d32525;
  margin-right: 11px;
}

.gdlr-core-title-item-title-side-border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: inline-block;
}

.gdlr-core-title-item-title-side-border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: inline-block;
}

.gdlr-core-title-item-title-side-border.gdlr-core-style-upper {
  margin-bottom: 0.6em;
}

.gdlr-core-title-item-title-side-border.gdlr-core-style-middle {
  margin-bottom: 0.3em;
}

.gdlr-core-personnel-thumbnail-hover-content {
  line-height: 1.7;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.gdlr-core-personnel-thumbnail-hover-content.gdlr-core-bottom {
  top: auto;
  bottom: 30px;
  transform: none;
  -webkit-transform: none;
}

.gdlr-core-personnel-thumbnail-hover-title {
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 6px;
}

.gdlr-core-personnel-thumbnail-hover-social {
  font-size: 19px;
  margin-bottom: 12px;
}

.gdlr-core-personnel-thumbnail-hover-social.gdlr-core-round-border
  .gdlr-core-social-network-icon {
  width: 38px;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  margin-left: 12px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.gdlr-core-personnel-thumbnail-hover-social.gdlr-core-round-border
  .gdlr-core-social-network-icon:first-child {
  margin-left: 0;
}

.gdlr-core-personnel-thumbnail-hover-content > div:last-child {
  margin-bottom: 0;
}

.gdlr-core-personnel-thumbnail-hover-content
  .gdlr-core-personnel-thumbnail-hover-title
  a,
.gdlr-core-personnel-thumbnail-hover-content
  .gdlr-core-personnel-thumbnail-hover-title
  a:hover,
.gdlr-core-personnel-thumbnail-hover-content
  .gdlr-core-personnel-thumbnail-hover-social
  a,
.gdlr-core-personnel-thumbnail-hover-content
  .gdlr-core-personnel-thumbnail-hover-social
  a:hover,
.gdlr-core-personnel-thumbnail-hover-content
  .gdlr-core-personnel-thumbnail-hover-excerpt {
  color: #fff;
}

.gdlr-core-personnel-item .gdlr-core-personnel-list {
  border-style: solid;
  border-width: 0;
}

.gdlr-core-personnel-item
  .gdlr-core-personnel-list.gdlr-core-outer-frame-element {
  overflow: hidden;
}

.gdlr-core-personnel-item .gdlr-core-personnel-list-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 8px;
}

.gdlr-core-personnel-item .gdlr-core-personnel-list-position {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-column {
  margin-bottom: 50px;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-content-wrap {
  padding-top: 37px;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-title {
  font-size: 16px;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-position {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-divider {
  border-bottom: 2px;
  border-bottom-style: solid;
  width: 45px;
  padding-top: 12px;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-content {
  margin-top: 20px;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-social {
  margin-top: 18px;
  margin-bottom: 12px;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-social-shortcode {
  margin-top: 20px;
}

.gdlr-core-personnel-style-grid.gdlr-core-right-align
  .gdlr-core-personnel-list-divider {
  margin-left: auto;
  margin-right: 0;
}

.gdlr-core-personnel-style-grid.gdlr-core-center-align
  .gdlr-core-personnel-list-divider {
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-personnel-style-grid.gdlr-core-with-background
  .gdlr-core-personnel-list-content-wrap {
  padding: 35px 32px 25px;
}

.gdlr-core-personnel-item-style-grid-no-space
  .gdlr-core-personnel-list-content-wrap {
  margin-left: 33px;
}

.gdlr-core-personnel-style-medium .gdlr-core-personnel-list-column {
  margin-bottom: 50px;
}

.gdlr-core-personnel-style-medium .gdlr-core-personnel-list-image {
  float: left;
  max-width: 37%;
  margin-right: 42px;
}

.gdlr-core-personnel-style-medium .gdlr-core-personnel-list-content-wrap {
  overflow: hidden;
}

.gdlr-core-personnel-style-medium .gdlr-core-personnel-list-title {
  font-size: 16px;
}

.gdlr-core-personnel-style-medium .gdlr-core-personnel-list-position {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
}

.gdlr-core-personnel-style-medium .gdlr-core-personnel-list-divider {
  border-bottom: 2px;
  border-bottom-style: solid;
  width: 45px;
  padding-top: 12px;
}

.gdlr-core-personnel-style-medium .gdlr-core-personnel-list-content {
  margin-top: 20px;
}

.gdlr-core-personnel-style-medium .gdlr-core-personnel-list-social {
  margin-top: 18px;
  margin-bottom: 12px;
}

.gdlr-core-personnel-style-medium .gdlr-core-personnel-list-social-shortcode {
  margin-top: 20px;
}

.gdlr-core-personnel-style-modern .gdlr-core-personnel-list {
  position: relative;
}

.gdlr-core-personnel-style-modern .gdlr-core-personnel-list-title {
  font-size: 17px;
}

.gdlr-core-personnel-style-modern .gdlr-core-personnel-list-content-wrap {
  position: absolute;
  bottom: 16px;
  left: 32px;
  right: 32px;
}

.gdlr-core-personnel-list
  .gdlr-core-personnel-list-content-wrap.gdlr-core-hover-overlay-content {
  opacity: 0;
  transition: opacity 300ms ease-out;
  -moz-transition: opacity 300ms ease-out;
  -o-transition: opacity 300ms ease-out;
  -webkit-transition: opacity 300ms ease-out;
}

.gdlr-core-personnel-list:hover
  .gdlr-core-personnel-list-content-wrap.gdlr-core-hover-overlay-content {
  opacity: 1;
}

.gdlr-core-personnel-style-grid-feature .gdlr-core-personnel-list-column {
  margin-bottom: 15px;
}

.gdlr-core-personnel-style-grid-feature .gdlr-core-personnel-list {
  padding-bottom: 65px;
  position: relative;
}

.gdlr-core-personnel-style-grid-feature .gdlr-core-personnel-list-content-wrap {
  position: absolute;
  left: 25px;
  right: 25px;
  bottom: 15px;
  z-index: 1;
  background: #fff;
  padding: 30px 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-personnel-style-grid-feature .gdlr-core-personnel-list-title {
  margin-bottom: 0;
}

.gdlr-core-personnel-style-grid-feature .gdlr-core-personnel-list-position {
  margin-bottom: 0;
  margin-top: 10px;
}

.gdlr-core-personnel-style-grid-feature .gdlr-core-personnel-list-social {
  margin-bottom: 0;
  margin-top: 10px;
}

.gdlr-core-personnel-style-grid-feature .gdlr-core-personnel-list-info-wrap {
  overflow: hidden;
  max-height: 0;
  transition: max-height 350ms;
  -moz-transition: max-height 350ms;
  -o-transition: max-height 350ms;
  -webkit-transition: max-height 350ms;
}

.gdlr-core-personnel-style-grid-feature
  .gdlr-core-personnel-list:hover
  .gdlr-core-personnel-list-info-wrap {
  max-height: 100px;
}

.gdlr-core-personnel-style-grid-feature
  .gdlr-core-personnel-list:hover
  .gdlr-core-opacity-on-hover
  img {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.gdlr-core-personnel-style-grid-feature
  .gdlr-core-personnel-list:hover
  .gdlr-core-zoom-on-hover
  img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.gdlr-core-personnel-style-grid-feature
  .gdlr-core-personnel-list:hover
  .gdlr-core-grayscale-effect
  img {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
}

.gdlr-core-personnel-style-grid-feature
  .gdlr-core-personnel-list:hover
  .gdlr-core-grayscale-effect-revert
  img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.gdlr-core-personnel-style-modern
  .gdlr-core-personnel-list:hover
  .gdlr-core-hover-opacity {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gdlr-core-personnel-style-modern
  .gdlr-core-personnel-list:hover
  .gdlr-core-zoom-on-hover
  img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.gdlr-core-personnel-style-modern
  .gdlr-core-personnel-list:hover
  .gdlr-core-grayscale-effect
  img {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
}

.gdlr-core-personnel-style-modern
  .gdlr-core-personnel-list:hover
  .gdlr-core-grayscale-effect-revert
  img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.gdlr-core-personnel-style-widget .gdlr-core-item-list {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-personnel-style-widget .gdlr-core-personnel-list-image {
  float: left;
  max-width: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.gdlr-core-personnel-style-widget .gdlr-core-personnel-list-content {
  text-align: left;
  overflow: hidden;
  padding-top: 8px;
}

.gdlr-core-personnel-style-widget .gdlr-core-personnel-list-title {
  font-size: 20px;
  margin-bottom: 5px;
  text-transform: none;
  letter-spacing: 0;
}

.gdlr-core-personnel-style-widget .gdlr-core-personnel-list-link i {
  vertical-align: middle;
  margin-left: 10px;
}

.gdlr-core-personnel-item
  .gdlr-core-flexslider.gdlr-core-with-outer-frame-element
  .flex-viewport {
  padding-top: 15px;
  margin-top: -15px;
  padding-left: 15px;
  margin-left: -15px;
  padding-right: 15px;
  margin-right: -15px;
  padding-bottom: 15px;
  margin-bottom: -15px;
}

.gdlr-core-personnel-filterer {
  margin-bottom: 40px;
}

.gdlr-core-personnel-filterer .gdlr-core-head {
  float: left;
  margin-right: 30px;
  font-size: 18px;
  line-height: 18px;
}

.gdlr-core-personnel-filterer .gdlr-core-tail {
  overflow: hidden;
}

.gdlr-core-personnel-filterer .gdlr-core-custom-dropdown-current {
  line-height: 18px;
  font-size: 15px;
}

.gdlr-core-personnel-filterer .gdlr-core-custom-dropdown-current:after {
  content: "\f0d7";
  font-family: fontAwesome;
  float: right;
}

.gdlr-core-personnel-item .gdlr-core-now-loading {
  position: absolute;
  /* background: url(../../../../../../../../../../plugins/goodlayers-core/include/images/portfolio/now-loading.gif)
    center no-repeat; */
  width: 55px;
  height: 55px;
  left: 50%;
  margin-left: -28px;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-custom-dropdown .gdlr-core-custom-dropdown-current {
  cursor: pointer;
  text-align: left;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  max-width: 250px;
}

.gdlr-core-custom-dropdown .gdlr-core-custom-dropdown-list {
  display: none;
  text-align: left;
  padding: 14px 0;
  max-width: 250px;
  position: absolute;
  width: 250px;
  z-index: 1;
  background: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.gdlr-core-custom-dropdown .gdlr-core-custom-dropdown-list-item {
  padding: 3px 25px;
  cursor: pointer;
}

.gdlr-core-custom-dropdown
  .gdlr-core-custom-dropdown-list-item.gdlr-core-active {
  display: none;
}

.gdlr-core-course-info-item {
  font-size: 17px;
}

.gdlr-core-course-info-item .gdlr-core-course-info-item-inner {
  padding: 40px 50px 30px;
  background: #f3f3f3;
}

.gdlr-core-course-info-item .gdlr-core-head {
  float: left;
  margin-right: 15px;
  font-weight: 700;
  margin-bottom: 12px;
}

.gdlr-core-course-info-item .gdlr-core-tail {
  overflow: hidden;
  margin-bottom: 12px;
}

.gdlr-core-course-item.gdlr-core-course-style-list .gdlr-core-course-item-list {
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 13px;
  margin-bottom: 13px;
}

.gdlr-core-course-item.gdlr-core-course-style-list .gdlr-core-course-item-link {
  display: block;
  padding-right: 30px;
}

.gdlr-core-course-item.gdlr-core-course-style-list .gdlr-core-course-item-id {
  font-size: 16px;
  font-weight: 500;
  min-width: 100px;
  display: inline-block;
}

.gdlr-core-course-item.gdlr-core-course-style-list
  .gdlr-core-course-item-title {
  font-size: 16px;
  font-weight: 500;
  transition: color 200ms ease-out;
  -moz-transition: color 200ms ease-out;
  -o-transition: color 200ms ease-out;
  -webkit-transition: color 200ms ease-out;
}

.gdlr-core-course-item.gdlr-core-course-style-list .gdlr-core-course-item-icon {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 20px;
  line-height: 20px;
  margin-top: -16px;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-list {
  margin-bottom: 30px;
  padding: 40px 52px;
  transition: border 200ms, box-shadow 400ms;
  -webkit-transition: border 200ms, box-shadow 400ms;
  -moz-transition: border 200ms, box-shadow 400ms;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-title {
  font-size: 19px;
  margin-bottom: 20px;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-id {
  margin-right: 23px;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-button {
  border-radius: 3px;
  font-size: 14px;
  padding: 10px 22px;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-info-wrap {
  margin-bottom: 20px;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-info {
  font-size: 15px;
  margin-bottom: 6px;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-info
  .gdlr-core-head {
  font-weight: 700;
  margin-right: 5px;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-title {
  color: #3e3e3e;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-id {
  color: #3db166;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-list {
  background: #f2f2f2;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-info {
  color: #6b6b6b;
}

.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-button,
.gdlr-core-course-item.gdlr-core-course-style-list-info
  .gdlr-core-course-item-button:hover {
  background-color: #50bd77;
  color: #fff;
}

.gdlr-core-course-item.gdlr-core-course-style-grid .gdlr-core-course-item-list {
  margin-bottom: 40px;
}

.gdlr-core-course-item.gdlr-core-course-style-grid
  .gdlr-core-course-item-list-inner {
  border-radius: 3px;
  overflow: hidden;
}

.gdlr-core-course-item.gdlr-core-course-style-grid
  .gdlr-core-course-item-title {
  font-size: 19px;
  font-weight: 800;
}

.gdlr-core-course-item.gdlr-core-course-style-grid
  .gdlr-core-course-item-content-wrap {
  padding: 40px;
  border-width: 1px;
  border-style: solid;
  background: #fff;
}

.gdlr-core-course-item.gdlr-core-course-style-grid
  .gdlr-core-with-thumbnail
  .gdlr-core-course-item-content-wrap {
  border-top-width: 0;
}

.gdlr-core-course-item.gdlr-core-course-style-grid
  .gdlr-core-course-item-info-wrap {
  margin-bottom: 20px;
}

.gdlr-core-course-item.gdlr-core-course-style-grid
  .gdlr-core-course-item-info
  .gdlr-core-head {
  font-weight: 800;
}

.gdlr-core-course-item.gdlr-core-course-style-grid .gdlr-core-course-item-info {
  margin-bottom: 8px;
}

.gdlr-core-course-item.gdlr-core-course-style-grid
  .gdlr-core-course-item-button
  i {
  margin-left: 10px;
}

.gdlr-core-image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  overflow: hidden;
  transition: opacity 500ms ease-out;
  -moz-transition: opacity 500ms ease-out;
  -o-transition: opacity 500ms ease-out;
  -webkit-transition: opacity 500ms ease-out;
}

.gdlr-core-image-overlay:hover,
.gdlr-core-image-overlay.gdlr-core-no-hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gdlr-core-image-overlay.gdlr-core-transparent {
  background: transparent;
}

.gdlr-core-image-overlay-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  text-align: center;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}

.gdlr-core-image-overlay-icon img {
  vertical-align: middle;
  padding-bottom: 1px;
}

.gdlr-core-image-overlay-icon.gdlr-core-size-28 {
  width: 28px;
  font-size: 28px;
  line-height: 28px;
  margin-left: -14px;
  margin-top: -14px;
}

.gdlr-core-image-overlay-icon.gdlr-core-size-22 {
  width: 25px;
  font-size: 22px;
  line-height: 25px;
  margin-left: -11px;
  margin-top: -11px;
}

.gdlr-core-image-overlay-icon.gdlr-core-size-15 {
  width: 20px;
  font-size: 15px;
  line-height: 20px;
  margin-left: -8px;
  margin-top: -10px;
}

.gdlr-core-image-overlay.gdlr-core-round-icon .gdlr-core-image-overlay-icon {
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

.gdlr-core-image-overlay.gdlr-core-round-icon
  .gdlr-core-image-overlay-icon.gdlr-core-size-28 {
  padding: 20px;
}

.gdlr-core-image-overlay.gdlr-core-round-icon
  .gdlr-core-image-overlay-icon.gdlr-core-size-22 {
  padding: 20px;
}

.gdlr-core-image-overlay.gdlr-core-round-icon
  .gdlr-core-image-overlay-icon.gdlr-core-size-15 {
  padding: 20px;
}

.gdlr-core-image-overlay.gdlr-core-round-icon
  .gdlr-core-image-overlay-icon.fa-play:before {
  margin-left: 6px;
}

.gdlr-core-image-overlay-content {
  position: absolute;
  left: 40px;
  right: 40px;
  top: 50%;
  line-height: 1.55;
  display: block;
}

.gdlr-core-image-item {
  line-height: 0;
}

.gdlr-core-image-item.gdlr-core-left-align .gdlr-core-image-item-wrap {
  margin-left: 0;
}

.gdlr-core-image-item.gdlr-core-right-align .gdlr-core-image-item-wrap {
  margin-right: 0;
}

.gdlr-core-image-item .gdlr-core-image-item-wrap {
  border-style: solid;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  max-width: 100%;
}

.gdlr-core-image-item .gdlr-core-image-item-wrap.gdlr-core-with-shadow {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
}

.gdlr-core-image-item .gdlr-core-image-item-style-round {
  overflow: hidden;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-image-item .gdlr-core-image-item-style-circle {
  overflow: hidden;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-image-item .gdlr-core-image-item-caption {
  font-size: 13px;
  font-weight: 700;
  font-style: italic;
  margin-top: 15px;
}

.gdlr-core-image-item
  .gdlr-core-image-item-style-circle
  .gdlr-core-image-overlay {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-image-item
  .gdlr-core-image-item-style-round
  .gdlr-core-image-overlay {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

@media only screen and (max-width: 767px) {
  .gdlr-core-mobile-left-align {
    text-align: left;
  }

  .gdlr-core-mobile-right-align {
    text-align: right;
  }

  .gdlr-core-mobile-center-align {
    text-align: center;
  }

  .gdlr-core-image-item.gdlr-core-mobile-left-align .gdlr-core-image-item-wrap {
    margin-left: 0;
  }

  .gdlr-core-image-item.gdlr-core-mobile-right-align
    .gdlr-core-image-item-wrap {
    margin-right: 0;
  }

  .gdlr-core-image-item.gdlr-core-mobile-center-align
    .gdlr-core-image-item-wrap {
    margin-left: auto;
    margin-right: auto;
  }
}

.gdlr-core-social-share-item a {
  margin: 0 15px;
  font-size: 16px;
}

.gdlr-core-social-share-item a:first-child {
  margin-left: 0px !important;
}

.gdlr-core-social-share-item a:last-child {
  margin-right: 0px !important;
}

.gdlr-core-social-share-item .fa {
  font-family: "FontAwesome";
}

.gdlr-core-social-share-item .fa6b {
  font-family: "Font Awesome 6 Brands";
}

.gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-count {
  font-size: 23px;
  font-weight: 700;
  margin-right: 10px;
}

.gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-suffix {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.gdlr-core-social-share-item.gdlr-core-style-round a {
  width: 28px;
  height: 28px;
  margin: 0 12px 0 0;
  display: inline-block;
  line-height: 1;
  text-align: center;
  padding: 6px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-social-share-item.gdlr-core-style-round a,
.gdlr-core-social-share-item.gdlr-core-style-round a:hover {
  color: #fff;
  background: #252525;
}

.gdlr-core-social-share-item.gdlr-core-social-share-vertical
  .gdlr-core-social-share-count {
  margin-bottom: 20px;
}

.gdlr-core-social-share-item.gdlr-core-social-share-vertical a {
  margin-left: 0;
  margin-right: 0;
  display: block;
  margin-bottom: 10px;
}

.gdlr-core-social-share-left-text .gdlr-core-divider {
  margin-right: 36px;
  margin-left: 30px;
  height: 18px;
  border-right-width: 2px;
  border-right-style: solid;
}

.gdlr-core-social-share-right-text .gdlr-core-divider {
  margin-right: 30px;
  margin-left: 36px;
  height: 18px;
  border-right-width: 2px;
  border-right-style: solid;
}

.gdlr-core-social-share-top-text .gdlr-core-social-share-count {
  display: block;
}

.gdlr-core-social-share-top-text .gdlr-core-divider {
  width: 30px;
  display: block;
  margin: 23px 0 28px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.gdlr-core-social-share-top-text.gdlr-core-right-align .gdlr-core-divider {
  margin-left: auto;
  margin-right: 0;
}

.gdlr-core-social-share-top-text.gdlr-core-center-align .gdlr-core-divider {
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-social-share-item.gdlr-core-style-color a {
  display: inline-block;
  width: 68px;
  text-align: center;
  margin-right: 3px;
  line-height: 30px;
  padding: 5px 0;
  margin-left: 0;
}

.gdlr-core-social-share-item.gdlr-core-style-color
  .gdlr-core-social-share-facebook {
  background: #3b5999;
  color: #fff;
}

.gdlr-core-social-share-item.gdlr-core-style-color
  .gdlr-core-social-share-linkedin {
  background: #0077b5;
  color: #fff;
}

.gdlr-core-social-share-item.gdlr-core-style-color
  .gdlr-core-social-share-google-plus {
  background: #dc4e41;
  color: #fff;
}

.gdlr-core-social-share-item.gdlr-core-style-color
  .gdlr-core-social-share-pinterest {
  background: #bd081c;
  color: #fff;
}

.gdlr-core-social-share-item.gdlr-core-style-color
  .gdlr-core-social-share-stumbleupon {
  background: #eb4924;
  color: #fff;
}

.gdlr-core-social-share-item.gdlr-core-style-color
  .gdlr-core-social-share-twitter {
  background: #55acee;
  color: #fff;
}

.gdlr-core-social-share-item.gdlr-core-style-color
  .gdlr-core-social-share-email {
  background: #000;
  color: #fff;
}

.gdlr-core-social-network-item .gdlr-core-social-network-icon {
  border-width: 0;
  border-style: solid;
  margin-right: 20px;
  font-size: 19px;
  display: inline-block;
  text-align: center;
}

.gdlr-core-social-network-item .gdlr-core-social-network-icon:last-child {
  margin-right: 0px !important;
}

.gdlr-core-social-network-item .gdlr-core-social-network-item-text {
  font-size: 15px;
  font-weight: 400;
  margin-left: 13px;
  letter-spacing: 1px;
}

.gdlr-core-social-network-item .gdlr-core-social-network-icon i {
  letter-spacing: 0;
}

.gdlr-core-social-network-item.gdlr-direction-vertical a {
  display: block;
  margin-right: 0;
  text-align: inherit;
  margin-bottom: 20px;
}

.gdlr-core-social-network-item.gdlr-direction-vertical a:last-child {
  margin-bottom: 0px !important;
}

.gdlr-core-icon-list-item ul {
  margin-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.gdlr-core-icon-list-item ul .clearfix {
  clear: none;
  height: auto;
  opacity: 1;
}

.gdlr-core-icon-list-item ul li {
  margin-bottom: 10px;
}

.gdlr-core-icon-list-item ul li:first-child {
  padding-top: 0;
}

.gdlr-core-icon-list-item .gdlr-core-icon-list-image {
  float: left;
  margin-right: 15px;
  vertical-align: middle;
  position: relative;
  line-height: 1;
  margin-top: 3px;
}

.gdlr-core-icon-list-item .gdlr-core-icon-list-icon-wrap {
  float: left;
  margin-right: 15px;
  vertical-align: middle;
  position: relative;
  line-height: 1;
  margin-top: 3px;
}

.gdlr-core-icon-list-item.gdlr-core-right-align .gdlr-core-icon-list-icon-wrap {
  float: right;
  margin-right: 0;
  margin-left: 20px;
}

.gdlr-core-icon-list-item .gdlr-core-icon-list-icon,
.gdlr-core-icon-list-item .gdlr-core-icon-list-icon-hover {
  display: inline-block;
  font-size: 14px;
  width: 14px;
  text-align: center;
  transition: opacity 200ms, transform 200ms;
  -moz-transition: opacity 200ms, -moz-transform 200ms;
  -o-transition: opacity 200ms, -o-transform 200ms;
  -webkit-transition: opacity 200ms, -webkit-transform 200ms;
}

.gdlr-core-icon-list-item .gdlr-core-icon-list-content-wrap {
  overflow: hidden;
}

.gdlr-core-icon-list-item .gdlr-core-icon-list-content {
  font-size: 14px;
  display: block;
}

.gdlr-core-icon-list-item .gdlr-core-icon-list-caption {
  font-size: 14px;
  display: block;
  margin-top: 4px;
}

.gdlr-core-icon-list-item.gdlr-core-style-2 .gdlr-core-icon-list-content {
  display: inline;
  margin-right: 12px;
}

.gdlr-core-icon-list-item.gdlr-core-style-2 .gdlr-core-icon-list-caption {
  display: inline;
}

.gdlr-core-icon-list-item.gdlr-core-with-divider ul li {
  padding: 11px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 0;
  backface-visibility: hidden;
}

.gdlr-core-icon-list-with-background-round .gdlr-core-icon-list-icon-wrap {
  padding: 6px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-icon-list-with-background-round .gdlr-core-icon-list-content-wrap {
  padding-top: 6px;
}

.gdlr-core-icon-list-with-background-circle .gdlr-core-icon-list-icon-wrap {
  padding: 8px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-icon-list-with-background-circle .gdlr-core-icon-list-content-wrap {
  padding-top: 6px;
}

.gdlr-core-icon-list-item li.gdlr-core-with-hover .gdlr-core-icon-list-icon {
  opacity: 1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
}

.gdlr-core-icon-list-item
  li.gdlr-core-with-hover
  .gdlr-core-icon-list-icon-hover {
  opacity: 0;
  width: 0;
  transform: scale(0);
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
}

.gdlr-core-icon-list-item
  li.gdlr-core-with-hover:hover
  .gdlr-core-icon-list-icon {
  opacity: 0;
  transform: scale(0);
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
}

.gdlr-core-icon-list-item
  li.gdlr-core-with-hover:hover
  .gdlr-core-icon-list-icon-hover {
  opacity: 1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
}

.gdlr-core-icon-list-item.gdlr-core-left-align
  .gdlr-core-icon-list-icon-wrap.gdlr-core-right {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}

.gdlr-core-icon-list-item.gdlr-core-right-align
  .gdlr-core-icon-list-icon-wrap.gdlr-core-right {
  float: left;
  margin-left: 0;
  margin-right: 15px;
}

.gdlr-core-icon-list-item.gdlr-core-style-3 .gdlr-core-icon-list-icon-wrap {
  margin: 0 auto;
  float: none;
  margin-bottom: 10px;
  display: inline-block;
}

.gdlr-core-dropcap {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  float: left;
  margin-right: 20px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.gdlr-core-dropcap.gdlr-core-type-circle {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-dropcap.gdlr-core-type-circle,
.gdlr-core-dropcap.gdlr-core-type-rectangle {
  text-align: center;
  padding: 8px 10px 12px;
  width: 50px;
}

.gdlr-core-dropcap.gdlr-core-type-normal {
  font-size: 48px;
  margin-top: 18px;
  margin-left: 10px;
}

.gdlr-core-sticky-banner {
  line-height: 1;
  display: inline-block;
  letter-spacing: 0.5px;
  padding: 18px 20px 17px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 22px;
}

.gdlr-core-sticky-banner i {
  font-size: 12px;
  margin-right: 10px;
}

.gdlr-core-blog-thumbnail img {
  margin: 0;
}

.gdlr-core-blog-thumbnail a img.alignnone {
  margin: 0;
}

.gdlr-core-blog-thumbnail .gdlr-core-sticky-banner {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
}

.gdlr-core-blog-thumbnail .wp-block-image {
  margin-bottom: 0;
}

.gdlr-core-blog-thumbnail .wp-block-gallery {
  flex-wrap: wrap;
}

.gdlr-core-blog-info-wrapper .gdlr-core-head {
  margin-right: 13px;
  vertical-align: middle;
  line-height: 1;
}

.gdlr-core-blog-info-wrapper .gdlr-core-head i {
  font-size: 15px;
}

.gdlr-core-blog-info-wrapper .gdlr-core-blog-info {
  display: inline-block;
  margin-right: 25px;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.gdlr-core-blog-info-wrapper .gdlr-core-blog-info:last-child {
  margin-right: 0;
}

.gdlr-core-blog-date-wrapper {
  float: left;
  width: 60px;
  border-right-width: 1px;
  border-right-style: solid;
  line-height: 1;
  margin-top: 8px;
  margin-right: 30px;
  padding-right: 20px;
  text-align: center;
  white-space: nowrap;
}

.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-day {
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: -4px;
  letter-spacing: 1px;
}

.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-month {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.gdlr-core-blog-item-holder {
  padding-bottom: 1px;
}

.gdlr-core-blog-item
  .gdlr-core-flexslider.gdlr-core-with-outer-frame-element
  .flex-viewport {
  padding-top: 15px;
  margin-top: -15px;
  padding-right: 15px;
  margin-right: -15px;
  padding-left: 15px;
  margin-left: -15px;
  padding-bottom: 15px;
  margin-bottom: -15px;
}

.gdlr-core-blog-item
  .gdlr-core-excerpt-read-more.gdlr-core-plain-text.gdlr-core-hover-border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: transparent !important;
}

.gdlr-core-blog-full {
  margin-bottom: 80px;
}

.gdlr-core-style-blog-full-with-frame .gdlr-core-blog-full {
  margin-bottom: 40px;
  overflow: hidden;
  border-style: solid;
  border-width: 0;
}

.gdlr-core-blog-full .gdlr-core-blog-thumbnail {
  margin-bottom: 44px;
}

.gdlr-core-blog-full .gdlr-core-blog-title {
  font-size: 37px;
  font-weight: 700;
  margin-bottom: 14px;
  letter-spacing: 1px;
}

.gdlr-core-blog-full .gdlr-core-blog-content {
  margin-bottom: 20px;
}

.gdlr-core-blog-full .gdlr-core-blog-full-head {
  margin-bottom: 25px;
}

.gdlr-core-blog-full .gdlr-core-blog-full-head-right {
  overflow: hidden;
}

.gdlr-core-blog-full .gdlr-core-excerpt-read-more {
  margin-top: 33px;
}

.gdlr-core-blog-full .gdlr-core-excerpt-read-more.gdlr-core-plain-text {
  display: inline-block;
  margin-top: 20px;
}

.gdlr-core-blog-full .gdlr-core-excerpt-read-more.gdlr-core-plain-text i {
  margin-left: 10px;
}

.gdlr-core-blog-full.gdlr-core-style-center {
  text-align: center;
}

.gdlr-core-blog-full.gdlr-core-style-center .gdlr-core-blog-date-wrapper {
  position: absolute;
}

.gdlr-core-blog-full.gdlr-core-style-center .gdlr-core-blog-full-head-right {
  padding-left: 80px;
  padding-right: 80px;
}

.gdlr-core-style-blog-full-with-frame .gdlr-core-blog-full-frame {
  padding: 50px 50px 40px;
  position: relative;
}

.gdlr-core-style-blog-full-with-frame .gdlr-core-blog-thumbnail {
  margin-bottom: 0;
}

.gdlr-core-blog-full.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-sticky-banner {
  bottom: 23px;
  left: 35px;
  padding: 10px 12px;
  font-weight: 600;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-full.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-thumbnail-content {
  position: absolute;
  right: 35px;
  bottom: 28px;
}

.gdlr-core-blog-full.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-thumbnail-content
  .gdlr-core-blog-info {
  float: left;
}

.gdlr-core-blog-full.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-comment-number {
  font-size: 15px;
  line-height: 1;
  margin-left: 25px;
  margin-top: 5px;
}

.gdlr-core-blog-full.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-comment-number
  i {
  margin-right: 10px;
}

.gdlr-core-blog-full.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category {
  font-size: 12px;
  line-height: 1;
  padding: 8px 15px;
  margin-left: 25px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-full.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-full.gdlr-core-style-2 .gdlr-core-blog-full-head {
  margin-bottom: 0;
}

.gdlr-core-blog-full.gdlr-core-style-2 .gdlr-core-blog-info-wrapper {
  margin-bottom: 5px;
}

.gdlr-core-blog-full.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  margin-right: 0;
  font-size: 13px;
  text-transform: none;
  letter-spacing: 0;
}

.gdlr-core-blog-full.gdlr-core-style-2
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-full.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info-sep {
  margin: 0 13px;
}

.gdlr-core-blog-full.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-full.gdlr-core-style-2 .gdlr-core-blog-title {
  margin-bottom: 25px;
}

.gdlr-core-blog-full.gdlr-core-style-2-date .gdlr-core-blog-info-wrapper {
  padding-top: 0;
  border: none;
  margin-bottom: 8px;
}

.gdlr-core-blog-full.gdlr-core-style-2-date .gdlr-core-blog-info {
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
  margin-right: 0;
  letter-spacing: 0;
}

.gdlr-core-blog-full.gdlr-core-style-2-date
  .gdlr-core-blog-info
  .gdlr-core-blog-info-sep {
  margin: 0 12px;
}

.gdlr-core-blog-full.gdlr-core-style-2-date
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-full.gdlr-core-style-2-date
  .gdlr-core-blog-info
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-full.gdlr-core-style-2-date .gdlr-core-blog-info-date {
  font-size: 15px;
  padding: 7px 26px;
  border-radius: 20px;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}

.gdlr-core-blog-full.gdlr-core-style-2-date .gdlr-core-blog-full-date {
  margin-top: -64px;
  position: relative;
  margin-left: 20px;
  margin-bottom: 22px;
}

.gdlr-core-blog-full.gdlr-core-style-2-date .gdlr-core-blog-full-frame {
  position: relative;
}

.gdlr-core-blog-full.gdlr-core-style-2-date
  .gdlr-core-blog-full-frame
  .gdlr-core-blog-full-date {
  position: absolute;
  top: -19px;
  margin-top: 0;
  margin-left: 0;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag
  .gdlr-core-blog-thumbnail
  .gdlr-core-sticky-banner {
  bottom: 23px;
  left: 35px;
  padding: 10px 12px;
  font-weight: 600;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag .gdlr-core-blog-full-head {
  margin-bottom: 0;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag .gdlr-core-blog-info-wrapper {
  margin-bottom: 5px;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  margin-right: 0;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info-sep {
  margin: 0 13px;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag .gdlr-core-blog-title {
  margin-bottom: 25px;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag
  .gdlr-core-excerpt-read-more.gdlr-core-plain-text {
  font-weight: 500;
  border-bottom-width: 2px;
  border-style: solid;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag
  .gdlr-core-excerpt-read-more.gdlr-core-plain-text
  i {
  margin-left: 5px;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag
  .gdlr-core-excerpt-read-more.gdlr-core-plain-text
  i:before {
  content: "\26";
  font-family: ElegantIcons;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag .gdlr-core-blog-info-tag {
  padding-top: 10px;
  display: block;
  margin-bottom: 20px;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag .gdlr-core-blog-info-tag a {
  font-size: 15px;
  display: inline-block;
  padding: 2px 12px;
  margin-bottom: 10px;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  margin-right: 4px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag
  .gdlr-core-blog-info-tag
  span.gdlr-core-head {
  display: none;
}

.gdlr-core-blog-full.gdlr-core-style-2-tag
  .gdlr-core-blog-info-tag
  span.gdlr-core-sep {
  display: none;
}

.gdlr-core-blog-full.gdlr-core-style-3 .gdlr-core-blog-info-category {
  font-size: 13px;
  line-height: 1;
  display: inline-block;
  padding: 10px 16px;
  margin-bottom: 27px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-full.gdlr-core-style-3
  .gdlr-core-blog-info-category
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-full.gdlr-core-style-3
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.gdlr-core-blog-full.gdlr-core-style-3.gdlr-core-with-thumbnail.gdlr-core-outer-frame-element
  .gdlr-core-blog-info-category {
  position: absolute;
  top: -16px;
}

.gdlr-core-blog-full.gdlr-core-style-3 .gdlr-core-blog-info-wrapper {
  margin-bottom: 5px;
}

.gdlr-core-blog-full.gdlr-core-style-3
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  margin-right: 0;
  font-size: 13px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 400;
}

.gdlr-core-blog-full.gdlr-core-style-3
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-full.gdlr-core-style-3
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info-sep {
  margin: 0 13px;
}

.gdlr-core-blog-full.gdlr-core-style-3
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid {
  margin-bottom: 50px;
}

.gdlr-core-flexslider.flexslider ul.slides > li .gdlr-core-blog-grid {
  margin-bottom: 0;
}

.gdlr-core-blog-grid .gdlr-core-blog-thumbnail {
  margin-bottom: 27px;
}

.gdlr-core-blog-grid .gdlr-core-blog-grid-date .gdlr-core-blog-info-date {
  font-size: 12px;
  letter-spacing: 1.5px;
  margin-bottom: 7px;
  text-transform: uppercase;
  font-weight: 600;
  display: block;
}

.gdlr-core-blog-grid
  .gdlr-core-blog-grid-date
  .gdlr-core-blog-info-date
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid .gdlr-core-blog-title {
  font-size: 25px;
  font-weight: 800;
  margin-bottom: 20px;
}

.gdlr-core-blog-grid .gdlr-core-blog-content {
  margin-bottom: 20px;
}

.gdlr-core-blog-grid .gdlr-core-blog-info-wrapper {
  padding-top: 12px;
  border-top-width: 1px;
  border-top-style: solid;
}

.gdlr-core-blog-grid .gdlr-core-excerpt-read-more {
  margin-top: 33px;
}

.gdlr-core-blog-grid .gdlr-core-excerpt-read-more:first-child {
  margin-top: 10px;
}

.gdlr-core-blog-grid .gdlr-core-excerpt-read-more.gdlr-core-plain-text {
  display: inline-block;
  margin-top: 20px;
}

.gdlr-core-blog-grid
  .gdlr-core-excerpt-read-more.gdlr-core-plain-text:first-child {
  margin-top: 5px;
}

.gdlr-core-blog-grid .gdlr-core-excerpt-read-more.gdlr-core-plain-text i {
  margin-left: 10px;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-sticky-banner {
  top: 18px;
  bottom: auto;
  left: 18px;
  padding: 10px 12px;
  font-weight: 600;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-comment-number {
  position: absolute;
  bottom: 20px;
  left: 25px;
  font-size: 15px;
  line-height: 1;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-comment-number
  i {
  margin-right: 10px;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category {
  position: absolute;
  right: 20px;
  bottom: 18px;
  font-size: 12px;
  line-height: 1;
  padding: 8px 15px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-2 .gdlr-core-blog-info-wrapper {
  padding-top: 0;
  border-top-width: 0;
  margin-bottom: 4px;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  margin-right: 0;
  font-size: 13px;
  text-transform: none;
  letter-spacing: 0;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info-sep {
  margin: 0 13px;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-flexslider
  .flex-direction-nav
  li {
  margin-top: -12px;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-flexslider
  .flex-direction-nav
  li
  a {
  padding: 0;
  background: transparent;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-flexslider
  .flex-direction-nav
  li
  a
  i {
  font-size: 24px;
  width: auto;
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-flexslider
  .flex-direction-nav
  li
  a.flex-prev
  i:before {
  font-family: "ElegantIcons";
  content: "#";
}

.gdlr-core-blog-grid.gdlr-core-style-2
  .gdlr-core-flexslider
  .flex-direction-nav
  li
  a.flex-next
  i:before {
  font-family: "ElegantIcons";
  content: "$";
}

.gdlr-core-blog-full.gdlr-core-style-2-date .gdlr-core-blog-info-date,
.gdlr-core-blog-grid.gdlr-core-style-2-date .gdlr-core-blog-info-date {
  background-color: #efefef;
}

.gdlr-core-blog-full.gdlr-core-style-2-date .gdlr-core-blog-info-date a,
.gdlr-core-blog-grid.gdlr-core-style-2-date .gdlr-core-blog-info-date a {
  color: #3dbca8;
}

.gdlr-core-blog-grid.gdlr-core-style-2-date .gdlr-core-blog-info-wrapper {
  padding-top: 0;
  border: none;
  margin-bottom: 8px;
}

.gdlr-core-blog-grid.gdlr-core-style-2-date .gdlr-core-blog-info {
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
  margin-right: 0;
  letter-spacing: 0;
}

.gdlr-core-blog-grid.gdlr-core-style-2-date
  .gdlr-core-blog-info
  .gdlr-core-blog-info-sep {
  margin: 0 12px;
}

.gdlr-core-blog-grid.gdlr-core-style-2-date
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-2-date
  .gdlr-core-blog-info
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-2-date .gdlr-core-blog-info-date {
  font-size: 15px;
  padding: 7px 26px;
  border-radius: 20px;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}

.gdlr-core-blog-grid.gdlr-core-style-2-date .gdlr-core-blog-grid-date {
  margin-top: -45px;
  position: relative;
  margin-left: 20px;
  margin-bottom: 22px;
}

.gdlr-core-blog-grid-with-frame.gdlr-core-style-2-date
  .gdlr-core-blog-grid-frame {
  position: relative;
}

.gdlr-core-blog-grid-with-frame.gdlr-core-style-2-date
  .gdlr-core-blog-grid-date {
  position: absolute;
  top: -19px;
  margin-top: 0;
  margin-left: 0;
  z-index: 2;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag
  .gdlr-core-blog-thumbnail
  .gdlr-core-sticky-banner {
  top: 18px;
  bottom: auto;
  left: 18px;
  padding: 10px 12px;
  font-weight: 600;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-comment-number {
  position: absolute;
  bottom: 20px;
  left: 25px;
  font-size: 15px;
  line-height: 1;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-comment-number
  i {
  margin-right: 10px;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category {
  position: absolute;
  right: 20px;
  bottom: 18px;
  font-size: 12px;
  line-height: 1;
  padding: 8px 15px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag .gdlr-core-blog-info-wrapper {
  padding-top: 0;
  border-top-width: 0;
  margin-bottom: 11px;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  margin-right: 0;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info-sep {
  margin: 0 13px;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag .gdlr-core-blog-info-tag {
  padding-top: 5px;
  display: block;
  margin-bottom: 20px;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag .gdlr-core-blog-info-tag a {
  font-size: 15px;
  display: inline-block;
  padding: 2px 12px;
  margin-bottom: 10px;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  margin-right: 4px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag
  .gdlr-core-blog-info-tag
  span.gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag
  .gdlr-core-blog-info-tag
  span.gdlr-core-sep {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag .gdlr-core-blog-title:after {
  content: "\26";
  font-family: ElegantIcons;
  position: absolute;
  right: 0;
  font-size: 15px;
  top: 6px;
}

.gdlr-core-blog-grid.gdlr-core-style-2-tag .gdlr-core-blog-title {
  position: relative;
  padding-right: 25px;
}

.gdlr-core-blog-grid.gdlr-core-style-3
  .gdlr-core-blog-thumbnail
  .gdlr-core-sticky-banner {
  top: 18px;
  bottom: auto;
  left: 18px;
  padding: 10px 12px;
  font-weight: 600;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-grid.gdlr-core-style-3 .gdlr-core-blog-info-category {
  font-size: 13px;
  line-height: 1;
  display: inline-block;
  padding: 10px 16px;
  margin-bottom: 27px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-grid.gdlr-core-style-3
  .gdlr-core-blog-info-category
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-3
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
}

.gdlr-core-blog-grid-with-frame.gdlr-core-style-3.gdlr-core-with-thumbnail
  .gdlr-core-blog-info-category {
  position: absolute;
  top: -16px;
}

.gdlr-core-blog-grid.gdlr-core-style-3 .gdlr-core-blog-info-wrapper {
  padding-top: 0;
  border-top-width: 0;
  margin-bottom: 4px;
}

.gdlr-core-blog-grid.gdlr-core-style-3
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-3
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  font-weight: 400;
  margin-right: 0;
  font-size: 13px;
  text-transform: none;
  letter-spacing: 0;
}

.gdlr-core-blog-grid.gdlr-core-style-3
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-3
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info-sep {
  margin: 0 10px;
}

.gdlr-core-blog-grid-with-frame.gdlr-core-style-3 .gdlr-core-blog-grid-frame {
  position: relative;
  z-index: 3;
}

.gdlr-core-blog-grid.gdlr-core-style-3-date {
  position: relative;
}

.gdlr-core-blog-grid.gdlr-core-style-3-date .gdlr-core-blog-thumbnail {
  margin-left: 54px;
}

.gdlr-core-blog-grid.gdlr-core-style-3-date .gdlr-core-blog-info-wrapper {
  padding-top: 0;
  border: none;
}

.gdlr-core-blog-grid.gdlr-core-style-3-date.gdlr-core-with-thumbnail
  .gdlr-core-blog-info-date {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 14px;
  padding: 20px;
  letter-spacing: 1px;
  font-size: 14px;
  transform: rotate(270deg) translate(-100%, 0);
  -webkit-transform: rotate(270deg) translate(-100%, 0);
  -moz-transform: rotate(270deg) translate(-100%, 0);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
}

.gdlr-core-blog-grid.gdlr-core-style-3-date.gdlr-core-with-thumbnail
  .gdlr-core-blog-info-date
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-4 .gdlr-core-blog-grid-top-info {
  margin-bottom: 20px;
}

.gdlr-core-blog-grid.gdlr-core-style-4
  .gdlr-core-blog-grid-top-info
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-4
  .gdlr-core-blog-grid-top-info
  .gdlr-core-blog-info {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.gdlr-core-blog-grid.gdlr-core-style-4
  .gdlr-core-blog-grid-top-info
  .gdlr-core-blog-info-date {
  float: left;
}

.gdlr-core-blog-grid.gdlr-core-style-4
  .gdlr-core-blog-grid-top-info
  .gdlr-core-blog-info-tag {
  float: right;
}

.gdlr-core-blog-grid.gdlr-core-style-4 .gdlr-core-excerpt-read-more i {
  display: none;
}

.gdlr-core-blog-grid.gdlr-core-style-4 .gdlr-core-excerpt-read-more {
  float: right;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.gdlr-core-blog-grid.gdlr-core-style-4.gdlr-core-left-button
  .gdlr-core-excerpt-read-more {
  float: none;
}

.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-comment-number,
.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-comment-number
  a,
.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-comment-number
  a:hover {
  color: #ffffff !important;
}

.gdlr-core-style-2 .gdlr-core-blog-thumbnail .gdlr-core-blog-info-category,
.gdlr-core-style-2 .gdlr-core-blog-thumbnail .gdlr-core-blog-info-category a,
.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category
  a:hover
  .gdlr-core-style-3
  .gdlr-core-blog-info-category,
.gdlr-core-style-3 .gdlr-core-blog-info-category a,
.gdlr-core-style-3 .gdlr-core-blog-info-category a:hover {
  color: #ffffff !important;
}

.gdlr-core-blog-grid-with-frame {
  border-width: 0;
}

.gdlr-core-blog-grid.gdlr-core-blog-grid-with-frame {
  overflow: hidden;
  border-style: solid;
}

.gdlr-core-blog-grid-with-frame .gdlr-core-blog-grid-frame {
  padding: 40px 35px 24px;
}

.gdlr-core-blog-grid-with-frame .gdlr-core-blog-thumbnail {
  margin-bottom: 0;
}

.gdlr-core-flexslider.flexslider
  ul.slides
  > li
  .gdlr-core-blog-grid.gdlr-core-blog-grid-with-frame {
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.gdlr-core-blog-item.gdlr-core-style-blog-column-no-space
  .gdlr-core-blog-grid-content-wrap {
  padding-left: 30px;
  padding-right: 30px;
}

.gdlr-core-blog-item.gdlr-core-style-blog-column-no-space .gdlr-core-load-more,
.gdlr-core-blog-item.gdlr-core-style-blog-column-no-space
  .gdlr-core-pagination {
  margin-top: 20px;
}

.gdlr-core-blog-medium {
  margin-bottom: 90px;
  display: table;
}

.gdlr-core-blog-medium > div,
.gdlr-core-blog-medium > div.clearfix {
  display: table-cell;
  vertical-align: middle;
}

.gdlr-core-blog-medium .gdlr-core-excerpt-read-more.gdlr-core-plain-text {
  display: inline-block;
  margin-top: 20px;
}

.gdlr-core-blog-medium .gdlr-core-excerpt-read-more.gdlr-core-plain-text i {
  margin-left: 10px;
}

.gdlr-core-blog-left-thumbnail .gdlr-core-blog-thumbnail-wrap {
  width: 35%;
  padding-right: 35px;
}

.gdlr-core-blog-left-thumbnail.gdlr-core-large .gdlr-core-blog-thumbnail-wrap {
  width: 55%;
}

.gdlr-core-blog-left-thumbnail .gdlr-core-blog-date-wrapper {
  float: right;
  margin-right: 0;
}

.gdlr-core-blog-left-thumbnail .gdlr-core-blog-title {
  font-size: 37px;
  font-weight: 700;
  margin-bottom: 16px;
}

.gdlr-core-blog-left-thumbnail .gdlr-core-blog-info-wrapper {
  margin-bottom: 26px;
}

.gdlr-core-blog-left-thumbnail .gdlr-core-excerpt-read-more {
  margin-top: 33px;
}

.gdlr-core-blog-right-thumbnail {
  text-align: right;
}

.gdlr-core-blog-right-thumbnail .gdlr-core-blog-thumbnail-wrap {
  width: 35%;
  padding-left: 35px;
}

.gdlr-core-blog-right-thumbnail.gdlr-core-large .gdlr-core-blog-thumbnail-wrap {
  width: 55%;
}

.gdlr-core-blog-right-thumbnail .gdlr-core-blog-date-wrapper {
  float: left;
  margin-right: 0;
  padding-left: 20px;
  border-left-width: 1px;
  border-left-style: solid;
  border-right: 0;
}

.gdlr-core-blog-right-thumbnail .gdlr-core-blog-title {
  font-size: 37px;
  font-weight: 700;
  margin-bottom: 16px;
}

.gdlr-core-blog-right-thumbnail .gdlr-core-blog-info-wrapper {
  margin-bottom: 26px;
}

.gdlr-core-blog-right-thumbnail .gdlr-core-excerpt-read-more {
  margin-top: 33px;
}

.gdlr-core-blog-medium.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-sticky-banner {
  top: 18px;
  bottom: auto;
  left: 18px;
  padding: 10px 12px;
  font-weight: 600;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-medium.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-comment-number {
  position: absolute;
  bottom: 20px;
  left: 25px;
  font-size: 15px;
  line-height: 1;
}

.gdlr-core-blog-medium.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-comment-number
  i {
  margin-right: 10px;
}

.gdlr-core-blog-medium.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category {
  position: absolute;
  right: 20px;
  bottom: 18px;
  font-size: 12px;
  line-height: 1;
  padding: 8px 15px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-medium.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-medium.gdlr-core-style-2 .gdlr-core-blog-info-wrapper {
  margin-bottom: 5px;
}

.gdlr-core-blog-medium.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  margin-right: 0;
  font-size: 13px;
  text-transform: none;
  letter-spacing: 0;
}

.gdlr-core-blog-medium.gdlr-core-style-2
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-medium.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info-sep {
  margin: 0 13px;
}

.gdlr-core-blog-medium.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-medium.gdlr-core-style-2 .gdlr-core-blog-title {
  margin-bottom: 20px;
}

.gdlr-core-blog-feature {
  position: relative;
}

.gdlr-core-blog-feature .gdlr-core-blog-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
  line-height: 1.3;
}

.gdlr-core-blog-feature .gdlr-core-blog-info-wrapper {
  margin-bottom: 10px;
}

.gdlr-core-blog-feature.gdlr-core-with-image .gdlr-core-blog-feature-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 35px 40px 45px;
}

.gdlr-core-blog-feature.gdlr-core-hover-overlay-content.gdlr-core-with-image
  .gdlr-core-blog-feature-content {
  opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity 500ms ease-out;
  -moz-transition: opacity 500ms ease-out;
  -o-transition: opacity 500ms ease-out;
  -webkit-transition: opacity 500ms ease-out;
}

.gdlr-core-blog-feature.gdlr-core-hover-overlay-content.gdlr-core-with-image:hover
  .gdlr-core-blog-feature-content {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gdlr-core-blog-feature .gdlr-core-blog-feature-content-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #000;
  filter: alpha(opacity=40);
  opacity: 0.4;
}

.gdlr-core-blog-feature .gdlr-core-blog-feature-content-overlay-gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  /* background: url(../../../../../../../../../../plugins/goodlayers-core/include/images/blog-style/blog-item-gradient.png)
    bottom repeat-x; */
}

.gdlr-core-blog-feature .gdlr-core-blog-feature-content-overlay-gradient2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  /* background: url(../../../../../../../../../../plugins/goodlayers-core/include/images/blog-style/blog-item-gradient2.png)
    bottom repeat-x; */
}

.gdlr-core-blog-feature .gdlr-core-blog-info-wrapper .gdlr-core-blog-info {
  font-size: 14px;
  text-transform: none;
  font-weight: 400;
}

.gdlr-core-blog-feature .gdlr-core-blog-info-category {
  position: absolute;
  top: 30px;
  left: 45px;
  font-size: 13px;
  line-height: 1;
  padding: 8px 15px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-feature .gdlr-core-blog-info-category .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-feature .gdlr-core-blog-info-category i {
  display: none;
}

.gdlr-core-blog-feature .gdlr-core-blog-info-category,
.gdlr-core-blog-feature .gdlr-core-blog-info-category a,
.gdlr-core-blog-feature .gdlr-core-blog-info-category a:hover {
  color: #fff;
}

.gdlr-core-blog-feature.gdlr-core-sub-item .gdlr-core-blog-title {
  font-size: 15px;
}

.gdlr-core-blog-feature.gdlr-core-sub-item .gdlr-core-blog-info-wrapper {
  display: none;
}

.gdlr-core-blog-feature.gdlr-core-sub-item.gdlr-core-with-image
  .gdlr-core-blog-feature-content {
  padding: 0 18px 8px 18px;
}

.gdlr-core-blog-feature.gdlr-core-sub-item .gdlr-core-blog-info-category {
  font-size: 11px;
  top: 13px;
  left: 15px;
}

.gdlr-core-blog-modern {
  position: relative;
  border-style: solid;
}

.gdlr-core-blog-modern .gdlr-core-blog-modern-content {
  padding: 0 0 20px;
}

.gdlr-core-blog-modern .gdlr-core-blog-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 2px;
  line-height: 1.6;
}

.gdlr-core-blog-modern-inner .gdlr-core-blog-info-wrapper {
  margin-bottom: 10px;
}

.gdlr-core-blog-modern .gdlr-core-blog-content {
  padding-top: 6px;
}

.gdlr-core-blog-modern .gdlr-core-excerpt-read-more {
  display: none;
}

.gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-modern-inner {
  position: relative;
}

.gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-modern-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 45px 40px 60px;
  transition: opacity 500ms ease-out, bottom 200ms ease-out;
  -moz-transition: opacity 500ms ease-out, bottom 200ms ease-out;
  -o-transition: opacity 500ms ease-out, bottom 200ms ease-out;
  -webkit-transition: opacity 500ms ease-out, bottom 200ms ease-out;
}

.gdlr-core-blog-modern.gdlr-core-hover-overlay-content.gdlr-core-with-image
  .gdlr-core-blog-modern-content {
  opacity: 0;
  filter: alpha(opacity=0);
}

.gdlr-core-blog-modern.gdlr-core-hover-overlay-content.gdlr-core-with-image:hover
  .gdlr-core-blog-modern-content {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gdlr-core-blog-modern .gdlr-core-blog-modern-content-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #000;
  filter: alpha(opacity=40);
  opacity: 0.4;
}

.gdlr-core-blog-modern .gdlr-core-blog-modern-content-overlay-gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  /* background: url(../../../../../../../../../../plugins/goodlayers-core/include/images/blog-style/blog-item-gradient.png)
    bottom repeat-x; */
}

.gdlr-core-blog-modern .gdlr-core-blog-modern-content-overlay-gradient2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  /* background: url(../../../../../../../../../../plugins/goodlayers-core/include/images/blog-style/blog-item-gradient2.png)
    bottom repeat-x; */
}

.gdlr-core-blog-item.gdlr-core-style-blog-image .gdlr-core-load-more,
.gdlr-core-blog-item.gdlr-core-style-blog-image .gdlr-core-pagination {
  margin-top: 20px;
}

.gdlr-core-blog-item.gdlr-core-style-blog-image-no-space .gdlr-core-load-more {
  margin-top: 50px;
}

.gdlr-core-blog-item.gdlr-core-style-blog-image-no-space .gdlr-core-pagination {
  margin-top: 50px;
}

.gdlr-core-blog-modern.gdlr-core-gradient-slide
  .gdlr-core-blog-thumbnail:after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  -moz-background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  -o-background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  -webkit-background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  transition: top 150ms;
  -moz-transition: top 150ms;
  -o-transition: top 150ms;
  -webkit-transition: top 150ms;
}

.gdlr-core-blog-modern.gdlr-core-gradient-slide:hover
  .gdlr-core-blog-thumbnail:after {
  top: 0;
}

.gdlr-core-blog-modern.gdlr-core-style-1 .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-modern.gdlr-core-style-2 .gdlr-core-blog-info i {
  display: none;
}

.gdlr-core-blog-modern.gdlr-core-style-2
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-modern.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category {
  position: absolute;
  top: 20px;
  left: 18px;
  font-size: 12px;
  line-height: 1;
  padding: 8px 15px;
  z-index: 1;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-blog-modern.gdlr-core-style-2
  .gdlr-core-blog-thumbnail
  .gdlr-core-blog-info-category
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-modern.gdlr-core-style-3 .gdlr-core-blog-title {
  margin-bottom: 8px;
}

.gdlr-core-blog-modern.gdlr-core-style-3 .gdlr-core-blog-modern-content {
  text-align: left;
}

.gdlr-core-blog-modern.gdlr-core-style-3
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-modern.gdlr-core-style-3 .gdlr-core-blog-info {
  margin-right: 15px;
}

.gdlr-core-blog-modern.gdlr-core-style-3
  .gdlr-core-blog-info
  .gdlr-core-blog-info-sep {
  margin-right: 15px;
}

.gdlr-core-blog-modern.gdlr-core-style-3
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-modern.gdlr-core-style-3:hover .gdlr-core-blog-modern-content {
  bottom: 20px;
}

.gdlr-core-blog-modern.gdlr-core-style-3.gdlr-core-opacity-on-hover img {
  opacity: 0.7;
}

.gdlr-core-blog-modern.gdlr-core-style-3.gdlr-core-opacity-on-hover:hover img {
  opacity: 0.3 !important;
}

.gdlr-core-blog-modern.gdlr-core-style-3.gdlr-core-with-image
  .gdlr-core-blog-modern-content {
  padding: 0 35px 30px;
}

.gdlr-core-blog-modern.gdlr-core-style-4 .gdlr-core-blog-title {
  margin-bottom: 8px;
}

.gdlr-core-blog-modern.gdlr-core-style-4 .gdlr-core-blog-modern-content {
  text-align: left;
}

.gdlr-core-blog-modern.gdlr-core-style-4
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-modern.gdlr-core-style-4 .gdlr-core-blog-info {
  margin-right: 15px;
}

.gdlr-core-blog-modern.gdlr-core-style-4
  .gdlr-core-blog-info
  .gdlr-core-blog-info-sep {
  margin-right: 15px;
}

.gdlr-core-blog-modern.gdlr-core-style-4
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-modern.gdlr-core-style-4.gdlr-core-opacity-on-hover img {
  opacity: 0.7;
}

.gdlr-core-blog-modern.gdlr-core-style-4.gdlr-core-opacity-on-hover:hover img {
  opacity: 0.3 !important;
}

.gdlr-core-blog-modern.gdlr-core-style-4.gdlr-core-with-image
  .gdlr-core-blog-modern-content {
  padding: 0 35px 30px;
}

.gdlr-core-blog-modern.gdlr-core-style-4.gdlr-core-with-image
  .gdlr-core-blog-content {
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-out;
  -moz-transition: max-height 300ms ease-out;
  -o-transition: max-height 300ms ease-out;
  -webkit-transition: max-height 300ms ease-out;
}

.gdlr-core-blog-modern.gdlr-core-style-4.gdlr-core-with-image:hover
  .gdlr-core-blog-content {
  margin-bottom: 0;
  max-height: 150px;
}

.gdlr-core-blog-modern.gdlr-core-style-5 .gdlr-core-blog-modern-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.gdlr-core-blog-modern.gdlr-core-style-5 .gdlr-core-blog-info-wrapper {
  order: 1;
}

.gdlr-core-blog-modern.gdlr-core-style-5 .gdlr-core-blog-title {
  order: 2;
}

.gdlr-core-blog-modern.gdlr-core-style-5 .gdlr-core-blog-content {
  order: 3;
}

.gdlr-core-blog-modern.gdlr-core-style-5
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-modern.gdlr-core-style-5
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-modern.gdlr-core-style-5
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  margin-right: 12px;
}

.gdlr-core-blog-modern.gdlr-core-style-5
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info
  .gdlr-core-blog-info-sep {
  margin-right: 10px;
}

.gdlr-core-blog-modern.gdlr-core-style-5 .gdlr-core-blog-modern-content {
  padding: 0 35px 30px;
}

.gdlr-core-blog-modern.gdlr-core-style-5 .gdlr-core-blog-content {
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-out;
  -moz-transition: max-height 300ms ease-out;
  -o-transition: max-height 300ms ease-out;
  -webkit-transition: max-height 300ms ease-out;
}

.gdlr-core-blog-modern.gdlr-core-style-5 .gdlr-core-blog-thumbnail {
  opacity: 0;
  background: #000;
  transition: opacity 300ms ease-out;
  -moz-transition: opacity 300ms ease-out;
  -o-transition: opacity 300ms ease-out;
  -webkit-transition: opacity 300ms ease-out;
}

.gdlr-core-blog-modern.gdlr-core-style-5:hover
  .gdlr-core-blog-modern-content
  a {
  transition: none;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
}

.gdlr-core-blog-modern.gdlr-core-style-5:hover .gdlr-core-blog-thumbnail {
  opacity: 1;
}

.gdlr-core-blog-modern.gdlr-core-style-5:hover .gdlr-core-blog-content {
  margin-bottom: 0;
  max-height: 80px;
}

.gdlr-core-blog-modern.gdlr-core-style-5:hover
  .gdlr-core-blog-modern-content
  .gdlr-core-blog-info-wrapper
  a,
.gdlr-core-blog-modern.gdlr-core-style-5:hover
  .gdlr-core-blog-modern-content
  .gdlr-core-blog-info-wrapper,
.gdlr-core-blog-modern.gdlr-core-style-5:hover
  .gdlr-core-blog-modern-content
  .gdlr-core-blog-title
  a,
.gdlr-core-blog-modern.gdlr-core-style-5:hover
  .gdlr-core-blog-modern-content
  .gdlr-core-blog-content {
  color: #fff !important;
}

.gdlr-core-blog-metro.gdlr-core-with-image {
  position: relative;
  overflow: hidden;
}

.gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-metro-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 20px 30px;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity 500ms ease-out;
  -moz-transition: opacity 500ms ease-out;
  -o-transition: opacity 500ms ease-out;
  -webkit-transition: opacity 500ms ease-out;
}

.gdlr-core-blog-metro.gdlr-core-with-image:hover .gdlr-core-blog-metro-content {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gdlr-core-blog-metro .gdlr-core-blog-metro-content {
  text-align: center;
  padding: 0 0 20px;
}

.gdlr-core-blog-metro .gdlr-core-blog-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 1.5;
}

.gdlr-core-blog-item.gdlr-core-style-blog-metro .gdlr-core-load-more,
.gdlr-core-blog-item.gdlr-core-style-blog-metro .gdlr-core-pagination {
  margin-top: 20px;
}

.gdlr-core-blog-item.gdlr-core-style-blog-metro-no-space .gdlr-core-load-more {
  margin-top: 50px;
}

.gdlr-core-blog-item.gdlr-core-style-blog-metro-no-space .gdlr-core-pagination {
  margin-top: 50px;
}

.gdlr-core-blog-list {
  margin-bottom: 40px;
}

.gdlr-core-blog-list.gdlr-core-blog-list-with-frame {
  margin-bottom: 6px;
}

.gdlr-core-blog-list .gdlr-core-blog-list-frame {
  padding: 20px 26px 24px;
}

.gdlr-core-blog-list .gdlr-core-blog-title {
  font-size: 19px;
  letter-spacing: 1px;
  font-weight: 800;
  margin-bottom: 10px;
}

.gdlr-core-blog-list .gdlr-core-blog-info {
  margin-right: 15px;
}

.gdlr-core-blog-list .gdlr-core-blog-info .gdlr-core-blog-info-sep {
  margin-right: 15px;
}

.gdlr-core-blog-list .gdlr-core-blog-info:first-child .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-widget {
  margin-bottom: 18px;
  padding-top: 18px;
  border-top-width: 1px;
  border-top-style: solid;
}

.gdlr-core-blog-widget:first-child {
  padding-top: 0;
  border: 0;
}

.gdlr-core-blog-widget .gdlr-core-blog-thumbnail {
  float: left;
  margin-right: 20px;
  max-width: 60px;
}

.gdlr-core-blog-widget .gdlr-core-sticky-banner {
  display: none;
}

.gdlr-core-blog-widget .gdlr-core-blog-widget-content {
  overflow: hidden;
}

.gdlr-core-blog-widget .gdlr-core-blog-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.gdlr-core-blog-widget .gdlr-core-blog-info-wrapper .gdlr-core-head {
  margin-right: 10px;
}

.gdlr-core-blog-widget-divider {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding-top: 3px;
}

.gdlr-core-blog-widget.gdlr-core-style-large {
  margin-bottom: 22px;
  padding-top: 22px;
}

.gdlr-core-blog-widget.gdlr-core-style-large:first-child {
  padding-top: 0;
}

.gdlr-core-blog-widget.gdlr-core-style-large .gdlr-core-blog-widget-content {
  padding-top: 8px;
}

.gdlr-core-blog-widget.gdlr-core-style-large .gdlr-core-blog-thumbnail {
  max-width: 80px;
}

.gdlr-core-blog-widget.gdlr-core-style-large .gdlr-core-blog-title {
  font-size: 16px;
  margin-bottom: 12px;
}

.gdlr-core-blog-widget.gdlr-core-style-small .gdlr-core-blog-title {
  font-size: 16px;
  font-weight: 500;
}

.gdlr-core-blog-widget.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-widget.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  font-weight: 400;
  margin-right: 0;
  font-size: 13px;
  text-transform: none;
  letter-spacing: 0;
}

.gdlr-core-blog-widget.gdlr-core-style-2
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info-sep {
  margin: 0 13px;
}

.gdlr-core-blog-widget.gdlr-core-style-2
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-blog-widget.gdlr-core-style-3 {
  padding-top: 25px;
  margin-bottom: 25px;
}

.gdlr-core-blog-widget.gdlr-core-style-3:first-child {
  padding-top: 0;
}

.gdlr-core-blog-widget.gdlr-core-style-3 .gdlr-core-blog-title {
  line-height: 1.5;
}

.gdlr-core-blog-widget.gdlr-core-style-3 .gdlr-core-blog-widget-content {
  display: flex;
  flex-direction: column;
}

.gdlr-core-blog-widget.gdlr-core-style-3 .gdlr-core-blog-info-wrapper {
  order: -1;
  margin-bottom: 10px;
}

.gdlr-core-blog-widget.gdlr-core-style-3 .gdlr-core-blog-widget-read-more {
  margin-top: 5px;
}

.gdlr-core-blog-widget.gdlr-core-style-3 .gdlr-core-blog-thumbnail {
  float: right;
  margin-left: 20px;
  margin-right: 0;
  max-width: 95px;
}

.gdlr-core-item-list-wrap.gdlr-core-featured
  .gdlr-core-item-list.gdlr-core-blog-widget {
  position: relative;
}

.gdlr-core-item-list-wrap.gdlr-core-featured
  .gdlr-core-blog-widget.gdlr-core-style-3
  .gdlr-core-blog-thumbnail {
  margin-left: 0;
}

.gdlr-core-item-list-wrap.gdlr-core-featured
  .gdlr-core-blog-widget.gdlr-core-style-3
  .gdlr-core-blog-widget-content {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 30px;
  bottom: 25px;
  right: 30px;
}

.gdlr-core-item-list-wrap.gdlr-core-featured
  .gdlr-core-blog-widget.gdlr-core-style-3
  .gdlr-core-blog-title
  a {
  color: #fff;
}

.gdlr-core-item-list-wrap.gdlr-core-featured
  .gdlr-core-blog-widget.gdlr-core-style-3
  .gdlr-core-blog-widget-read-more
  a {
  color: #d3d3d3;
}

.gdlr-core-item-list-wrap.gdlr-core-featured
  .gdlr-core-blog-widget.gdlr-core-style-3
  .gdlr-core-blog-info-wrapper
  a,
.gdlr-core-item-list-wrap.gdlr-core-featured
  .gdlr-core-blog-widget.gdlr-core-style-3
  .gdlr-core-blog-info-wrapper
  i {
  color: #d3d3d3;
}

.gdlr-core-item-list-wrap.gdlr-core-featured
  .gdlr-core-blog-widget.gdlr-core-style-3
  .gdlr-core-blog-thumbnail:after {
  content: " ";
  position: absolute;
  top: 30%;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.gdlr-core-item-list-wrap.gdlr-core-with-featured
  .gdlr-core-blog-widget.gdlr-core-style-3:first-child {
  padding-top: 20px;
}

.gdlr-core-item-list-wrap.gdlr-core-featured
  .gdlr-core-blog-widget
  .gdlr-core-blog-thumbnail {
  float: none;
  max-width: 100%;
  margin-right: 0;
  margin-bottom: 25px;
}

.gdlr-core-item-list-wrap.gdlr-core-featured .gdlr-core-blog-widget-divider {
  display: none;
}

@media only screen and (max-width: 767px) {
  .gdlr-core-blog-widget.gdlr-core-style-3 .gdlr-core-blog-info-wrapper {
    display: none;
  }

  .gdlr-core-blog-widget.gdlr-core-style-3 .gdlr-core-blog-widget-read-more {
    display: none;
  }

  .gdlr-core-item-list-wrap.gdlr-core-featured
    .gdlr-core-blog-widget.gdlr-core-style-3
    .gdlr-core-blog-widget-content {
    bottom: 20px;
  }
}

.gdlr-core-blog-column-hover-background {
  padding: 55px 45px 50px;
  position: relative;
}

.gdlr-core-blog-column-hover-background .gdlr-core-content {
  position: relative;
}

.gdlr-core-blog-column-hover-background .gdlr-core-column-date {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 18px;
}

.gdlr-core-blog-column-hover-background .gdlr-core-column-date .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-column-hover-background .gdlr-core-blog-title {
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 800;
}

.gdlr-core-blog-column-hover-background .gdlr-core-blog-info-wrapper {
  margin-top: 15px;
}

.gdlr-core-blog-column-hover-background
  .gdlr-core-blog-info-wrapper
  .gdlr-core-head {
  display: none;
}

.gdlr-core-blog-column-hover-background
  .gdlr-core-blog-info-wrapper
  .gdlr-core-blog-info {
  font-weight: 400;
  font-size: 13px;
  text-transform: none;
}

.gdlr-core-blog-column-hover-background .gdlr-core-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  opacity: 0;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.gdlr-core-blog-column-hover-background .gdlr-core-background-overlay {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
}

.gdlr-core-blog-column-hover-background:hover .gdlr-core-background {
  opacity: 1;
}

.gdlr-core-blog-column-hover-background:hover .gdlr-core-blog-title a,
.gdlr-core-blog-column-hover-background:hover .gdlr-core-blog-info a {
  color: #fff !important;
}

.gdlr-core-video-item .gdlr-core-video-item-type-vimeo,
.gdlr-core-video-item .gdlr-core-video-item-type-youtube {
  position: relative;
}

.gdlr-core-video-item .gdlr-core-video-item-overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gdlr-core-video-item .gdlr-core-video-item-overlay-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}

.gdlr-core-video-item .gdlr-core-video-item-overlay-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 116px;
  margin-left: -58px;
  margin-top: -58px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  line-height: 30px;
  padding: 42px 0 42px 7px;
  color: #fff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-video-widget {
  position: relative;
}

.gdlr-core-video-widget i {
  position: absolute;
  left: 50%;
  top: 50%;
  background: #000;
  color: #fff;
  font-size: 18px;
  line-height: 60px;
  width: 60px;
  text-align: center;
  margin-top: -30px;
  margin-left: -30px;
  padding-left: 6px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-instagram-widget {
  margin-left: -8px;
  margin-right: -8px;
}

.gdlr-core-instagram-widget .gdlr-core-media-image {
  padding: 8px;
}

ul.gdlr-core-category-background-widget {
  margin-left: 0;
  list-style: none;
  font-weight: 500;
  font-size: 15px;
}

ul.gdlr-core-category-background-widget li.gdlr-core-no-bg {
  background: #f3f3f3;
}

ul.gdlr-core-category-background-widget li {
  margin-bottom: 8px;
  background-position: center;
  background-size: cover;
}

.gdlr-core-category-background-widget-count {
  float: right;
  font-size: 12px;
  margin-top: 3px;
}

ul.gdlr-core-category-background-widget li a {
  display: block;
  padding: 17px 25px;
}

ul.gdlr-core-category-background-widget li.gdlr-core-no-bg a,
ul.gdlr-core-category-background-widget li.gdlr-core-no-bg a:hover {
  color: #666;
}

ul.gdlr-core-category-background-widget li.gdlr-core-with-bg a,
ul.gdlr-core-category-background-widget li.gdlr-core-with-bg a:hover {
  color: #fff;
}

.gdlr-core-opening-hour-widget-list {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.gdlr-core-opening-hour-widget-list .gdlr-core-head {
  float: left;
  font-weight: 600;
}

.gdlr-core-opening-hour-widget-list .gdlr-core-head i {
  font-weight: 400;
  margin-right: 8px;
  vertical-align: middle;
}

.gdlr-core-opening-hour-widget-list .gdlr-core-tail {
  float: right;
}

.gdlr-core-post-slider-item .gdlr-core-post-slider-slide {
  position: relative;
}

.gdlr-core-post-slider-item .gdlr-core-post-slider-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px 25px;
}

.gdlr-core-post-slider-item .gdlr-core-post-slider-title {
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.gdlr-core-post-slider-item .gdlr-core-blog-info {
  font-size: 12px;
}

.gdlr-core-post-slider-item .gdlr-core-blog-info .gdlr-core-blog-info-sep {
  margin: 0 8px;
}

.gdlr-core-post-slider-item
  .gdlr-core-blog-info:first-child
  .gdlr-core-blog-info-sep {
  display: none;
}

.gdlr-core-post-slider-item .gdlr-core-post-slider-gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background: url(../../../../../../../../../../plugins/goodlayers-core/include/images/post-slider/gradient.png); */
  background-position: bottom;
  background-repeat: repeat-x;
}

.gdlr-core-post-slider-item .gdlr-core-post-slider-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-wrap {
  width: 50%;
  float: left;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head {
  position: relative;
  padding-bottom: 45px;
  cursor: pointer;
}

.gdlr-core-roadmap-item
  .gdlr-core-roadmap-item-head:last-child
  .gdlr-core-roadmap-item-head-divider {
  display: none;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-content {
  text-align: right;
  overflow: hidden;
  margin-right: 55px;
  padding-top: 8px;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-count {
  float: right;
  width: 50px;
  text-align: center;
  margin-right: -25px;
  font-size: 15px;
  line-height: 20px;
  padding: 15px 0;
  font-weight: 700;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  transition: background-color 200ms;
  -moz-transition: background-color 200ms;
  -o-transition: background-color 200ms;
  -webkit-transition: background-color 200ms;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-divider {
  position: absolute;
  bottom: 15px;
  right: 0;
  height: 15px;
  border-left-width: 1px;
  border-left-style: solid;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content-area {
  margin-left: 70px;
  display: none;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content-area.gdlr-core-active {
  display: block;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content-title {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  margin-bottom: 35px;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content-caption {
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 9px;
  letter-spacing: 2px;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-caption {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content-wrap {
  overflow: hidden;
}

.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content {
  font-size: 17px;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-title {
  font-size: 13px;
  font-weight: 800;
  padding: 6px 14px 6px 18px;
  position: relative;
  float: left;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-title:after {
  content: "\f107";
  font-family: fontAwesome;
  margin-left: 10px;
  float: right;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-head-wrap {
  padding-top: 5px;
  padding-bottom: 5px;
  position: absolute;
  left: 0;
  top: 100%;
  display: none;
  z-index: 103;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  margin-top: 5px;
  min-width: 140px;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-head {
  padding: 3px 18px;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-head.gdlr-core-active {
  display: none;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-content-wrap {
  overflow: hidden;
  padding-top: 4px;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-content {
  display: none;
  white-space: nowrap;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-content.gdlr-core-active {
  display: block;
}

.gdlr-core-blog-aside-format .gdlr-core-blog-content {
  font-style: italic;
}

.gdlr-core-blog-aside-format .gdlr-core-excerpt-read-more,
.gdlr-core-blog-aside-format .gdlr-core-excerpt-read-more.gdlr-core-plain-text {
  display: none;
}

.gdlr-core-blog-aside-format.gdlr-core-large .gdlr-core-blog-content {
  font-size: 18px;
  padding: 50px 60px;
}

.gdlr-core-blog-aside-format.gdlr-core-small .gdlr-core-blog-content {
  font-size: 16px;
  padding: 40px 37px;
}

.gdlr-core-blog-quote-format {
  position: relative;
}

.gdlr-core-blog-quote-format .gdlr-core-blog-quote-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.gdlr-core-blog-quote-format .gdlr-core-blog-quote {
  line-height: 1;
  position: relative;
  font-weight: 800;
}

.gdlr-core-blog-quote-format .gdlr-core-blog-content-wrap {
  overflow: hidden;
  position: relative;
}

.gdlr-core-blog-quote-format .gdlr-core-blog-quote-content {
  font-style: italic;
}

.gdlr-core-blog-quote-format .gdlr-core-blog-quote-content blockquote,
.gdlr-core-blog-quote-format .gdlr-core-blog-quote-content q {
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.gdlr-core-blog-quote-format .gdlr-core-blog-quote-author {
  font-style: italic;
  margin-bottom: 20px;
  font-weight: 700;
}

.gdlr-core-blog-quote-format .gdlr-core-blog-quote-author:before {
  content: "-";
  margin-right: 2px;
}

.gdlr-core-blog-quote-format .gdlr-core-excerpt-read-more,
.gdlr-core-blog-quote-format .gdlr-core-excerpt-read-more.gdlr-core-plain-text {
  display: none;
}

.gdlr-core-blog-quote-format.gdlr-core-large .gdlr-core-blog-content {
  padding: 50px 45px 40px;
}

.gdlr-core-blog-quote-format.gdlr-core-large .gdlr-core-blog-quote {
  float: left;
  font-size: 120px;
  margin-top: -10px;
  margin-right: 35px;
}

.gdlr-core-blog-quote-format.gdlr-core-large .gdlr-core-blog-quote-content,
.gdlr-core-blog-quote-format.gdlr-core-large .gdlr-core-blog-quote-content q,
.gdlr-core-blog-quote-format.gdlr-core-large
  .gdlr-core-blog-quote-content
  blockquote {
  font-size: 18px;
}

.gdlr-core-blog-quote-format.gdlr-core-large .gdlr-core-blog-quote-author {
  font-size: 15px;
}

.gdlr-core-blog-quote-format.gdlr-core-small .gdlr-core-blog-content {
  padding: 40px 37px 30px;
}

.gdlr-core-blog-quote-format.gdlr-core-small .gdlr-core-blog-quote {
  font-size: 80px;
  margin-bottom: -10px;
}

.gdlr-core-blog-quote-format.gdlr-core-small .gdlr-core-blog-quote-content q,
.gdlr-core-blog-quote-format.gdlr-core-small
  .gdlr-core-blog-quote-content
  blockquote,
.gdlr-core-blog-quote-format.gdlr-core-small .gdlr-core-blog-quote-content {
  font-size: 16px;
}

.gdlr-core-blog-quote-format.gdlr-core-small .gdlr-core-blog-quote-author {
  font-size: 15px;
}

.gdlr-core-blog-link-format .gdlr-core-blog-icon-link {
  font-size: 23px;
}

.gdlr-core-blog-link-format .gdlr-core-blog-content-wrap {
  overflow: hidden;
}

.gdlr-core-blog-link-format .gdlr-core-blog-title {
  font-size: 20px;
  letter-spacing: 0;
  margin-bottom: 9px;
}

.gdlr-core-blog-link-format .gdlr-core-blog-content {
  font-size: 14px;
  margin-bottom: 0;
}

.gdlr-core-blog-link-format .gdlr-core-excerpt-read-more,
.gdlr-core-blog-link-format .gdlr-core-excerpt-read-more.gdlr-core-plain-text {
  display: none;
}

.gdlr-core-blog-link-format.gdlr-core-large .gdlr-core-blog-icon-link {
  float: left;
  margin-right: 30px;
  margin-top: 5px;
}

.gdlr-core-blog-link-format.gdlr-core-small .gdlr-core-blog-icon-link {
  margin-bottom: 25px;
  display: block;
}

.gdlr-core-blog-link-format.gdlr-core-blog-grid
  .gdlr-core-blog-content-outer-wrap {
  padding: 40px 35px 35px;
}

.gdlr-core-blog-link-format.gdlr-core-blog-full
  .gdlr-core-blog-content-outer-wrap {
  padding: 50px 50px 40px;
}

.gdlr-core-blog-link-format.gdlr-core-with-frame
  .gdlr-core-blog-content-outer-wrap {
  background: #fff;
}

.gdlr-core-blog-quote-format.gdlr-core-small.gdlr-core-style-2 {
  text-align: center;
}

.gdlr-core-blog-quote-format.gdlr-core-small.gdlr-core-style-2
  .gdlr-core-blog-quote {
  font-size: 110px;
  margin-bottom: -45px;
}

.gdlr-core-blog-quote-format.gdlr-core-small.gdlr-core-style-2
  .gdlr-core-blog-content {
  padding: 55px 54px 47px;
}

.gdlr-core-blog-quote-format.gdlr-core-small.gdlr-core-style-2
  .gdlr-core-blog-quote-content
  blockquote {
  font-size: 18px;
  font-weight: 400;
}

.gdlr-core-blog-quote-format.gdlr-core-small.gdlr-core-style-2
  .gdlr-core-blog-quote-author {
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  margin-top: 20px;
}

.gdlr-core-blog-link-format.gdlr-core-small.gdlr-core-style-2 {
  text-align: center;
}

.gdlr-core-blog-link-format.gdlr-core-small.gdlr-core-style-2
  .gdlr-core-blog-icon-link {
  font-size: 30px;
}

.gdlr-core-blog-link-format.gdlr-core-small.gdlr-core-style-2
  .gdlr-core-blog-title {
  font-size: 23px;
  margin-bottom: 15px;
}

.gdlr-core-blog-link-format.gdlr-core-small.gdlr-core-style-2
  .gdlr-core-blog-content {
  font-size: 13px;
}

.gdlr-core-blog-link-format.gdlr-core-small.gdlr-core-style-2
  .gdlr-core-blog-content-outer-wrap {
  padding: 70px 35px 72px;
}

.gdlr-core-blog-aside-format.gdlr-core-small.gdlr-core-style-2
  .gdlr-core-blog-content {
  font-size: 18px;
  font-style: normal;
  text-align: center;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-frame {
  position: relative;
  background: #fff;
  padding: 45px 40px 60px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-frame-border {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-style: solid;
  border-width: 0;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-item-title {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 0;
  font-size: 28px;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-item-title-wrap {
  margin-bottom: 36px;
  position: relative;
}

.gdlr-core-testimonial-item
  .gdlr-core-testimonial-column
  .gdlr-core-testimonial {
  margin-bottom: 50px;
}

.gdlr-core-testimonial-item
  .gdlr-core-testimonial-column
  .gdlr-core-testimonial.gdlr-core-with-frame {
  margin-bottom: 0;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-content {
  font-style: italic;
  margin-bottom: 20px;
  font-size: 15px;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-content p {
  margin-bottom: 0;
  line-height: inherit;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-author-image {
  width: 55px;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-author-image img {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-item-divider {
  margin-top: 38px;
  padding-bottom: 10px;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-position {
  font-size: 13px;
  font-style: italic;
}

.gdlr-core-testimonial-item
  .gdlr-core-testimonial-position
  .gdlr-core-rating
  i {
  margin-right: 4px;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-position .gdlr-core-rating {
  margin-right: 10px;
}

.gdlr-core-testimonial-item
  .gdlr-core-testimonial-position
  .gdlr-core-rating.gdlr-core-right {
  float: right;
  margin-right: 0;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-author-content {
  overflow: hidden;
  padding-top: 3px;
}

.gdlr-core-testimonial-item.gdlr-core-testimonial-style-center
  .gdlr-core-testimonial-author-content {
  float: left;
  overflow: auto;
  padding-top: 3px;
}

.gdlr-core-testimonial-content-wrap {
  position: relative;
}

.gdlr-core-testimonial-style-left .gdlr-core-testimonial-quote {
  font-size: 60px;
  line-height: 1;
  float: left;
  margin-right: 30px;
}

.gdlr-core-testimonial-style-left .gdlr-core-testimonial-content-wrap {
  overflow: hidden;
}

.gdlr-core-testimonial-style-left .gdlr-core-testimonial-author-image {
  float: left;
  margin-right: 20px;
}

.gdlr-core-testimonial-style-left .gdlr-core-testimonial-quote.gdlr-core-top {
  font-size: 110px;
  float: none;
  height: 65px;
}

.gdlr-core-testimonial-style-left-bg .gdlr-core-testimonial-content-wrap {
  overflow: hidden;
}

.gdlr-core-testimonial-style-left-bg .gdlr-core-testimonial-content {
  margin-bottom: 35px;
  padding-top: 40px;
}

.gdlr-core-testimonial-style-left-bg .gdlr-core-testimonial-quote {
  position: absolute;
  opacity: 0.3;
  font-size: 240px;
  margin-top: -160px;
  margin-left: -6px;
  font-style: normal;
  font-weight: 700;
}

.gdlr-core-testimonial-style-left-bg .gdlr-core-testimonial-author-image {
  width: 85px;
  float: left;
  margin-right: 20px;
}

.gdlr-core-testimonial-style-left-bg .gdlr-core-testimonial-author-content {
  padding-top: 10px;
}

.gdlr-core-testimonial-style-left-2 .gdlr-core-testimonial-author-image {
  width: 85px;
  float: left;
  margin-right: 36px;
  position: relative;
}

.gdlr-core-testimonial-style-left-2 .gdlr-core-testimonial-quote {
  font-size: 100px;
  line-height: 1;
  position: absolute;
  top: -5px;
  right: -18px;
}

.gdlr-core-testimonial-style-left-2 .gdlr-core-testimonial-content-wrap {
  overflow: hidden;
}

.gdlr-core-testimonial-style-left-2 .gdlr-core-testimonial-content {
  font-style: normal;
}

.gdlr-core-testimonial-style-left-2 .gdlr-core-testimonial-title {
  font-size: 18px;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 0;
}

.gdlr-core-testimonial-style-left-2 .gdlr-core-testimonial-position {
  font-size: 16px;
  font-style: normal;
}

.gdlr-core-testimonial-style-left-2 .gdlr-core-testimonial-content {
  font-style: normal;
  margin-bottom: 0;
}

.gdlr-core-testimonial-style-left-3 .gdlr-core-testimonial-author-image {
  float: left;
  margin-right: 30px;
}

.gdlr-core-testimonial-style-left-3 .gdlr-core-testimonial-content-wrap {
  overflow: hidden;
}

.gdlr-core-testimonial-style-left-3 .gdlr-core-testimonial-content-head-wrap {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.gdlr-core-testimonial-style-left-3 .gdlr-core-rating {
  font-size: 16px;
  letter-spacing: 2px;
}

.gdlr-core-testimonial-style-left-3
  .gdlr-core-flexslider[data-type="carousel"]
  ul.slides
  > li.flex-with-active-class {
  opacity: 0;
  transition: opacity 300ms, transform 600ms;
  -moz-transition: opacity 300ms, transform 600ms;
  -webkit-transition: opacity 300ms, transform 600ms;
  -o-transition: opacity 300ms, transform 600ms;
}

.gdlr-core-testimonial-style-left-3
  .gdlr-core-flexslider[data-type="carousel"]
  ul.slides
  > li.flex-with-active-class.flex-active-slide {
  opacity: 1;
  transition: opacity 300ms, transform 600ms;
  -moz-transition: opacity 300ms, transform 600ms;
  -webkit-transition: opacity 300ms, transform 600ms;
  -o-transition: opacity 300ms, transform 600ms;
}

.gdlr-core-testimonial-style-left-3
  .gdlr-core-flexslider.gdlr-core-overflow-visible[data-type="carousel"]
  ul.slides
  > li.flex-with-active-class {
  opacity: 0.5;
  transform: scale(0.85);
}

.gdlr-core-testimonial-style-left-3
  .gdlr-core-flexslider.gdlr-core-overflow-visible[data-type="carousel"]
  ul.slides
  > li.flex-active-slide {
  opacity: 1;
  transform: scale(1);
}

.gdlr-core-testimonial-style-right {
  text-align: right;
}

.gdlr-core-testimonial-style-right .gdlr-core-testimonial-quote {
  font-size: 60px;
  line-height: 1;
  float: right;
  margin-left: 30px;
}

.gdlr-core-testimonial-style-right .gdlr-core-testimonial-content-wrap {
  overflow: hidden;
}

.gdlr-core-testimonial-style-right .gdlr-core-testimonial-author-image {
  float: right;
  margin-left: 24px;
}

.gdlr-core-testimonial-style-center {
  text-align: center;
}

.gdlr-core-testimonial-style-center .gdlr-core-testimonial-quote {
  font-size: 80px;
  line-height: 60px;
  padding: 15px 0 10px;
}

.gdlr-core-testimonial-style-center .gdlr-core-testimonial-quote:first-child {
  padding-top: 0;
}

.gdlr-core-testimonial-style-center .gdlr-core-testimonial-author-wrap {
  display: inline-block;
  text-align: left;
}

.gdlr-core-testimonial-style-center .gdlr-core-testimonial-author-image {
  float: left;
  margin-right: 20px;
}

.gdlr-core-testimonial-style-center-2 {
  text-align: center;
}

.gdlr-core-testimonial-style-center-2 .gdlr-core-testimonial-author-image {
  margin: 18px auto 0;
  width: 76px;
}

.gdlr-core-testimonial-style-center-2 .gdlr-core-testimonial-title {
  font-weight: 500;
  margin-bottom: 3px;
}

.gdlr-core-testimonial-style-center-2 .gdlr-core-testimonial-position {
  font-style: normal;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
}

.gdlr-core-testimonial-style-center-2.gdlr-core-layout-reverse
  .gdlr-core-testimonial-author-image {
  margin: 0 auto 20px;
}

.gdlr-core-testimonial-style-center-2.gdlr-core-layout-reverse
  .gdlr-core-testimonial-content {
  margin-bottom: 0;
}

.gdlr-core-testimonial-style-center-2.gdlr-core-layout-reverse
  .gdlr-core-testimonial-content-wrap,
.gdlr-core-testimonial-style-center-2.gdlr-core-layout-reverse
  .gdlr-core-testimonial-author-wrap {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 20px;
}

.gdlr-core-testimonial-style-center-3 {
  text-align: center;
}

.gdlr-core-testimonial-style-center-3 .gdlr-core-testimonial-author-image {
  margin: 0 auto 10px;
}

.gdlr-core-testimonial-style-center-3 .gdlr-core-rating {
  display: block;
  margin-bottom: 18px;
}

.gdlr-core-testimonial-style-center-3 .gdlr-core-testimonial-content {
  margin-bottom: 30px;
}

.gdlr-core-testimonial-style-center-4 {
  text-align: center;
}

.gdlr-core-testimonial-style-center-4 .gdlr-core-testimonial-author-image {
  margin: 0 auto 20px;
}

.gdlr-core-testimonial-style-center-4 .gdlr-core-testimonial-head {
  margin-bottom: 20px;
}

.gdlr-core-testimonial-style-center-4 .gdlr-core-rating i {
  margin: 0 3px;
}

.gdlr-core-testimonial-style-center-4 .gdlr-core-testimonial-quote {
  position: absolute;
  right: 20px;
  top: 0;
  font-size: 160px;
  height: 100px;
  line-height: 1;
}

.gdlr-core-testimonial-style-chat .gdlr-core-testimonial-author-image {
  width: 78px;
  margin: 0 auto;
  margin-top: 14px;
}

.gdlr-core-testimonial-style-chat .gdlr-core-testimonial {
  text-align: center;
  transition: transform 150ms ease-out;
  -moz-transition: transform 150ms ease-out;
  -o-transition: transform 150ms ease-out;
  -webkit-transition: transform 150ms ease-out;
}

.gdlr-core-testimonial-style-chat .gdlr-core-rating {
  font-size: 18px;
  display: block;
  margin-bottom: 3px;
}

.gdlr-core-testimonial-style-chat .gdlr-core-rating i {
  margin-left: 10px;
}

.gdlr-core-testimonial-style-chat .gdlr-core-rating i:first-child {
  margin-left: 0;
}

.gdlr-core-testimonial-style-chat .gdlr-core-testimonial-content {
  padding: 33px 35px;
  background: #fff;
  border-radius: 5px;
  position: relative;
  transition: box-shadow 150ms ease-out;
  -moz-transition: -moz-box-shadow 150ms ease-out;
  -o-transition: -o-box-shadow 150ms ease-out;
  -webkit-transition: -webkit-box-shadow 150ms ease-out;
}

.gdlr-core-testimonial-style-chat .gdlr-core-testimonial-content-chat {
  position: absolute;
  bottom: -8px;
  width: 0;
  height: 0;
  left: 50%;
  margin-left: -4px;
  border-style: solid;
  border-top-color: #fff;
  border-width: 8px 8px 0px !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}

.gdlr-core-testimonial-style-chat .gdlr-core-testimonial:hover {
  transform: translate3d(0, -15px, 0);
  -webkit-transform: translate3d(0, -15px, 0);
}

.gdlr-core-testimonial-style-chat
  .gdlr-core-testimonial:hover
  .gdlr-core-testimonial-content {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.gdlr-core-testimonial-style-block .gdlr-core-testimonial-frame {
  text-align: center;
  padding: 40px 20px 20px;
  margin-top: 50px;
}

.gdlr-core-testimonial-style-block .gdlr-core-testimonial-frame-border {
  border-width: 1px;
  border-style: solid;
}

.gdlr-core-testimonial-style-block .gdlr-core-testimonial-author-image {
  width: 100px;
  margin: -90px auto 30px;
}

.gdlr-core-testimonial-style-block .gdlr-core-testimonial-author-wrap {
  margin-bottom: 20px;
}

.gdlr-core-testimonial-item
  .gdlr-core-flexslider-nav.gdlr-core-center-align
  .flex-direction-nav {
  margin-top: 40px;
  margin-bottom: 0;
}

.gdlr-core-testimonial .gdlr-core-testimonial-quote,
.gdlr-core-testimonial .gdlr-core-testimonial-content,
.gdlr-core-testimonial .gdlr-core-testimonial-title,
.gdlr-core-testimonial .gdlr-core-testimonial-position,
.gdlr-core-testimonial .gdlr-core-testimonial-position .gdlr-core-rating i {
  transition: color 300ms;
  -webkit-transition: color 300ms;
  -moz-transition: color 300ms;
}

.gdlr-core-testimonial .gdlr-core-testimonial-frame {
  transition: background 300ms;
  -webkit-transition: background 300ms;
  -moz-transition: background 300ms;
}

.gdlr-core-testimonial
  .gdlr-core-testimonial-frame.gdlr-core-move-up-with-shadow {
  transition: transform 150ms ease-out, box-shadow 150ms ease-out,
    border-color 300ms ease-out, border-width 300ms, background 300ms;
  -moz-transition: transform 150ms ease-out, -moz-box-shadow 150ms ease-out,
    border-color 300ms ease-out, border-width 300ms, background 300ms;
  -o-transition: transform 150ms ease-out, -o-box-shadow 150ms ease-out,
    border-color 300ms ease-out, border-width 300ms, background 300ms;
  -webkit-transition: transform 150ms ease-out,
    -webkit-box-shadow 150ms ease-out, border-color 300ms ease-out,
    border-width 300ms, background 300ms;
}

.gdlr-core-testimonial-style-image-left .gdlr-core-testimonial-author-image {
  width: 39%;
  float: left;
  margin-right: 60px;
}

.gdlr-core-testimonial-style-image-left
  .gdlr-core-testimonial-author-image
  img {
  border-radius: 0;
}

.gdlr-core-testimonial-style-image-left .gdlr-core-testimonial-content {
  position: relative;
}

.gdlr-core-testimonial-style-image-left .gdlr-core-testimonial-content-wrap {
  overflow: hidden;
  position: relative;
  padding: 48px 48px 0;
}

.gdlr-core-testimonial-style-image-left .gdlr-core-testimonial-quote {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 160px;
  height: 0;
  line-height: 1;
  font-weight: 700;
}

.gdlr-core-flexslider-left-content-nav {
  margin-top: 40px;
}

.gdlr-core-flexslider-left-content-nav > i {
  font-size: 15px;
  display: inline-block;
  width: 30px;
  text-align: center;
  line-height: 28px;
  border-width: 1px;
  border-style: solid;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-class-list.gdlr-core-style-grid {
  margin-bottom: 40px;
}

.gdlr-core-class-list.gdlr-core-style-grid .gdlr-core-thumbnail {
  margin-bottom: 35px;
}

.gdlr-core-class-list.gdlr-core-style-grid .gdlr-core-title-divider {
  border-bottom-width: 5px;
  border-bottom-style: solid;
  width: 30px;
  margin-top: 10px;
}

.gdlr-core-class-list.gdlr-core-style-grid .gdlr-core-excerpt {
  margin-top: 20px;
}

.gdlr-core-class-list.gdlr-core-style-grid.gdlr-core-center-align
  .gdlr-core-title-divider {
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-class-list.gdlr-core-style-grid.gdlr-core-right-align
  .gdlr-core-title-divider {
  margin-left: auto;
}

.gdlr-core-class-list.gdlr-core-style-modern {
  position: relative;
}

.gdlr-core-class-list.gdlr-core-style-modern .gdlr-core-title {
  margin-bottom: 0;
}

.gdlr-core-class-list.gdlr-core-style-modern .gdlr-core-title a {
  color: #fff;
}

.gdlr-core-class-list.gdlr-core-style-modern .gdlr-core-excerpt {
  margin-top: 20px;
  color: #fff;
}

.gdlr-core-class-list.gdlr-core-style-modern
  .gdlr-core-thumbnail-overlay-content {
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.gdlr-core-class-list.gdlr-core-style-modern .gdlr-core-thumbnail-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
}

.gdlr-core-class-list.gdlr-core-style-modern:hover
  .gdlr-core-thumbnail-overlay {
  opacity: 0.8;
}

.gdlr-core-page-list.gdlr-core-style-grid {
  margin-bottom: 40px;
}

.gdlr-core-page-list.gdlr-core-style-grid .gdlr-core-thumbnail {
  margin-bottom: 35px;
}

.gdlr-core-page-list.gdlr-core-style-grid .gdlr-core-title-divider {
  border-bottom-width: 5px;
  border-bottom-style: solid;
  width: 30px;
  margin-top: 10px;
}

.gdlr-core-flexslider .gdlr-core-page-list.gdlr-core-style-grid {
  width: calc(100% + 1px);
}

.gdlr-core-page-list.gdlr-core-style-modern {
  position: relative;
}

.gdlr-core-page-list.gdlr-core-style-modern .gdlr-core-title {
  margin-bottom: 0;
}

.gdlr-core-page-list.gdlr-core-style-modern .gdlr-core-title a {
  color: #fff;
}

.gdlr-core-page-list.gdlr-core-style-modern
  .gdlr-core-thumbnail-overlay-content {
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  text-align: center;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.gdlr-core-page-list.gdlr-core-style-modern .gdlr-core-thumbnail-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
}

.gdlr-core-page-list.gdlr-core-style-modern:hover .gdlr-core-thumbnail-overlay {
  opacity: 0.8;
}

.gdlr-core-featured-gallery-images {
  display: flex;
  gap: 10px;
}

.gdlr-core-featured-gallery-images .gdlr-core-media-image {
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.gdlr-core-featured-gallery-images .gdlr-core-bg-image {
  position: relative;
}

.gdlr-core-featured-gallery-images .gdlr-core-bg-image > a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gdlr-core-featured-gallery-images .gdlr-core-size-3 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gdlr-core-featured-gallery-images .gdlr-core-size-3 > div {
  width: 100%;
  height: calc(50% - 5px);
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.gdlr-core-featured-gallery-images .gdlr-core-size-4 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gdlr-core-featured-gallery-images .gdlr-core-size-4 > div {
  width: 100%;
  height: calc(50% - 5px);
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.gdlr-core-featured-gallery-images .gdlr-core-size-5 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-right: -10px;
}

.gdlr-core-featured-gallery-images .gdlr-core-size-5 > div {
  width: calc(50% - 10px);
  height: calc(50% - 5px);
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.gdlr-core-featured-gallery {
  position: relative;
}

.gdlr-core-featured-gallery-button-wrap {
  bottom: 20px;
  left: 20px;
  position: absolute;
  pointer-events: none;
}

.gdlr-core-featured-gallery .gdlr-core-featured-gallery-button,
.gdlr-core-featured-gallery .gdlr-core-featured-gallery-button:hover {
  background: #fff;
  color: #2a2a2a;
}

.gdlr-core-featured-gallery-button {
  padding: 10px 15px 8px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-featured-gallery-button.gdlr-core-video {
  pointer-events: auto;
}

.gdlr-core-featured-gallery-button svg {
  margin-right: 8px;
  vertical-align: middle;
  margin-top: -1px;
}

.gdlr-core-featured-gallery-button.gdlr-core-no-event {
  pointer-events: none;
}

.gdlr-core-gallery-item .gdlr-core-gallery-caption {
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.5px;
  text-align: center;
  padding-top: 17px;
  padding-bottom: 10px;
}

.gdlr-core-gallery-item .gdlr-core-gallery-list {
  text-align: center;
  overflow: hidden;
}

.gdlr-core-gallery-item-style-grid-no-space .gdlr-core-gallery-caption {
  margin-bottom: 15px;
}

.gdlr-core-gallery-item-style-grid-no-space .gdlr-core-pagination {
  margin-top: 40px;
}

.gdlr-core-gallery-item .gdlr-core-flexslider-nav {
  margin-bottom: 45px;
}

.gdlr-core-gallery-item .gdlr-core-flexslider-nav ul {
  margin-bottom: 0;
}

.gdlr-core-image-overlay.gdlr-core-gallery-image-overlay
  .gdlr-core-image-overlay-content {
  background: transparent;
  top: auto;
  bottom: 25px;
}

.gdlr-core-image-overlay.gdlr-core-gallery-image-overlay
  .gdlr-core-image-overlay-title {
  font-size: 15px;
  display: block;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.gdlr-core-image-overlay.gdlr-core-gallery-image-overlay
  .gdlr-core-image-overlay-caption {
  font-size: 15px;
  display: block;
  margin-top: 4px;
  font-style: italic;
}

.gdlr-core-gallery-shortcode-grid-fixed
  .gdlr-core-gallery-shortcode-item.gdlr-core-first {
  width: 33.33%;
  float: left;
  clear: left;
}

.gdlr-core-gallery-shortcode-grid-fixed
  .gdlr-core-gallery-shortcode-item-right {
  overflow: hidden;
}

.gdlr-core-gallery-shortcode-grid-fixed
  .gdlr-core-gallery-shortcode-item.gdlr-core-second,
.gdlr-core-gallery-shortcode-grid-fixed
  .gdlr-core-gallery-shortcode-item.gdlr-core-third {
  float: left;
  width: 50%;
}

.gdlr-core-gallery-shortcode-grid-fixed
  .gdlr-core-gallery-shortcode-item.gdlr-core-fourth {
  float: left;
}

.gdlr-core-timeline-item .gdlr-core-timeline-item-date {
  width: 300px;
}

.gdlr-core-timeline-item .gdlr-core-timeline-item-list {
  position: relative;
  padding-bottom: 38px;
}

.gdlr-core-timeline-item .gdlr-core-timeline-item-list:last-child {
  padding-bottom: 0;
}

.gdlr-core-timeline-item .gdlr-core-timeline-item-date {
  font-size: 20px;
  font-weight: 800;
  float: left;
  text-align: right;
  padding-right: 90px;
  line-height: 34px;
}

.gdlr-core-timeline-item .gdlr-core-timeline-item-bullet {
  display: inline-block;
  width: 11px;
  height: 11px;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 1px;
  margin-left: 28px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-timeline-item .gdlr-core-timeline-item-divider {
  position: absolute;
  top: 23px;
  bottom: -11px;
  margin-left: 4px;
  border-left-width: 1px;
  border-left-style: solid;
}

.gdlr-core-timeline-item
  .gdlr-core-timeline-item-list:last-child
  .gdlr-core-timeline-item-divider {
  display: none;
}

.gdlr-core-timeline-item .gdlr-core-timeline-item-content-wrap {
  overflow: hidden;
}

.gdlr-core-timeline-item .gdlr-core-timeline-item-title {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 1px;
}

.gdlr-core-timeline-item .gdlr-core-timeline-item-caption {
  font-size: 17px;
  font-weight: 600;
}

.gdlr-core-timeline-item .gdlr-core-timeline-item-content {
  font-size: 21px;
  margin-top: 14px;
  font-weight: 600;
}

.gdlr-core-code-item .gdlr-core-code-item-content {
  margin-bottom: 0;
  padding: 25px 30px 5px;
  margin-top: 0;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-code-item-light .gdlr-core-code-item-content {
  background: #f5f5f5;
  color: #a9a9a9;
  border: 1px solid #e4e4e4;
}

.gdlr-core-code-item-dark .gdlr-core-code-item-content {
  background: #383838;
  color: #a2a2a2;
}

.gdlr-core-call-to-action-item .gdlr-core-call-to-action-item-title {
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.gdlr-core-call-to-action-item .gdlr-core-call-to-action-item-inner {
  position: relative;
}

.gdlr-core-call-to-action-item .gdlr-core-call-to-action-item-caption {
  font-size: 19px;
  letter-spacing: 0;
  margin-top: 16px;
}

.gdlr-core-call-to-action-item .gdlr-core-call-to-action-item-button a {
  margin-right: 0;
}

.gdlr-core-call-to-action-item.gdlr-core-style-left-align {
  text-align: left;
}

.gdlr-core-call-to-action-item.gdlr-core-style-left-align
  .gdlr-core-call-to-action-item-button {
  margin-top: 42px;
}

.gdlr-core-call-to-action-item.gdlr-core-style-center-align {
  text-align: center;
}

.gdlr-core-call-to-action-item.gdlr-core-style-center-align
  .gdlr-core-call-to-action-item-button {
  margin-top: 40px;
}

.gdlr-core-call-to-action-item.gdlr-core-style-right-align {
  text-align: right;
}

.gdlr-core-call-to-action-item.gdlr-core-style-right-align
  .gdlr-core-call-to-action-item-button {
  margin-top: 42px;
}

.gdlr-core-call-to-action-item.gdlr-core-style-left-align-right-button {
  text-align: left;
}

.gdlr-core-call-to-action-item.gdlr-core-style-left-align-right-button
  .gdlr-core-call-to-action-item-inner {
  padding-right: 160px;
}

.gdlr-core-call-to-action-item.gdlr-core-style-left-align-right-button
  .gdlr-core-call-to-action-item-button {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -26px;
}

.gdlr-core-call-to-action-item.gdlr-core-style-right-align-left-button {
  text-align: right;
}

.gdlr-core-call-to-action-item.gdlr-core-style-right-align-left-button
  .gdlr-core-call-to-action-item-inner {
  padding-left: 160px;
}

.gdlr-core-call-to-action-item.gdlr-core-style-right-align-left-button
  .gdlr-core-call-to-action-item-button {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -26px;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-day i {
  margin-right: 12px;
  vertical-align: middle;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-time {
  float: right;
  font-weight: 700;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-list-item {
  border-bottom-width: 1px;
  padding-bottom: 9px;
  margin-bottom: 10px;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-time i {
  margin-left: 12px;
  vertical-align: middle;
}

.gdlr-core-opening-hour-item.gdlr-core-divider-style-solid
  .gdlr-core-opening-hour-list-item {
  border-bottom-style: solid;
}

.gdlr-core-opening-hour-item.gdlr-core-divider-style-dashed
  .gdlr-core-opening-hour-list-item {
  border-bottom-style: dashed;
}

.gdlr-core-opening-hour-item.gdlr-core-divider-style-dotted
  .gdlr-core-opening-hour-list-item {
  border-bottom-style: dotted;
}

.gdlr-core-breadcrumbs-item {
  font-size: 14px;
  padding: 22px 0;
}

.gdlr-core-breadcrumbs-item > span {
  margin: 0 22px;
}

.gdlr-core-breadcrumbs-item > span:first-child {
  margin-left: 0;
}

.gdlr-core-breadcrumbs-item a span {
  transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
  -webkit-transition: color 200ms;
}

.gdlr-core-divider-item {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.gdlr-core-divider-item .gdlr-core-divider-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-divider-item.gdlr-core-left-align .gdlr-core-divider-container {
  margin-left: 0;
}

.gdlr-core-divider-item.gdlr-core-right-align .gdlr-core-divider-container {
  margin-right: 0;
}

.gdlr-core-divider-item-dot .gdlr-core-divider-container {
  height: 6px;
  background: #ddd;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-divider-item-center-circle .gdlr-core-divider-line {
  position: relative;
}

.gdlr-core-divider-item-center-circle .gdlr-core-divider-line-bold {
  width: 26px;
  height: 26px;
  margin: 0 auto;
  border-width: 1px;
  border-style: solid;
  border-radius: 13px;
  -moz-border-radius: 13px;
  -webkit-border-radius: 13px;
}

.gdlr-core-divider-item-center-circle .gdlr-core-divider-line-bold:before {
  content: " ";
  display: block;
  width: 14px;
  height: 14px;
  margin: 5px;
  border-width: 1px;
  border-style: solid;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
}

.gdlr-core-divider-item-center-circle .gdlr-core-divider-line:before {
  content: " ";
  display: block;
  position: absolute;
  top: 13px;
  left: 0;
  right: 50%;
  margin-right: 13px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-divider-item-center-circle .gdlr-core-divider-line:after {
  content: " ";
  display: block;
  position: absolute;
  top: 13px;
  right: 0;
  left: 50%;
  margin-left: 13px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-divider-item-normal .gdlr-core-divider-line {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #d32525;
  border-width: 2px;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.gdlr-core-divider-item-normal.gdlr-core-style-vertical
  .gdlr-core-divider-line {
  border-bottom: 0;
  width: 0;
  height: 70px;
  border-left-width: 3px;
  border-left-style: solid;
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-divider-item-normal.gdlr-core-style-vertical.gdlr-core-left-align
  .gdlr-core-divider-line {
  margin-left: 0.6em;
}

.gdlr-core-divider-item-normal.gdlr-core-style-vertical.gdlr-core-right-align
  .gdlr-core-divider-line {
  margin-right: 0.6em;
}

.gdlr-core-divider-item-normal.gdlr-core-style-vertical
  .gdlr-core-divider-line-vertical-text {
  writing-mode: tb-rl;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 12px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.gdlr-core-divider-item-normal.gdlr-core-style-vertical
  .gdlr-core-divider-line-vertical-icon {
  margin-bottom: 8px;
}

.gdlr-core-divider-item-normal.gdlr-core-style-vertical.gdlr-core-left-align
  .gdlr-core-divider-line-vertical-text {
  margin-left: 0;
}

.gdlr-core-divider-item-normal.gdlr-core-style-vertical.gdlr-core-right-align
  .gdlr-core-divider-line-vertical-text {
  margin-right: 0;
}

.gdlr-core-divider-item-with-icon .gdlr-core-divider-item-with-icon-inner {
  position: relative;
  text-align: center;
  line-height: 1;
}

.gdlr-core-divider-item-with-icon .gdlr-core-divider-line {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-divider-item-with-icon .gdlr-core-divider-line.gdlr-core-left {
  position: absolute;
  top: 50%;
  left: 0;
}

.gdlr-core-divider-item-with-icon .gdlr-core-divider-line.gdlr-core-right {
  position: absolute;
  top: 50%;
  right: 0;
}

.gdlr-core-divider-item-with-icon i {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
}

.gdlr-core-divider-item-with-icon img {
  width: auto;
  vertical-align: middle;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}

.gdlr-core-divider-item-small-center .gdlr-core-divider-line {
  width: 100px;
  margin: 0 auto;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-divider-item-small-center .gdlr-core-divider-line-bold {
  content: " ";
  display: block;
  width: 40px;
  margin: 0 auto -2px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.gdlr-core-divider-item-small-left .gdlr-core-divider-line {
  width: 80px;
  margin: 0 auto 0 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-divider-item-small-left .gdlr-core-divider-line-bold {
  content: " ";
  display: block;
  width: 40px;
  margin: 0 auto -2px 0;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.gdlr-core-divider-item-small-right .gdlr-core-divider-line {
  width: 80px;
  margin: 0 0 0 auto;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-divider-item-small-right .gdlr-core-divider-line-bold {
  content: " ";
  display: block;
  width: 40px;
  margin: 0 0 -2px auto;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.gdlr-core-item-list.gdlr-core-animate-init {
  opacity: 0;
  filter: alpha(opacity=0);
}

.gdlr-core-item-list.gdlr-core-animate-init.gdlr-core-animate {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.gdlr-core-item-list.gdlr-core-animate-end {
  opacity: 1;
  filter: alpha(opacity=100);
}

[data-layout="masonry"] .gdlr-core-item-list {
  opacity: 0;
  filter: alpha(opacity=0);
}

[data-layout="masonry"] .gdlr-core-item-list.gdlr-core-animate-end {
  opacity: 1;
  filter: alpha(opacity=100);
}

[data-layout="masonry"] .gdlr-core-item-list.gdlr-core-animate {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.gdlr-core-opacity-on-hover img,
.gdlr-core-zoom-on-hover img,
.gdlr-core-zoom-rotate-on-hover img,
.gdlr-core-grayscale-effect img,
.gdlr-core-grayscale-effect-revert img {
  transition: opacity 300ms ease-out, transform 300ms ease-out,
    filter 300ms ease-out;
  -moz-transition: opacity 300ms ease-out, -moz-transform 300ms ease-out,
    -moz-filter 300ms ease-out;
  -o-transition: opacity 300ms ease-out, -o-transform 300ms ease-out,
    -o-filter 300ms ease-out;
  -webkit-transition: opacity 300ms ease-out, -webkit-transform 300ms ease-out,
    -webkit-filter 300ms ease-out;
}

.gdlr-core-move-up-with-shadow,
.gdlr-core-blog-grid.gdlr-core-blog-grid-with-frame.gdlr-core-move-up-with-shadow {
  transition: transform 150ms ease-out, box-shadow 150ms ease-out,
    border-color 300ms ease-out, border-width 300ms;
  -moz-transition: transform 150ms ease-out, -moz-box-shadow 150ms ease-out,
    border-color 300ms ease-out, border-width 300ms;
  -o-transition: transform 150ms ease-out, -o-box-shadow 150ms ease-out,
    border-color 300ms ease-out, border-width 300ms;
  -webkit-transition: transform 150ms ease-out,
    -webkit-box-shadow 150ms ease-out, border-color 300ms ease-out,
    border-width 300ms;
}

.gdlr-core-move-up-with-shadow:hover {
  transform: translate3d(0, -15px, 0);
  -webkit-transform: translate3d(0, -15px, 0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.gdlr-core-opacity-on-hover {
  background: #000;
}

.gdlr-core-opacity-on-hover:hover img {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.gdlr-core-zoom-on-hover {
  overflow: hidden;
}

.gdlr-core-zoom-on-hover:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.gdlr-core-zoom-rotate-on-hover {
  overflow: hidden;
}

.gdlr-core-zoom-rotate-on-hover:hover img {
  -webkit-transform: scale(1.5) rotate(30deg);
  transform: scale(1.5) rotate(30deg);
}

.gdlr-core-grayscale-effect img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.gdlr-core-grayscale-effect:hover img {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
}

.gdlr-core-grayscale-effect-revert img {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
}

.gdlr-core-grayscale-effect-revert:hover img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.gdlr-core-hover-opacity {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.gdlr-core-hover-element:hover .gdlr-core-hover-opacity {
  opacity: 1;
}

.gdlr-core-blog-metro.gdlr-core-no-image {
  background: transparent;
}

.gdlr-core-blog-modern.gdlr-core-no-image {
  background: transparent;
}

.gdlr-core-blog-modern.gdlr-core-no-image
  .gdlr-core-blog-modern-content-overlay {
  display: none;
}

[data-gdlr-animation] {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

[data-gdlr-animation="fadeIn"],
[data-gdlr-animation="fadeInDown"],
[data-gdlr-animation="fadeInUp"],
[data-gdlr-animation="fadeInLeft"],
[data-gdlr-animation="fadeInRight"] {
  opacity: 0;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

.gdlr-core-preview-text {
  text-align: center;
  padding: 10px;
  background: #f3f3f3;
}

.gdlr-core-shape-divider-wrap {
  overflow: hidden;
  line-height: 0;
}

.gdlr-core-shape-divider-wrap svg {
  left: 50%;
  position: relative;
  width: 100%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.gdlr-core-shape-divider-wrap.gdlr-core-flip svg {
  -webkit-transform: translateX(-50%) rotateY(180deg);
  -ms-transform: translateX(-50%) rotateY(180deg);
  transform: translateX(-50%) rotateY(180deg);
}

.gdlr-core-shape-divider-wrap.gdlr-core-pos-top {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
}

.gdlr-core-shape-divider-wrap.gdlr-core-pos-top.gdlr-core-inverted {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.gdlr-core-shape-divider-wrap.gdlr-core-pos-bottom {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.gdlr-core-shape-divider-wrap.gdlr-core-pos-bottom.gdlr-core-inverted {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.gdlr-core-shape-divider-wrap.gdlr-core-preview {
  position: static;
}

.gdlr-core-shape-divider-wrap img {
  width: 100%;
}

.gdlr-core-shape-divider-wrap.gdlr-core-pos-top.gdlr-core-custom-image-with-height {
  background-position: top;
  background-size: cover;
}

.gdlr-core-shape-divider-wrap.gdlr-core-pos-bottom.gdlr-core-custom-image-with-height {
  background-position: bottom;
  background-size: cover;
}

.gdlr-core-sly-slider {
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.gdlr-core-sly-slider .slides {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 99999px;
}

.gdlr-core-sly-slider .slides li {
  float: left;
}

.gdlr-core-sly-slider .slides li:first-child {
  margin-left: 0px !important;
}

.gdlr-core-sly-slider .slides li:last-child {
  margin-right: 0px !important;
}

.gdlr-core-sly-slider .slides li .gdlr-core-media-image img {
  max-height: 100%;
  width: auto;
}

.gdlr-core-sly-slider .slides:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.gdlr-core-sly-scroll {
  background: #eee;
}

.gdlr-core-sly-scroll .gdlr-core-sly-scroll-handle {
  background: #717171;
}

.gdlr-core-sly-scroll {
  height: 8px;
  margin-top: 30px;
}

.gdlr-core-sly-scroll .gdlr-core-sly-scroll-handle {
  height: 8px;
  width: 0;
}

ul.gdlr-core-sly-nav li {
  background: #dfdfdf;
}

ul.gdlr-core-sly-nav li.active {
  background: #000;
}

ul.gdlr-core-sly-nav {
  text-align: center;
  margin-top: 30px;
  font-size: 0;
  margin-bottom: 0;
  margin-left: 0;
  list-style: none;
}

ul.gdlr-core-sly-nav li {
  display: inline-block;
  color: transparent;
  margin: 0 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transition: transform 300ms;
  -webkit-transition: transform 300ms;
  -moz-transition: transform 300ms;
}

ul.gdlr-core-sly-nav li.active {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
}

.gdlr-core-sly-slider.gdlr-core-bullet-style-cylinder + .gdlr-core-sly-nav li {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.gdlr-core-sly-slider.gdlr-core-bullet-style-cylinder + .gdlr-core-sly-nav li {
  width: 12px;
  height: 12px;
  transition: width 300ms;
  -webkit-transition: width 300ms;
  -moz-transition: width 300ms;
}

.gdlr-core-sly-slider.gdlr-core-bullet-style-cylinder
  + .gdlr-core-sly-nav
  li:hover,
.gdlr-core-sly-slider.gdlr-core-bullet-style-cylinder
  + .gdlr-core-sly-nav
  li.active {
  width: 32px;
}

.gdlr-core-gallery-with-thumbnail-wrap .gdlr-core-sly-slider {
  max-width: 80%;
  margin: 30px auto 0;
}

.gdlr-core-gallery-with-thumbnail-wrap .gdlr-core-sly-slider .slides {
  margin: 0 auto 0;
}

.gdlr-core-gallery-with-thumbnail-wrap .gdlr-core-sly-slider .slides li {
  margin: 0 8px;
  cursor: pointer;
}

.gdlr-core-gallery-with-thumbnail-wrap
  .gdlr-core-sly-slider
  .gdlr-core-media-image {
  height: 65px;
}

.gdlr-core-gallery-with-thumbnail-wrap .gdlr-core-image-overlay {
  /* background: url(../../../../../../../../../../plugins/goodlayers-core/include/images/gallery/gallery-shadow.png)
    bottom center repeat-x; */
}

.gdlr-core-gallery-with-thumbnail-wrap.gdlr-core-inside-slider {
  position: relative;
}

.gdlr-core-gallery-with-thumbnail-wrap.gdlr-core-inside-slider
  .gdlr-core-sly-slider {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  bottom: 42px;
  left: 10%;
  right: 10%;
  z-index: 3;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.gdlr-core-gallery-with-thumbnail-wrap.gdlr-core-inside-slider:hover
  .gdlr-core-sly-slider,
.gdlr-core-gallery-with-thumbnail-wrap.gdlr-core-inside-slider:hover
  .gdlr-core-image-overlay {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gdlr-core-gallery-with-thumbnail-wrap.gdlr-core-inside-slider
  .gdlr-core-image-overlay-content {
  bottom: 145px;
}

.gdlr-core-gallery-with-thumbnail-wrap.gdlr-core-inside-slider
  .gdlr-core-image-overlay-caption {
  font-size: 14px;
  margin-top: 11px;
  letter-spacing: 1px;
}

.gdlr-core-gallery-with-thumbnail-wrap.gdlr-core-inside-slider
  .gdlr-core-image-overlay-title {
  font-size: 16px;
}

.gdlr-core-gallery-with-thumbnail-wrap.gdlr-core-inside-slider.gdlr-core-disable-hover
  .gdlr-core-sly-slider {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gdlr-core-flexslider.flexslider ul.slides {
  zoom: 1;
  margin-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.gdlr-core-flexslider.flexslider ul.slides:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.gdlr-core-flexslider.flexslider ul.slides > li {
  margin-bottom: 0;
  display: none;
  -webkit-backface-visibility: hidden;
}

.gdlr-core-flexslider.flexslider ul.slides > li .gdlr-core-item-list {
  margin-bottom: 0;
}

.gdlr-core-flexslider.flexslider[data-type="carousel"] ul.slides > li {
  margin-left: 0;
}

.gdlr-core-flexslider.flexslider[data-type="slider"] ul.slides > li {
  text-align: center;
}

.gdlr-core-flexslider {
  position: relative;
}

.gdlr-core-flexslider .flex-direction-nav {
  margin-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.gdlr-core-flexslider .flex-direction-nav li {
  position: absolute;
  line-height: 1;
  top: 50%;
  margin-top: -24px;
  z-index: 15;
}

.gdlr-core-flexslider .flex-direction-nav li a {
  display: block;
  padding: 14px;
  background: #000;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-flexslider .flex-direction-nav li a i {
  font-size: 18px;
  width: 18px;
  text-align: center;
  display: block;
}

.gdlr-core-flexslider .flex-direction-nav .flex-nav-prev {
  left: 35px;
}

.gdlr-core-flexslider .flex-direction-nav .flex-nav-next {
  right: 35px;
}

.gdlr-core-flexslider[data-nav="both"] .flex-direction-nav li {
  margin-top: -45px;
}

.gdlr-core-flexslider.gdlr-core-v1[data-nav="navigation-outer"] {
  position: relative;
}

.gdlr-core-flexslider.gdlr-core-v1[data-nav="navigation-outer"]
  .flex-direction-nav
  .flex-nav-prev {
  left: -85px;
  padding-right: 40px;
}

.gdlr-core-flexslider.gdlr-core-v1[data-nav="navigation-outer"]
  .flex-direction-nav
  .flex-nav-next {
  right: -85px;
  padding-left: 40px;
}

.gdlr-core-flexslider.gdlr-core-v1[data-nav="navigation-outer"]
  .flex-direction-nav
  .flex-nav-prev
  a,
.gdlr-core-flexslider.gdlr-core-v1[data-nav="navigation-outer"]
  .flex-direction-nav
  .flex-nav-next
  a {
  opacity: 0;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.gdlr-core-flexslider.gdlr-core-v1[data-nav="navigation-outer"]:hover
  .flex-direction-nav
  .flex-nav-prev
  a,
.gdlr-core-flexslider.gdlr-core-v1[data-nav="navigation-outer"]:hover
  .flex-direction-nav
  .flex-nav-next
  a {
  opacity: 1;
}

.gdlr-core-flexslider[data-nav="navigation-outer-plain-round"]
  .flex-direction-nav
  li
  a {
  display: block;
  border-width: 2px;
  border-style: solid;
  background: transparent;
  padding: 0;
  width: 47px;
  height: 47px;
  text-align: center;
}

.gdlr-core-flexslider[data-nav="navigation-outer-plain-round"]
  .flex-direction-nav
  li
  a
  i {
  font-size: 47px;
  width: auto;
  line-height: 42px;
}

.gdlr-core-flexslider[data-nav="navigation-outer-plain-round"]
  .flex-direction-nav
  .flex-nav-prev {
  left: -85px;
  padding-right: 40px;
}

.gdlr-core-flexslider[data-nav="navigation-outer-plain-round"]
  .flex-direction-nav
  .flex-nav-next {
  right: -85px;
  padding-left: 40px;
}

.gdlr-core-flexslider[data-nav="navigation-outer-plain-round"]
  .flex-direction-nav
  .flex-nav-prev
  a,
.gdlr-core-flexslider[data-nav="navigation-outer-plain-round"]
  .flex-direction-nav
  .flex-nav-next
  a {
  opacity: 0;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.gdlr-core-flexslider[data-nav="navigation-outer-plain-round"]:hover
  .flex-direction-nav
  .flex-nav-prev
  a,
.gdlr-core-flexslider[data-nav="navigation-outer-plain-round"]:hover
  .flex-direction-nav
  .flex-nav-next
  a {
  opacity: 1;
}

.gdlr-core-flexslider[data-nav="navigation-outer-plain-round"]
  .flex-direction-nav
  li
  a.flex-prev
  i {
  margin-left: -3px;
}

.gdlr-core-flexslider[data-nav="navigation-outer-plain-round"]
  .flex-direction-nav
  li
  a.flex-next
  i {
  margin-left: -1px;
}

.gdlr-core-flexslider[data-nav="navigation-bottom"] .flex-nav-prev,
.gdlr-core-flexslider[data-nav="navigation-bottom"] .flex-nav-next {
  position: static;
  display: inline-block;
  margin: 40px 4px 0;
}

.gdlr-core-flexslider[data-nav="navigation-bottom"] .flex-direction-nav {
  text-align: center;
}

.gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a {
  border-radius: 0;
}

.gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a i {
  font-size: 24px;
}

.gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-nav-prev a,
.gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-nav-next a {
  padding: 20px 25px 20px 22px;
}

.gdlr-core-flexslider.gdlr-core-nav-style-middle-large {
  position: static;
}

.gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li {
  margin-top: 0;
  top: auto;
}

.gdlr-core-flexslider.gdlr-core-nav-style-middle-large
  .flex-direction-nav
  li
  a {
  padding: 17px;
  border-style: solid;
  border-width: 2px;
  background: transparent;
}

.gdlr-core-flexslider.gdlr-core-nav-style-middle-large
  .flex-direction-nav
  li
  a
  i {
  font-size: 29px;
  width: 29px;
}

.gdlr-core-flexslider.gdlr-core-nav-style-middle-plain {
  position: static;
}

.gdlr-core-flexslider.gdlr-core-nav-style-middle-plain .flex-direction-nav li {
  margin-top: 0;
  top: auto;
}

.gdlr-core-flexslider.gdlr-core-nav-style-middle-plain
  .flex-direction-nav
  li
  a {
  padding: 14px;
  background: transparent;
}

.gdlr-core-flexslider.gdlr-core-nav-style-middle-plain
  .flex-direction-nav
  li
  a
  i {
  font-size: 35px;
  width: 35px;
}

.gdlr-core-flexslider.gdlr-core-nav-style-middle-plain
  .flex-direction-nav
  li
  a
  i.arrow_carrot-left:before {
  font-family: simple-line-icons;
  content: "\e605";
}

.gdlr-core-flexslider.gdlr-core-nav-style-middle-plain
  .flex-direction-nav
  li
  a
  i.arrow_carrot-right:before {
  font-family: simple-line-icons;
  content: "\e606";
}

.gdlr-core-flexslider .flex-control-nav {
  margin-bottom: 0;
  position: relative;
}

.gdlr-core-flexslider .flex-control-nav li {
  top: auto;
}

.gdlr-core-flexslider .flex-control-nav {
  list-style: none;
  line-height: 0;
  margin-top: 30px;
  text-align: center;
  margin-left: 0;
}

.gdlr-core-flexslider .flex-control-nav li {
  display: inline-block;
  margin: 0 5px;
}

.gdlr-core-flexslider .flex-control-nav li a {
  width: 12px;
  height: 12px;
  display: block;
  text-indent: -999px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round .flex-control-nav li a {
  border: none;
  width: 7px;
  height: 7px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round
  .flex-control-nav
  li
  a.flex-active {
  transform: scale(1.5);
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round2 .flex-control-nav li a {
  border-width: 3px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round2
  .flex-control-nav
  li
  a.flex-active {
  background: transparent !important;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round3 .flex-control-nav li a {
  border: none;
  width: 8px;
  height: 8px;
  margin-bottom: 2px;
  margin-top: 2px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round3
  .flex-control-nav
  li:nth-child(1)
  a,
.gdlr-core-flexslider.gdlr-core-bullet-style-round3
  .flex-control-nav
  li:nth-last-child(1)
  a {
  width: 4px;
  height: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round3
  .flex-control-nav
  li:nth-child(2)
  a,
.gdlr-core-flexslider.gdlr-core-bullet-style-round3
  .flex-control-nav
  li:nth-last-child(2)
  a {
  width: 6px;
  height: 6px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round3
  .flex-control-nav
  li
  a.flex-active {
  width: 8px;
  height: 8px;
  margin-bottom: 2px;
  margin-top: 2px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round4
  .flex-control-nav
  li
  a.flex-active {
  width: 7px;
  height: 7px;
  margin: 3px 6px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round4 .flex-control-nav li a {
  border-width: 2px;
  width: 13px;
  height: 13px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round5 .flex-control-nav li {
  margin: 0 4px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round5 .flex-control-nav li a {
  border-width: 2px;
  width: 15px;
  height: 15px;
  margin: 2px 0;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-round5
  .flex-control-nav
  li
  a.flex-active {
  width: 19px;
  height: 19px;
  margin: 0;
  background: transparent !important;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder .flex-control-nav {
  margin-top: 45px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder .flex-control-nav li a {
  width: 20px;
  height: 6px;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder-left .flex-control-nav {
  margin-top: 45px;
  text-align: left;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder-left
  .flex-control-nav
  li
  a {
  width: 24px;
  height: 6px;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder.gdlr-core-style-2
  .flex-control-nav
  li
  a {
  width: 12px;
  height: 12px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  transition: width 150ms;
  -moz-transition: width 150ms;
  -o-transition: width 150ms;
  -webkit-transition: width 150ms;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder.gdlr-core-style-2
  .flex-control-nav
  li
  a:hover,
.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder.gdlr-core-style-2
  .flex-control-nav
  li
  a.flex-active {
  width: 32px;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder.gdlr-core-style-3
  .flex-control-nav
  li
  a {
  height: 4px;
  transition: width 150ms;
  -moz-transition: width 150ms;
  -o-transition: width 150ms;
  -webkit-transition: width 150ms;
}

.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder.gdlr-core-style-3
  .flex-control-nav
  li
  a.flex-active {
  width: 30px;
}

.gdlr-core-blog-item .gdlr-core-flexslider .flex-control-nav {
  margin-top: 45px;
}

.gdlr-core-flexslider-nav .flex-direction-nav {
  margin-left: 0;
  list-style: none;
  zoom: 1;
}

.gdlr-core-flexslider-nav .flex-direction-nav:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.gdlr-core-flexslider-nav.gdlr-core-absolute {
  position: absolute;
  top: 0;
}

.gdlr-core-flexslider-nav.gdlr-core-absolute-center {
  position: absolute;
  top: 50%;
}

.gdlr-core-flexslider-nav.gdlr-core-absolute.gdlr-core-left,
.gdlr-core-flexslider-nav.gdlr-core-absolute-center.gdlr-core-left {
  left: 0;
}

.gdlr-core-flexslider-nav.gdlr-core-absolute.gdlr-core-right,
.gdlr-core-flexslider-nav.gdlr-core-absolute-center.gdlr-core-right {
  right: 0;
}

.gdlr-core-flexslider-nav.gdlr-core-center-align .flex-direction-nav {
  display: inline-block;
  line-height: 1;
}

.gdlr-core-flexslider-nav.gdlr-core-block-center {
  text-align: center;
  line-height: 0;
}

.gdlr-core-flexslider-nav.gdlr-core-block-center .flex-direction-nav {
  display: inline-block;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style-2 li {
  float: left;
  line-height: 1;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style-2 li a {
  font-size: 29px;
  display: block;
  margin-left: 7px;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style-2 li a i {
  display: block;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style-2 li a.flex-prev i:before {
  content: "\23";
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style-2 li a.flex-next i:before {
  content: "\24";
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style-2.gdlr-core-absolute-center {
  margin-top: -18px;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style li {
  float: left;
  line-height: 1;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style li a {
  font-size: 29px;
  display: block;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style li a i {
  display: block;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style.gdlr-core-absolute-center {
  margin-top: -18px;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style.gdlr-core-small li a {
  font-size: 23px;
  display: block;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style.gdlr-core-small.gdlr-core-absolute-center {
  margin-top: -15px;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-style.gdlr-core-size-widget li a {
  font-size: 19px;
  margin-top: -4px;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li {
  float: left;
  line-height: 1;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a {
  font-size: 47px;
  display: block;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a i {
  display: block;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a.flex-next {
  margin-left: 8px;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a.flex-next i {
  display: block;
  margin: -6px -4px -4px -6px;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a.flex-prev i {
  display: block;
  margin: -6px -4px -4px -6px;
}

.gdlr-core-flexslider-nav.gdlr-core-plain-circle-style.gdlr-core-absolute-center {
  margin-top: -22px;
}

.gdlr-core-flexslider-nav.gdlr-core-round-style li {
  float: left;
  line-height: 1;
}

.gdlr-core-flexslider-nav.gdlr-core-round-style li.flex-nav-prev {
  margin-right: 8px;
}

.gdlr-core-flexslider-nav.gdlr-core-round-style li a {
  font-size: 29px;
  padding: 16px;
  display: block;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-flexslider-nav.gdlr-core-round-style li a i {
  display: block;
}

.gdlr-core-flexslider-nav.gdlr-core-round-style.gdlr-core-absolute-center {
  margin-top: -31px;
}

.gdlr-core-flexslider-nav.gdlr-core-round-style.gdlr-core-small li a {
  font-size: 19px;
  padding: 17px;
}

.gdlr-core-flexslider-nav.gdlr-core-round-style.gdlr-core-small
  li.flex-nav-prev {
  margin-right: 5px;
}

.gdlr-core-flexslider-nav.gdlr-core-rectangle-style li {
  float: left;
  line-height: 1;
}

.gdlr-core-flexslider-nav.gdlr-core-rectangle-style li.flex-nav-prev {
  margin-right: 1px;
}

.gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a {
  width: 40px;
  height: 40px;
  font-size: 19px;
  padding: 10px;
  display: block;
}

.gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a i {
  display: block;
}

.gdlr-core-flexslider-nav.gdlr-core-rectangle-style.gdlr-core-absolute-center {
  margin-top: -24px;
}

.gdlr-core-flexslider-nav.gdlr-core-rectangle-style.gdlr-core-large
  li.flex-nav-prev {
  margin-right: 4px;
}

.gdlr-core-flexslider-nav.gdlr-core-rectangle-style.gdlr-core-large li a {
  width: 55px;
  height: 55px;
  font-size: 23px;
  text-align: center;
  padding: 15px;
}

.gdlr-core-flexslider-nav.gdlr-core-rectangle-style.gdlr-core-large.gdlr-core-absolute-center {
  margin-top: -30px;
}

.gdlr-core-flexslider[data-effect="kenburn"] img {
  animation-duration: 15000ms;
  -webkit-animation-duration: 15000ms;
}

.gdlr-core-flexslider[data-effect="kenburn"] li {
  overflow: hidden;
}

.gdlr-core-flexslider[data-effect="kenburn"] li.gdlr-core-active img {
  -webkit-animation-name: kenBurn;
  animation-name: kenBurn;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

@-webkit-keyframes kenBurn {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

@keyframes kenBurn {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

.gdlr-core-flexslider[data-type="carousel"]
  ul.slides
  > li.flex-with-active-class {
  opacity: 0;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
}

.gdlr-core-flexslider[data-type="carousel"]
  ul.slides
  > li.flex-with-active-class.flex-active-slide {
  opacity: 1;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
}

.gdlr-core-flexslider.gdlr-core-overflow-visible[data-type="carousel"]
  ul.slides
  > li.flex-with-active-class {
  opacity: 1;
}

.gdlr-core-flexslider-custom-nav {
  line-height: 1;
}

.gdlr-core-flexslider-custom-nav i {
  display: inline-block;
  transition: color 300ms, opacity 300ms;
  -moz-transition: color 300ms, opacity 300ms;
  -o-transition: color 300ms, opacity 300ms;
  -webkit-transition: color 300ms, opacity 300ms;
}

.gdlr-core-flexslider-custom-nav .flex-prev {
  cursor: pointer;
  margin-right: 12px;
}

.gdlr-core-flexslider-custom-nav .flex-next {
  cursor: pointer;
}

.gdlr-core-flexslider-custom-nav.gdlr-core-style-navigation-top {
  margin-bottom: 35px;
}

.gdlr-core-flexslider-custom-nav.gdlr-core-style-navigation-bottom {
  margin-top: 55px;
  position: relative;
}

.gdlr-core-flexslider-custom-nav.gdlr-core-style-navigation-inner .flex-prev {
  position: absolute;
  left: 20px;
  top: 50%;
  z-index: 1;
}

.gdlr-core-flexslider-custom-nav.gdlr-core-style-navigation-inner .flex-next {
  position: absolute;
  right: 20px;
  top: 50%;
  z-index: 1;
}

.gdlr-core-flexslider[data-nav="navigation-outer"] {
  position: static;
  padding-left: 300px;
  margin-left: -300px;
  padding-right: 300px;
  margin-right: -300px;
}

.gdlr-core-flexslider-custom-nav.gdlr-core-style-navigation-outer {
  position: absolute;
  left: 0;
  right: 0;
}

.gdlr-core-flexslider-custom-nav.gdlr-core-style-navigation-outer .flex-prev {
  position: absolute;
  left: 35px;
  z-index: 1;
}

.gdlr-core-flexslider-custom-nav.gdlr-core-style-navigation-outer .flex-next {
  position: absolute;
  right: 35px;
  z-index: 1;
}

.gdlr-core-flexslider.gdlr-core-overflow-visible .flex-viewport {
  overflow: visible !important;
}

.gdlr-core-flexslider
  .gdlr-core-flexslider-custom-nav.gdlr-core-show-on-hover
  i {
  opacity: 0;
}

.gdlr-core-flexslider:hover
  .gdlr-core-flexslider-custom-nav.gdlr-core-show-on-hover
  i {
  opacity: 1;
}

.gdlr-core-flexslider-outer .gdlr-core-column-24 {
  padding-right: 50px;
}

@media only screen and (max-width: 767px) {
  .gdlr-core-flexslider-outer .gdlr-core-column-24 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.gdlr-core-flexslider.flexslider[data-type="carousel"].gdlr-core-flex-inactive
  ul.slides {
  margin-left: 0px !important;
}

.gdlr-core-flexslider.flexslider[data-type="carousel"].gdlr-core-flex-inactive
  ul.slides
  > li {
  opacity: 1 !important;
}

.gdlr-core-flexslider[data-nav="touch-navigation"] ul.slides {
  cursor: grab;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.goodlayers-carousel-nav-follow[data-type="touch-slide-black"] {
  background: #1f1f1f;
  color: #fff;
}

.goodlayers-carousel-nav-follow[data-type="touch-slide-black"]:before,
.goodlayers-carousel-nav-follow[data-type="touch-slide-black"]:after {
  border-color: #fff;
}

.goodlayers-carousel-nav-follow[data-type="touch-slide-white"] {
  background: #fff;
  color: #1f1f1f;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.09);
}

.goodlayers-carousel-nav-follow[data-type="touch-slide-white"]:before,
.goodlayers-carousel-nav-follow[data-type="touch-slide-white"]:after {
  border-color: #1f1f1f;
}

.goodlayers-carousel-nav-follow {
  position: fixed;
  opacity: 0;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  margin-top: -40px;
  z-index: 0;
  pointer-events: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: transform 300ms, opacity 300ms;
}

.goodlayers-carousel-nav-follow:hover {
  cursor: none;
}

.goodlayers-carousel-nav-follow.goodlayers-active {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 999;
}

.goodlayers-carousel-nav-follow.goodlayers-over-link {
  opacity: 0.2;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  z-index: 0.2;
}

.goodlayers-carousel-nav-follow:before {
  content: " ";
  width: 8px;
  height: 8px;
  position: absolute;
  display: block;
  border-style: solid;
  border-width: 1px 0 0 1px;
  transform: translate(18px, 36px) rotate(-45deg);
}

.goodlayers-carousel-nav-follow:after {
  content: " ";
  width: 8px;
  height: 8px;
  position: absolute;
  display: block;
  border-style: solid;
  border-width: 1px 0 0 1px;
  transform: translate(53px, 36px) rotate(135deg);
}

.wp-admin .goodlayers-carousel-nav-follow {
  display: none;
}

.gdlr-core-custom-menu-item {
  font-size: 16px;
  font-weight: 600;
}

.gdlr-core-custom-menu-item ul {
  list-style: none;
  margin-left: 0;
}

.gdlr-core-custom-menu-item ul li {
  padding-bottom: 14px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 14px;
}

.gdlr-core-custom-menu-item.gdlr-core-style-2 {
  font-weight: 400;
}

.gdlr-core-custom-menu-item.gdlr-core-style-2 ul li:before {
  content: "\e606";
  font-size: 0.8em;
  margin-right: 16px;
  font-family: simple-line-icons;
}

.gdlr-core-custom-menu-item.gdlr-core-style-2 ul li {
  padding-bottom: 0;
  border: 0;
  margin-bottom: 10px;
}

.gdlr-core-portfolio-badge-wrap {
  position: relative;
}

.gdlr-core-portfolio-badge {
  line-height: 20px;
  width: 42px;
  height: 42px;
  text-align: center;
  padding: 11px 0;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  right: -11px;
  top: -11px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  z-index: 10;
}

.gdlr-core-portfolio-item
  .gdlr-core-flexslider.gdlr-core-with-outer-frame-element
  .flex-viewport {
  padding-top: 11px;
  padding-right: 11px;
  margin-right: -11px;
}

.gdlr-core-portfolio-item .gdlr-core-now-loading {
  position: absolute;
  /* background: url(../../../../../../../../../../plugins/goodlayers-core/include/images/portfolio/now-loading.gif)
    center no-repeat; */
  width: 55px;
  height: 55px;
  left: 50%;
  margin-left: -28px;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-portfolio-view-all-works {
  text-align: center;
  margin-top: 50px;
}

.gdlr-core-portfolio-view-all-works .gdlr-core-button {
  letter-spacing: 0;
  text-transform: none;
}

.gdlr-core-portfolio-view-all-works i {
  font-size: 35px;
  cursor: pointer;
  vertical-align: middle;
}

.gdlr-core-portfolio-view-all-works i.flex-prev {
  margin-right: 20px;
}

.gdlr-core-portfolio-view-all-works i.flex-next {
  margin-left: 18px;
}

.gdlr-core-portfolio-thumbnail {
  position: relative;
  overflow: hidden;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-overlay {
  text-align: center;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-title {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  margin-bottom: 0;
  display: block;
  line-height: 1.4;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-icon {
  font-size: 22px;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info {
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0.5px;
  display: block;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info .gdlr-core-sep {
  margin: 0 3px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-icon-title
  .gdlr-core-portfolio-title {
  margin-top: 26px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-icon
  .gdlr-core-portfolio-title {
  margin-bottom: 26px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-tag
  .gdlr-core-portfolio-info {
  margin-top: 26px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-tag-title
  .gdlr-core-portfolio-info {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: 2px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-date
  .gdlr-core-portfolio-date-wrap {
  margin-top: 12px;
  display: block;
  font-size: 15px;
  font-style: normal;
  letter-spacing: 0;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-icon-title-tag
  .gdlr-core-portfolio-title {
  margin-top: 30px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-icon-title-tag
  .gdlr-core-portfolio-info {
  margin-top: 10px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-icon-title_bottom
  .gdlr-core-portfolio-title {
  position: absolute;
  bottom: 33px;
  left: 30px;
  right: 30px;
  text-align: left;
  line-height: 1.4;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-icon-title_bottom.gdlr-core-no-image
  .gdlr-core-portfolio-title {
  position: static;
  margin-top: 10px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-margin-title-icon
  .gdlr-core-portfolio-title {
  margin-bottom: 26px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-margin-title-tag
  .gdlr-core-portfolio-info {
  margin-top: 26px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-margin-icon-title-tag
  .gdlr-core-portfolio-title {
  margin-top: 30px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-margin-icon-title-tag
  .gdlr-core-portfolio-info {
  margin-top: 10px;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-image-overlay-slide {
  transform: translate3d(0, 100%, 0);
  -webkit-transform: translate3d(0, 100%, 0);
  transition: transform 300ms ease-out;
  -moz-transition: transform 300ms ease-out;
  -o-transition: transform 300ms ease-out;
  -webkit-transition: transform 300ms ease-out;
}

.gdlr-core-portfolio-thumbnail:hover .gdlr-core-image-overlay-slide {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-tag-slide
  .gdlr-core-image-overlay-slide {
  opacity: 1;
  top: auto;
  text-align: left;
  padding: 20px 30px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-tag-slide
  .gdlr-core-image-overlay-content {
  position: static;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-tag-slide
  .gdlr-core-portfolio-info {
  margin-top: 3px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-tag-slide2
  .gdlr-core-image-overlay-slide {
  opacity: 1;
  top: auto;
  text-align: left;
  padding: 20px 30px;
  bottom: 50px;
  right: 50px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-tag-slide2
  .gdlr-core-image-overlay-content {
  position: static;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-tag-slide2
  .gdlr-core-image-overlay-slide {
  transform: translate3d(-100%, 0, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-tag-slide2:hover
  .gdlr-core-image-overlay-slide {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-button
  .gdlr-core-portfolio-hover-button {
  margin-top: 25px;
  display: block;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-hover-button a {
  margin: 0 14px;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-image-overlay.gdlr-core-with-margin {
  left: 18px;
  top: 18px;
  right: 18px;
  bottom: 18px;
}

.gdlr-core-portfolio-thumbnail
  .gdlr-core-portfolio-overlay.gdlr-core-title-tag-bottom {
  text-align: left;
}

.gdlr-core-portfolio-thumbnail
  .gdlr-core-portfolio-overlay.gdlr-core-title-tag-bottom
  .gdlr-core-image-overlay-content {
  left: 28px;
  bottom: 23px;
  top: auto;
}

.gdlr-core-portfolio-thumbnail
  .gdlr-core-portfolio-overlay.gdlr-core-title-tag-bottom
  .gdlr-core-portfolio-title {
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 8px;
  line-height: 1;
  margin-bottom: 10px;
}

.gdlr-core-portfolio-thumbnail
  .gdlr-core-portfolio-overlay.gdlr-core-title-tag-bottom
  .gdlr-core-portfolio-info {
  font-size: 15px;
  font-style: normal;
}

.gdlr-core-flexslider.flexslider ul.slides > li .gdlr-core-portfolio-grid {
  margin-bottom: 0;
}

.gdlr-core-portfolio-grid {
  margin-bottom: 40px;
  position: relative;
}

.gdlr-core-portfolio-grid .gdlr-core-portfolio-content-wrap {
  padding-top: 28px;
}

.gdlr-core-portfolio-grid
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-title {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 7px;
  position: relative;
}

.gdlr-core-portfolio-grid
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-info {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 7px;
  display: block;
  position: relative;
}

.gdlr-core-portfolio-grid
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-info
  .gdlr-core-sep {
  margin: 0 3px;
}

.gdlr-core-portfolio-grid
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-content {
  padding-top: 8px;
  margin-bottom: 7px;
  position: relative;
}

.gdlr-core-portfolio-grid.gdlr-core-style-with-frame {
  overflow: hidden;
}

.gdlr-core-portfolio-grid.gdlr-core-style-with-frame
  .gdlr-core-portfolio-content-wrap {
  padding: 28px 29px 19px;
  position: relative;
}

.gdlr-core-portfolio-grid.gdlr-core-style-with-frame
  .gdlr-core-portfolio-grid-frame {
  background: #f5f5f5;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.gdlr-core-portfolio-grid.gdlr-core-style-with-bottom-border
  .gdlr-core-portfolio-content-wrap {
  padding: 25px 29px 10px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.gdlr-core-portfolio-item-style-grid-no-space
  .gdlr-core-portfolio-content-wrap {
  padding-left: 30px;
  padding-right: 30px;
}

.gdlr-core-portfolio-grid2 {
  background: #f3f3f3;
  overflow: hidden;
  border-style: solid;
}

.gdlr-core-portfolio-grid2
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-info {
  background: #3d3ac2;
}

.gdlr-core-portfolio-grid2 .gdlr-core-portfolio-content-wrap {
  padding: 33px 29px 30px;
}

.gdlr-core-portfolio-grid2
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-info,
.gdlr-core-portfolio-grid2
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-info
  a,
.gdlr-core-portfolio-grid2
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-info
  a:hover {
  color: #fff;
}

.gdlr-core-portfolio-grid2
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-title {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 13px;
}

.gdlr-core-portfolio-grid2
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-info {
  display: inline-block;
  font-size: 10px;
  margin-bottom: 13px;
  padding: 4px 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-portfolio-grid3 {
  margin-bottom: 40px;
}

.gdlr-core-portfolio-grid3 .gdlr-portfolio-learn-more {
  font-size: 17px;
  font-weight: 600;
}

.gdlr-core-portfolio-grid3 .gdlr-portfolio-learn-more i {
  font-size: 28px;
  line-height: 1;
  vertical-align: middle;
  margin-left: 16px;
}

.gdlr-core-portfolio-grid3 .gdlr-core-portfolio-content-wrap {
  background: #fff;
  margin-right: 40px;
  padding: 18px 28px;
  margin-top: -50px;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.gdlr-core-portfolio-grid3 .gdlr-core-portfolio-content-wrap:first-child {
  margin-top: 0;
}

.gdlr-core-portfolio-grid3
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-title {
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 5px;
}

.gdlr-core-portfolio-medium {
  display: table;
  width: 100%;
  margin-bottom: 80px;
}

.gdlr-core-portfolio-medium .gdlr-core-portfolio-thumbnail-wrap {
  display: table-cell;
  position: relative;
}

.gdlr-core-portfolio-medium .gdlr-core-portfolio-content-wrap {
  display: table-cell;
  vertical-align: middle;
}

.gdlr-core-portfolio-medium
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-title {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 6px;
}

.gdlr-core-portfolio-medium
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-info {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 6px;
}

.gdlr-core-portfolio-medium
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-info
  .gdlr-core-sep {
  margin: 0 3px;
}

.gdlr-core-portfolio-medium
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-content {
  padding-top: 25px;
}

.gdlr-core-portfolio-medium
  .gdlr-core-portfolio-content-wrap
  .gdlr-core-portfolio-read-more {
  display: inline-block;
  margin-top: 29px;
  font-style: italic;
}

.gdlr-core-portfolio-medium.gdlr-core-size-small
  .gdlr-core-portfolio-thumbnail-wrap {
  width: 35%;
}

.gdlr-core-portfolio-medium.gdlr-core-size-large
  .gdlr-core-portfolio-thumbnail-wrap {
  width: 70%;
}

.gdlr-core-portfolio-medium.gdlr-core-style-left
  .gdlr-core-portfolio-content-wrap {
  padding-left: 80px;
}

.gdlr-core-portfolio-medium.gdlr-core-style-right
  .gdlr-core-portfolio-content-wrap {
  padding-right: 80px;
}

.gdlr-core-portfolio-medium-feature {
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 60px;
  margin-left: 35px;
  margin-right: 35px;
}

.gdlr-core-portfolio-medium-feature .gdlr-core-item-list-inner {
  display: table;
}

.gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-title {
  font-size: 22px;
  margin-bottom: 0;
}

.gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-info {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
}

.gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-content-wrap {
  display: table-cell;
  width: 29%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  background: #f3f3f3;
  border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
}

.gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-content-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-thumbnail {
  margin-top: -60px;
  margin-bottom: -60px;
  transition: margin 300ms;
  -moz-transition: margin 300ms;
  -o-transition: margin 300ms;
  -webkit-transition: margin 300ms;
}

.gdlr-core-portfolio-medium-feature:hover .gdlr-core-portfolio-thumbnail {
  margin-top: -70px;
  margin-bottom: -50px;
}

.gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-thumbnail-wrap {
  display: table-cell;
  vertical-align: middle;
}

.gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-thumbnail {
  box-shadow: 20px 0 40px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 20px 0 40px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -20px 0 40px rgba(0, 0, 0, 0.2);
}

.gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-content-wrap {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.gdlr-core-flexslider .gdlr-core-portfolio-medium-feature {
  margin-bottom: 0;
}

.gdlr-core-portfolio-item-style-medium-feature .flex-viewport {
  overflow: visible !important;
}

.gdlr-core-portfolio-modern2 .gdlr-core-image-overlay-content {
  bottom: 30px;
  left: 35px;
  right: 35px;
  top: auto;
  text-align: left;
}

.gdlr-core-portfolio-modern2 .gdlr-core-portfolio-title {
  font-size: 30px;
  display: inline-block;
  padding-bottom: 5px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 18px;
}

.gdlr-core-portfolio-modern2 .gdlr-core-portfolio-caption {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.gdlr-core-portfolio-modern3 .gdlr-core-image-overlay-content {
  top: 85px;
  left: 45px;
  text-align: left;
}

.gdlr-core-portfolio-modern3 .gdlr-core-portfolio-title {
  font-size: 27px;
  font-weight: 400;
  letter-spacing: 2px;
  padding-bottom: 0;
  border: 0;
  margin-bottom: 25px;
  line-height: 1.6;
}

.gdlr-core-portfolio-modern3 .gdlr-core-portfolio-caption {
  font-size: 13px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 7px;
  transform: rotate(270deg) translate(-100%, 0);
  -webkit-transform: rotate(270deg) translate(-100%, 0);
  -moz-transform: rotate(270deg) translate(-100%, 0);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
}

.gdlr-core-portfolio-modern3 .gdlr-core-portfolio-caption:before {
  content: " ";
  display: inline-block;
  width: 37px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 0.3em;
  margin-right: 30px;
}

.gdlr-core-portfolio-modern,
.gdlr-core-portfolio-modern-desc {
  overflow: hidden;
}

.gdlr-core-portfolio-modern-desc .gdlr-core-image-overlay-content {
  top: auto;
  bottom: 30px;
}

.gdlr-core-portfolio-modern-desc .gdlr-core-portfolio-title {
  font-size: 16px;
  margin-bottom: 5px;
}

.gdlr-core-portfolio-modern-desc .gdlr-core-portfolio-info-tag {
  margin-bottom: 5px;
}

.gdlr-core-portfolio-modern-desc .gdlr-core-portfolio-content {
  padding-top: 16px;
  padding-bottom: 20px;
  display: block;
}

.gdlr-core-portfolio-modern-desc .gdlr-core-portfolio-icon-wrap {
  display: block;
  margin-bottom: 50px;
}

.gdlr-core-portfolio-grid-full {
  position: relative;
}

.gdlr-core-portfolio-grid-full .gdlr-core-portfolio-info {
  font-size: 15px;
}

.gdlr-core-portfolio-grid-full .gdlr-core-portfolio-title {
  font-size: 28px;
}

.gdlr-core-portfolio-grid-full .gdlr-core-portfolio-read-more {
  font-size: 14px;
  float: right;
}

.gdlr-core-portfolio-grid-full .gdlr-core-portfolio-content-wrap {
  position: relative;
  margin-left: 25%;
  margin-top: -75px;
}

.gdlr-core-portfolio-grid-full .gdlr-core-portfolio-flex-nav {
  float: left;
  margin-top: 85px;
  margin-right: 15px;
  font-size: 12px;
}

.gdlr-core-portfolio-grid-full .gdlr-core-portfolio-flex-nav i {
  cursor: pointer;
  margin: 0 6px;
}

.gdlr-core-portfolio-grid-full .gdlr-core-portfolio-content {
  overflow: hidden;
  background: #fff;
  padding: 25px 30px;
}

.gdlr-core-portfolio-metro {
  overflow: hidden;
}

.gdlr-core-portfolio-item .gdlr-core-portfolio-read-more-wrap {
  position: relative;
  margin-top: 20px;
  margin-bottom: 8px;
}

.gdlr-core-portfolio-item .gdlr-core-portfolio-read-more {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.gdlr-core-portfolio-fixed-metro-item.gdlr-core-half-height {
  position: relative;
  overflow: hidden;
}

.gdlr-core-portfolio-fixed-metro-item.gdlr-core-half-height
  .gdlr-core-portfolio-thumbnail {
  position: static;
}

.gdlr-core-portfolio-single-nav-wrap {
  border-top: 2px;
  border-style: solid;
  padding: 47px 0;
}

.gdlr-core-portfolio-single-nav-area {
  position: relative;
}

.gdlr-core-portfolio-single-nav i {
  font-size: 25px;
}

.gdlr-core-portfolio-single-nav .gdlr-core-portfolio-text {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
}

.gdlr-core-portfolio-single-nav-left {
  float: left;
}

.gdlr-core-portfolio-single-nav-left i {
  margin-right: 15px;
  vertical-align: middle;
}

.gdlr-core-portfolio-single-nav-right {
  float: right;
}

.gdlr-core-portfolio-single-nav-right i {
  margin-left: 15px;
  vertical-align: middle;
}

.gdlr-core-portfolio-single-nav-wrap.gdlr-core-style-2 {
  border-top: 0;
  position: relative;
  text-align: center;
}

.gdlr-core-portfolio-single-nav-wrap.gdlr-core-style-2
  .gdlr-core-portfolio-single-nav
  i {
  font-size: 20px;
  margin-right: 20px;
  background: #f3f3f3;
  padding: 14px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-portfolio-single-nav-wrap.gdlr-core-style-2
  .gdlr-core-portfolio-single-nav
  .gdlr-core-portfolio-text {
  font-size: 17px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
}

.gdlr-core-portfolio-single-nav-wrap.gdlr-core-style-2
  .gdlr-core-portfolio-single-nav-middle {
  font-size: 22px;
  background: #f3f3f3;
  padding: 13px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -24px;
  line-height: 0;
}

.gdlr-core-portfolio-single-related {
  padding-top: 70px;
}

.gdlr-core-portfolio-single-related .gdlr-core-portfolio-single-related-head {
  font-size: 22px;
  margin-bottom: 42px;
}

.gdlr-core-portfolio-single-related.gdlr-core-style-grid
  .gdlr-core-portfolio-item-holder {
  margin-bottom: 20px;
}

.gdlr-core-portfolio-single-related.gdlr-core-style-modern
  .gdlr-core-portfolio-item-holder {
  margin-bottom: 30px;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-half {
  margin-left: 0;
  list-style: none;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-half li {
  float: left;
  width: 50%;
  margin-bottom: 8px;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-half li ul {
  list-style: none;
  margin-left: 18px;
  margin-top: 8px;
  margin-bottom: 0;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-half:after,
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-half li ul:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-half li:nth-child(2n-1) {
  clear: left;
}

@media only screen and (max-width: 1050px) {
  ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-half li {
    width: auto;
    float: none;
  }
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list {
  margin-left: 0;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 13px 0;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li a {
  display: inline-block;
  padding-left: 15px;
  border-left-width: 3px;
  border-left-style: solid;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li a:before {
  content: "\f178";
  font-family: "fontAwesome";
  padding-right: 12px;
  margin-left: -26px;
  opacity: 0;
  transition: opacity 200ms, margin-left 200ms;
  -moz-transition: opacity 200ms, margin-left 200ms;
  -o-transition: opacity 200ms, margin-left 200ms;
  -webkit-transition: opacity 200ms, margin-left 200ms;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li a:hover:before,
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list
  li.current-menu-item
  a:before,
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list
  li.current-menu-ancestor
  a:before {
  opacity: 1;
  margin-left: 0;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list2 {
  margin-left: 0;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list2 li {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list2 li:first-child {
  border-top-width: 1px;
  border-top-style: solid;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list2 li a {
  font-weight: 600;
  display: inline-block;
  padding: 10px 0;
  border-left-width: 0;
  border-left-style: solid;
  border-left-color: transparent;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list2 li a:before {
  content: "\24";
  font-family: "ElegantIcons";
  padding-right: 12px;
  font-size: 18px;
  vertical-align: middle;
  opacity: 1;
  margin-left: 0;
  font-weight: 400;
  transition: opacity 200ms, margin-left 200ms;
  -moz-transition: opacity 200ms, margin-left 200ms;
  -o-transition: opacity 200ms, margin-left 200ms;
  -webkit-transition: opacity 200ms, margin-left 200ms;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list2 li a:hover,
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list2
  li.current-menu-item
  a,
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list2
  li.current-menu-ancestor
  a {
  border-left-width: 3px;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list2 li a:hover:before,
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list2
  li.current-menu-item
  a:before,
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list2
  li.current-menu-ancestor
  a:before {
  opacity: 1;
  margin-left: 12px;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list3 {
  margin-left: 0;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list3 li {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list3 li a {
  font-weight: 600;
  display: inline-block;
  padding: 10px 0;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list3 li a:before {
  content: "\24";
  display: inline-block;
  font-family: "ElegantIcons";
  font-size: 18px;
  vertical-align: middle;
  width: 0;
  opacity: 0;
  font-weight: 400;
  transition: opacity 200ms, width 200ms;
  -moz-transition: opacity 200ms, width 200ms;
  -o-transition: opacity 200ms, width 200ms;
  -webkit-transition: opacity 200ms, width 200ms;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list3 li a:hover:before,
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list3
  li.current-menu-item
  a:before,
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list3
  li.current-menu-ancestor
  a:before {
  opacity: 1;
  width: 30px;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-plain {
  margin-left: 0;
  list-style: none;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-plain li {
  margin-bottom: 8px;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box {
  list-style: none;
  margin-left: 0;
  font-weight: 600;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box li a {
  display: block;
  background: #f3f3f3;
  margin-bottom: 2px;
  padding: 12px 22px;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box li a:after {
  content: "\f105";
  font-family: fontAwesome;
  float: right;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box2 {
  list-style: none;
  margin-left: 0;
  font-weight: 600;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box2:after {
  content: " ";
  border-bottom-width: 5px;
  border-bottom-style: solid;
  display: block;
  margin-top: 28px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box2 li a {
  display: block;
  background: #f3f3f3;
  margin-bottom: 4px;
  padding: 17px 22px 19px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box2 li a:after {
  content: "\f178";
  font-family: fontAwesome;
  float: right;
}

.gdlr-core-custom-menu-widget .gdlr-core-nav-side-text.gdlr-core-with-bg {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

.gdlr-core-recent-comment-widget .gdlr-core-recent-comment-widget-item {
  padding-top: 22px;
  border-top-width: 1px;
  border-top-style: solid;
  margin-bottom: 23px;
}

.gdlr-core-recent-comment-widget
  .gdlr-core-recent-comment-widget-item:first-child {
  padding-top: 0;
  border-top: none;
}

.gdlr-core-recent-comment-widget .gdlr-core-recent-comment-widget-avatar {
  overflow: hidden;
  border-radius: 50%;
  float: left;
  margin-right: 25px;
  width: 50px;
}

.gdlr-core-recent-comment-widget .gdlr-core-recent-comment-widget-content {
  overflow: hidden;
}

.gdlr-core-recent-comment-widget .gdlr-core-recent-comment-widget-author {
  font-weight: 700;
}

.gdlr-core-recent-comment-widget .gdlr-core-recent-comment-widget-author {
  font-size: 16px;
  margin-bottom: 10px;
}

.gdlr-core-recent-comment-widget .gdlr-core-recent-comment-widget-excerpt {
  font-size: 14px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-1 {
  padding-top: 8px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-1
  .gdlr-core-recent-post-widget {
  margin-bottom: 20px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-1
  .gdlr-core-recent-post-widget-thumbnail {
  float: left;
  max-width: 75px;
  margin-right: 23px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-1
  .gdlr-core-recent-post-widget-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2px;
  margin-top: -5px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-1
  .gdlr-core-recent-post-widget-content {
  overflow: hidden;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-1 .gdlr-core-blog-info {
  display: inline-block;
  margin-right: 20px;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-1 .gdlr-core-blog-info i {
  display: inline-block;
  font-size: 15px;
  margin-right: 8px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-1
  .gdlr-core-blog-info
  .gdlr-core-head {
  vertical-align: middle;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-3 {
  padding-top: 8px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-3
  .gdlr-core-recent-post-widget {
  margin-bottom: 20px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-3
  .gdlr-core-recent-post-widget-thumbnail {
  float: left;
  max-width: 75px;
  margin-right: 23px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  overflow: hidden;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-3
  .gdlr-core-recent-post-widget-title {
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 8px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-3
  .gdlr-core-recent-post-widget-content {
  overflow: hidden;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-3 .gdlr-core-blog-info {
  display: inline-block;
  margin-right: 20px;
  font-size: 13px;
  line-height: 15px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-3 .gdlr-core-blog-info i {
  display: inline-block;
  font-size: 15px;
  margin-right: 8px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-4 {
  padding-top: 8px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-4
  .gdlr-core-recent-post-widget {
  margin-bottom: 30px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-4
  .gdlr-core-recent-post-widget-thumbnail {
  float: left;
  max-width: 60px;
  margin-right: 23px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  overflow: hidden;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-4
  .gdlr-core-recent-post-widget-title {
  font-size: 18px;
  font-weight: 700;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-4
  .gdlr-core-recent-post-widget-content {
  overflow: hidden;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-4 .gdlr-core-blog-info {
  display: inline-block;
  margin-right: 20px;
  font-size: 15px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-4 .gdlr-core-blog-info i {
  display: none;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-4
  .gdlr-core-blog-info
  .gdlr-core-head {
  vertical-align: middle;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-2
  .gdlr-core-recent-post-widget-title {
  font-size: 16px;
  font-weight: 600;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-2
  .gdlr-core-recent-post-widget-info
  i {
  display: none;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-2
  .gdlr-core-recent-post-widget-info {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-2
  .gdlr-core-recent-post-widget-content {
  margin-bottom: 20px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-5
  .gdlr-core-recent-post-widget-title {
  font-size: 16px;
  font-weight: 500;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-5
  .gdlr-core-recent-post-widget-info
  i {
  display: none;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-5
  .gdlr-core-recent-post-widget-info {
  font-size: 13px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-5
  .gdlr-core-recent-post-widget-content {
  margin-bottom: 20px;
  padding-top: 20px;
  border-top-width: 1px;
  border-top-style: solid;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-5
  .gdlr-core-recent-post-widget:first-child
  .gdlr-core-recent-post-widget-content {
  padding-top: 0;
  border: 0;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-full
  .gdlr-core-recent-post-widget {
  margin-bottom: 30px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-full
  .gdlr-core-recent-post-widget-thumbnail {
  margin-bottom: 20px;
  position: relative;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-full
  .gdlr-core-recent-post-widget-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-full .gdlr-core-blog-info {
  display: inline-block;
  margin-right: 20px;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-full .gdlr-core-blog-info i {
  display: inline-block;
  font-size: 15px;
  margin-right: 8px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-full
  .gdlr-core-recent-post-widget-thumbnail
  .gdlr-core-blog-info-category {
  position: absolute;
  left: 18px;
  top: 16px;
  font-size: 12px;
  line-height: 1;
  padding: 8px 15px;
  text-transform: none;
  letter-spacing: 0;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-full
  .gdlr-core-recent-post-widget-thumbnail
  .gdlr-core-blog-info-category
  a,
.gdlr-core-recent-post-widget-wrap.gdlr-core-style-full
  .gdlr-core-recent-post-widget-thumbnail
  .gdlr-core-blog-info-category
  span {
  color: #fff;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-full
  .gdlr-core-recent-post-widget-thumbnail
  .gdlr-core-blog-info-category
  .gdlr-core-head {
  display: none;
}

.gdlr-core-recent-post-widget-wrap.gdlr-core-style-full
  .gdlr-core-recent-post-widget-thumbnail
  .gdlr-core-recent-post-widget-thumbnail-video {
  margin-left: -30px;
  margin-top: -30px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  padding: 20px 17px 20px 23px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-post-slider-widget-wrap {
  padding-top: 8px;
}

.gdlr-core-post-slider-widget-overlay {
  top: auto;
}

.gdlr-core-post-slider-widget-overlay .gdlr-core-post-slider-widget-title {
  font-size: 18px;
  margin-bottom: 8px;
  display: block;
}

.gdlr-core-post-slider-widget-overlay .gdlr-core-image-overlay-content {
  position: relative;
  left: 0;
  right: 0;
  padding: 23px 28px 23px;
  text-align: left;
}

.gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info {
  display: inline-block;
  margin-right: 18px;
  font-size: 11px;
  text-transform: uppercase;
}

.gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info i {
  font-size: 15px;
  margin-right: 10px;
}

.gdlr-core-portfolio-slider-widget-wrap {
  padding-top: 8px;
}

.gdlr-core-portfolio-slider-widget-wrap
  .gdlr-core-portfolio-thumbnail
  .gdlr-core-portfolio-title {
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 600;
  letter-spacing: 0.3;
}

.gdlr-core-recent-portfolio-widget-wrap {
  padding-top: 8px;
}

.gdlr-core-recent-portfolio-widget {
  max-width: 103px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.gdlr-core-instagram-item .gdlr-core-instagram-item-title {
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.gdlr-core-instagram-item .gdlr-core-instagram-item-title-wrap {
  margin-bottom: 30px;
}

.gdlr-core-instagram-item .flexslider ul li img {
  display: block;
}

.gdlr-core-instagram-item .gdlr-core-instagram-item-title-nav {
  font-size: 22px;
  text-align: center;
}

.gdlr-core-instagram-item .gdlr-core-instagram-item-title-nav i {
  margin: 0 12px;
  cursor: pointer;
}

.gdlr-core-instagram-item .gdlr-core-instagram-item-content a {
  display: block;
  padding-bottom: 100%;
  position: relative;
}

.gdlr-core-instagram-item .flexslider ul.slides a {
  display: block;
  padding-bottom: 100%;
  position: relative;
}

.gdlr-core-instagram-item .gdlr-core-media-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

ul.gdlr-core-twitter-widget-wrap {
  list-style: none;
  margin-left: 0;
}

ul.gdlr-core-twitter-widget-wrap li {
  margin-bottom: 30px;
}

ul.gdlr-core-twitter-widget-wrap li:before {
  content: "\f099";
  font-family: "fontAwesome";
  float: left;
  font-size: 18px;
  margin-right: 25px;
}

ul.gdlr-core-twitter-widget-wrap li .gdlr-core-twitter-widget {
  overflow: hidden;
}

ul.gdlr-core-twitter-widget-wrap li .gdlr-core-twitter-widget-content {
  display: block;
  margin-bottom: 15px;
}

ul.gdlr-core-twitter-widget-wrap li .gdlr-core-twitter-widget-date {
  display: block;
  font-size: 13px;
}

ul.gdlr-core-twitter-content-list {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}

ul.gdlr-core-twitter-content-list li {
  margin-bottom: 25px;
}

ul.gdlr-core-twitter-content-list li:last-child {
  margin-bottom: 0;
}

.gdlr-core-twitter-item-list .gdlr-core-twitter-item-list-date {
  display: block;
  margin-top: 12px;
}

.gdlr-core-event-item .gdlr-core-event-item-info-wrap {
  font-size: 15px;
}

.gdlr-core-event-item .gdlr-core-event-item-info-wrap .gdlr-core-head {
  margin-right: 10px;
}

.gdlr-core-event-item-info.gdlr-core-type-start-date-month {
  display: block;
  float: left;
  width: 40px;
  text-align: center;
  white-space: nowrap;
  margin-right: 25px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  padding-bottom: 10px;
}

.gdlr-core-type-start-date-month .gdlr-core-date {
  font-size: 34px;
  line-height: 1;
  font-weight: 700;
  display: block;
}

.gdlr-core-type-start-date-month .gdlr-core-month {
  font-size: 17px;
  display: block;
  font-weight: 700;
  text-transform: uppercase;
}

.gdlr-core-event-item-list.gdlr-core-style-widget .gdlr-core-event-widget {
  margin-bottom: 28px;
}

.gdlr-core-event-item-list.gdlr-core-style-widget .gdlr-core-event-item-title {
  font-size: 23px;
  margin-bottom: 15px;
}

.gdlr-core-event-item-list.gdlr-core-style-widget
  .gdlr-core-event-item-content-wrap {
  overflow: hidden;
}

.gdlr-core-event-item-list.gdlr-core-style-widget .gdlr-core-event-item-info {
  margin-bottom: 2px;
  margin-right: 25px;
}

.gdlr-core-event-item-list .gdlr-core-evet-widget-thumbnail {
  margin-bottom: 25px;
}

.gdlr-core-event-item-list.gdlr-core-style-widget.gdlr-core-with-border
  .gdlr-core-event-widget {
  padding-bottom: 28px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-event-item-list.gdlr-core-style-widget.gdlr-core-with-border
  .gdlr-core-event-widget {
  padding-bottom: 28px;
}

.gdlr-core-event-item-list.gdlr-core-style-widget.gdlr-core-with-border:last-child
  .gdlr-core-event-widget {
  padding-bottom: 0;
  border-bottom-width: 0;
}

.gdlr-core-event-item-list.gdlr-core-style-grid {
  margin-bottom: 35px;
}

.gdlr-core-event-item-list.gdlr-core-style-grid
  .gdlr-core-event-item-thumbnail {
  overflow: hidden;
}

.gdlr-core-event-item-list.gdlr-core-style-grid.gdlr-core-without-frame
  .gdlr-core-event-item-thumbnail {
  margin-bottom: 28px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-event-item-list.gdlr-core-style-grid .gdlr-core-event-item-title {
  font-size: 19px;
  margin-bottom: 14px;
  font-weight: 700;
}

.gdlr-core-event-item-list.gdlr-core-style-grid
  .gdlr-core-event-item-content-wrap {
  overflow: hidden;
}

.gdlr-core-event-item-list.gdlr-core-style-grid .gdlr-core-event-item-info {
  display: block;
  margin-bottom: 2px;
}

.gdlr-core-event-item-list.gdlr-core-style-grid2 {
  margin-bottom: 35px;
}

.gdlr-core-event-item-list.gdlr-core-style-grid2
  .gdlr-core-event-item-thumbnail {
  overflow: hidden;
}

.gdlr-core-event-item-list.gdlr-core-style-grid2.gdlr-core-without-frame
  .gdlr-core-event-item-thumbnail {
  margin-bottom: 28px;
  overflow: hidden;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-event-item-list.gdlr-core-style-grid2 .gdlr-core-event-item-title {
  font-size: 19px;
  margin-bottom: 22px;
  font-weight: 700;
}

.gdlr-core-event-item-list.gdlr-core-style-grid2
  .gdlr-core-event-item-content-wrap {
  overflow: hidden;
}

.gdlr-core-event-item-list.gdlr-core-style-grid2 .gdlr-core-event-item-info {
  display: block;
  margin-bottom: 2px;
  font-weight: 600;
}

.gdlr-core-event-item-list.gdlr-core-style-grid2
  .gdlr-core-event-item-info.gdlr-core-type-start-time {
  margin-bottom: 10px;
  font-weight: 400;
}

.gdlr-core-event-item-list .gdlr-core-frame {
  background: #fff;
  padding: 20px 32px 30px;
}

.widget
  .gdlr-core-event-item-list.gdlr-core-style-widget
  .gdlr-core-event-item-title {
  font-size: 19px;
  margin-bottom: 13px;
}

@media only screen and (max-width: 768px) {
  .gdlr-core-event-item-list .gdlr-core-evet-widget-thumbnail.gdlr-core-extend {
    margin-left: -50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1170px) {
  .gdlr-core-event-item-list .gdlr-core-evet-widget-thumbnail.gdlr-core-extend {
    margin-left: -100px;
  }
}

@media only screen and (min-width: 1171px) and (max-width: 1400px) {
  .gdlr-core-event-item-list .gdlr-core-evet-widget-thumbnail.gdlr-core-extend {
    margin-left: -200px;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .gdlr-core-event-item-list .gdlr-core-evet-widget-thumbnail.gdlr-core-extend {
    margin-left: -300px;
  }
}

@media only screen and (min-width: 1601px) {
  .gdlr-core-event-item-list .gdlr-core-evet-widget-thumbnail.gdlr-core-extend {
    margin-left: -400px;
  }
}

@media only screen and (max-width: 768px) {
  .rtl
    .gdlr-core-event-item-list
    .gdlr-core-evet-widget-thumbnail.gdlr-core-extend {
    margin-left: 0;
    margin-right: -50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1170px) {
  .rtl
    .gdlr-core-event-item-list
    .gdlr-core-evet-widget-thumbnail.gdlr-core-extend {
    margin-left: 0;
    margin-right: -100px;
  }
}

@media only screen and (min-width: 1171px) and (max-width: 1400px) {
  .rtl
    .gdlr-core-event-item-list
    .gdlr-core-evet-widget-thumbnail.gdlr-core-extend {
    margin-left: 0;
    margin-right: -200px;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .rtl
    .gdlr-core-event-item-list
    .gdlr-core-evet-widget-thumbnail.gdlr-core-extend {
    margin-left: 0;
    margin-right: -300px;
  }
}

@media only screen and (min-width: 1601px) {
  .rtl
    .gdlr-core-event-item-list
    .gdlr-core-evet-widget-thumbnail.gdlr-core-extend {
    margin-left: 0;
    margin-right: -400px;
  }
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle
  .gdlr-core-newsletter-form {
  max-width: 535px;
  margin: 0 auto;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle
  .gdlr-core-newsletter-email {
  float: left;
  width: 70%;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle
  .gdlr-core-newsletter-submit {
  overflow: hidden;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle
  .gdlr-core-newsletter-email
  input[type="email"] {
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  border-width: 1px;
  padding: 19px 20px;
  height: 58px;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  display: block;
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  padding: 19px 0;
  height: 58px;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle-tbutton
  .gdlr-core-newsletter-form {
  max-width: 535px;
  margin: 0 auto;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle-tbutton
  .gdlr-core-newsletter-email {
  float: left;
  width: 70%;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle-tbutton
  .gdlr-core-newsletter-submit {
  overflow: hidden;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle-tbutton
  .gdlr-core-newsletter-email
  input[type="email"] {
  width: 98%;
  margin-right: 2%;
  font-size: 15px;
  line-height: 20px;
  border-width: 1px;
  padding: 19px 20px;
  height: 58px;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle-tbutton
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  display: block;
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  padding: 17px 0;
  height: 58px;
  background: transparent;
  border-width: 2px;
  border-style: solid;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle-full
  .gdlr-core-newsletter-form {
  max-width: 535px;
  margin: 0 auto;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle-full
  .gdlr-core-newsletter-email {
  margin-bottom: 14px;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle-full
  .gdlr-core-newsletter-email
  input[type="email"] {
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  border-width: 1px;
  padding: 15px 20px;
}

.gdlr-core-newsletter-item.gdlr-core-style-rectangle-full
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  padding: 14px 0;
}

.gdlr-core-newsletter-item.gdlr-core-style-transparent-bottom-border
  .gdlr-core-newsletter-form {
  max-width: 535px;
  margin: 0 auto;
}

.gdlr-core-newsletter-item.gdlr-core-style-transparent-bottom-border
  .gdlr-core-newsletter-email {
  float: left;
  width: 70%;
  padding-right: 20px;
}

.gdlr-core-newsletter-item.gdlr-core-style-transparent-bottom-border
  .gdlr-core-newsletter-submit {
  overflow: hidden;
}

.gdlr-core-newsletter-item.gdlr-core-style-transparent-bottom-border
  .gdlr-core-newsletter-email
  input[type="email"] {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 0;
  height: 48px;
  border-width: 0 0 2px 0;
  border-bottom-style: solid;
  background: transparent !important;
}

.gdlr-core-newsletter-item.gdlr-core-style-transparent-bottom-border
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  display: block;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 0;
  height: 48px;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve .gdlr-core-newsletter-form {
  max-width: 535px;
  margin: 0 auto;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve .gdlr-core-newsletter-email {
  float: left;
  width: 70%;
  padding-right: 10px;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve .gdlr-core-newsletter-submit {
  overflow: hidden;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve
  .gdlr-core-newsletter-email
  input[type="email"] {
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  border-width: 0;
  padding: 19px 20px;
  height: 58px;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding: 19px 0;
  height: 58px;
  text-transform: none;
  font-weight: 400;
  letter-spacing: 0;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-newsletter-item.gdlr-core-style-round .gdlr-core-newsletter-email {
  float: left;
  padding-right: 12px;
  width: 65%;
}

.gdlr-core-newsletter-item.gdlr-core-style-round .gdlr-core-newsletter-submit {
  float: left;
  width: 35%;
}

.gdlr-core-newsletter-item.gdlr-core-style-round
  .gdlr-core-newsletter-email
  input[type="email"] {
  font-size: 15px;
  height: 51px;
  line-height: 21px;
  padding: 10px 25px;
  border-width: 1px;
  width: 100%;
}

.gdlr-core-newsletter-item.gdlr-core-style-round
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  height: 51px;
  font-size: 12px;
  font-weight: 600;
}

.gdlr-core-newsletter-item.gdlr-core-style-round
  .gdlr-core-newsletter-email
  input[type="email"],
.gdlr-core-newsletter-item.gdlr-core-style-round
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.gdlr-core-newsletter-item.gdlr-core-style-round2 .gdlr-core-newsletter-form {
  position: relative;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.gdlr-core-newsletter-item.gdlr-core-style-round2 .gdlr-core-newsletter-email {
  float: left;
  padding-right: 12px;
  width: 100%;
}

.gdlr-core-newsletter-item.gdlr-core-style-round2 .gdlr-core-newsletter-submit {
  float: left;
  position: absolute;
  right: 0;
}

.gdlr-core-newsletter-item.gdlr-core-style-round2
  .gdlr-core-newsletter-email
  input[type="email"] {
  font-size: 15px;
  height: 55px;
  line-height: 21px;
  padding: 11px 25px 11px 58px;
  border-width: 1px;
  width: 100%;
}

.gdlr-core-newsletter-item.gdlr-core-style-round2
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  height: 54px;
  font-size: 12px;
  font-weight: 600;
  padding-left: 40px;
  padding-right: 40px;
}

.gdlr-core-newsletter-item.gdlr-core-style-round2
  .gdlr-core-newsletter-email
  input[type="email"],
.gdlr-core-newsletter-item.gdlr-core-style-round2
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.gdlr-core-newsletter-item.gdlr-core-style-round2
  .gdlr-core-newsletter-email:before {
  font-family: fontAwesome;
  content: "\f1d8";
  position: absolute;
  top: 50%;
  margin-top: -14px;
  left: 28px;
}

.gdlr-core-newsletter-item.gdlr-core-style-round3 .gdlr-core-newsletter-form {
  position: relative;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.gdlr-core-newsletter-item.gdlr-core-style-round3 .gdlr-core-newsletter-email {
  float: left;
  padding-right: 12px;
  width: 100%;
}

.gdlr-core-newsletter-item.gdlr-core-style-round3 .gdlr-core-newsletter-submit {
  float: left;
  position: absolute;
  right: 0;
  opacity: 0;
}

.gdlr-core-newsletter-item.gdlr-core-style-round3
  .gdlr-core-newsletter-email
  input[type="email"] {
  font-size: 15px;
  height: 55px;
  line-height: 21px;
  padding: 11px 25px 11px 30px;
  border-width: 1px;
  width: 100%;
}

.gdlr-core-newsletter-item.gdlr-core-style-round3
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  height: 54px;
  font-size: 12px;
  font-weight: 600;
  padding-left: 40px;
  padding-right: 40px;
}

.gdlr-core-newsletter-item.gdlr-core-style-round3
  .gdlr-core-newsletter-email
  input[type="email"],
.gdlr-core-newsletter-item.gdlr-core-style-round3
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.gdlr-core-newsletter-item.gdlr-core-style-round3
  .gdlr-core-newsletter-email:before {
  font-family: fontAwesome;
  content: "\f1d8";
  position: absolute;
  top: 50%;
  margin-top: -14px;
  right: 40px;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve2
  .gdlr-core-newsletter-submit:after {
  content: "\f0a9";
  font-family: fontAwesome;
  font-size: 25px;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -12px;
  margin-right: -12px;
  color: #fff;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve2 .gdlr-core-newsletter-form {
  position: relative;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve2 .gdlr-core-newsletter-email {
  float: left;
  padding-right: 12px;
  width: 100%;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve2 .gdlr-core-newsletter-submit {
  float: left;
  position: absolute;
  right: 0;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve2
  .gdlr-core-newsletter-email
  input[type="email"] {
  font-size: 15px;
  height: 46px;
  line-height: 21px;
  padding: 6px 20px;
  border-width: 1px;
  width: 100%;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve2
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  height: 46px;
  width: 60px;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  color: transparent !important;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve2
  .gdlr-core-newsletter-email
  input[type="email"],
.gdlr-core-newsletter-item.gdlr-core-style-curve2
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve3
  .gdlr-core-newsletter-submit:after {
  content: "\e94e";
  font-family: "gdl-custom-set";
  font-size: 20px;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -10px;
  margin-right: -10px;
  color: #fff;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve3 .gdlr-core-newsletter-form {
  position: relative;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve3 .gdlr-core-newsletter-email {
  float: left;
  padding-right: 65px;
  width: 100%;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve3 .gdlr-core-newsletter-submit {
  float: left;
  position: absolute;
  right: 0;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve3
  .gdlr-core-newsletter-email
  input[type="email"] {
  font-size: 15px;
  height: 55px;
  line-height: 21px;
  padding: 6px 20px;
  border-width: 0;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.gdlr-core-newsletter-item.gdlr-core-style-curve3
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  height: 55px;
  width: 55px;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  color: transparent !important;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve3 .gdlr-core-newsletter-form {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve3
  .gdlr-core-newsletter-email
  input[type="email"],
.gdlr-core-newsletter-item.gdlr-core-style-curve3
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve3.gdlr-core-rectangle
  .gdlr-core-newsletter-form {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.gdlr-core-newsletter-item.gdlr-core-style-curve3.gdlr-core-rectangle
  .gdlr-core-newsletter-email
  input[type="email"],
.gdlr-core-newsletter-item.gdlr-core-style-curve3.gdlr-core-rectangle
  .gdlr-core-newsletter-submit
  input[type="submit"] {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.gdlr-core-with-fa-send-o-button form {
  position: relative;
}

.gdlr-core-with-fa-send-o-button form div.tnp-field {
  margin-bottom: 0;
}

.gdlr-core-with-fa-send-o-button form input[type="email"] {
  padding-left: 20px;
  padding-right: 50px;
}

.gdlr-core-with-fa-send-o-button .tnp-field-button input[type="submit"] {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
}

.gdlr-core-with-fa-send-o-button .tnp-field-button:after {
  font-family: fontAwesome;
  content: "\f1d8";
  position: absolute;
  right: 18px;
  top: 50%;
  font-size: 16px;
  line-height: 20px;
  margin-top: -10px;
  pointer-events: none;
}

.gdlr-core-with-fa-send-o-button form input[type="email"] {
  color: #888;
}

.gdlr-core-with-fa-send-o-button
  form
  input[type="email"]::-webkit-input-placeholder {
  color: #888;
}

.gdlr-core-with-fa-send-o-button form input[type="email"]::-moz-placeholder {
  color: #888;
}

.gdlr-core-with-fa-send-o-button
  form
  input[type="email"]:-ms-input-placeholder {
  color: #888;
}

.gdlr-core-with-fa-send-o-button form input[type="email"]:-moz-placeholder {
  color: #888;
}

.tnp.gdlr-core-style-2 .tnp-field-button input[type="submit"] {
  opacity: 1;
  text-indent: -150px;
  width: 50px;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
}

.tnp.gdlr-core-style-2 form input[type="email"] {
  padding-right: 20px;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
}

.tnp.gdlr-core-style-2 .tnp-field-email {
  margin-right: 56px;
}

.tnp.gdlr-core-style-2 .tnp-field-button:after {
  content: "\f0da";
  right: 20px;
}

.gdlr-core-product-category-item
  .gdlr-core-product-category-thumbnail.gdlr-core-media-image {
  margin-bottom: 30px;
}

.gdlr-core-product-category-item .gdlr-core-product-category-title {
  font-size: 18px;
  margin-bottom: 8px;
}

.gdlr-core-product-category-item .gdlr-core-item-list {
  margin-bottom: 50px;
}

.gdlr-core-product-category-item .gdlr-core-product-category-read-more {
  font-size: 14px;
  text-transform: uppercase;
}

.gdlr-core-product-category-item .gdlr-core-product-category-read-more:after {
  content: "\f178";
  font-family: "FontAwesome";
  text-align: right;
  display: inline-block;
  margin-left: 10px;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 300ms, transform 300ms;
}

.gdlr-core-product-category-item
  .gdlr-core-product-category-read-more:hover:after {
  opacity: 1;
  transform: translateX(0);
}

.woocommerce span.onsale {
  color: #fff;
  border-radius: 100%;
  position: absolute;
  line-height: 48px;
  font-weight: 600;
  width: 48px;
  height: 48px;
  font-size: 13px;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail {
  position: relative;
  margin-bottom: 38px;
  overflow: hidden;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail-info {
  position: absolute;
  bottom: -58px;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  transition: opacity 200ms, bottom 200ms;
  -moz-transition: opacity 200ms, bottom 200ms;
  -o-transition: opacity 200ms, bottom 200ms;
  -webkit-transition: opacity 200ms, bottom 200ms;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail-info > a {
  float: left;
  width: 50%;
  height: 58px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  padding: 19px 10px 0;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail-info i {
  font-size: 16px;
  margin-right: 12px;
}

.gdlr-core-product-item.woocommerce
  .gdlr-core-product-thumbnail:hover
  .gdlr-core-product-thumbnail-info {
  opacity: 1;
  bottom: 0;
}

.gdlr-core-product-item.woocommerce
  .gdlr-core-product-thumbnail
  .gdlr-core-product-add-to-cart.added {
  display: none;
}

.gdlr-core-product-item.woocommerce
  .gdlr-core-product-thumbnail
  .added_to_cart:before {
  content: "\e015";
  font-size: 16px;
  margin-right: 12px;
  font-family: ElegantIcons;
  display: inline-block;
  vertical-align: text-top;
  margin-top: -2px;
}

.gdlr-core-product-item .gdlr-core-product-attributes {
  margin-bottom: 20px;
  font-size: 17px;
}

.gdlr-core-product-item .gdlr-core-product-att .gdlr-head {
  margin-right: 10px;
}

.gdlr-core-product-item .gdlr-core-product-att .gdlr-head:after {
  content: ":";
}

.gdlr-core-product-item .gdlr-core-product-att .gdlr-tail {
  font-weight: 700;
}

.gdlr-core-product-grid {
  margin-bottom: 60px;
}

.flexslider .gdlr-core-product-grid {
  margin-bottom: 0;
}

.gdlr-core-product-grid .gdlr-core-product-grid-content-wrap {
  text-align: center;
}

.gdlr-core-product-grid .gdlr-core-product-grid-content-wrap .onsale {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.gdlr-core-product-grid .gdlr-core-product-grid-content {
  display: inline-block;
  vertical-align: middle;
}

.gdlr-core-product-grid .gdlr-core-product-title {
  font-size: 17px;
  margin-bottom: 10px;
}

.gdlr-core-product-grid .gdlr-core-product-price {
  font-size: 15px;
  font-weight: 800;
}

.gdlr-core-product-grid .gdlr-core-product-price ins {
  text-decoration: none;
}

.gdlr-core-product-grid .gdlr-core-product-price del {
  margin-right: 9px;
  opacity: 0.5;
}

.related.products .gdlr-core-product-grid {
  margin-bottom: 15px;
  position: relative;
}

ul.gdlr-core-product-sub-category {
  margin-left: 0;
  list-style: none;
  margin-bottom: 0;
}

ul.gdlr-core-product-sub-category li {
  float: left;
  margin: 0 0 35px;
  padding: 0 15px;
  position: relative;
  width: 25%;
}

ul.gdlr-core-product-sub-category li h3 {
  font-size: 18px;
  margin-top: 24px;
  margin-bottom: 0;
}

.gdlr-core-hide-in-desktop,
.gdlr-core-hide-in-desktop-tablet {
  display: none;
}

.gdlr-core-product-grid-2 {
  margin-bottom: 60px;
}

.flexslider .gdlr-core-product-grid-2 {
  margin-bottom: 0;
}

.gdlr-core-product-grid-2 .gdlr-core-product-grid-content-wrap {
  text-align: center;
}

.gdlr-core-product-grid-2 .gdlr-core-product-grid-content {
  display: inline-block;
  vertical-align: middle;
}

.gdlr-core-product-grid-2 .gdlr-core-product-title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 8px;
}

.gdlr-core-product-grid-2 .gdlr-core-product-price {
  font-size: 18px;
  font-weight: 800;
}

.gdlr-core-product-grid-2 .gdlr-core-product-price ins {
  text-decoration: none;
}

.gdlr-core-product-grid-2 .gdlr-core-product-price del {
  margin-right: 9px;
  opacity: 0.5;
}

.woocommerce.gdlr-core-product-style-grid-2 .products .star-rating {
  margin-right: auto;
  margin-left: auto;
}

body
  .gdlr-core-product-item.woocommerce
  .gdlr-core-product-grid-2
  div.star-rating:before,
body
  .gdlr-core-product-item.woocommerce
  .gdlr-core-product-grid-2
  div.star-rating
  span:before {
  letter-spacing: 0.2em;
}

body
  .gdlr-core-product-item.woocommerce
  .gdlr-core-product-grid-2
  div.star-rating {
  margin-top: 10px;
  width: 6.35em;
  padding-right: 0;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-2
  .gdlr-core-product-thumbnail
  span.onsale {
  font-size: 15px;
  width: 70px;
  height: 70px;
  text-align: center;
  padding: 12px 0 0;
  left: 15px;
  top: 15px;
  z-index: 0;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-2
  .gdlr-core-product-thumbnail-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
}

.gdlr-core-product-item
  .gdlr-core-product-grid-2
  .gdlr-core-product-thumbnail-info
  > a {
  float: none;
  width: auto;
  height: auto;
  padding: 0;
  margin-top: -38px;
  font-size: 19px;
  font-weight: 700;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: auto;
  color: #0c0c0c;
  background: transparent;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-2
  .gdlr-core-product-thumbnail-info
  .icon_cart_alt:before {
  content: "\e013";
}

.gdlr-core-product-item
  .gdlr-core-product-grid-2
  .gdlr-core-product-thumbnail-info
  i {
  font-size: 34px;
  font-weight: 400;
  line-height: 34px;
  display: block;
  margin-bottom: 23px;
  margin-right: 0;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-2
  .gdlr-core-product-thumbnail-info
  > a.added_to_cart:before {
  font-size: 34px;
  font-weight: 400;
  line-height: 34px;
  display: block;
  margin-bottom: 25px;
  margin-right: 0;
}

.related.products .gdlr-core-product-grid-2 {
  margin-bottom: 15px;
  position: relative;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-2
  .gdlr-core-product-thumbnail-info.gdlr-core-with-price
  .amount {
  font-size: 24px;
  font-weight: 700;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-2
  .gdlr-core-product-thumbnail-info.gdlr-core-with-price
  .gdlr-core-product-price {
  margin-bottom: 20px;
  margin-top: 8px;
  letter-spacing: 0;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-2
  .gdlr-core-product-thumbnail-info.gdlr-core-with-price
  a {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.gdlr-core-product-grid-3 .gdlr-core-product-title {
  margin-bottom: 28px;
  text-align: left;
}

.gdlr-core-product-grid-3 .gdlr-core-product-grid-content {
  padding: 30px;
  background: #fff;
  border-style: solid;
  border-width: 0;
}

.gdlr-core-product-grid-3 .gdlr-core-product-price ins {
  text-decoration: none;
}

.gdlr-core-product-grid-3 .gdlr-core-product-price del {
  margin-right: 9px;
  opacity: 0.5;
}

.gdlr-core-product-grid-3 .gdlr-core-product-add-to-cart {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 1px;
  margin-right: 0;
}

.gdlr-core-product-grid-3 .gdlr-core-product-add-to-cart i {
  display: none;
}

.gdlr-core-product-item .gdlr-core-product-grid-3 .gdlr-core-product-thumbnail {
  margin-bottom: 0;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-grid-3 .star-rating {
  float: right;
  margin-top: 4px;
}

.gdlr-core-product-item.woocommerce
  .gdlr-core-product-grid-3
  .gdlr-core-product-price {
  float: left;
}

.gdlr-core-product-item.woocommerce
  .gdlr-core-product-grid-3
  .gdlr-core-product-grid-info {
  margin-top: -10px;
  margin-bottom: 5px;
}

.gdlr-core-product-grid-3.gdlr-core-button-style-border
  .gdlr-core-product-grid-content {
  text-align: center;
}

.gdlr-core-product-grid-3.gdlr-core-button-style-border
  .gdlr-core-product-add-to-cart {
  border-width: 2px;
  border-style: solid;
  padding: 14px 25px;
  display: inline-block;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

.gdlr-core-product-grid-3.gdlr-core-without-frame
  .gdlr-core-product-grid-content {
  padding: 30px 0 0;
  background: transparent;
}

.gdlr-core-product-grid-3.gdlr-core-with-border .gdlr-core-product-thumbnail {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-product-grid-3.gdlr-core-with-border .gdlr-core-product-add-to-cart {
  float: right;
}

.gdlr-core-product-grid-3 .gdlr-core-product-attributes {
  text-align: left;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail
  span.onsale {
  font-size: 15px;
  font-weight: 600;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  left: 15px;
  top: 15px;
  z-index: 0;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
}

.gdlr-core-product-item
  .gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info
  > a {
  float: none;
  width: auto;
  height: auto;
  padding: 0;
  margin-top: -38px;
  text-transform: none;
  letter-spacing: 0;
  font-size: 19px;
  font-weight: 700;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: auto;
  color: #0c0c0c;
  background: transparent;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info
  .icon_cart_alt:before {
  content: "\e013";
}

.gdlr-core-product-item
  .gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info
  i {
  font-size: 34px;
  font-weight: 400;
  line-height: 34px;
  display: block;
  margin-bottom: 23px;
  margin-right: 0;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info
  > a.added_to_cart:before {
  font-size: 34px;
  font-weight: 400;
  line-height: 34px;
  display: block;
  margin-bottom: 25px;
  margin-right: 0;
}

.gdlr-core-product-grid-3 .gdlr-core-product-thumbnail-info-modern {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 300ms;
}

.gdlr-core-product-grid-3 .gdlr-core-product-thumbnail-info-modern,
.gdlr-core-product-grid-3 .gdlr-core-product-thumbnail-info-modern a {
  color: #a39fab;
}

.gdlr-core-product-grid-3 .gdlr-core-product-thumbnail-info-modern a:hover {
  color: #473e67;
}

.gdlr-core-product-grid-3 .gdlr-core-product-thumbnail-info-modern-inner svg {
  max-width: 16px;
}

.gdlr-core-product-grid-3 .gdlr-core-product-thumbnail-info-modern-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.095);
}

.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern-inner
  > .yith-wcwl-add-to-wishlist,
.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern-inner
  > .gdlr-core-product-add-to-cart,
.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern-inner
  > .added_to_cart,
.gdlr-core-product-grid-3 .gdlr-core-product-thumbnail-info-modern-inner > a {
  order: 0;
  width: 42px;
  height: 42px;
  background: #fff;
  text-align: center;
  padding-top: 0;
  line-height: 42px;
  overflow: hidden;
  display: block;
}

.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern-inner
  > .yith-wcwl-add-to-wishlist,
.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern-inner
  > .added_to_cart,
.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern-inner
  > .gdlr-core-product-add-to-cart {
  border-right: 1px solid #e7e7e7;
}

.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern
  .gdlr-core-product-add-to-cart
  span {
  display: none;
}

.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern
  .gdlr-core-product-add-to-cart
  i {
  display: inline;
}

.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern
  .yith-wcwl-add-to-wishlist
  .feedback
  .yith-wcwl-icon {
  margin-right: 0;
}

.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern
  .yith-wcwl-add-to-wishlist,
.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern
  .gdlr-core-product-add-to-cart,
.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern
  .added_to_cart,
.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern
  .gdlr-core-product-thumbnail-link {
  font-size: 15px;
}

.gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail:hover
  .gdlr-core-product-thumbnail-info-modern {
  opacity: 1;
}

.gdlr-core-product-item.woocommerce
  .gdlr-core-product-grid-3
  .gdlr-core-product-thumbnail-info-modern
  .added_to_cart:before {
  margin-top: -1px;
  vertical-align: baseline;
  font-size: inherit;
  margin-right: auto;
  margin-left: auto;
}

.gdlr-core-product-grid-4 {
  margin-bottom: 40px;
}

.gdlr-core-product-grid-4 .gdlr-core-product-title {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 2px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: transparent;
  transition: border-color 300ms;
  -moz-transition: border-color 300ms;
  -o-transition: border-color 300ms;
  -webkit-transition: border-color 300ms;
}

.gdlr-core-product-item .gdlr-core-product-grid-4 .gdlr-core-product-thumbnail {
  margin-bottom: 20px;
}

.gdlr-core-product-item
  .gdlr-core-product-grid-4
  .gdlr-core-product-grid-content {
  text-align: center;
}

.gdlr-core-product-grid-5 {
  overflow: hidden;
}

.gdlr-core-product-grid-5 .gdlr-core-product-grid-content {
  position: relative;
  padding: 30px;
  background: #fff;
}

.gdlr-core-product-grid-5 .gdlr-core-product-title {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0;
}

.gdlr-core-product-grid-5 .gdlr-core-product-excerpt {
  margin-bottom: 16px;
}

.gdlr-core-product-grid-5 .gdlr-core-product-add-to-cart i {
  display: none;
}

.gdlr-core-product-grid-5 .gdlr-core-product-grid-info {
  margin: 30px -30px -30px;
  border-top-width: 1px;
  border-top-style: solid;
  padding: 20px 30px;
}

.gdlr-core-product-grid-5 .gdlr-core-rating {
  float: left;
}

.gdlr-core-product-grid-5 .gdlr-core-rating .star-rating {
  letter-spacing: 4px;
}

.gdlr-core-product-grid-5 .gdlr-core-rating-count {
  font-size: 13px;
}

.gdlr-core-product-grid-5 .gdlr-core-product-price {
  float: right;
}

.gdlr-core-product-grid-5 .gdlr-core-product-price .amount {
  font-size: 20px;
  font-weight: 600;
}

.gdlr-core-product-grid-5 .gdlr-core-product-price ins {
  text-decoration: none;
}

.gdlr-core-product-grid-5 .gdlr-core-product-price del .amount {
  font-size: 14px;
  font-weight: 400;
}

.gdlr-core-product-grid-5 .add_to_cart_button span {
  font-weight: 600;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-grid-5 .star-rating {
  margin-bottom: 2px;
}

.gdlr-core-product-item.woocommerce
  .gdlr-core-product-grid-5
  .star-rating
  span::before {
  color: #ffc457;
}

.gdlr-core-product-item.woocommerce
  .gdlr-core-product-grid-5
  .gdlr-core-product-thumbnail {
  margin-bottom: 0;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-grid-5 span.onsale {
  display: inline-block;
  padding: 9px 20px;
  width: auto;
  height: auto;
  line-height: 1;
  min-width: 0;
  min-height: 0;
  font-weight: 600;
  left: 50%;
  top: -16px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-product-item.woocommerce .star-rating {
  float: right;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1;
  font-size: 1em;
  width: 5.4em;
  font-family: star;
}

.gdlr-core-product-item.woocommerce .star-rating::before {
  content: "\73\73\73\73\73";
  color: #d3ced2;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
}

.gdlr-core-product-item.woocommerce .star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}

.gdlr-core-product-item.woocommerce .star-rating span::before {
  content: "\53\53\53\53\53";
  top: 0;
  position: absolute;
  left: 0;
}

.gdlr-core-product-item.woocommerce .gdlr-core-wc-out-of-stock {
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  background: #f3f3f3;
  padding: 7px 12px;
}

.gdlr-core-product-table-item .gdlr-core-product-thumbnail {
  max-width: 70px;
  border-width: 1px;
  border-style: solid;
  max-width: 60px;
  overflow: hidden;
  margin-right: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.gdlr-core-product-table-item .gdlr-core-item-list-inner {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  flex-wrap: wrap;
}

.gdlr-core-product-table-head-wrap {
  flex-grow: 1;
  flex-basis: 0%;
  display: flex;
  align-items: center;
}

.gdlr-core-product-table-head-wrap .gdlr-core-product-table-head {
  overflow: hidden;
}

.gdlr-core-product-table-head-wrap .gdlr-core-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.gdlr-core-product-table-head-wrap .gdlr-core-caption {
  font-size: 14px;
}

.gdlr-core-product-table-head-wrap .gdlr-core-caption span {
  margin-right: 12px;
  white-space: nowrap;
}

.gdlr-core-product-variation-table {
  flex-grow: 1;
  flex-basis: 0%;
  display: flex;
}

.gdlr-core-product-variation-table .gdlr-core-item {
  flex-grow: 1;
  flex-basis: 0%;
  white-space: nowrap;
  text-align: center;
}

.gdlr-core-product-variation-table .gdlr-core-item > div {
  padding: 0 5px;
}

.gdlr-core-product-variation-table .gdlr-core-item .amount {
  font-size: 15px;
}

.gdlr-core-product-table-item .gdlr-core-learn-more {
  font-weight: 500;
  margin-left: 40px;
}

.gdlr-core-product-table-item .gdlr-core-now-loading {
  position: absolute;
  /* background: url(../../../../../../../../../../plugins/goodlayers-core/include/images/portfolio/now-loading.gif) center no-repeat; */
  width: 55px;
  height: 55px;
  left: 50%;
  margin-left: -28px;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-product-table-item span.woocommerce-Price-amount.amount {
  color: inherit;
}

@media only screen and (max-width: 999px) {
  .gdlr-core-product-table-head-wrap {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }

  .gdlr-core-product-table-item .gdlr-core-product-thumbnail {
    margin-right: 0;
  }

  .gdlr-core-product-table-item .gdlr-core-learn-more {
    margin-left: 0;
  }

  .gdlr-core-product-variation-table {
    text-align: center;
    flex-wrap: wrap;
  }

  .gdlr-core-product-table-item .gdlr-core-item-list-inner {
    flex-direction: column;
  }

  .gdlr-core-product-table-head-wrap .gdlr-core-title {
    margin-bottom: 20px;
  }

  .gdlr-core-product-table-head-wrap .gdlr-core-caption {
    margin-bottom: 7px;
  }

  .gdlr-core-product-table-item .gdlr-core-learn-more {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .gdlr-core-product-table-item .gdlr-core-item-list-inner {
    margin-bottom: 38px;
  }
}

.gdlr-core-product-grid-6 {
  text-align: center;
}

.gdlr-core-product-grid-6 .gdlr-core-product-thumbnail {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.gdlr-core-product-grid-6 .gdlr-core-product-title {
  font-size: 22px;
  margin-bottom: 8px;
}

.gdlr-core-product-grid-6 .gdlr-core-product-price {
  font-weight: 600;
  margin-bottom: 15px;
}

.gdlr-core-product-grid-6 .gdlr-core-rating-count {
  display: none;
}

.gdlr-core-product-grid-6 .gdlr-core-rating {
  margin-bottom: 16px;
  padding-left: 0.4em;
}

.gdlr-core-product-grid-6 .gdlr-core-product-add-to-cart i,
.gdlr-core-product-grid-6 .add_to_cart_button i {
  margin-right: 10px;
  display: none;
}

.gdlr-core-product-grid-6 .gdlr-core-product-price ins {
  text-decoration: none;
}

.woocommerce .gdlr-core-product-grid-6 .star-rating {
  padding-left: 0.4px;
  float: none;
  margin: 0 auto;
  width: 7.3em;
  letter-spacing: 0.4em;
}

.woocommerce .gdlr-core-product-grid-6 span.onsale {
  width: 70px;
  height: 70px;
  line-height: 70px;
  left: 20px;
  top: 20px;
  font-size: 16px;
  font-weight: 500;
}

.woocommerce .products .gdlr-core-product-grid-6 .star-rating {
  margin-left: auto;
  margin-right: auto;
  width: 6.8em;
  letter-spacing: 0.3em;
}

.gdlr-core-product-box:hover {
  background: #223439;
}

.gdlr-core-product-box:hover .gdlr-core-product-title a {
  color: #fff;
}

.gdlr-core-product-box:hover .gdlr-core-product-attribute {
  color: #5c972b;
}

.gdlr-core-product-box {
  background: #f5f8f2;
}

.gdlr-core-product-box .gdlr-core-product-title a {
  color: #223439;
}

.gdlr-core-product-box .gdlr-core-product-attribute {
  color: #9b9b9b;
}

.gdlr-core-product-box {
  text-align: center;
  padding: 25px 20px;
  position: relative;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  transition: background 300ms;
  -webkit-transition: background 300ms;
  -moz-transition: background 300ms;
}

.gdlr-core-product-box .gdlr-core-head {
  margin-right: 8px;
}

.gdlr-core-product-box .gdlr-core-product-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gdlr-core-product-box .gdlr-core-product-title {
  font-size: 20px;
}

.gdlr-core-product-box span.woocommerce-Price-amount.amount {
  font-size: inherit;
  font-weight: inherit;
  vertical-align: baseline;
}

.gdlr-core-product-box .gdlr-core-product-attribute {
  margin-bottom: 5px;
  transition: color 300ms;
  -webkit-transition: color 300ms;
  -moz-transition: color 300ms;
}

.gdlr-core-product-box span.woocommerce-Price-amount.amount,
.gdlr-core-product-box:hover span.woocommerce-Price-amount.amount {
  color: inherit;
}

.gdlr-core-product-box span.woocommerce-Price-amount.amount {
  margin-bottom: 5px;
}

.gdlr-core-body .gdlr-core-course-form input,
.gdlr-core-course-form .gdlr-core-course-form-combobox {
  background: #3b4b6b;
}

.gdlr-core-body .gdlr-core-course-form input,
.gdlr-core-course-form .gdlr-core-course-form-combobox select,
.gdlr-core-course-form .gdlr-core-course-form-combobox:after {
  color: #b1c0e0;
}

.gdlr-core-body .gdlr-core-course-form input[type="submit"] {
  background: #50bd77;
}

.gdlr-core-course-form input::-webkit-input-placeholder {
  color: #b1c0e0;
}

.gdlr-core-course-form input::-moz-placeholder {
  color: #b1c0e0;
}

.gdlr-core-course-form input:-ms-input-placeholder {
  color: #b1c0e0;
}

.gdlr-core-course-form input:-moz-placeholder {
  color: #b1c0e0;
}

.gdlr-core-course-form input {
  font-size: 14px;
  padding: 18px 22px;
  width: 100%;
  border: 0;
  line-height: 1.7;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-course-form .gdlr-core-course-form-combobox {
  position: relative;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-course-form .gdlr-core-course-form-combobox:after {
  font-family: fontAwesome;
  content: "\f107";
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -10px;
  pointer-events: none;
}

.gdlr-core-course-form .gdlr-core-course-form-combobox select {
  font-size: 14px;
  padding: 18px 22px;
  width: 100%;
  border: 0;
  height: auto;
  line-height: 1.7;
  background: transparent;
}

.gdlr-core-course-form .gdlr-core-course-form-combobox select,
.gdlr-core-course-form .gdlr-core-course-form-combobox select:focus {
  outline: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.gdlr-core-course-form input[type="submit"] {
  font-size: 15px;
  color: #fff;
  letter-spacing: 0;
  width: 100%;
}

.gdlr-core-course-form input[type="submit"].gdlr-core-auto-size {
  width: auto;
}

.gdlr-core-course-search-item .gdlr-core-search-frame {
  padding: 40px 35px;
  background: #f3f3f3;
  background-size: cover;
  background-position: center;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.gdlr-core-course-search-item .gdlr-core-course-search-item-title {
  font-size: 19px;
  margin-bottom: 35px;
}

.gdlr-core-course-search-item .gdlr-core-course-form {
  margin-left: -7px;
  margin-right: -7px;
}

.gdlr-core-course-search-item .gdlr-core-course-column {
  padding-left: 7px;
  padding-right: 7px;
}

.gdlr-core-course-search-item .gdlr-core-course-search-field {
  margin-bottom: 14px;
}

.gdlr-core-course-search-item .gdlr-core-course-form-submit {
  padding-top: 8px;
}

.gdlr-core-course-search-page-content-wrap {
  float: right;
  margin-bottom: 50px;
}

.gdlr-core-course-search-page {
  padding-top: 60px;
  padding-bottom: 40px;
}

.gdlr-core-course-archive-page-content-wrap {
  float: right;
}

.gdlr-core-course-archive-page {
  padding-top: 60px;
  padding-bottom: 40px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 999px) {
  .gdlr-core-hide-in-desktop {
    display: block;
  }

  .gdlr-core-hide-in-tablet,
  .gdlr-core-hide-in-tablet-mobile {
    display: none;
  }

  .gdlr-core-tablet-column-60 {
    width: 100%;
  }

  .gdlr-core-flexslider-nav,
  .gdlr-core-flexslider .flex-direction-nav,
  .gdlr-core-flexslider-custom-nav {
    display: none;
  }

  .gdlr-core-flexslider[data-nav="navigation-bottom"] .flex-direction-nav {
    display: block;
  }

  .gdlr-core-testimonial-style-left-2 .gdlr-core-testimonial-author-image {
    float: none;
    margin: 0 auto 30px;
  }
}

@media only screen and (max-width: 767px) {
  .gdlr-core-hide-in-desktop-tablet {
    display: block;
  }

  .gdlr-core-hide-in-mobile,
  .gdlr-core-hide-in-tablet-mobile {
    display: none;
  }

  .gdlr-core-hide-in-tablet {
    display: block;
  }

  body .gdlr-core-container {
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
  }

  body .gdlr-core-container-custom {
    max-width: 500px !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .gdlr-core-pbf-wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .gdlr-core-pbf-wrapper-full {
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .gdlr-core-pbf-column-content-margin {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .gdlr-core-page-builder-column-right-divider {
    display: none;
  }

  .gdlr-core-pbf-wrapper-full-no-space {
    max-width: 500px;
  }

  .gdlr-core-pbf-wrapper-full-no-space .gdlr-core-pbf-column-content-margin {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .gdlr-core-pbf-background-frame {
    display: none;
  }

  .gdlr-core-pbf-background {
    background-attachment: inherit !important;
  }

  .gdlr-core-pbf-sidebar-wrapper {
    margin: 0px !important;
    overflow: visible;
  }

  .gdlr-core-pbf-sidebar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .gdlr-core-column-extend-left > .gdlr-core-pbf-background-wrap {
    margin-left: -30px;
    margin-right: -30px;
  }

  .gdlr-core-column-extend-right > .gdlr-core-pbf-background-wrap {
    margin-left: -30px;
    margin-right: -30px;
  }

  .gdlr-core-column-10,
  .gdlr-core-column-12,
  .gdlr-core-column-15,
  .gdlr-core-column-20,
  .gdlr-core-column-24,
  .gdlr-core-column-30,
  .gdlr-core-column-36,
  .gdlr-core-column-40,
  .gdlr-core-column-45,
  .gdlr-core-column-48,
  .gdlr-core-column-50,
  .gdlr-core-column-60 {
    width: 100%;
  }

  .gdlr-core-pbf-column-content {
    min-height: 0;
  }

  .gdlr-core-pbf-sidebar-content,
  .gdlr-core-pbf-sidebar-right,
  .gdlr-core-pbf-sidebar-left {
    height: auto !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .gdlr-core-audio {
    padding: 13px 3px 7px;
  }

  .gdlr-core-call-to-action-item.gdlr-core-style-left-align-right-button
    .gdlr-core-call-to-action-item-inner {
    padding-right: 0;
  }

  .gdlr-core-call-to-action-item.gdlr-core-style-left-align-right-button
    .gdlr-core-call-to-action-item-button {
    position: static;
    margin-top: 45px;
  }

  .gdlr-core-call-to-action-item.gdlr-core-style-right-align-left-button
    .gdlr-core-call-to-action-item-inner {
    padding-left: 0;
  }

  .gdlr-core-call-to-action-item.gdlr-core-style-right-align-left-button
    .gdlr-core-call-to-action-item-button {
    position: static;
    margin-top: 45px;
  }

  .gdlr-core-block-item-title-wrap.gdlr-core-left-align
    .gdlr-core-block-item-title {
    display: block;
  }

  .gdlr-core-block-item-title-wrap.gdlr-core-left-align .gdlr-core-separator {
    display: none;
  }

  .gdlr-core-block-item-title-wrap .gdlr-core-block-item-read-more {
    margin-top: 14px;
    display: inline-block;
  }

  .gdlr-core-pbf-wrapper-full-no-space .gdlr-core-block-item-title-wrap {
    margin-left: 0;
    margin-right: 0;
  }

  .gdlr-core-pbf-wrapper-full-no-space .gdlr-core-pagination {
    padding-left: 0;
    padding-right: 0;
  }

  .gdlr-core-social-share-left-text .gdlr-core-social-share-count {
    display: block;
    margin-bottom: 10px;
  }

  .gdlr-core-social-share-left-text .gdlr-core-divider {
    display: none;
  }

  .gdlr-core-social-share-left-text {
    text-align: center;
  }

  .gdlr-core-social-share-right-text .gdlr-core-social-share-count {
    display: block;
    margin-top: 10px;
  }

  .gdlr-core-social-share-right-text .gdlr-core-divider {
    display: none;
  }

  .gdlr-core-social-share-right-text {
    text-align: center;
  }

  .gdlr-core-blog-date-wrapper {
    display: none;
  }

  .gdlr-core-blog-medium,
  .gdlr-core-blog-medium > div,
  .gdlr-core-blog-medium > div.clearfix {
    display: block;
  }

  .gdlr-core-blog-left-thumbnail .gdlr-core-blog-thumbnail-wrap {
    width: auto;
    padding-right: 0;
    margin-bottom: 25px;
  }

  .gdlr-core-blog-right-thumbnail .gdlr-core-blog-thumbnail-wrap {
    width: auto;
    padding-left: 0;
    margin-bottom: 25px;
  }

  .gdlr-core-blog-left-thumbnail.gdlr-core-large .gdlr-core-blog-thumbnail-wrap,
  .gdlr-core-blog-right-thumbnail.gdlr-core-large
    .gdlr-core-blog-thumbnail-wrap {
    width: 100%;
  }

  .gdlr-core-blog-medium.gdlr-core-blog-right-thumbnail {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .gdlr-core-blog-medium.gdlr-core-blog-right-thumbnail
    .gdlr-core-blog-thumbnail-wrap {
    order: 1;
    width: 100%;
  }

  .gdlr-core-blog-medium.gdlr-core-blog-right-thumbnail
    .gdlr-core-blog-medium-content-wrapper {
    order: 2;
    width: 100%;
  }

  .gdlr-core-portfolio-medium {
    display: flex;
    flex-flow: column;
  }

  .gdlr-core-portfolio-medium .gdlr-core-portfolio-thumbnail-wrap {
    order: 1;
    -ms-order: 1;
  }

  .gdlr-core-portfolio-medium .gdlr-core-portfolio-content-wrap {
    order: 2;
    -ms-order: 1;
  }

  .gdlr-core-portfolio-medium.gdlr-core-size-large
    .gdlr-core-portfolio-thumbnail-wrap,
  .gdlr-core-portfolio-medium.gdlr-core-size-small
    .gdlr-core-portfolio-thumbnail-wrap {
    display: block;
    width: auto;
    margin-bottom: 35px;
  }

  .gdlr-core-portfolio-medium.gdlr-core-style-left
    .gdlr-core-portfolio-content-wrap {
    padding-left: 0;
    display: block;
  }

  .gdlr-core-portfolio-medium-feature {
    margin-right: 0;
    margin-left: 0;
    padding: 0;
  }

  .gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-content-wrap {
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }

  .gdlr-core-portfolio-medium-feature .gdlr-core-item-list-inner {
    display: block;
  }

  .gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-thumbnail-wrap {
    display: block;
  }

  .gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-thumbnail {
    margin-top: 0;
    margin-bottom: 0;
  }

  .gdlr-core-portfolio-medium-feature:hover .gdlr-core-portfolio-thumbnail {
    margin-top: 0;
    margin-bottom: 0;
  }

  .gdlr-core-tab-item .gdlr-core-tab-item-content {
    padding: 35px 0 0;
  }

  .gdlr-core-tab-style1-vertical.gdlr-core-left-align
    .gdlr-core-tab-item-title-wrap {
    float: none;
    border-right: 0;
    max-width: none;
  }

  .gdlr-core-tab-style1-vertical.gdlr-core-left-align
    .gdlr-core-tab-item-title {
    float: left;
    height: 52px;
    padding-left: 25px;
    padding-right: 25px;
    border-top-width: 1px;
  }

  .gdlr-core-tab-style1-vertical.gdlr-core-left-align
    .gdlr-core-tab-item-title.gdlr-core-active {
    padding-right: 25px;
  }

  .gdlr-core-tab-style1-vertical.gdlr-core-left-align
    .gdlr-core-tab-item-title:last-child {
    border-right-width: 1px;
  }

  .gdlr-core-tab-style1-vertical.gdlr-core-left-align
    .gdlr-core-tab-item-content-wrap {
    border: none;
  }

  .gdlr-core-tab-style1-vertical.gdlr-core-right-align
    .gdlr-core-tab-item-title-wrap {
    float: none;
    border-left: 0;
    max-width: none;
  }

  .gdlr-core-tab-style1-vertical.gdlr-core-right-align
    .gdlr-core-tab-item-title {
    float: right;
    height: 52px;
    padding-left: 25px;
    padding-right: 25px;
    border-top-width: 1px;
  }

  .gdlr-core-tab-style1-vertical.gdlr-core-right-align
    .gdlr-core-tab-item-title.gdlr-core-active {
    padding-right: 25px;
  }

  .gdlr-core-tab-style1-vertical.gdlr-core-right-align
    .gdlr-core-tab-item-title:last-child {
    border-left-width: 1px;
  }

  .gdlr-core-tab-style1-vertical.gdlr-core-right-align
    .gdlr-core-tab-item-content-wrap {
    border: none;
  }

  .gdlr-core-tab-style2-vertical.gdlr-core-left-align
    .gdlr-core-tab-item-title-wrap {
    float: none;
    margin-right: 0;
    border: none;
    max-width: none;
  }

  .gdlr-core-tab-style2-vertical.gdlr-core-left-align
    .gdlr-core-tab-item-title-line {
    display: none;
  }

  .gdlr-core-tab-style2-vertical.gdlr-core-left-align
    .gdlr-core-tab-item-title {
    padding: 12px 20px 0 0;
    float: left;
  }

  .gdlr-core-tab-style2-vertical.gdlr-core-left-align
    .gdlr-core-tab-item-content-wrap {
    border: none;
  }

  .gdlr-core-tab-style2-vertical.gdlr-core-right-align
    .gdlr-core-tab-item-title-wrap {
    float: none;
    margin-left: 0;
    border: none;
    max-width: none;
  }

  .gdlr-core-tab-style2-vertical.gdlr-core-right-align
    .gdlr-core-tab-item-title-line {
    display: none;
  }

  .gdlr-core-tab-style2-vertical.gdlr-core-right-align
    .gdlr-core-tab-item-title {
    padding: 12px 0 0 20px;
    float: right;
  }

  .gdlr-core-tab-style2-vertical.gdlr-core-right-align
    .gdlr-core-tab-item-content-wrap {
    border: none;
  }

  .gdlr-core-gallery-item-style-scroll .gdlr-core-media-image {
    max-height: 200px;
  }

  .gdlr-core-gallery-with-thumbnail-wrap.gdlr-core-inside-slider
    .gdlr-core-image-overlay-content {
    bottom: 35px;
  }

  .gdlr-core-gallery-with-thumbnail-wrap.gdlr-core-inside-slider
    .gdlr-core-sly-slider {
    display: none;
  }

  .gdlr-core-price-table-item .gdlr-core-price-table {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .gdlr-core-price-table-item
    .gdlr-core-price-table-column:last-child
    .gdlr-core-price-table {
    margin-bottom: 0;
  }

  .gdlr-core-title-item.gdlr-core-left-align .gdlr-core-title-item-link {
    position: static;
    margin-top: 12px;
  }

  .gdlr-core-title-item-caption-right .gdlr-core-title-item-title-wrap {
    float: none;
    margin-bottom: 13px;
    margin-right: 0;
    width: auto !important;
  }

  .gdlr-core-newsletter-item.gdlr-core-style-round .gdlr-core-newsletter-email {
    width: 100%;
    float: none;
  }

  .gdlr-core-newsletter-item.gdlr-core-style-round
    .gdlr-core-newsletter-submit {
    width: 100%;
    float: none;
    margin-top: 17px;
  }

  .gdlr-core-newsletter-item.gdlr-core-style-rectangle
    .gdlr-core-newsletter-email {
    float: none;
    width: 100%;
    margin-bottom: 10px;
  }

  .gdlr-core-newsletter-item.gdlr-core-style-round2
    .gdlr-core-newsletter-email {
    float: none;
    padding-right: 0;
  }

  .gdlr-core-newsletter-item.gdlr-core-style-round2
    .gdlr-core-newsletter-email:before {
    top: 30px;
  }

  .gdlr-core-newsletter-item.gdlr-core-style-round2
    .gdlr-core-newsletter-submit {
    position: relative;
    width: 100%;
    float: none;
  }

  .gdlr-core-newsletter-item.gdlr-core-style-round2
    .gdlr-core-newsletter-submit
    input[type="submit"] {
    width: 100%;
  }

  .gdlr-core-timeline-item .gdlr-core-timeline-item-bullet {
    display: none;
  }

  .gdlr-core-timeline-item .gdlr-core-timeline-item-date {
    width: auto !important;
    padding-right: 0;
    float: none;
    text-align: left;
  }

  .gdlr-core-roadmap-item {
    margin-right: -20px;
    margin-left: -20px;
  }

  .gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-content {
    margin-right: 20px;
  }

  .gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-count {
    display: none;
  }

  .gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-divider {
    display: none;
  }

  .gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-title {
    font-size: 11px;
    text-transform: none;
    letter-spacing: 0;
  }

  .gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-caption {
    font-size: 11px;
  }

  .gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-wrap {
    width: 37%;
  }

  .gdlr-core-roadmap-item .gdlr-core-roadmap-item-content-area {
    margin-left: 0;
  }

  .gdlr-core-roadmap-item .gdlr-core-roadmap-item-head {
    padding-bottom: 16px;
  }

  .gdlr-core-roadmap-item .gdlr-core-roadmap-item-content {
    font-size: 15px;
  }

  .gdlr-core-roadmap-item .gdlr-core-roadmap-item-content-title {
    font-size: 15px;
    margin-bottom: 29px;
  }

  .gdlr-core-personnel-style-medium .gdlr-core-personnel-list-image {
    float: none;
    margin-right: 0;
    max-width: 100%;
  }

  .gdlr-core-instagram-widget > div {
    float: left;
    width: auto;
    max-width: 120px;
    clear: none;
  }

  .gdlr-core-tab-feature-item .gdlr-core-tab-feature-size-2,
  .gdlr-core-tab-feature-item .gdlr-core-tab-feature-size-3,
  .gdlr-core-tab-feature-item .gdlr-core-tab-feature-size-4 {
    float: none;
    width: auto;
    padding: 20px 0;
  }

  .gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-wrap {
    padding: 40px 30px;
  }

  .gdlr-core-tab-feature-item .gdlr-core-tab-feature-content-left {
    float: none;
    width: auto;
    margin-bottom: 30px;
  }

  .gdlr-core-tab-feature-item .gdlr-core-tab-feature-title-image {
    display: none;
  }

  .gdlr-core-tab-feature-item .gdlr-core-tab-feature-button {
    margin-bottom: 15px;
  }

  .gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-wrap {
    padding-left: 0;
  }

  .gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-item-wrap {
    width: auto;
    float: none;
  }

  .gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-item-title-wrap {
    padding-top: 30px;
    padding-left: 0;
    text-align: center;
  }

  .gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-title-divider {
    display: none;
  }

  .gdlr-core-tab-featurev-item .gdlr-core-tab-featurev-content-wrap {
    padding: 50px 30px;
  }

  .gdlr-core-filterer-wrap {
    margin-top: 0px !important;
  }

  .gdlr-core-filterer-wrap.gdlr-core-right-align {
    text-align: center;
  }

  .gdlr-core-filterer-wrap .gdlr-core-filterer-slide-bar {
    display: none !important;
  }

  .gdlr-core-price-list-item .gdlr-core-price-list-divider {
    display: none;
  }

  .gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-bottom-slide-bar {
    display: none;
  }

  .gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-content-right {
    float: none;
    padding-left: 0;
    width: auto;
    margin-bottom: 30px;
  }

  .gdlr-core-tab-feature3-item .gdlr-core-tab-feature3-content {
    padding-top: 0px !important;
  }

  .gdlr-core-countdown-wrap .gdlr-core-countdown-block {
    margin-bottom: 30px;
  }

  .gdlr-core-price-table-item .gdlr-core-price-table-column {
    margin-bottom: 30px;
  }

  .gdlr-core-testimonial-style-image-left .gdlr-core-testimonial-author-image {
    width: auto;
    margin-right: 0;
  }

  .gdlr-core-featured-gallery-images {
    flex-direction: column;
  }

  .gdlr-core-featured-gallery-images > .gdlr-core-column-20 {
    height: 300px;
    width: auto;
  }

  .gdlr-core-testimonial-style-left-3 .gdlr-core-testimonial-author-image {
    float: none;
    margin: 0 auto 20px;
  }

  .gdlr-core-testimonial-style-left-3 .gdlr-core-testimonial-content-head-wrap {
    flex-direction: column;
    text-align: center;
  }
}

@media only screen and (max-width: 419px) {
  .gdlr-core-alert-box-item .gdlr-core-alert-box-item-inner {
    padding: 25px 25px;
  }

  .gdlr-core-blockquote-item.gdlr-core-small-size.gdlr-core-left-align
    .gdlr-core-blockquote-item-quote {
    font-size: 60px;
    margin-right: 20px;
  }

  .gdlr-core-blockquote-item.gdlr-core-small-size.gdlr-core-right-align
    .gdlr-core-blockquote-item-quote {
    font-size: 60px;
    margin-left: 20px;
  }

  .gdlr-core-blockquote-item.gdlr-core-medium-size.gdlr-core-left-align
    .gdlr-core-blockquote-item-quote {
    margin-right: 20px;
    font-size: 80px;
  }

  .gdlr-core-blockquote-item.gdlr-core-medium-size.gdlr-core-right-align
    .gdlr-core-blockquote-item-quote {
    font-size: 80px;
    margin-left: 20px;
  }

  .gdlr-core-blockquote-item.gdlr-core-large-size.gdlr-core-left-align
    .gdlr-core-blockquote-item-quote {
    font-size: 90px;
    margin-right: 20px;
  }

  .gdlr-core-blockquote-item.gdlr-core-large-size.gdlr-core-right-align
    .gdlr-core-blockquote-item-quote {
    font-size: 90px;
    margin-left: 20px;
  }
}

body.ilightbox-noscroll {
  overflow: auto;
}

.gdlr-core-privacy-box-wrap {
  color: #a3a3a3;
  background: #1d1d1d;
}

.gdlr-core-body .gdlr-core-privacy-box-wrap .gdlr-core-privacy-box-button {
  color: #fff;
}

.gdlr-core-body .gdlr-core-privacy-box-wrap .gdlr-core-privacy-box-button {
  background: #1a49a8;
}

.gdlr-core-privacy-box-wrap .gdlr-core-privacy-box-lb,
.gdlr-core-privacy-box-wrap .gdlr-core-privacy-box-lb:hover {
  color: #fff;
}

.gdlr-core-privacy-box-wrap {
  font-size: 14px;
  z-index: 99;
}

.gdlr-core-privacy-box-wrap .gdlr-core-privacy-box-button {
  font-weight: 700;
  padding: 11px 28px;
  display: inline-block;
  vertical-align: middle;
  text-transform: none;
  border-radius: 22px;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
}

.gdlr-core-privacy-box-wrap
  .gdlr-core-privacy-box-action
  .gdlr-core-privacy-box-lb {
  margin-left: 20px;
}

.gdlr-core-privacy-box-wrap.gdlr-core-pos-top-bar {
  padding: 20px 35px;
}

.gdlr-core-privacy-box-wrap.gdlr-core-pos-top-bar .gdlr-core-privacy-box-text {
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.gdlr-core-privacy-box-wrap.gdlr-core-pos-top-bar
  .gdlr-core-privacy-box-action {
  float: right;
}

.gdlr-core-privacy-box-wrap.gdlr-core-pos-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 35px;
}

.gdlr-core-privacy-box-wrap.gdlr-core-pos-bottom-bar
  .gdlr-core-privacy-box-text {
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.gdlr-core-privacy-box-wrap.gdlr-core-pos-bottom-bar
  .gdlr-core-privacy-box-action {
  float: right;
}

.gdlr-core-privacy-box-wrap.gdlr-core-pos-bottom-left {
  position: fixed;
  bottom: 35px;
  left: 35px;
  max-width: 425px;
  padding: 30px 35px;
}

.gdlr-core-privacy-box-wrap.gdlr-core-pos-bottom-left
  .gdlr-core-privacy-box-text {
  margin-bottom: 20px;
}

.gdlr-core-privacy-box-wrap.gdlr-core-pos-bottom-right {
  position: fixed;
  bottom: 35px;
  right: 35px;
  max-width: 425px;
  padding: 30px 35px;
}

.gdlr-core-privacy-box-wrap.gdlr-core-pos-bottom-right
  .gdlr-core-privacy-box-text {
  margin-bottom: 20px;
}

.gdlr-core-lightbox-wrapper {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background: #000;
  background: rgba(0, 0, 0, 0.7);
}

.gdlr-core-lightbox-wrapper .gdlr-core-lightbox-row {
  display: table;
  width: 100%;
  height: 100%;
}

.gdlr-core-lightbox-wrapper .gdlr-core-lightbox-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 30px;
}

.gdlr-core-lightbox-wrapper .gdlr-core-lightbox-content {
  background: #fff;
  color: #9b9b9b;
  margin: 30px auto;
  max-width: 970px;
  position: relative;
  max-height: calc(100% - 60px);
  overflow: auto;
}

.gdlr-core-lightbox-wrapper .gdlr-core-lightbox-form-close {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  background: #efefef;
  padding: 25px 15px;
  cursor: pointer;
}

.gdlr-core-lightbox-wrapper .gdlr-core-lightbox-form-close:before {
  content: " ";
  display: block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  width: 20px;
  border-color: #444;
  transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.gdlr-core-lightbox-wrapper .gdlr-core-lightbox-form-close:after {
  content: " ";
  display: block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  width: 20px;
  border-color: #444;
  margin-top: -1px;
  transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.gdlr-core-gdpr-form-left .gdlr-core-gdlr-form-nav li {
  color: #8c8c8c;
}

.gdlr-core-gdpr-form-left .gdlr-core-gdlr-form-nav li.gdlr-core-active {
  color: #000;
}

.gdlr-core-gdpr-form-left .gdlr-core-gdlr-form-nav li:before {
  content: "\f178";
  font-family: FontAwesome;
  display: inline-block;
  width: 0;
  color: transparent;
  transition: width 300ms, color 300ms;
  -moz-transition: width 300ms, color 300ms;
  -o-transition: width 300ms, color 300ms;
  -webkit-transition: width 300ms, color 300ms;
}

.gdlr-core-gdpr-form-left .gdlr-core-gdlr-form-nav li:hover:before {
  width: 26px;
  color: #8c8c8c;
}

.gdlr-core-gdpr-form-left .gdlr-core-gdlr-form-nav li.gdlr-core-active:before {
  width: 26px;
  color: #000;
}

.gdlr-core-body .gdlr-core-gdpr-form-submit input[type="submit"] {
  background: #1a49a8;
}

.gdlr-core-gdpr-form-checkbox-appearance {
  background: #dcdcdc;
}

.gdlr-core-gdpr-form-checkbox-appearance .gdlr-core-gdpr-form-checkbox-button {
  background: #828282;
}

.gdlr-core-gdpr-form-checkbox-text {
  color: #bbb;
}

.gdlr-core-gdpr-form-checkbox:checked
  + .gdlr-core-gdpr-form-checkbox-appearance
  .gdlr-core-gdpr-form-checkbox-button {
  background-color: #1a49a8;
}

.gdlr-core-gdpr-form-checkbox:checked
  + .gdlr-core-gdpr-form-checkbox-appearance
  + .gdlr-core-gdpr-form-checkbox-text,
.gdlr-core-gdpr-form-checkbox-required
  + .gdlr-core-gdpr-form-checkbox-appearance
  + .gdlr-core-gdpr-form-checkbox-text {
  color: #1a49a8;
}

#gdlr-core-gdpr-form {
  overflow: hidden;
}

.gdlr-core-gdpr-form-left {
  float: left;
  padding-top: 60px;
  padding-left: 40px;
  border-right-width: 1px;
  border-right-style: solid;
  border-color: #e6e6e6;
  padding-bottom: 1059px;
  margin-bottom: -999px;
}

.gdlr-core-gdpr-form-left .gdlr-core-gdlr-form-left-title {
  font-size: 26px;
  font-weight: 800;
  margin-bottom: 25px;
  color: #383838;
}

.gdlr-core-gdpr-form-left .gdlr-core-gdlr-form-nav {
  margin-left: 0;
  list-style: none;
  width: 235px;
}

.gdlr-core-gdpr-form-left .gdlr-core-gdlr-form-nav li {
  padding-bottom: 12px;
  padding-right: 20px;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #e6e6e6;
  transition: color 200ms;
  -webkit-transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
}

.gdlr-core-gdpr-form-right {
  padding-left: 50px;
  padding-top: 60px;
  padding-right: 30px;
  overflow-y: scroll;
  max-height: 700px;
}

.gdlr-core-gdpr-form-right .gdlr-core-gdpr-form-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 25px;
  color: #383838;
}

.gdlr-core-gdpr-form-right .gdlr-core-gdpr-form-content-wrap {
  display: none;
}

.gdlr-core-gdpr-form-right .gdlr-core-gdpr-form-content-wrap.gdlr-core-active {
  display: block;
}

.gdlr-core-gdpr-form-right .gdlr-core-gdpr-form-content {
  margin-bottom: 30px;
}

.gdlr-core-gdpr-form-right .gdlr-core-gdpr-form-submit {
  margin-top: 60px;
  margin-bottom: 35px;
  text-align: right;
}

.gdlr-core-gdpr-form-submit input[type="submit"] {
  font-size: 14px;
  text-transform: none;
  font-weight: 600;
  padding: 15px 28px 17px;
  letter-spacing: 0;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
}

.gdlr-core-gdpr-form-checkbox-item label {
  display: inline-block;
}

.gdlr-core-gdpr-form-checkbox-item input {
  display: none;
}

.gdlr-core-gdpr-form-checkbox-appearance {
  display: inline-block;
  vertical-align: middle;
  height: 28px;
  width: 50px;
  overflow: hidden;
  border-radius: 14px;
  -moz-border-radius: 14px;
  -webkit-border-radius: 14px;
  cursor: pointer;
}

.gdlr-core-gdpr-form-checkbox-appearance .gdlr-core-gdpr-form-checkbox-button {
  height: 22px;
  width: 22px;
  margin: 3px;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  transition: margin-left 200ms, background-color 200ms;
  -webkit-transition: margin-left 200ms, background-color 200ms;
  -moz-transition: margin-left 200ms, background-color 200ms;
  -o-transition: margin-left 200ms, background-color 200ms;
}

.gdlr-core-gdpr-form-checkbox-text {
  display: inline-block;
  margin-left: 17px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  transition: color 200ms;
  -webkit-transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
}

.gdlr-core-gdpr-form-checkbox-text .gdlr-core-enable {
  display: none;
}

.gdlr-core-gdpr-form-checkbox-text .gdlr-core-enable-required {
  display: none;
}

.gdlr-core-gdpr-form-checkbox-text .gdlr-core-disable {
  display: inline;
}

.gdlr-core-gdpr-form-checkbox:checked
  + .gdlr-core-gdpr-form-checkbox-appearance
  .gdlr-core-gdpr-form-checkbox-button,
.gdlr-core-gdpr-form-checkbox-required
  + .gdlr-core-gdpr-form-checkbox-appearance
  .gdlr-core-gdpr-form-checkbox-button {
  margin-left: 25px;
}

.gdlr-core-gdpr-form-checkbox:checked
  + .gdlr-core-gdpr-form-checkbox-appearance
  + .gdlr-core-gdpr-form-checkbox-text
  .gdlr-core-enable {
  display: inline;
}

.gdlr-core-gdpr-form-checkbox:checked
  + .gdlr-core-gdpr-form-checkbox-appearance
  + .gdlr-core-gdpr-form-checkbox-text
  .gdlr-core-disable {
  display: none;
}

.gdlr-core-gdpr-form-checkbox-required
  + .gdlr-core-gdpr-form-checkbox-appearance
  + .gdlr-core-gdpr-form-checkbox-text
  .gdlr-core-enable-required {
  display: inline;
}

.gdlr-core-gdpr-form-checkbox-required
  + .gdlr-core-gdpr-form-checkbox-appearance
  + .gdlr-core-gdpr-form-checkbox-text
  .gdlr-core-disable {
  display: none;
}

.gdlr-core-pb-privacy-box-content-wrap .gdlr-core-pb-privacy-box-content {
  color: #c4c4c4;
}

.gdlr-core-pb-privacy-box-content-wrap .gdlr-core-pb-privacy-box-content a,
.gdlr-core-pb-privacy-box-content-wrap
  .gdlr-core-pb-privacy-box-content
  a:hover {
  color: #fff;
}

.gdlr-core-pb-privacy-box-content-wrap .gdlr-core-pb-privacy-box-title {
  color: #fff;
}

.gdlr-core-pb-privacy-box-wrap {
  position: relative;
}

.gdlr-core-pb-privacy-box-wrap img {
  display: block;
}

.gdlr-core-pb-privacy-box-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.65;
}

.gdlr-core-pb-privacy-box-content-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gdlr-core-pb-privacy-box-content-table {
  display: table;
  width: 100%;
  height: 100%;
  max-width: 330px;
  margin: 0 auto;
}

.gdlr-core-pb-privacy-box-content-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.gdlr-core-pb-privacy-box-content-wrap .gdlr-core-pb-privacy-box-title {
  font-weight: 600;
}

.gdlr-core-pb-privacy-box-wrap.gdlr-core-type-pb-item
  .gdlr-core-pb-privacy-box-title {
  font-size: 17px;
}

.gdlr-core-pb-privacy-box-wrap.gdlr-core-type-pb-item
  .gdlr-core-pb-privacy-box-content {
  font-size: 14px;
}

.gdlr-core-pb-privacy-box-wrap.gdlr-core-type-pb-wrapper {
  height: 600px;
  background-size: cover;
  /* background-image: url(../../../../../../../../../../plugins/goodlayers-core/include/images/content-blocked.jpg); */
}

.gdlr-core-pb-privacy-box-wrap.gdlr-core-type-pb-wrapper
  .gdlr-core-pb-privacy-box-title {
  font-size: 18px;
}

.gdlr-core-pb-privacy-box-wrap.gdlr-core-type-pb-wrapper
  .gdlr-core-pb-privacy-box-content {
  font-size: 15px;
}

.gdlr-core-pb-privacy-box-wrap.gdlr-core-type-pb-column {
  min-height: 600px;
  background-size: cover;
  /* background-image: url(../../../../../../../../../../plugins/goodlayers-core/include/images/content-blocked.jpg); */
}

.gdlr-core-pb-privacy-box-wrap.gdlr-core-type-pb-column
  .gdlr-core-pb-privacy-box-title {
  font-size: 14px;
}

.gdlr-core-pb-privacy-box-wrap.gdlr-core-type-pb-column
  .gdlr-core-pb-privacy-box-content {
  font-size: 13px;
}

@media only screen and (max-width: 767px) {
  .gdlr-core-pb-privacy-box-wrap.gdlr-core-type-pb-wrapper {
    height: 400px;
  }

  .gdlr-core-gdpr-form-left {
    padding: 30px;
    margin-bottom: 0;
    border-right: 0;
    float: none;
    text-align: center;
  }

  .gdlr-core-gdpr-form-left .gdlr-core-gdlr-form-nav {
    margin: 0 auto;
  }

  .gdlr-core-gdpr-form-right {
    padding: 0 30px;
    text-align: center;
  }
}

.gdlr-core-widget-list-shortcode {
  background-color: #f5f5f5;
  color: #949494;
  border-style: solid;
}

.gdlr-core-widget-list-shortcode ul {
  list-style: none;
  margin-left: 0;
}

.gdlr-core-widget-list-shortcode ul li {
  border-top-width: 1px;
  border-top-style: solid;
  padding: 13px 27px;
  border-color: #e1e1e1;
}

.gdlr-core-widget-list-shortcode ul li:first-child {
  border-top: none;
}

.gdlr-core-widget-list-shortcode .gdlr-core-widget-list-shortcode-title {
  font-size: 14px;
  font-weight: 600;
  padding: 35px 27px 0;
  margin-bottom: 13px;
  color: #252525;
}

.gdlr-core-widget-box-shortcode {
  font-size: 13px;
  padding: 40px 35px 10px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.gdlr-core-widget-box-shortcode .gdlr-core-widget-box-shortcode-title {
  font-size: 21px;
}

.gdlr-core-widget-box-shortcode .gdlr-core-widget-box-shortcode-icon {
  font-size: 21px;
  color: #4d96e9;
  margin-right: 20px;
  float: left;
}

.gdlr-core-widget-box-shortcode .gdlr-core-widget-box-shortcode-content {
  overflow: hidden;
}

.gdlr-core-widget-box-shortcode .gdlr-core-widget-box-shortcode-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gdlr-core-revolution-slider-item {
  z-index: 0;
}

.wpcf7-form-control-wrap {
  display: block;
}

.wpcf7-form-control-wrap .wpcf7-form-indicator {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.gdlr-core-center-align .wpcf7-spinner {
  display: block;
  margin: 10px auto;
}

.gdlr-core-page-builder-body .wpgmp_map_parent {
  margin-bottom: 0;
  display: block;
}

.gdlr-core-round-checkbox .wpcf7-list-item-label {
  background: #686868;
}

.gdlr-core-round-checkbox
  label
  input[type="checkbox"]:checked
  + .wpcf7-list-item-label:before {
  background: #fff;
}

.gdlr-core-round-checkbox .wpcf7-list-item-label {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.gdlr-core-round-checkbox .wpcf7-list-item-label:before {
  width: 12px;
  height: 12px;
  content: " ";
  display: inline-block;
  margin: 4px;
  vertical-align: top;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  transition: background 150ms;
  -webkit-transition: background 150ms;
  -moz-transition: background 150ms;
}

.gdlr-core-round-checkbox label input[type="checkbox"] {
  display: none;
}

span[class^="wpcf7-form-control-wrap checkbox"] {
  display: inline-block;
  vertical-align: middle;
}

span[class^="wpcf7-form-control-wrap checkbox"]
  .wpcf7-form-control
  .wpcf7-list-item {
  margin: 0 20px 0 0;
}

.lg-outer {
  direction: ltr;
}

.gdlr-core-product-item .gdlr-core-now-loading {
  position: absolute;
  /* background: url(../../../../../../../../../../plugins/goodlayers-core/include/images/portfolio/now-loading.gif)
    center no-repeat; */
  width: 55px;
  height: 55px;
  left: 50%;
  margin-left: -28px;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.gdlr-core-product-item .gdlr-core-filterer-wrap.gdlr-core-style-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 25px;
  font-weight: 500;
}

.gdlr-core-product-item .gdlr-core-filterer-wrap.gdlr-core-style-box a {
  min-width: 110px;
  text-align: center;
  background: #000;
  padding: 35px 58px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}

.gdlr-core-product-item
  .gdlr-core-filterer-wrap.gdlr-core-style-box
  a
  .gdlr-core-icon {
  display: block;
  font-size: 50px;
  margin-bottom: 16px;
  line-height: 32px;
}

.gdlr-core-product-item
  .gdlr-core-filterer-wrap.gdlr-core-style-box.gdlr-core-center-align {
  justify-content: center;
}

.gdlr-core-product-item
  .gdlr-core-filterer-wrap.gdlr-core-style-box.gdlr-core-right-align {
  justify-content: flex-end;
}

.gdlr-core-product-item .gdlr-core-filterer-wrap a {
  color: #233439;
}

.gdlr-core-product-item .gdlr-core-filterer-wrap a.gdlr-core-active,
.gdlr-core-product-item .gdlr-core-filterer-wrap a:hover {
  color: #fff;
}

.gdlr-core-product-item .gdlr-core-filterer-wrap.gdlr-core-style-box a {
  background: #f5f5f5;
}

.gdlr-core-product-item
  .gdlr-core-filterer-wrap.gdlr-core-style-box
  a.gdlr-core-active,
.gdlr-core-product-item .gdlr-core-filterer-wrap.gdlr-core-style-box a:hover {
  background: #233439;
}

html .woocommerce ul.product_list_widget li .star-rating {
  margin-top: 5px;
}

.gdlr-core-filterer-wrap.gdlr-core-style-dropdown {
  text-align: right;
}

.gdlr-core-filterer-wrap.gdlr-core-style-dropdown
  .gdlr-core-filterer-dropdown-content {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}

.gdlr-core-filterer-wrap.gdlr-core-style-dropdown
  .gdlr-core-filterer-dropdown-text {
  background: #2d3958;
  color: #fff;
  padding: 15px 65px 15px 35px;
  min-width: 250px;
  text-align: left;
  cursor: pointer;
}

.gdlr-core-filterer-wrap.gdlr-core-style-dropdown .gdlr-core-filterer-dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  padding: 20px 32px;
  min-width: 250px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: height 300ms, opacity 300ms;
  opacity: 0;
  display: flex;
  flex-direction: column;
  z-index: -1;
  text-align: left;
}

.gdlr-core-filterer-wrap.gdlr-core-style-dropdown
  .gdlr-core-filterer-dropdown
  a {
  font-size: 14px;
  margin-bottom: 10px;
  color: #2d3958;
}

.gdlr-core-filterer-wrap.gdlr-core-style-dropdown
  .gdlr-core-filterer-dropdown
  a.gdlr-core-active,
.gdlr-core-filterer-wrap.gdlr-core-style-dropdown
  .gdlr-core-filterer-dropdown
  a:hover {
  color: #ac7d4b;
}

.gdlr-core-filterer-wrap.gdlr-core-style-dropdown
  .gdlr-core-filterer-dropdown
  a:last-child {
  margin-bottom: 0;
}

.gdlr-core-filterer-wrap.gdlr-core-style-dropdown
  .gdlr-core-filterer-dropdown-text:after {
  content: "";
  border-width: 1px 1px 0 0;
  border-style: solid;
  width: 8px;
  height: 8px;
  position: absolute;
  right: 30px;
  top: 20px;
  transform: rotate(135deg);
}

.gdlr-core-filterer-wrap.gdlr-core-style-dropdown
  .gdlr-core-filterer-dropdown-content:hover
  .gdlr-core-filterer-dropdown {
  opacity: 1;
  z-index: 10;
}

.gdlr-core-filterer-wrap.gdlr-core-style-dropdown
  .gdlr-core-filterer-dropdown-content:hover
  .gdlr-core-filterer-dropdown-text:after {
  transform: rotate(-45deg);
  top: 24px;
}

.gdlr-core-contact-form-7-item
  .gdlr-core-input-wrap
  [class^="gdlr-core-column-"] {
  margin-bottom: 0;
}
