.fixed-header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
}

.gdlr-core-pbf-wrapper.solution-section {
  margin-bottom: 0px;
  padding: 130px 0px 0px 0px;
  z-index: 9;
}

.gdlr-core-js.solution-background {
  background-image: url("/public/assets/solution-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.gdlr-core-title-item-caption.gdlr-core-info-font.gdlr-core-skin-caption.solution {
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #ffffff;
}

.gdlr-core-title-item-left-dot.solution {
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  margin-right: 13px;
}

.gdlr-core-title-item-title.gdlr-core-skin-title.solution {
  font-size: 61px;
  font-weight: 600;
  letter-spacing: 0px;
  text-transform: none;
  color: #ffffff;
}

.gdlr-core-pbf-column-content-margin.gdlr-core-js.gdlr-core-move-up-with-shadow.solution {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 30px 35px;
  margin: 0px 20px 20px;
  padding: 50px 15px;
  height: 400px;
}

.gdlr-core-pbf-background-wrap.solution.item {
  background-color: white;
}

.second-banner-section {
  padding: 100px 0px 100px 0px;
}

.second-banner-section .second-banner-bg {
  background-image: url("/public/assets/home-second-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.second-banner-section
  .gdlr-core-divider-item.gdlr-core-divider-item-normal.gdlr-core-item-pdlr.gdlr-core-center-align.gdlr-core-style-vertical {
  margin-bottom: 45px;
}

.second-banner-section .gdlr-core-divider-linegdlr-core-skin-divider {
  border-color: #d32525;
  border-width: 2px;
  height: 80px;
}

.second-banner-section
  .gdlr-core-title-item.gdlr-core-item-pdb.clearfix.gdlr-core-center-align.gdlr-core-title-item-caption-top.gdlr-core-item-pdlr {
  padding-bottom: 25px;
}

.second-banner-section .gdlr-core-title-item-title.gdlr-core-skin-title {
  font-size: 47px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #000000;
}

.second-banner-section .gdlr-core-pbf-column-content.clearfix.gdlr-core-js {
  max-width: 1000px;
}

.second-banner-section .gdlr-core-text-box-item-content {
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  color: #6c6c6c;
}

.why-choose-section {
  padding: 100px 0px 20px 0px;
}

.why-choose-section .gdlr-core-pbf-background.gdlr-core-parallax.gdlr-core-js {
  background-image: url("/public/assets/why-choose-bg.png");
  background-repeat: no-repeat;
  background-position: top center;
}

.why-choose-section .gdlr-core-pbf-column-content-margin.gdlr-core-js {
  padding: 0px 20px 45px 0px;
}

.why-choose-section
  .gdlr-core-image-item-wrap.gdlr-core-media-image.gdlr-core-image-item-style-rectangle {
  border-width: 0px;
}

.why-choose-section
  .gdlr-core-title-item-caption.gdlr-core-info-font.gdlr-core-skin-caption {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #d32525;
}
.why-choose-section .gdlr-core-title-item-left-dot {
  width: 5px;
  height: 5px;
  background-color: #d32525;
  margin-right: 13px;
}

.why-choose-section .gdlr-core-title-item-title.gdlr-core-skin-title {
  font-size: 59px;
  font-weight: 700;
  text-transform: none;
  color: #090909;
}

.why-choose-section .gdlr-core-title-item-title.gdlr-core-skin-title {
  font-size: 42px;
  font-weight: 700;
  text-transform: none;
  color: #090909;
}

.count-section {
  padding: 100px 0px 100px 0px;
}

.count-section .gdlr-core-pbf-background.gdlr-core-parallax.gdlr-core-js {
  background-image: url("/public/assets/home-statics-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.count-section
  .gdlr-core-title-item-caption.gdlr-core-info-font.gdlr-core-skin-caption {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #a5a5a5;
  margin-bottom: 0px;
}

.count-section
  .gdlr-core-counter-item-number.gdlr-core-skin-title.gdlr-core-title-font {
  font-size: 57px;
  font-weight: 600;
  margin-bottom: 0px;
}

.count-section
  .gdlr-core-title-item-caption.gdlr-core-info-font.gdlr-core-skin-caption.title {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #d32525;
  margin-bottom: 0px;
}

.count-section .gdlr-core-divider-line.gdlr-core-skin-divider {
  border-color: #d32525;
  border-width: 3px;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.count-section .gdlr-core-counter-item-count.gdlr-core-js,
.count-section .gdlr-core-counter-item-suffix {
  color: white;
}

.customer-section {
  padding: 50px 0px 50px 0px;
}

.customer-section .gdlr-core-pbf-background.gdlr-core-parallax.gdlr-core-js {
  background-image: url("/public/assets/home-customer-bg.jpg");
  background-repeat: no-repeat;
  background-position: top center;
}

.customer-section .gdlr-core-testimonial-frame-border {
  border-right-width: 3px;
  border-color: #d32525;
}

.customer-section
  .gdlr-core-testimonial-content.gdlr-core-info-font.gdlr-core-skin-content {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  color: #424242;
  padding-bottom: 10px;
}

.customer-section
  .gdlr-core-testimonial-title.gdlr-core-title-font.gdlr-core-skin-title {
  color: #d32525;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.customer-section
  .gdlr-core-testimonial-position.gdlr-core-info-font.gdlr-core-skin-caption {
  color: #848484;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.customer-section
  .gdlr-core-title-item-caption.gdlr-core-info-font.gdlr-core-skin-caption {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #d32525;
  margin-bottom: 0px;
}

.customer-section .gdlr-core-divider-line.gdlr-core-skin-divider {
  border-color: #d32525;
  border-width: 2px;
  height: 80px;
}

.customer-section .gdlr-core-title-item-left-dot {
  width: 5px;
  height: 5px;
  background-color: #d32525;
  margin-right: 13px;
}

.customer-section .gdlr-core-title-item-title.gdlr-core-skin-title {
  font-size: 61px;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: none;
  color: #090909;
}

.collapse-item,
.Collapsible {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 15px;
}

.customer-section-slides {
  margin-left: -35px;
  margin-right: 25px;
}

.Collapsible__trigger {
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0px;
  text-transform: none;
}

.about-us-banner {
  background-image: url("/public/assets/about-us-banner.jpg");
}

.big-name-customer {
  padding: 115px 0px 95px 0px;
}

.big-name-customer .gdlr-core-pbf-background.gdlr-core-parallax.gdlr-core-js {
  background-image: url("/public/assets/big-name-customer-bg.png");
  background-repeat: no-repeat;
  background-position: top center;
}

.big-name-customer
  .gdlr-core-title-item-caption.gdlr-core-info-font.gdlr-core-skin-caption {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #d32525;
}

.big-name-customer .gdlr-core-title-item-caption-prefix {
  border-color: #d32525;
  height: 13px;
  margin-right: 14px;
}

.big-name-customer .gdlr-core-title-item-title.gdlr-core-skin-title {
  font-size: 46px;
  letter-spacing: 0px;
  text-transform: none;
  color: #1b1b1b;
}

.big-name-customer .gdlr-core-text-box-item-content {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: none;
  color: #090909;
}

.expertise-page {
  padding: 80px 0px 30px 0px;
}

.expertise-page .gdlr-core-pbf-background.gdlr-core-parallax.gdlr-core-js {
  background-image: url("/public/assets/expertise-bg.png");
  background-repeat: no-repeat;
  background-position: top center;
}

.expertise-page .gdlr-core-title-item-title.gdlr-core-skin-title {
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: none;
  color: #1b1b1b;
}

.expertise-page .gdlr-core-divider-line.gdlr-core-skin-divider {
  border-color: #d32525;
  border-width: 4px;
  border-radius: 3px;
}

.expertise-page .gdlr-core-text-box-item-content {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: none;
  color: #353535;
}

.vision-and-value {
  padding: 0px 0px 30px 0px;
}

.vision-and-value .section-background {
  background-image: url("/public/assets/vision-and-value.png");
  background-repeat: no-repeat;
  background-position: top center;
}

.vision-and-value
  .gdlr-core-pbf-column-content-margin.gdlr-core-js.gdlr-core-column-extend-left {
  margin: 0px;
  padding-top: 150px;
  padding-right: 0px;
  padding-bottom: 150px;
  height: 522.188px;
}
.vision-and-value
  .gdlr-core-pbf-background.gdlr-core-parallax.gdlr-core-js.second-bg {
  background-image: url("/public/assets/vision-and-value-left.jpg");
  background-size: cover;
  background-position: center;
}

.vision-and-value
  .gdlr-core-title-item-caption.gdlr-core-info-font.gdlr-core-skin-caption {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #d32525;
}

.vision-and-value .gdlr-core-title-item-caption-prefix {
  border-color: #d32525;
  height: 13px;
  margin-right: 14px;
}

.vision-and-value .gdlr-core-title-item-title.gdlr-core-skin-title {
  font-size: 47px;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: none;
  color: #1b1b1b;
}

.vision-and-value .gdlr-core-icon-list-content {
  color: #1b1b1b;
  font-size: 19px;
  font-weight: 500;
  text-transform: none;
}

.product-list {
  margin: 100px 0;
}

.product-list .gdlr-core-portfolio-title.gdlr-core-skin-title {
  font-size: 26px;
  font-weight: 700;
  text-transform: none;
}

.gdlr-core-portfolio-medium-feature .gdlr-core-portfolio-content-wrap {
  background-color: #d32525;
}

.product-list .gdlr-core-portfolio-title.gdlr-core-skin-title {
  color: white;
}

.contact-us-banner {
  background-image: url("/public/assets/contact-banner.jpg");
}

.information-section {
  padding: 30px 0px 20px 0px;
  color: white;
}

.information-section .gdlr-core-pbf-background {
  opacity: 0.2;
  background-image: url("/public/assets/contact-info-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.information-section .gdlr-core-title-item-title.gdlr-core-skin-title {
  font-size: 26px;
  letter-spacing: 0px;
  text-transform: none;
}

@keyframes slideIn {
  from {
    display: none;
    transform: translateY(-20px);
  }
  to {
    display: block;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    display: block;
    transform: translateY(0);
  }
  to {
    display: none;
    transform: translateY(-20px);
  }
}

.menu-item-show {
  animation: slideIn 0.3s ease-out forwards;
}

.menu-item-hide {
  animation: slideOut 0.3s ease-out forwards;
}
